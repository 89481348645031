import { call, select, takeLatest } from 'redux-saga/effects'

import { DashBoardAPI } from '@/api/atmeye/dasboard/dashBoardAPI'
import { ActionsDashboard } from '@/constants/atmeye/actions/actionsDashboard'
import { actionsATMEyeDashboard } from '@/store/atmeye/dashboard/actions'
import { getSubPageWidgets, getViewDataIdsToFetch } from '@/store/atmeye/dashboard/selectors'
import { makeRequestWithSpinner, OptionsType } from '@/store/usm/sagaWorkers/saga'
import {
  AddNewSubPageTypes,
  AddNewSubscriptionPageTypes,
  AllSubpagesResponseType,
  CurrentSubPagesTypes,
  DeleteDashboardDataTypes,
  DevicesCountWidget,
  EditDeviceWidget,
  EditEventsWidget,
  EditSubpageWidget,
  EventsCountWidget,
  ItemsWidgetTypes,
  ResponseWidgetDataInfo,
  SubPagesWidgetTypes,
  UpdatePage,
  ViewData,
  WidgetHeaderTypesResponse,
} from '@/types/atmeye/dashboardTypes'
import { ReturnAction } from '@/types/usm/userGroup/userGroup'

function* getHeaderWidgetResponse(): Generator {
  const fetcher = new DashBoardAPI()
  const options: OptionsType<WidgetHeaderTypesResponse, never> = {
    fetcher: fetcher.getWidgetHeader.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingWidgetHeaderData,
    stopFetching: actionsATMEyeDashboard.stopFetchingWidgetHeaderData,
    fill: actionsATMEyeDashboard.fillWidgetHeaderDataSuccess,
    setErrorAction: actionsATMEyeDashboard.fillWidgetHeaderDataError,
  }

  yield makeRequestWithSpinner<WidgetHeaderTypesResponse, never>(options)
}

function* getAllSubpages(): Generator {
  const fetcher = new DashBoardAPI()
  const options: OptionsType<Array<AllSubpagesResponseType>, never> = {
    fetcher: fetcher.getAllSubpages.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingAllSubPages,
    stopFetching: actionsATMEyeDashboard.stopFetchingAllSubPages,
    fill: actionsATMEyeDashboard.fillAllSubPagesSuccess,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
  }

  yield makeRequestWithSpinner<Array<AllSubpagesResponseType>, never>(options)
}
const isSubscription = function(
  payload: AddNewSubPageTypes | AddNewSubscriptionPageTypes,
): payload is AddNewSubscriptionPageTypes {
  // eslint-disable-next-line no-void,@typescript-eslint/consistent-type-assertions
  return (<AddNewSubscriptionPageTypes>payload).isMain !== void 0
}

function* getAddNewSubpage({
  payload,
  actions,
}: ReturnAction<any, AddNewSubPageTypes | AddNewSubscriptionPageTypes>): Generator {
  const fetcher = new DashBoardAPI<AddNewSubPageTypes | AddNewSubscriptionPageTypes>(payload)
  const options: OptionsType<AllSubpagesResponseType, AddNewSubPageTypes> = {
    fetcher: isSubscription(payload)
      ? fetcher.addNewSubpageSubscription.bind(fetcher, payload)
      : fetcher.addNewSubPage.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingAllSubPages,
    stopFetching: actionsATMEyeDashboard.stopFetchingAllSubPages,
    fill: actionsATMEyeDashboard.fillAddNewSubPagesSuccess,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
    formActions: actions,
  }
  yield makeRequestWithSpinner<AllSubpagesResponseType, AddNewSubPageTypes>(options)
}

function* getCreateSubPageClone({ payload, actions }: ReturnAction<any, string>): Generator {
  const fetcher = new DashBoardAPI<string>(payload)
  const options: OptionsType<AllSubpagesResponseType, string> = {
    fetcher: fetcher.createSubPageClone.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingAllSubPages,
    stopFetching: actionsATMEyeDashboard.stopFetchingAllSubPages,
    fill: actionsATMEyeDashboard.fillCreateSubPagesCloneSuccess,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
    formActions: actions,
  }
  yield makeRequestWithSpinner<AllSubpagesResponseType, string>(options)
}

function* getDeleteDataDashboard({
  payload,
  fetcher,
}: DeleteDashboardDataTypes<number>): Generator {
  const options: OptionsType<number, number> = {
    fetcher,
    startFetching: actionsATMEyeDashboard.startFetchingAllSubPages,
    stopFetching: actionsATMEyeDashboard.stopFetchingAllSubPages,
    fill: actionsATMEyeDashboard.fillDeleteSubPagesSuccess,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
    // formActions: actions,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<number, number>(options)
}

function* getDeleteSubPage({ payload }: ReturnAction<never, number>): Generator {
  const fetcher = new DashBoardAPI<number>(payload)
  yield call(getDeleteDataDashboard, { fetcher: fetcher.deleteSubPage.bind(fetcher), payload })
}

function* getUpdateSubPage({
  payload: { pageId, ...body },
  actions,
}: ReturnAction<string, AddNewSubPageTypes & UpdatePage>): Generator {
  const fetcher = new DashBoardAPI<AddNewSubPageTypes>(body, pageId)
  const options: OptionsType<AllSubpagesResponseType, string> = {
    fetcher: fetcher.updateSubPage.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingAllSubPages,
    stopFetching: actionsATMEyeDashboard.stopFetchingAllSubPages,
    fill: actionsATMEyeDashboard.fillUpdateSubPagesSuccess,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
    formActions: actions,
    // fetcherParam: pageId,
  }

  yield makeRequestWithSpinner<AllSubpagesResponseType, string>(options)
}

function* getUpdateMySubPage({
  payload: { pageId, ...body },
  actions,
}: ReturnAction<string, AddNewSubPageTypes & UpdatePage>): Generator {
  const fetcher = new DashBoardAPI<AddNewSubPageTypes>(body, pageId)
  const options: OptionsType<AllSubpagesResponseType, string> = {
    fetcher: fetcher.updateMySubPage.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingAllSubPages,
    stopFetching: actionsATMEyeDashboard.stopFetchingAllSubPages,
    fill: actionsATMEyeDashboard.fillUpdateMySubPageSuccess,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
    formActions: actions,
  }

  yield makeRequestWithSpinner<AllSubpagesResponseType, string>(options)
}

function* getCurrentPageInfo({ payload }: { payload: string; type: string }): Generator {
  const fetcher = new DashBoardAPI<string>(payload)
  const options: OptionsType<CurrentSubPagesTypes, never> = {
    fetcher: fetcher.getFullSubPagesData.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingFullSubpagesData,
    stopFetching: actionsATMEyeDashboard.stopFetchingFullSubpagesData,
    fill: actionsATMEyeDashboard.fillFullSubPagesDataSuccess,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
  }

  yield makeRequestWithSpinner<CurrentSubPagesTypes, never>(options)
}

function* getWidgetEventFilterData(payload: string): Generator {
  const fetcher = new DashBoardAPI<string>(payload)
  const options: OptionsType<ResponseWidgetDataInfo, never> = {
    fetcher: fetcher.getWidgetEventInfo.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingWidgetFilterData,
    stopFetching: actionsATMEyeDashboard.stopFetchingWidgetFilterData,
    fill: actionsATMEyeDashboard.fillAllSuccessWidgetFilterData,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
  }

  yield makeRequestWithSpinner<ResponseWidgetDataInfo, never>(options)
}

function* getCountDataWidgetViewData({
  widgetIds,
  isWithLoader,
}: {
  widgetIds: Array<number>
  isWithLoader?: boolean
}): Generator {
  const queryString = widgetIds.reduce((acc, curr) => {
    return acc + '&widgetsIds=' + curr
  }, '')
  const fetcher = new DashBoardAPI<string>(queryString)
  const options: OptionsType<ViewData, string> = {
    fetcher: fetcher.getCountViewData.bind(fetcher),
    // TODO will be fixed in ATIQ2-1427
    ...(isWithLoader && {
      startFetching: actionsATMEyeDashboard.startFetchingViewData.bind(undefined, widgetIds),
    }),
    stopFetching: actionsATMEyeDashboard.stopFetchingViewData,
    fill: actionsATMEyeDashboard.fillAllSuccessViewData,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
    fetcherParam: queryString,
  }

  yield makeRequestWithSpinner<ViewData, string>(options)
}

function* getViewData(isWithLoader?: boolean): Generator {
  const data = (yield select(getSubPageWidgets)) as Array<ItemsWidgetTypes>
  const widgetIds = data.map(({ widgetId }) => widgetId)
  if (widgetIds.length) {
    yield call(getCountDataWidgetViewData, { widgetIds, isWithLoader })
  }
}

function* getViewDataById(isWithLoader?: boolean): Generator {
  const data = (yield select(getViewDataIdsToFetch)) as number[]

  if (data.length) {
    yield call(getCountDataWidgetViewData, { widgetIds: data, isWithLoader })
  }
}

function* getWidgetDeviceFilterData(payload: string): Generator {
  const fetcher = new DashBoardAPI<string>(payload)
  const options: OptionsType<ResponseWidgetDataInfo, never> = {
    fetcher: fetcher.getWidgetDeviceInfo.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingWidgetFilterData,
    stopFetching: actionsATMEyeDashboard.stopFetchingWidgetFilterData,
    fill: actionsATMEyeDashboard.fillAllSuccessWidgetFilterData,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
  }

  yield makeRequestWithSpinner<ResponseWidgetDataInfo, never>(options)
}

function* getWidgetFilterDataById({
  payload,
}: ReturnAction<
  string,
  { widgetId: string; widgetType: 'EVENTS_COUNT' | 'DEVICES_COUNT' }
>): Generator {
  if (payload.widgetType === 'EVENTS_COUNT') {
    yield call(getWidgetEventFilterData, payload.widgetId)
  } else {
    yield call(getWidgetDeviceFilterData, payload.widgetId)
  }
}

function* getUnSubScribeSubPage({ payload }: ReturnAction<never, number>): Generator {
  const fetcher = new DashBoardAPI<number>(payload)
  yield call(getDeleteDataDashboard, { fetcher: fetcher.unSubscribeSubPage.bind(fetcher), payload })
}

function* deleteWidgetDashboard({ payload }: ReturnAction<never, number>): Generator {
  const fetcher = new DashBoardAPI<string>(String(payload))
  const options: OptionsType<number, number> = {
    fetcher: fetcher.deleteWidgetId.bind(fetcher),
    startFetching: actionsATMEyeDashboard.startFetchingDeleteDashboardWidget,
    stopFetching: actionsATMEyeDashboard.stopFetchingDeleteDashboardWidget,
    fill: actionsATMEyeDashboard.deleteDashboardWidget.bind(undefined, payload),
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
  }

  yield makeRequestWithSpinner<number, number>(options)
}

function* createCountWidget({
  payload,
  fetcher,
}: DeleteDashboardDataTypes<DevicesCountWidget | EventsCountWidget>): Generator {
  const options: OptionsType<ItemsWidgetTypes, DevicesCountWidget | EventsCountWidget> = {
    fetcher,
    startFetching: actionsATMEyeDashboard.startFetchingWidgetFilterData,
    stopFetching: actionsATMEyeDashboard.stopFetchingWidgetFilterData,
    fill: (payload: ItemsWidgetTypes) => {
      return actionsATMEyeDashboard.fillAllSuccessCreateFilterData(payload)
    },
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<ItemsWidgetTypes, DevicesCountWidget | EventsCountWidget>(options)
}

function* editCountWidget({
  payload,
  fetcher,
  widgetId,
}: DeleteDashboardDataTypes<DevicesCountWidget | EventsCountWidget> & {
  widgetId: number
}): Generator {
  const options: OptionsType<EditSubpageWidget, DevicesCountWidget | EventsCountWidget> = {
    fetcher,
    startFetching: actionsATMEyeDashboard.startFetchingWidgetFilterData,
    stopFetching: actionsATMEyeDashboard.stopFetchingWidgetFilterData,
    fill: actionsATMEyeDashboard.fillAllSuccessEditFilterData.bind(undefined, {
      widgetId: widgetId,
      widgetTitle: payload.widgetTitle,
    }),
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<EditSubpageWidget, DevicesCountWidget | EventsCountWidget>(options)
}

function* getEventsCountWidget({ payload }: ReturnAction<never, EventsCountWidget>): Generator {
  const fetcher = new DashBoardAPI<EventsCountWidget>(payload)
  yield call(createCountWidget, {
    fetcher: fetcher.createEventsCountWidget.bind(fetcher),
    payload,
  })
  yield call(getViewDataById)
}

function* getDevicesCountWidget({ payload }: ReturnAction<string, DevicesCountWidget>): Generator {
  const fetcher = new DashBoardAPI<DevicesCountWidget>(payload)
  yield call(createCountWidget, {
    fetcher: fetcher.createDevicesCountWidget.bind(fetcher),
    payload,
  })
  yield call(getViewDataById)
}

function* editEventsCountWidget({
  payload: { data, widgetId },
}: ReturnAction<never, EditEventsWidget>): Generator {
  const fetcher = new DashBoardAPI<EventsCountWidget>(data)
  yield call(editCountWidget, {
    fetcher: fetcher.editEventsCountWidget.bind(fetcher, widgetId),
    payload: data,
    widgetId,
  })
  yield call(getViewDataById)
}

function* editDevicesCountWidget({
  payload: { widgetId, data },
}: ReturnAction<string, EditDeviceWidget>): Generator {
  const fetcher = new DashBoardAPI<DevicesCountWidget>(data)
  yield call(editCountWidget, {
    fetcher: fetcher.editDevicesCountWidget.bind(fetcher, widgetId),
    payload: data,
    widgetId,
  })
  yield call(getViewDataById)
}

function* getCurrentPageWidgets(payload: string): Generator {
  const fetcher = new DashBoardAPI<string>(payload)
  const options: OptionsType<SubPagesWidgetTypes, never> = {
    fetcher: fetcher.getSubPagesWidgets.bind(fetcher),
    stopFetching: actionsATMEyeDashboard.stopFetchingPageWidgets,
    fill: actionsATMEyeDashboard.fillFullSubPagesWidgetSuccess,
    setErrorAction: actionsATMEyeDashboard.fillAllSubPagesError,
  }

  yield makeRequestWithSpinner<SubPagesWidgetTypes, never>(options)
}

function* getSubPagesData({
  payload: { subpageId, isWithLoader },
}: ReturnAction<{ subpageId: string; isWithLoader: boolean }, never>): Generator {
  yield call(getCurrentPageInfo, { payload: subpageId, type: '' })
  yield call(getCurrentPageWidgets, subpageId)
  yield call(getViewData, isWithLoader)
}

export default function*(): Generator {
  yield takeLatest(ActionsDashboard.GetHeaderWidgetDataAsync, getHeaderWidgetResponse)
  yield takeLatest(ActionsDashboard.GetAllSubpagesAsync, getAllSubpages)
  yield takeLatest(ActionsDashboard.GetAddNewSubpagesAsync, getAddNewSubpage)
  yield takeLatest(ActionsDashboard.GetDeleteSubpagesAsync, getDeleteSubPage)
  yield takeLatest(ActionsDashboard.GetEditSubpagesAsync, getUpdateSubPage)
  yield takeLatest(ActionsDashboard.GetEditMySubpageAsync, getUpdateMySubPage)
  yield takeLatest(ActionsDashboard.GetCreateSubPageCloneAsync, getCreateSubPageClone)
  yield takeLatest(ActionsDashboard.GetCurrentSubPageDataAsync, getSubPagesData)
  yield takeLatest(ActionsDashboard.GetWidgetFilterDataAsync, getWidgetFilterDataById)
  yield takeLatest(ActionsDashboard.GetUnSubScribeSubPageAsync, getUnSubScribeSubPage)
  yield takeLatest(ActionsDashboard.GetDeleteDashboardWidget, deleteWidgetDashboard)
  yield takeLatest(ActionsDashboard.getDevicesCountWidget, getDevicesCountWidget)
  yield takeLatest(ActionsDashboard.getEventsCountWidget, getEventsCountWidget)
  yield takeLatest(ActionsDashboard.editDevicesCountWidget, editDevicesCountWidget)
  yield takeLatest(ActionsDashboard.editEventsCountWidget, editEventsCountWidget)
  yield takeLatest(ActionsDashboard.GetFullSubPagesDataAsync, getCurrentPageInfo)
}
