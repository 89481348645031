import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { CMAuthApi } from '@/api/cm/authApi/index'
import { CMCommonApi } from '@/api/cm/commonApi'
import { ComVerboseError } from '@/api/cm/swaggerGeneratedApi'
import {
  AppNavigationRoutes,
  CASH_MANAGEMENT_BLOCK_PREFIX,
  CMActions,
  cmBaseUrl,
  DEFAULT_CULTURES,
  emptyUserSettingsConfig,
  INITIAL_LOCALE,
} from '@/constants'
import { LocalStorageItems } from '@/constants/localStorageItems'
import {
  clearLocalStorage,
  getParsedLocalStorageItem,
  setDataToLocalStorage,
} from '@/helpers/localStorage'
import { history } from '@/Router'
import { signInResponseFail, stopIsInitializationAuthProcess } from '@/store/auth'
import { PopUpService } from '@/utils/services/popUpService'

import { setComCMError } from '../common'
import {
  changePasswordResponse,
  changePasswordResponseFail,
  setChangePasswordModalOpen,
  setUserCM,
  signInResponseCM,
  signInResponseFailCM,
  signOutCM,
} from './actions'

const setLocalStorageWithBaseUrl = setDataToLocalStorage(cmBaseUrl)

function* signIn({ payload: { username, password } }: AnyAction): SagaIterator {
  try {
    const culture = sessionStorage.getItem(LocalStorageItems.Locale) || INITIAL_LOCALE
    const response = yield call(CMAuthApi.signIn, { username, password, culture })
    const {
      data: {
        token,
        userDispName: userDisplayName,
        permissions,
        modules,
        login: userName,
        uid: userId,
        isManager,
        isSortersEnabled,
      },
    } = response

    const payload = {
      token,
      userDisplayName,
      permissions,
      modules,
      userName,
      userId,
      isManager,
      isSortersEnabled,
    }

    if (response && response.status === 200) {
      yield put(setUserCM(payload))
    }
  } catch (error) {
    const parsedError: ComVerboseError = typeof error === 'string' ? JSON.parse(error) : {}

    yield put(signInResponseFailCM(parsedError?.message || error.message))
    yield put(signInResponseFail(parsedError?.message || error.message))
  }
}

function* setUser({ payload }: AnyAction): SagaIterator {
  try {
    const {
      token,
      userDisplayName,
      permissions,
      modules,
      userName,
      userId,
      isManager,
      isSortersEnabled,
    } = payload

    const initialUserSettings = {
      [userName]: emptyUserSettingsConfig,
    }

    const allUsersSettings =
      getParsedLocalStorageItem(cmBaseUrl)(LocalStorageItems.UsersSettings) || {}
    const newUserSettings = !allUsersSettings[userName] ? initialUserSettings : {}

    const userData = {
      id: userId,
      displayName: userDisplayName,
      login: userName,
      isManager: isManager || false,
      isSortersEnabled: isSortersEnabled || false,
    }
    const userSettings = { ...allUsersSettings, ...newUserSettings }

    localStorage.setItem(LocalStorageItems.AccessTokenCM, token)

    setLocalStorageWithBaseUrl(LocalStorageItems.UserModules, JSON.stringify(modules))
    setLocalStorageWithBaseUrl(LocalStorageItems.UserPermissions, JSON.stringify(permissions))
    setLocalStorageWithBaseUrl(LocalStorageItems.UserData, JSON.stringify(userData))
    setLocalStorageWithBaseUrl(LocalStorageItems.UsersSettings, JSON.stringify(userSettings))

    history.push(CASH_MANAGEMENT_BLOCK_PREFIX)
    yield put(signInResponseCM(userDisplayName))
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error.message)

    yield put(signInResponseFailCM(parsedError.message || error.message))
    yield put(signInResponseFail(parsedError.message || error.message))
  } finally {
    yield put(stopIsInitializationAuthProcess())
  }
}

function* getCultures(): SagaIterator {
  try {
    const cultures = yield call(CMCommonApi.getCultures)
    sessionStorage.setItem(LocalStorageItems.AllLocales, JSON.stringify(cultures))
  } catch (error) {
    sessionStorage.setItem(LocalStorageItems.AllLocales, JSON.stringify(DEFAULT_CULTURES))
  }
}

function* handleSetServerCulture({ culture }: AnyAction): SagaIterator {
  try {
    yield call(CMAuthApi.setCulture, { cultureId: culture })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* handleChangePassword({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(CMAuthApi.changePassword, payload)
    yield put(changePasswordResponse(response))
    yield put(setChangePasswordModalOpen(false))
    PopUpService.showGlobalPopUp('translate#cm.ChangePasswordSuccess', 'success')
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error.message)

    yield put(changePasswordResponseFail(parsedError.message || error.message))
  }
}

function* checkIsLogin(): SagaIterator {
  try {
    yield call(CMAuthApi.checkIsLogin)
  } catch (error) {
    yield put(signOutCM())
  }
}

function* signOut({ payload }: AnyAction): SagaIterator {
  if (payload) {
    yield call(CMAuthApi.logout)
  }
  clearLocalStorage()

  history.push(AppNavigationRoutes.SignInPage)
}

export default function*(): Generator {
  yield takeLatest(CMActions.SetUserCM, setUser)
  yield takeLatest(CMActions.SignInRequest, signIn)
  yield takeLatest(CMActions.GetCultures, getCultures)
  yield takeLatest(CMActions.SetServerCulture, handleSetServerCulture)
  yield takeLatest(CMActions.SignOut, signOut)
  yield takeLatest(CMActions.CheckIsLogin, checkIsLogin)
  yield takeLatest(CMActions.ChangePasswordRequest, handleChangePassword)
}
