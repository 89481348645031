import React, { FC, PropsWithChildren, ReactElement, ReactNode } from 'react'

import Loader from '@/components/blocks/Loader'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'

import { usePermission } from './usePermission'

type Props = {
  permissionKey?: Permission
  fallback?: JSX.Element | string
  loadingComponent?: JSX.Element | string
  children: PropsWithChildren<ReactNode>
}

const Restricted: FC<Props> = ({
  permissionKey,
  fallback,
  loadingComponent,
  children,
}: Props): ReactElement => {
  const { loading, allowed } = usePermission(permissionKey)
  if (loading) {
    return <>{loadingComponent || <Loader />}</>
  }

  if (allowed) {
    return <>{children}</>
  }

  return <>{fallback}</>
}

export { Restricted }
