/* eslint-disable @typescript-eslint/camelcase */
import queryString from 'query-string'

import {
  BuildInfo,
  ComExtUser,
  ComUuidValue,
  ComVaultReport,
  ComVaultsFilter,
  Culture,
  Currency,
  eUserRole,
  eVL2VaultType,
  FilterTreeNode,
  MonRemainderUpdateAvailabilityEntry,
  SelectorItem,
  UsrChangePasswordRequest,
  VaultBagCassette,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { store } from '@/App'
import { APIConfiguration, LocalStorageItems } from '@/constants'
import { signOutVaultV1 } from '@/store/vault-v1/auth'
import { setActErrorVisible } from '@/store/vault-v1/common'
import { SelectableItem } from '@/types'
import { mapEnumsToOptions } from '@/utils/mapEnumsToOptions'
import { PopUpService } from '@/utils/services/popUpService'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class VaultV1CommonApi {
  public static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getCultures(): Promise<Culture> {
    try {
      return await VaultV1CommonApi.requestService.get('/common/cultures')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFilterNodes(): Promise<FilterTreeNode> {
    try {
      return await VaultV1CommonApi.requestService.get('/common/filternodes')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getIntegrations(): Promise<string[]> {
    try {
      return await VaultV1CommonApi.requestService.get('/common/enabledIntegrations')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async changePassword(payload: UsrChangePasswordRequest): Promise<void> {
    try {
      return await VaultV1CommonApi.requestService.post('/user/changePassword', payload)
    } catch (error) {
      const errorMessage = error.message

      throw errorMessage
    }
  }

  public static async getServerBuildInfo(): Promise<BuildInfo> {
    try {
      return await VaultV1CommonApi.requestService.get('/common/buildInfo')
    } catch (e) {
      PopUpService.showGlobalPopUp(`translate#response.error.cmFailedToGetServerBuildInfo`, 'error')
      throw new Error(e)
    }
  }

  public static async getClientBuildInfo(): Promise<string> {
    try {
      if (process.env.NODE_ENV !== 'production') return 'Development build'

      const response = await fetch(`${process.env.PUBLIC_URL}/buildinfo.txt`)

      if (response?.ok) {
        return await response.text()
      }

      throw new Error()
    } catch (e) {
      PopUpService.showGlobalPopUp(`translate#response.error.cmFailedToGetClientBuildInfo`, 'error')
      throw new Error(e)
    }
  }

  public static async getVaults(props?: ComVaultsFilter): Promise<SelectorItem[]> {
    const { vaultTypes, showAll, userId, sortingType } = props || {}

    try {
      const requestBody: ComVaultsFilter = {
        vaultTypes,
        showAll,
        userId,
        sortingType,
      }

      if (!requestBody.vaultTypes) {
        const response = await VaultV1CommonApi.getEnums('eVL2VaultType')

        requestBody.vaultTypes = response.map(enumItem => enumItem.value) as eVL2VaultType[]
      }

      return await VaultV1CommonApi.requestService.post('/common/vaults', requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCurrencies(): Promise<Currency[]> {
    try {
      return await VaultV1CommonApi.requestService.get('/common/currencies/active')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCompanies(showOnlyUserCompanies?: boolean): Promise<ComUuidValue[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/common/companies',
          query: { showOnlyUserCompanies: showOnlyUserCompanies || false },
        },
        { skipNull: true, encode: false },
      )

      return await VaultV1CommonApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getVaultBalancesStatus(
    ids: string[],
  ): Promise<MonRemainderUpdateAvailabilityEntry[]> {
    try {
      return await VaultV1CommonApi.requestService.post(
        '/monitoring/vaultBalances/isUpdatable',
        ids.map(id => ({
          coid: id,
          cmOrderType: 'None',
        })),
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getEnums(
    name: 'eVL2VaultType' | 'eOrderStatus' | 'eOrderType',
  ): Promise<SelectableItem[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/common/enums',
          query: { name },
        },
        { skipNull: true, encode: false },
      )
      const response = await VaultV1CommonApi.requestService.get(url)
      return mapEnumsToOptions(response)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getUsers(
    userRole: eUserRole,
    filterByCompany = false,
  ): Promise<ComExtUser[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/common/users',
          query: { userRole, filterByCompany },
        },
        { skipNull: true, encode: false },
      )

      return await VaultV1CommonApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getVaultsCashiers(cassetteId: string): Promise<SelectorItem[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/common/vaultsCashierType',
          query: { cassetteId },
        },
        { skipNull: true, encode: false },
      )

      return await VaultV1CommonApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getCashierSubVaults(cashierId: string): Promise<SelectorItem[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/common/cashierSubVaults',
          query: { cashierId },
        },
        { skipNull: true, encode: false },
      )

      return await VaultV1CommonApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getCassetteSets(): Promise<ComUuidValue[]> {
    try {
      return await VaultV1CommonApi.requestService.get('common/cassetteSets')
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getCassetteSetsItems(
    guid: string,
    functionalType: string,
  ): Promise<VaultBagCassette[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'common/cassetteSetItems',
          query: { guid, functionalType },
        },
        { skipNull: true, encode: false },
      )

      return await VaultV1CommonApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getReport(
    payload: ComVaultReport,
    reportFormat = 'PDF',
  ): Promise<Blob | undefined> {
    try {
      const { url, postBody, reportMethod } = payload
      const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenVaultV1) || ''

      const methods = {
        report_get: 'GET',
        report_post: 'POST',
      }

      const fullUrl = `${APIConfiguration.VAULT_V1_API_BASE_URL}${url}?reportFormat=${reportFormat}`

      const response = await fetch(fullUrl, {
        body: postBody,
        headers: { Authorization: `Bearer ${tokenCM}` },
        method: methods[reportMethod] || 'POST',
      })

      if (!response?.ok) {
        response.text().then(text => {
          const parsedError = JSON.parse(JSON.parse(text).message)
          const errorMessage = parsedError.message

          if (errorMessage && parsedError.errorType === 'silent_error') {
            PopUpService.showGlobalPopUp(errorMessage, 'error')
          }

          if (errorMessage && parsedError.errorType === 'ack_error') {
            store.dispatch(setActErrorVisible({ isVisible: true, errorMessage }))
          }
        })
      }

      if (response?.ok) {
        return await response.blob()
      } else if (response.status === 401) {
        store.dispatch(signOutVaultV1())

        PopUpService.showGlobalPopUp(`translate#response.error.unauthorized`, 'error')
      }
    } catch (error) {
      throw Error(String(error as Error))
    }
  }
}
