import { VaultV1Actions } from '@/constants'
import { AnyAction } from 'redux'

export interface State {
  displayName: string | null
  isLoggingIn: boolean
  error: string | null
  changePasswordState: {
    isModalOpen: boolean
    isLoading: boolean
    error: string | null
  }
}

const initialState: State = {
  displayName: '',
  isLoggingIn: false,
  error: null,
  changePasswordState: {
    isModalOpen: false,
    isLoading: false,
    error: null,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.SignInRequest:
      return {
        ...state,
        isLoggingIn: true,
        displayName: '',
        error: null,
      }
    case VaultV1Actions.SignInResponse:
      return {
        ...state,
        displayName: payload,
        isLoggingIn: false,
      }
    case VaultV1Actions.SignInFail:
      return {
        ...state,
        isLoggingIn: false,
        displayName: '',
        error: payload,
      }

    case VaultV1Actions.SetChangePasswordModalOpen:
      return {
        ...state,
        changePasswordState: {
          ...initialState.changePasswordState,
          isModalOpen: payload,
        },
      }
    case VaultV1Actions.ChangePasswordRequest:
      return {
        ...state,
        changePasswordState: {
          ...state.changePasswordState,
          isLoading: true,
          error: null,
        },
      }
    case VaultV1Actions.ChangePasswordResponse:
      return {
        ...state,
        changePasswordState: {
          ...state.changePasswordState,
          isModalOpen: false,
          isLoading: false,
          error: null,
        },
      }
    case VaultV1Actions.ChangePasswordFail:
      return {
        ...state,
        changePasswordState: {
          ...state.changePasswordState,
          isLoading: false,
          error: payload,
        },
      }
    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
