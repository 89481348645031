import { AnyAction } from 'redux'

import { Filter, GetGroupDetailsResponse, Grouping, Sorting } from '@/api/sd/ticketsApi/types'
import { TableFilter } from '@/components/controls/AppTable/types'
import { ListResponseTypes } from '@/components/NPMPakage/components/ObjectsTree/types'
import { AtmeyeAlarmsActions, TicketActions } from '@/constants'
import { WINDOWS_TYPES } from '@/constants/atmeye/tables/windowTypes'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { DEFAULT_TICKET_DETAILS, ticketConfig } from '@/store/sd/tickets/reducer'
import {
  GroupingTemplate,
  Pagination,
  ParentGroup,
  TicketDetailsData,
  TicketsConfigData,
  TypeOfTickets,
} from '@/types'
import { deepCopy } from '@/utils/deepCopy'
import { getAtmeyeSavedGrouping, getLocalStorageItem } from '@/utils/localStorage'

export interface State {
  selectedGroupingTemplate: string
  data: {
    allTickets: ListResponseTypes
    myTickets: ListResponseTypes
    historyTickets: ListResponseTypes
  }
  groupDetails: GetGroupDetailsResponse
  groupDetailsTest: {}

  pageSizeForGroupingTickets: string

  pageCount: number
  filter: {
    allTickets: Filter
    myTickets: Filter
    historyTickets: Filter
  }
  filterTemplateFields: {
    [key: string]: {
      id: string
      displayName: string
    }
  }
  filterFields: {
    allTickets: {
      id: string
      displayName: string
    }
    myTickets: {
      id: string
      displayName: string
    }
    historyTickets: {
      id: string
      displayName: string
    }
  }
  grouping: {
    allTickets: Grouping
    myTickets: Grouping
    historyTickets: Grouping
  }
  ticketDetails: TicketDetailsData
  config: {
    allTickets: TicketsConfigData
    myTickets: TicketsConfigData
    historyTickets: TicketsConfigData
  }
  pagination: Pagination
  sorting: {
    allTickets: Sorting
    myTickets: Sorting
    historyTickets: Sorting
  }
  stringFitler: {
    allTickets: TableFilter | {}
    myTickets: TableFilter | {}
    historyTickets: TableFilter | {}
  }
  typeOfTickets: TypeOfTickets
  rowsPerPage: {
    allTickets: number
    myTickets: number
    historyTickets: number
  }
  page: {
    allTickets: number
    myTickets: number
    historyTickets: number
  }
  media: {
    currentEvent: number | null
    fetchingEnabled: boolean
    data: {
      [key: string]: {
        image: any
        type: string
      }
    }
  }
  parentGroups: ParentGroup[]
  isFetchingTickets: boolean
  isFetchingGroupDetails: boolean
  isSavingSettings: boolean
  isDeletingTicket: boolean
  isFetchingTicket: boolean
  isFetchingGroupTemplates: boolean
  isFetchingFilterTemplates: boolean
  isSavingFilterTemplates: boolean
  isEditingFilterTemplate: boolean
  isSavingGroupTemplate: boolean
  isEditingGroupTemplate: boolean
  isDeletingFilterTemplate: boolean
  isDeletingGroupingTemplate: boolean
  isFetchingTicketsConfig: boolean
  isFetchingTicketDetails: boolean
  downloadFile: string
  isDownloadingFile: boolean
  error: string | null
  isFetchingPagination: boolean
  isOpenFiltersModal: boolean
  isSubmitButtonClicked: boolean
}

const initialTicketConfig = deepCopy(ticketConfig)
const savedGrouping = getAtmeyeSavedGrouping(WINDOWS_TYPES.SECURITY_ALARMS)

const lsConfig = getLocalStorageItem(LocalStorageItems.SecurityAlarms)

export const initialState: State = {
  data: {
    allTickets: {
      details: undefined,
      groups: [],
      hasGroups: false,
      totalCount: 0,
    },
    myTickets: { details: undefined, groups: [], hasGroups: false, totalCount: 0 },
    historyTickets: {
      details: undefined,
      groups: [],
      hasGroups: false,
      totalCount: 0,
    },
  },
  groupDetails: null,
  groupDetailsTest: {},
  pageCount: 0,
  filter: {
    allTickets: {
      predefinedFilters: {
        availableSortingFields: [],
        deviceTypeItems: [],
        receiveDateItems: {
          end: null,
          start: null,
        },
        statusItems: [],
        serviceTypeItems: [],
      },
      selectedTemplate: lsConfig?.config?.selectedFiltersTemplate || '',
      selectedFilters: lsConfig?.config?.selectedFilters || {},
      filterTemplates: [],
    },
    myTickets: {
      predefinedFilters: {
        availableSortingFields: [],
        deviceTypeItems: [],
        receiveDateItems: {
          end: null,
          start: null,
        },
        statusItems: [],
        serviceTypeItems: [],
      },
      selectedTemplate: '',
      selectedFilters: {},
      filterTemplates: [],
    },
    historyTickets: {
      predefinedFilters: {
        availableSortingFields: [],
        deviceTypeItems: [],
        receiveDateItems: {
          end: null,
          start: null,
        },
        statusItems: [],
        serviceTypeItems: [],
      },
      selectedTemplate: '',
      selectedFilters: {},
      filterTemplates: [],
    },
  },
  filterTemplateFields: {},
  filterFields: {
    allTickets: {
      id: '',
      displayName: '',
    },
    myTickets: {
      id: '',
      displayName: '',
    },
    historyTickets: {
      id: '',
      displayName: '',
    },
  },
  selectedGroupingTemplate: savedGrouping?.templateId || '',
  grouping: {
    allTickets: {
      predefinedGroupings: {
        items: [],
      },
      selectedGroupings: savedGrouping?.groups || [],
      groupingsTemplates: [],
    },
    myTickets: {
      predefinedGroupings: {
        items: [],
      },
      selectedGroupings: [],
      groupingsTemplates: [],
    },
    historyTickets: {
      predefinedGroupings: {
        items: [],
      },
      selectedGroupings: [],
      groupingsTemplates: [],
    },
  },
  sorting: {
    allTickets: { atm_date: 'desc' },
    myTickets: { atm_date: 'desc' },
    historyTickets: { atm_date: 'desc' },
  },
  stringFitler: {
    allTickets: {},
    myTickets: {},
    historyTickets: {},
  },
  typeOfTickets: 'allTickets',
  rowsPerPage: {
    allTickets: 50,
    myTickets: 50,
    historyTickets: 50,
  },
  page: {
    allTickets: 1,
    myTickets: 1,
    historyTickets: 1,
  },
  parentGroups: [],
  ticketDetails: deepCopy(DEFAULT_TICKET_DETAILS),
  pagination: {
    pageNumber: 1,
    pageSize: 1,
    totalElements: 1,
    totalPages: 1,
  },
  config: {
    allTickets: initialTicketConfig,
    myTickets: initialTicketConfig,
    historyTickets: initialTicketConfig,
  },
  media: {
    currentEvent: null,
    data: {},
    fetchingEnabled: true,
  },

  pageSizeForGroupingTickets: '',
  downloadFile: '',
  isDownloadingFile: false,
  isFetchingTickets: false,
  isFetchingGroupDetails: false,
  isFetchingTicket: false,
  isSavingSettings: false,
  isFetchingGroupTemplates: false,
  isSavingGroupTemplate: false,
  isEditingGroupTemplate: false,
  isFetchingFilterTemplates: false,
  isSavingFilterTemplates: false,
  isEditingFilterTemplate: false,
  isDeletingTicket: false,
  isDeletingFilterTemplate: false,
  isDeletingGroupingTemplate: false,
  isFetchingTicketsConfig: false,
  isFetchingTicketDetails: false,
  error: null,
  isFetchingPagination: false,
  isOpenFiltersModal: false,
  isSubmitButtonClicked: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  const typeOfTickets = state.typeOfTickets as TypeOfTickets

  switch (type) {
    case AtmeyeAlarmsActions.GetTransactionsRequest:
      return {
        ...state,
        isFetchingTickets: true,
      }

    case AtmeyeAlarmsActions.GetTransactionsResponse:
      return {
        ...state,

        data: {
          ...state.data,

          [typeOfTickets]: {
            ...state.data[typeOfTickets],

            hasGroups: payload.hasGroups ? payload.hasGroups : state.data[typeOfTickets].hasGroups,
            groups: payload.groups ? payload.groups : state.data[typeOfTickets].groups,
            details: payload.details
              ? {
                  ...state.data[typeOfTickets].details,
                  items: payload.details ? payload.details.items : null,
                }
              : state.data[typeOfTickets].details,
            totalCount:
              payload.totalCount != null
                ? payload.totalCount
                : state.data[typeOfTickets] != null && state.data[typeOfTickets].totalCount != null
                ? state.data[typeOfTickets].totalCount
                : null,
          },
        },
        isFetchingTickets: false,
        isOpenFiltersModal: state.isSubmitButtonClicked ? false : state.isOpenFiltersModal,
        error: null,
        isSubmitButtonClicked: false,
      }

    case AtmeyeAlarmsActions.GetTicketResponseFail:
      return {
        ...state,
        error: payload.error,
        isFetchingTickets: false,
        isSubmitButtonClicked: false,
      }

    case AtmeyeAlarmsActions.GetAlarmsExport:
      return {
        ...state,
        isDownloadingFile: true,
      }

    case AtmeyeAlarmsActions.GetExportResponse:
      return {
        ...state,
        downloadFile: payload,
        isDownloadingFile: false,
      }

    case AtmeyeAlarmsActions.GetExportResponseFail:
      return {
        ...state,
        error: payload,
        isDownloadingFile: false,
      }

    case AtmeyeAlarmsActions.SetFilterModalState:
      return {
        ...state,
        isOpenFiltersModal: payload,
      }

    case AtmeyeAlarmsActions.IsSubmitFiltersButtonClicked:
      return {
        ...state,
        isSubmitButtonClicked: true,
      }

    case AtmeyeAlarmsActions.GetAlarmsExportGrouped:
      return {
        ...state,
        isDownloadingFile: true,
      }

    case AtmeyeAlarmsActions.GetExportGroupedResponse:
      return {
        ...state,
        downloadFile: payload,
        isDownloadingFile: false,
      }

    case AtmeyeAlarmsActions.GetExportGroupedResponseFail:
      return {
        ...state,
        error: payload,
        isDownloadingFile: false,
      }

    case AtmeyeAlarmsActions.GetGroupDetailsRequest:
      return {
        ...state,
        isFetchingGroupDetails: true,
      }

    case AtmeyeAlarmsActions.GetGroupDetailsResponse:
      return {
        ...state,
        groupDetails: Array.isArray(state.groupDetails)
          ? [...state.groupDetails, ...payload.details.items]
          : [...payload.details.items],
        isFetchingGroupDetails: false,
      }

    case AtmeyeAlarmsActions.SetGroupDetailsResponse:
      return {
        ...state,
        groupDetailsTest: { ...state.groupDetailsTest, ...payload },
        isFetchingGroupDetails: false,
      }

    case AtmeyeAlarmsActions.SetGroupDetailsResponseEmpty:
      return {
        ...state,
        groupDetailsTest: { ...state.groupDetailsTest, ...payload },
        isFetchingGroupDetails: false,
      }

    case TicketActions.GetGroupDetailsResponseFail:
      return {
        ...state,
        error: payload,
        isFetchingGroupDetails: false,
      }

    case AtmeyeAlarmsActions.SetParentGroups:
      return {
        ...state,
        parentGroups: payload,
      }

    case AtmeyeAlarmsActions.CollectFilterTemplateFields: {
      const updatedFields = {
        ...state.filterTemplateFields,
        [payload.fieldName]: payload.value,
      }
      return {
        ...state,
        filterTemplateFields: { ...updatedFields },
        filterFields: {
          ...state.filterFields,
          [typeOfTickets]: {
            filterFields: { ...updatedFields },
          },
        },
      }
    }

    case AtmeyeAlarmsActions.ChangeSelectedFilters:
      return {
        ...state,

        filter: {
          ...state.filter,

          [typeOfTickets]: {
            ...state.filter[typeOfTickets],

            selectedFilters: payload,
          },
        },
        isFetchingTickets: true,
      }

    case AtmeyeAlarmsActions.SetSdFilterTemplateResponse: {
      return {
        ...state,

        filter: {
          ...state.filter,

          [typeOfTickets]: {
            ...state.filter[typeOfTickets],

            filterTemplates: payload,
          },
        },
      }
    }

    case AtmeyeAlarmsActions.CreateSdFilterTemplateResponse: {
      const updatedFilterTemplatesArray = [...state.filter[typeOfTickets].filterTemplates, payload]

      return {
        ...state,

        filter: {
          ...state.filter,

          [typeOfTickets]: {
            ...state.filter[typeOfTickets],

            filterTemplates: updatedFilterTemplatesArray,
          },
        },
      }
    }

    case AtmeyeAlarmsActions.UpdateSdFilterTemplateResponse: {
      const updatedTemplateArray = state.filter[
        typeOfTickets
      ].filterTemplates.map((template: any) =>
        template.id === payload.id ? { ...template, name: payload.name } : template,
      )

      return {
        ...state,

        filter: {
          ...state.filter,

          [typeOfTickets]: {
            ...state.filter[typeOfTickets],

            filterTemplates: updatedTemplateArray,
          },
        },
      }
    }

    case AtmeyeAlarmsActions.DeleteFilterTemplateResponse: {
      const updatedTemplateArray = state.filter[typeOfTickets].filterTemplates.filter(
        (template: any) => template.id !== payload.id,
      )
      return {
        ...state,

        filter: {
          ...state.filter,

          [typeOfTickets]: {
            ...state.filter[typeOfTickets],

            filterTemplates: updatedTemplateArray,
          },
        },
      }
    }

    case AtmeyeAlarmsActions.GetGroupingTemplatesRequest:
      return {
        ...state,
        isFetchingGroupTemplates: true,
      }

    case TicketActions.GetGroupingTemplatesResponse:
      return {
        ...state,

        grouping: {
          ...state.grouping,

          [typeOfTickets]: {
            ...state.grouping[typeOfTickets],

            groupingsTemplates: payload,
          },
        },
        isFetchingGroupTemplates: false,
      }

    case AtmeyeAlarmsActions.SetGroupTemplates: {
      const { templates, withAppendNewTemplate } = payload
      const arrayWithNewAppendedTemplate = [
        ...(state.grouping[typeOfTickets].groupingsTemplates
          ? state.grouping[typeOfTickets].groupingsTemplates
          : []),
        ...templates,
      ]
      return {
        ...state,

        grouping: {
          ...state.grouping,

          [typeOfTickets]: {
            ...state.grouping[typeOfTickets],

            groupingsTemplates: withAppendNewTemplate ? arrayWithNewAppendedTemplate : templates,
          },
        },
        isFetchingGroupTemplates: false,
      }
    }

    case AtmeyeAlarmsActions.EditSdGroupTemplateFromResponse: {
      const resultArray = state.grouping[
        typeOfTickets
      ].groupingsTemplates.map((tem: GroupingTemplate) => (tem.id !== payload.id ? tem : payload))
      return {
        ...state,

        grouping: {
          ...state.grouping,

          [typeOfTickets]: {
            ...state.grouping[typeOfTickets],

            groupingsTemplates: resultArray,
          },
        },
      }
    }

    case TicketActions.DeleteSdGroupTemplateResponseSuccess: {
      const resultArray = state.grouping[typeOfTickets].groupingsTemplates.filter(
        (tem: GroupingTemplate) => tem.id !== payload.id,
      )

      return {
        ...state,

        grouping: {
          ...state.grouping,

          [typeOfTickets]: {
            ...state.grouping[typeOfTickets],

            groupingsTemplates: resultArray,
          },
        },
      }
    }

    case TicketActions.GetGroupingTemplatesResponseFail:
      return {
        ...state,
        error: payload,
        isFetchingGroupTemplates: false,
      }

    case AtmeyeAlarmsActions.SetCurrentFilterTemplate:
      return {
        ...state,

        filter: {
          ...state.filter,

          [typeOfTickets]: {
            ...state.filter[typeOfTickets],

            selectedTemplate: payload,
          },
        },
      }

    case TicketActions.GetTicketsConfigRequest:
      return { ...state, isFetchingTicketsConfig: true, error: null }

    case AtmeyeAlarmsActions.GetTicketsConfigResponse:
      return {
        ...state,

        isFetchingTicketsConfig: false,
        config: {
          ...state.config,
          [payload.typeOfTickets]: payload.config,
        },
      }

    case TicketActions.GetTicketsConfigResponseFail:
      return { ...state, isFetchingTicketsConfig: false, error: payload }

    case AtmeyeAlarmsActions.ChangeSorting:
      return {
        ...state,
        sorting: { ...state.sorting, [typeOfTickets]: payload },
      }

    case TicketActions.SetTypeOfTickets:
      return { ...state, typeOfTickets: payload }

    case AtmeyeAlarmsActions.SetRowPerPage:
      return {
        ...state,
        rowsPerPage: { ...state.rowsPerPage, [typeOfTickets]: payload },
      }

    case AtmeyeAlarmsActions.SetPage:
      return { ...state, page: { ...state.page, [typeOfTickets]: payload } }

    case AtmeyeAlarmsActions.ChangeGrouping:
      return {
        ...state,
        isFetchingTickets: true,
        grouping: {
          ...state.grouping,

          [typeOfTickets]: {
            ...state.grouping[typeOfTickets],
            selectedGroupings: payload,
          },
        },
      }

    case TicketActions.SetStringFilter:
      return {
        ...state,
        stringFitler: { ...state.stringFitler, [typeOfTickets]: payload },
      }

    case AtmeyeAlarmsActions.ClearGroupedTicketDetailsData:
      return {
        ...state,
        page: initialState.page,
        groupDetails: initialState.groupDetails,
      }

    case AtmeyeAlarmsActions.SetMedia:
      return {
        ...state,
        media: {
          currentEvent: payload.eventId,
          data: {
            ...state.media.data,

            [payload.eventId]: {
              image: payload.image,
              type: payload.type,
            },
          },
          fetchingEnabled: payload.fetchingEnabled,
        },
      }

    case AtmeyeAlarmsActions.ClearCurrentMedia:
      return {
        ...state,
        media: {
          currentEvent: null,
          data: {},
          fetchingEnabled: false,
        },
      }

    case AtmeyeAlarmsActions.SetCurrentMedia:
      return {
        ...state,
        media: {
          currentEvent: payload.eventId,
          data: {
            ...state.media.data,
          },
          fetchingEnabled: state.media.fetchingEnabled,
        },
      }

    case AtmeyeAlarmsActions.SetMediaErrorMessage:
      return {
        ...state,
        media: {
          currentEvent: payload.eventId,
          data: {
            ...state.media.data,
            [payload.eventId]: {
              image: {
                ...state.media.data[payload.eventId]?.image,
                errorMessage: payload.message,
              },
            },
          },
          fetchingEnabled: false,
        },
      }

    case AtmeyeAlarmsActions.SaveTableCell: {
      if (!state.data[typeOfTickets]?.details?.items) return { ...state }
      const newItems = [...state.data[typeOfTickets]?.details?.items]
      if (!payload.nesting) {
        newItems[payload.parentIndex].item[payload.fieldId] = payload.fieldValue
      } else {
        newItems[payload.parentIndex].childItems[payload.rowIndex][payload.fieldId] =
          payload.fieldValue
      }
      return {
        ...state,

        data: {
          ...state.data,

          [typeOfTickets]: {
            ...state.data[typeOfTickets],

            details: {
              ...state.data[typeOfTickets].details,
              items: newItems,
            },
          },
        },
      }
    }

    case AtmeyeAlarmsActions.UpdateTableRow: {
      return {
        ...state,

        data: {
          ...state.data,

          [typeOfTickets]: {
            ...state.data[typeOfTickets],

            details: {
              ...state.data[typeOfTickets].details,

              items: state.data[typeOfTickets].details?.items?.map(row => {
                const { id, ...restData } = payload

                if (row.item.event_id === id) {
                  return {
                    ...row,
                    item: {
                      ...row.item,
                      ...restData,
                    },
                  }
                }

                return row
              }),
            },
          },
        },
      }
    }

    case AtmeyeAlarmsActions.SaveTableCellGrouping: {
      const newItems = [...state.groupDetails]
      newItems[payload.parentIndex].item[payload.fieldId] = payload.fieldValue
      return {
        ...state,
        groupDetails: newItems,
      }
    }

    case AtmeyeAlarmsActions.SetDataPaginationRequest:
      return {
        ...state,
        isFetchingPagination: true,
      }

    case AtmeyeAlarmsActions.SetDataPagination: {
      return {
        ...state,
        isFetchingPagination: false,
        data: {
          ...state.data,

          [typeOfTickets]: {
            totalCount: payload.totalCount,
            details: {
              ...state.data[typeOfTickets].details,

              pageCount: payload.pageCount,
              outputPageNo: payload.outputPageNo,
            },
          },
        },
      }
    }

    case AtmeyeAlarmsActions.SetSelectedGroupingTemplate: {
      return {
        ...state,
        selectedGroupingTemplate: payload,
      }
    }
    default:
      return state
  }
}
