import 'react-splitter-layout/lib/index.css'

import { Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withResizeDetector } from 'react-resize-detector'
import SplitterLayout from 'react-splitter-layout'

import { generateMessage, useCMWebSocket } from '@/api/cm/socket/sortersIntegration'
import { MessageType } from '@/api/cm/socket/types'
import ActErrorModal from '@/components/blocks/cm/ActErrorModal'
import ApproveItemModal from '@/components/blocks/cm/Admin/ApproveItemModal'
import CashSortersModal from '@/components/blocks/cm/CashSortersModal'
import ObjectsTree from '@/components/blocks/cm/ObjectsTree'
import { ConfirmModal } from '@/components/blocks/ConfirmModal'
import LoadingOverlay from '@/components/blocks/LoadingOverlay'
import { ErrorBoundary } from '@/components/wrappers/ErrorBoundary'
import {
  AppNavigationRoutes,
  CASH_MANAGEMENT_BLOCK_PREFIX,
  cmBaseUrl,
  LocalStorageItems,
} from '@/constants'
import { CM_OBJECT_TREE } from '@/constants/cm'
import { CMSocketContext } from '@/context'
import { getParsedLocalStorageItem, getUserDataLocalStorage } from '@/helpers/localStorage'
import {
  getActErrorModal,
  getApprovalItem,
  getCashSortersModalOpen,
  getCloseCashSorterModalOpen,
  getFilterNodesCM,
  getFilterNodesRequestCM,
  getGlobalFilter,
  getSorterId,
  openApproveItemsModal,
  setCashSortersModal,
  setCloseCashSorterModal,
  setGlobalFilter,
  setObjectTreeFilter,
} from '@/store/cm/common'
import { useSplitter } from '@/utils/hooks/useSplitter'
import { useTranslate } from '@/utils/internalization'

import { PageWrapper, useClasses } from './styles'
import { Props } from './types'

const CMPageLayout = React.memo(
  ({ children, width }: Props): ReactElement => {
    const classes = useClasses()
    const dispatch = useDispatch()
    const translate = useTranslate()

    const { isModalOpen: isApproveModalOpen } = useSelector(getApprovalItem) || {}
    const { isActErrorVisible } = useSelector(getActErrorModal)

    const { socket, isConnected } = useCMWebSocket()

    const { pathname } = window.location
    const { login } = getUserDataLocalStorage(cmBaseUrl) || {}
    const allUsersSettings =
      getParsedLocalStorageItem(cmBaseUrl)(LocalStorageItems.UsersSettings) || {}
    const isObjectsTreeVisible = allUsersSettings[login]?.isObjectsTreeVisible || false
    const isOpenedTreeByDefault = allUsersSettings[login]?.isOpenedTreeByDefault || false
    const isAppBarVisible = allUsersSettings[login]?.isAppBarVisible || false

    const tabsWithoutTree = [
      AppNavigationRoutes.CashManagement.AdminPage,
      AppNavigationRoutes.CashManagement.RoutesPage,
    ]

    const { panelWight, collapsedColumn, columnDoubleClickRef, setSplitPane } = useSplitter({
      widthKey: CM_OBJECT_TREE,
    })

    const { filterNodes, filterNodesLoading, filterNodesLoaded } = useSelector(getFilterNodesCM)
    const isCashSortersModalOpen = useSelector(getCashSortersModalOpen)

    const sorter = useSelector(getSorterId)
    const isCloseCashSorterModalOpen = useSelector(getCloseCashSorterModalOpen)
    const globalFilter = useSelector(getGlobalFilter)

    useEffect(() => {
      dispatch(getFilterNodesRequestCM())
    }, [])

    const setObjectTreeNodesChecked = useCallback((nodes: string[]) => {
      dispatch(setGlobalFilter({ filterIds: nodes }))
      dispatch(setObjectTreeFilter())
    }, [])

    const isShowObjectTree = useMemo(
      () =>
        isObjectsTreeVisible &&
        !tabsWithoutTree.some(tab => pathname.includes(tab)) &&
        pathname !== CASH_MANAGEMENT_BLOCK_PREFIX,
      [isObjectsTreeVisible, tabsWithoutTree, pathname],
    )

    const handleCloseCashSortersModal = useCallback(() => {
      dispatch(setCashSortersModal(false))
    }, [])

    const handleCloseApproveItemsModal = useCallback(() => {
      dispatch(openApproveItemsModal({ isModalOpen: !isApproveModalOpen }))
    }, [isApproveModalOpen])

    const handleCloseConfirmSorterModal = useCallback(() => {
      dispatch(setCloseCashSorterModal(false))
    }, [])

    const confirmCloseSorter = useCallback(() => {
      if (socket && isConnected) {
        const message = generateMessage({
          typeKey: MessageType.ReleaseSorterRequest,
        })
        socket.send(JSON.stringify(message))

        handleCloseConfirmSorterModal()
      }
    }, [socket, isConnected])

    return (
      <>
        <CMSocketContext.Provider value={{ socket, isConnected }}>
          <PageWrapper isAppBarVisible={isAppBarVisible}>
            <SplitterLayout
              primaryIndex={1}
              secondaryInitialSize={panelWight || 200}
              onSecondaryPaneSizeChange={setSplitPane(CM_OBJECT_TREE)}
              primaryMinSize={width / 1.16}
              secondaryMinSize={20}
              customClassName={clsx({
                [classes.collapsedColumn]: collapsedColumn,
                [classes.columnHide]: !isShowObjectTree,
              })}
              ref={columnDoubleClickRef}
            >
              {isShowObjectTree ? (
                <ErrorBoundary>
                  <div className={classes.objectTreeWrap}>
                    <Grid container className={classes.objectsTreeWithTitle}>
                      <LoadingOverlay active={filterNodesLoading} loaded={filterNodesLoaded}>
                        <Typography variant="h3" className={classes.title}>
                          {translate('translate#cm.ObjectsTree')}
                        </Typography>
                        <Grid item md={12} className={classes.objectsTree}>
                          <ObjectsTree
                            nodes={filterNodes}
                            setCheckedIds={setObjectTreeNodesChecked}
                            checkedIds={globalFilter?.filterIds || []}
                            openedByDefault={isOpenedTreeByDefault}
                          />
                        </Grid>
                      </LoadingOverlay>
                    </Grid>
                  </div>
                </ErrorBoundary>
              ) : (
                <div />
              )}
              <SplitterLayout>
                <div
                  className={clsx(classes.content, {
                    [classes.withoutObjectTree]: !isShowObjectTree,
                  })}
                >
                  {children}
                </div>
              </SplitterLayout>
            </SplitterLayout>
          </PageWrapper>
          {isCashSortersModalOpen && (
            <CashSortersModal
              isOpen={isCashSortersModalOpen}
              handleClose={handleCloseCashSortersModal}
            />
          )}
          {isCloseCashSorterModalOpen && (
            <ConfirmModal
              message={`${translate('translate#cm.CloseSorter')} ${sorter} ?`}
              open={isCloseCashSorterModalOpen}
              onClose={handleCloseConfirmSorterModal}
              onConfirm={confirmCloseSorter}
              classes={{
                title: classes.confirmTitle,
                buttonsContainer: classes.confirmBtnContainer,
                contentWrapper: classes.confirmWrapper,
              }}
            />
          )}
          {isActErrorVisible && <ActErrorModal />}
          {isApproveModalOpen && (
            <ApproveItemModal isOpen={isApproveModalOpen} onClose={handleCloseApproveItemsModal} />
          )}
        </CMSocketContext.Provider>
      </>
    )
  },
)

export default withResizeDetector(CMPageLayout)
