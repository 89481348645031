import React from 'react'

import { store } from '@/App'

export const ATMeyeRoutes = React.lazy(async () => {
  const {
    default: { reducer: reducerDevices, saga: sagaDevices },
  } = await import('@/store/atmeye/devices')

  store.injectReducer('devices', reducerDevices)
  store.injectSaga('devices', sagaDevices)

  const {
    default: { reducer: deviceSettingsReducer, saga: deviceSettingsSaga },
  } = await import('@/store/atmeye/deviceSettings')

  store.injectReducer('deviceSettings', deviceSettingsReducer)
  store.injectSaga('deviceSettings', deviceSettingsSaga)

  const {
    default: { reducer: reducerReports, saga: sagaReports },
  } = await import('@/store/atmeye/reports')

  store.injectReducer('atemeyeReports', reducerReports)
  store.injectSaga('atemeyeReports', sagaReports)

  const {
    default: { reducer: reducerRfm, saga: sagaRfm },
  } = await import('@/store/atmeye/rfm')

  store.injectReducer('rfm', reducerRfm)
  store.injectSaga('rfm', sagaRfm)

  const {
    default: {
      reducer: reducerCentralSettingsDeviceSelector,
      saga: sagaCentralSettingsDeviceSelector,
    },
  } = await import('@/store/atmeye/centralSettingsDeviceSelector')

  store.injectReducer('centralSettingsDeviceSelector', reducerCentralSettingsDeviceSelector)
  store.injectSaga('centralSettingsDeviceSelector', sagaCentralSettingsDeviceSelector)

  const {
    default: { reducer: reducerRfmMultipleDevices, saga: sagaRfmMultipleDevices },
  } = await import('@/store/atmeye/rfmMultipleDevices')

  store.injectReducer('rfmMultipleDevices', reducerRfmMultipleDevices)
  store.injectSaga('rfmMultipleDevices', sagaRfmMultipleDevices)

  const {
    default: { reducer: reducerTransactions, saga: sagaTransactions },
  } = await import('@/store/atmeye/transactions')

  store.injectReducer('transactions', reducerTransactions)
  store.injectSaga('transactions', sagaTransactions)

  const {
    default: { reducer: reducerTransactionsByDevice, saga: sagaTransactionsByDevice },
  } = await import('@/store/atmeye/transactionsByDevice')

  store.injectReducer('transactionsByDevice', reducerTransactionsByDevice)
  store.injectSaga('transactionsByDevice', sagaTransactionsByDevice)

  const {
    default: { reducer: reducerSecurityAlarms, saga: sagaSecurityAlarms },
  } = await import('@/store/atmeye/securityAlarms')

  store.injectReducer('securityAlarms', reducerSecurityAlarms)
  store.injectSaga('securityAlarms', sagaSecurityAlarms)

  const {
    default: { reducer: reducerSecurityAlarmsByDevice, saga: sagaSecurityAlarmsByDevice },
  } = await import('@/store/atmeye/securityAlarmsByDevice')

  store.injectReducer('securityAlarmsByDevice', reducerSecurityAlarmsByDevice)
  store.injectSaga('securityAlarmsByDevice', sagaSecurityAlarmsByDevice)

  const {
    default: { reducer: reducerTechnicalEvents, saga: sagaTechnicalEvents },
  } = await import('@/store/atmeye/technicalEvents')

  store.injectReducer('technicalEvents', reducerTechnicalEvents)
  store.injectSaga('technicalEvents', sagaTechnicalEvents)

  const {
    default: { reducer: reducerTechnicalEventsByDevice, saga: sagaTechnicalEventsByDevice },
  } = await import('@/store/atmeye/technicalEventsByDevice')

  store.injectReducer('technicalEventsByDevice', reducerTechnicalEventsByDevice)
  store.injectSaga('technicalEventsByDevice', sagaTechnicalEventsByDevice)

  const {
    default: { reducer: reducerBlackList, saga: sagaBlackList },
  } = await import('@/store/atmeye/blackList')

  store.injectReducer('blackList', reducerBlackList)
  store.injectSaga('blackList', sagaBlackList)

  const {
    default: { reducer: reducerDeviceDayNightSettings, saga: sagaDeviceDeviceDayNightSettings },
  } = await import('@/store/atmeye/cameraDayNightSettings')

  store.injectReducer('deviceDayNightSettings', reducerDeviceDayNightSettings)
  store.injectSaga('deviceDayNightSettings', sagaDeviceDeviceDayNightSettings)

  const {
    default: { reducer: multipleSnapshotsReducer, saga: multipleSnapshotsSaga },
  } = await import('@/store/atmeye/multipleSnapshots')

  store.injectReducer('multipleSnapshots', multipleSnapshotsReducer)
  store.injectSaga('multipleSnapshotsSaga', multipleSnapshotsSaga)

  const {
    default: {
      reducer: multipleSnapshotsDeviceSelectorReducer,
      saga: multipleSnapshotsDeviceSelectorSaga,
    },
  } = await import('@/store/atmeye/multipleSnapshotsDeviceSelector')

  store.injectReducer('multipleSnapshotsDeviceSelector', multipleSnapshotsDeviceSelectorReducer)
  store.injectSaga('multipleSnapshotsDeviceSelector', multipleSnapshotsDeviceSelectorSaga)

  const {
    default: { reducer: centralSettingsReducer, saga: centralSettingsSaga },
  } = await import('@/store/atmeye/centralSettings')

  store.injectReducer('centralSettings', centralSettingsReducer)
  store.injectSaga('centralSettingsSaga', centralSettingsSaga)

  const {
    default: { reducer: notificationReducer, saga: notificationSaga },
  } = await import('@/store/notifications')

  store.injectReducer('notifications', notificationReducer)
  store.injectSaga('notificationSaga', notificationSaga)

  const {
    default: { reducer: mapATMEyeReducer },
  } = await import('@/store/atmeye/map')

  store.injectReducer('atmEyeMap', mapATMEyeReducer)

  const {
    default: { reducer: commonATMeyeReducer, saga: commonATMeyeSaga },
  } = await import('@/store/atmeye/common')

  store.injectReducer('commonATMeye', commonATMeyeReducer)
  store.injectSaga('commonATMeyeSaga', commonATMeyeSaga)

  return import('@/components/routers/atmeye')
})
