import { LocalStorageItems } from '@/constants'

export const getUserModules = (baseUrl: string): string[] => {
  const modulesString = localStorage.getItem(LocalStorageItems.UserModules)

  if (modulesString) {
    const modules = JSON.parse(modulesString)

    return modules[baseUrl]
  } else {
    return []
  }
}

export const getUserPermissions = (baseUrl: string): string[] => {
  const permissionsString = localStorage.getItem(LocalStorageItems.UserPermissions)

  if (permissionsString) {
    const permissions = JSON.parse(permissionsString)

    return permissions[baseUrl]
  } else {
    return []
  }
}

// Check ----------------------------------------------------------------------------------

export const checkUserModules = (baseUrl: string, modulesToFind: string[]): boolean => {
  const modules = getUserModules(baseUrl)
  return !!modulesToFind.filter(el => modules.includes(el)).length
}

export const checkUserPermissions = (baseUrl: string, permissionsToFind: string[]): boolean => {
  const permissions = getUserPermissions(baseUrl)
  return !!permissionsToFind.filter(el => permissions.includes(el)).length
}
