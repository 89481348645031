import React, { useState, useCallback, useContext, useEffect } from 'react'
import { useClasses } from './styles'
import { changeSelectedFilters } from '@/store/sd/tickets/index'
import { TimerWIthRefreshModal } from '@/components/blocks/TimerWIthRefreshModal'
import { AppNavigationRoutes, SD_BLOCK_PREFIX } from '@/constants/paths'
import WidgetHeaderControls from '@/components/pages/sd/dashboard/blocks/WidgetHeaderControls'
import AppTable from '@/components/controls/AppTable/component'
import ChartSection from '@/components/pages/sd/dashboard/TicketPage/components/ChartSection/component'
import Button from '@/components/controls/Button'
import Collapse from '@material-ui/core/Collapse'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { useDispatch, useSelector } from 'react-redux'
import { NavigationContext } from '@/context'
import { TableFilter } from '@/components/controls/AppTable/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import { FormattedMessage } from 'react-intl'
import {
  getAllCompanies,
  getAllDevices,
  getChartDataRequest,
  getChartExtendedDataRequest,
  setStatus,
  getTicketsRequest,
  getTicketsConfigRequest,
  setSorting,
  setTicketsPage,
} from '@/store/dashboard/actions'
import {
  getChartExtendedTableData,
  getChartExtendedTotalRowData,
  getIsLookingChartExtendedData,
  autoRefresh,
  currentWidgetId,
  getTicketsConfig,
  getChartExtendedSubHeaderRowData,
} from '@/store/dashboard/selectors'
import { TicketsConfigData } from '@/types'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { useTranslate } from '@/utils/internalization'

export enum ChartExtendedTableDataColumns {
  id = 'id',
  Status = 'status',
  Count = 'count',
  SLANoDeadline = 'sla(noDeadline)',
  SLANoDeadlinePercents = 'SLANoDeadlinePercents',
  SLANoDelayed = 'sla(noDelayed)',
  SLANoDelayedPercents = 'SLANoDelayedPercents',
  SLANearing = 'sla(nearing)',
  SLANearingPercents = 'SLANearingPercents',
  SLAOK = 'sla(ok)',
  SLAOKPercents = 'SLAOKPercents',
}

const columnDisplayOrder = [
  ChartExtendedTableDataColumns.Status,
  ChartExtendedTableDataColumns.Count,
  ChartExtendedTableDataColumns.SLANearing,
  // ChartExtendedTableDataColumns.SLANearingPercents,
  ChartExtendedTableDataColumns.SLAOK,
  // ChartExtendedTableDataColumns.SLAOKPercents,
  ChartExtendedTableDataColumns.SLANoDelayed,
  // ChartExtendedTableDataColumns.SLANoDelayedPercents,
  ChartExtendedTableDataColumns.SLANoDeadline,
  // ChartExtendedTableDataColumns.SLANoDeadlinePercents,
]

const doubleColumns = [
  ChartExtendedTableDataColumns.SLANearing,
  ChartExtendedTableDataColumns.SLAOK,
  ChartExtendedTableDataColumns.SLANoDelayed,
  ChartExtendedTableDataColumns.SLANoDeadline,
]

const TicketPage = (): React.ReactElement => {
  const translate = useTranslate()
  const ticketsConfig: TicketsConfigData = useSelector(getTicketsConfig)
  const { handleAddTab } = useContext(NavigationContext)
  const autoRefreshTimerData = useSelector(autoRefresh)
  const widgetId = useSelector(currentWidgetId)
  const idPrefix = 'ticket-page'
  const testId = getTestId(idPrefix)
  const tableData = useSelector(getChartExtendedTableData)
  const subHeaderRowData = useSelector(getChartExtendedSubHeaderRowData(translate))
  const totalRowData = useSelector(getChartExtendedTotalRowData)

  const isLoadingTableData = useSelector(getIsLookingChartExtendedData)
  const dispatch = useDispatch()
  const [keys, setKeys] = useState<string[]>(columnDisplayOrder)
  const [chartIsVisible, setChartIsVisible] = useState<boolean>(true)

  const classes = useClasses({ chartIsVisible })

  useEffect(() => {
    dispatch(getAllCompanies())
    dispatch(getAllDevices())
    dispatch(getChartDataRequest())
    dispatch(getChartExtendedDataRequest())
  }, [])

  const handleVisibleChart = useCallback(() => {
    setChartIsVisible(prevState => !prevState)
  }, [])

  useEffect((): void => {
    const initialKeys = localStorage.getItem(LocalStorageItems.SDOpenedTicketsByStatusesColumns)
    if (initialKeys) {
      setKeys(JSON.parse(initialKeys))
    }
  }, [])

  const handleColumnsChange = useCallback((fields: string[]): void => {
    setKeys(fields)
    if (columnDisplayOrder !== fields) {
      localStorage.setItem(
        LocalStorageItems.SDOpenedTicketsByStatusesColumns,
        JSON.stringify(fields),
      )
    }
  }, [])

  const handleChangeHeaderSelects = useCallback(() => {
    dispatch(getChartDataRequest())
    dispatch(getChartExtendedDataRequest())
  }, [])

  const handleFilterSave = useCallback(
    (filters: any): void => {
      dispatch(changeSelectedFilters(filters))
      dispatch(getTicketsRequest())
    },
    [dispatch],
  )

  const handleClickRow = useCallback(
    (path: string, label: string) => (row: any): void => {
      if (handleAddTab) {
        handleAddTab(path, label, false)()
      }

      if (!ticketsConfig.formUID) {
        dispatch(getTicketsConfigRequest())
      }

      dispatch(setSorting(null))
      dispatch(setTicketsPage(1))
      dispatch(setStatus(row[0].status))
      dispatch(getTicketsRequest())
    },
    [ticketsConfig, handleAddTab, dispatch],
  )

  const renderAutoRefreshTimer = useCallback(
    (): React.ReactElement => (
      <TimerWIthRefreshModal
        testIdPrefix={idPrefix}
        autoRefreshTimer={
          autoRefreshTimerData.find(item => {
            return item.widgetId === widgetId
          }) || autoRefreshTimerData[0]
        }
        timerId={widgetId}
      />
    ),
    [autoRefreshTimerData],
  )

  const handleTableParamsChange = useCallback(
    (
      param: 'filter' | 'sort' | 'pagination' | 'group',
      value:
        | TableFilter
        | { [key: string]: 'asc' | 'desc' | undefined }
        | { rowsAmount: number; position: number }
        | TableGroup[],
    ): void => {
      if (param === 'filter') {
        handleFilterSave(value)
      }
    },
    [handleFilterSave],
  )

  const slaStyles = [
    { color: '#b5b5b5', label: 'SLA (no deadline)' },
    { color: '#ff274e', label: 'SLA (no delayed)' },
    { color: '#f1c40f', label: 'SLA (nearing)' },
    { color: '#99BE54', label: 'SLA (ok)' },
  ]

  return (
    <Test id={testId(0)}>
      <div className={classes.wrapper}>
        <Test id={testId(1)}>
          <div className={classes.header}>
            <div className={classes.headerTitle}>
              <FormattedMessage
                id="title.opened.tickets.by.status"
                defaultMessage="Opened tickets by status"
              />
            </div>
            <Test id={testId(3)}>
              <div className={classes.headerControls}>
                <WidgetHeaderControls onApply={handleChangeHeaderSelects} testIdPrefix={idPrefix} />
                <Test id={testId(4)}>
                  <div className={classes.timerWrapper}>{renderAutoRefreshTimer()}</div>
                </Test>
                <Button variant="outlined" width="xs" size="large" onClick={handleVisibleChart}>
                  {!chartIsVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Button>
              </div>
            </Test>
          </div>
        </Test>
        <Collapse in={chartIsVisible}>
          <ChartSection testIdPrefix={idPrefix} />
        </Collapse>
        <Test id={testId(5)}>
          <div className={classes.tableSection}>
            <div className={classes.tableTitle}>
              <FormattedMessage
                id="title.opened.tickets.states.by.sla"
                defaultMessage="Opened tickets states by SLA"
              />
            </div>
            <AppTable
              data={tableData}
              slaStyles={slaStyles}
              totalRow={totalRowData}
              subHeaderRow={subHeaderRowData}
              columnDisplayOrder={keys}
              doubleColumn={doubleColumns}
              onRowClick={handleClickRow(
                `${SD_BLOCK_PREFIX}${AppNavigationRoutes.TicketPage}/${0}/status`,
                'translate#title.status',
              )}
              setColumnDisplayOrder={handleColumnsChange}
              idColumnKey={ChartExtendedTableDataColumns.id}
              onParamChange={handleTableParamsChange}
              isLoading={isLoadingTableData}
              columnsWithTranslate={[ChartExtendedTableDataColumns.Status]}
              areDatesInTable={false}
              tableClasses={{
                root: classes.table,
                // table:
                // actions: classes.actions,
                // container: classes.container,
                headColumn: classes.headColumn,
                headColumnCell: classes.headColumnCell,
                row: classes.row,
                cell: classes.tableCell,
              }}
            />
          </div>
        </Test>
      </div>
    </Test>
  )
}

export default TicketPage
