import { AnyAction } from 'redux'

import {
  AdmPlnCasSet,
  AdmPlnCasSetBase,
  AdmPlnCassetteType,
  AdmPlnTeam,
  AdmPlnTeamGroup,
  CassetteForValidationResponse,
  CitPlnAcceptRequest,
  CitPlnCalculateRequest,
  CitPlnCashPoint,
  CitPlnCashPointsFilter,
  CitPlnCashPointsResponse,
  CitPlnDeclineCashOrderRequest,
  CitPlnDeclineRequest,
  CitPlnDeleteCashOrderRequest,
  CitPlnLimit,
  CitPlnOrderTask,
  CitPlnOrderTasksResponse,
  CitPlnSetPropertyRequest,
  CitPlnSetUnloadingRequest,
  CitPlnSetWorkOrderCassettes,
  CitPlnWorkOrder,
  CitPlnWorkOrderCassettes,
  CitPlnWorkOrderCassettesResponse,
  CitPlnWorkOrdersFilter,
  ComCMReport,
  ComUuidValue,
  eCitCashpointProperty,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants/actions/cmActions'
import { Paging, Sort } from '@/types/cm'

export const stopLoadingAfterError = (): AnyAction => ({
  type: CMActions.CitPlnStopLoadingAfterError,
})

export const deleteCashOrderRequest = (payload: CitPlnDeleteCashOrderRequest): AnyAction => ({
  type: CMActions.CitPlnDeleteCashOrderRequest,
  payload,
})

export const declineCashOrderRequest = (payload: CitPlnDeclineCashOrderRequest): AnyAction => ({
  type: CMActions.CitPlnDeclineCashOrderRequest,
  payload,
})

export const setUnloadingRequest = (payload: CitPlnSetUnloadingRequest): AnyAction => ({
  type: CMActions.CitPlnSetUnloadingRequest,
  payload,
})

export const declineCashPlanRequest = (payload: CitPlnDeclineRequest): AnyAction => ({
  type: CMActions.CitPlnDeclineCashPlanRequest,
  payload,
})

export const calculateCashPointRequest = (payload: CitPlnCalculateRequest): AnyAction => ({
  type: CMActions.CitPlnCalculateCashPointRequest,
  payload,
})
export const calculateCashPointFail = (error: string): AnyAction => ({
  type: CMActions.CitPlnCalculateCashPointFail,
  payload: error,
})

export const acceptCashPointRequest = (payload: CitPlnAcceptRequest): AnyAction => ({
  type: CMActions.CitPlnAcceptCashPointRequest,
  payload,
})

export const getCitPlnCashPointsRequest = (): AnyAction => ({
  type: CMActions.CitPlnGetCashPointsRequest,
})

export const getCitPlnCashPointsResponse = (payload: CitPlnCashPointsResponse): AnyAction => ({
  type: CMActions.CitPlnGetCashPointsResponse,
  payload,
})

export const getCitPlnCashPointsResponseFail = (payload: any): AnyAction => ({
  type: CMActions.CitPlnGetCashPointsResponseFail,
  payload,
})

export const getCitPlnCashPointRequest = (guid: string): AnyAction => ({
  type: CMActions.CitPlnGetCashPointRequest,
  payload: guid,
})
export const getCitPlnCashPointResponse = (
  payload: CitPlnCashPoint,
  withoutUpdateSelected = false,
): AnyAction => ({
  type: CMActions.CitPlnGetCashPointResponse,
  payload: { point: payload, withoutUpdateSelected },
})
export const getCitPlnCashPointFail = (): AnyAction => ({
  type: CMActions.CitPlnGetCashPointFail,
})

export const setCitPlnSelectedCashPoint = (payload?: CitPlnCashPoint): AnyAction => ({
  type: CMActions.CitPlnSetSelectedCashPoint,
  payload,
})

export const getCitPlnCashPointPropertyRequest = (payload: eCitCashpointProperty): AnyAction => ({
  type: CMActions.CitPlnGetCashPointPropertyRequest,
  payload,
})

export const setCitPlnCashPointPropertyRequest = (
  payload: CitPlnSetPropertyRequest,
): AnyAction => ({
  type: CMActions.CitPlnSetCashPointPropertyRequest,
  payload,
})
export const setCitPlnCashPointPropertyFail = (): AnyAction => ({
  type: CMActions.CitPlnSetCashPointPropertyFail,
})

export const getCitPlnCashPointPropertyResponse = (payload: {
  [key: string]: ComUuidValue[]
}): AnyAction => ({
  type: CMActions.CitPlnGetCashPointPropertyResponse,
  payload,
})

export const getCitPlnReportsRequest = (): AnyAction => ({
  type: CMActions.CitPlnGetReportsRequest,
})

export const getCitPlnReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.CitPlnGetReportsResponse,
  payload,
})

export const getCitPlnReportsResponseFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnGetReportsResponseFail,
  payload: message,
})

export const getCitPlnReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.CitPlnGetReportRequest,
  payload,
})

export const getCitPlnReportResponse = (payload: any): AnyAction => ({
  type: CMActions.CitPlnGetReportResponse,
  payload,
})

export const getCitPlnReportResponseFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnGetReportResponseFail,
  payload: message,
})

export const handleCitPlnKeyPress = (
  actionName:
    | 'TOGGLE_CHECKBOX'
    | 'NEXT_ROW'
    | 'PREV_ROW'
    | 'OPEN_FILTER'
    | 'OPEN_PRINT_DIALOG'
    | 'REFRESH',
): AnyAction => ({
  type: CMActions.CitPlnHandleKeyPress,
  payload: actionName,
})

export const setCheckedWorkOrder = (payload: {
  checked: Set<string>
  type: string
}): AnyAction => ({
  type: CMActions.CitPlnSetCheckedWorkOrder,
  payload,
})

export const setCheckedWorkOrderSingle = (payload: {
  type: string
  checked: boolean
  sessionId: string
}): AnyAction => ({
  type: CMActions.CitPlnSetCheckedWorkOrderSingle,
  payload,
})

export const createWorkOrderRequest = (): AnyAction => ({
  type: CMActions.CitPlnCreateWorkOrderRequest,
})

export const approveWorkOrderRequest = (): AnyAction => ({
  type: CMActions.CitPlnApproveWorkOrderRequest,
})

export const deleteWorkOrderRequest = (): AnyAction => ({
  type: CMActions.CitPlnDeleteWorkOrderRequest,
})
export const deleteWorkOrderResponse = (): AnyAction => ({
  type: CMActions.CitPlnDeleteWorkOrderResponse,
})
export const deleteWorkOrderFail = (): AnyAction => ({
  type: CMActions.CitPlnDeleteWorkOrderFail,
})

export const filterSubmitRequest = (payload: any): AnyAction => ({
  type: CMActions.CitPlnFilterSubmitRequest,
  payload,
})

export const getCitPlnWorkOrdersRequest = (): AnyAction => ({
  type: CMActions.CitPlnGetWorkOrdersRequest,
})

export const getCitPlnWorkOrdersResponse = (payload: CitPlnWorkOrder): AnyAction => ({
  type: CMActions.CitPlnGetWorkOrdersResponse,
  payload,
})
export const getCitPlnWorkOrdersFail = (error: string): AnyAction => ({
  type: CMActions.CitPlnGetWorkOrdersFail,
  error,
})

export const setCitPlnDetailsSelectedTab = (tab: string | number): AnyAction => ({
  type: CMActions.CitPlnSetDetailsSelectedTab,
  payload: tab,
})

export const workOrdersFilterSubmitRequest = (payload: CitPlnWorkOrdersFilter): AnyAction => ({
  type: CMActions.CitPlnWorkOrdersFilterSubmitRequest,
  payload,
})

export const setCitPlnWorkOrdersFiltersModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetWorkOrdersFiltersModalOpen,
  payload: isOpen,
})

export const setCitPlnWorkOrdersDocumentModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetWorkOrdersDocumentModalOpen,
  payload: isOpen,
})

export const getCitPlnWorkOrdersReportsRequest = (): AnyAction => ({
  type: CMActions.CitPlnWorkOrdersGetReportsRequest,
})

export const getCitPlnWorkOrdersReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.CitPlnWorkOrdersGetReportsResponse,
  payload,
})

export const getCitPlnWorkOrdersReportsResponseFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnWorkOrdersGetReportsResponseFail,
  payload: message,
})

export const getCitPlnWorkOrdersReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.CitPlnWorkOrdersGetReportRequest,
  payload,
})

export const getCitPlnWorkOrdersReportResponse = (payload: any): AnyAction => ({
  type: CMActions.CitPlnWorkOrdersGetReportResponse,
  payload,
})

export const getCitPlnWorkOrdersReportResponseFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnWorkOrdersGetReportResponseFail,
  payload: message,
})

export const setCitPlnCashPointsFilter = (payload: CitPlnCashPointsFilter): AnyAction => ({
  type: CMActions.CitPlnSetCashPointsFilter,
  payload,
})

export const setCitPlnFiltersModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetFiltersModalOpen,
  payload: isOpen,
})

export const setCitPlnDocumentModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetDocumentModalOpen,
  payload: isOpen,
})

export const setCitPlnMainSettingsModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetMainSettingsModalOpen,
  payload: isOpen,
})

export const refreshCitPlnCashPointsPanel = (): AnyAction => ({
  type: CMActions.CitPlnRefreshCashPointsPanel,
})

export const setCitPlnUpperLimitsModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetUpperLimitsModalOpen,
  payload: isOpen,
})

export const setCitPlnCalculateModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetCalculateModalOpen,
  payload: isOpen,
})

export const setCitPlnUnloadConfirmationModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetUnloadConfirmationModalOpen,
  payload: isOpen,
})

export const setCitPlnDeclineModalTypeOpen = (openModalType: string): AnyAction => ({
  type: CMActions.CitPlnSetDeclineModalsOpen,
  payload: openModalType,
})

export const setCitPlnDeclineNoteModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetDeclineNoteModalOpen,
  payload: isOpen,
})

export const setCitPlnUpperLimitsRequest = (payload: CitPlnLimit[]): AnyAction => ({
  type: CMActions.CitPlnSetUpperLimitsRequest,
  payload: payload,
})

export const setCitPlnCashPointsPagingRequest = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.CitPlnSetCashPointsPagingRequest,
  payload,
})

export const setCitPlnCashPointsPagingResponse = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.CitPlnSetCashPointsPagingResponse,
  payload,
})

export const setCitPlnSelectedWorkOrder = (payload: CitPlnWorkOrder): AnyAction => ({
  type: CMActions.CitPlnSetSelectedWorkOrder,
  payload,
})

export const sortCitPlnCashPointsTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.CitPlnSortCashPointsTable,
  payload,
})

export const sortCitPlnWorkOrdersTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.CitPlnSortWorkOrdersTable,
  payload,
})

export const setCitPlnWorkOrderName = (name: string): AnyAction => ({
  type: CMActions.CitPlnSetWorkOrderName,
  payload: name,
})

export const setCitPlnUnloadOrderName = (name: string): AnyAction => ({
  type: CMActions.CitPlnSetUnloadOrderName,
  payload: name,
})

export const setCitPlnRepairOrderName = (name: string): AnyAction => ({
  type: CMActions.CitPlnSetRepairOrderName,
  payload: name,
})

export const getCitPlnWOCassettesRequest = (): AnyAction => ({
  type: CMActions.CitPlnGetWOCassettesRequest,
})

export const getCitPlnWOCassettesResponse = (
  woCassettes: CitPlnWorkOrderCassettesResponse,
): AnyAction => ({
  type: CMActions.CitPlnGetWOCassettesResponse,
  payload: woCassettes,
})

export const getCitPlnWOCassettesFail = (error: string): AnyAction => ({
  type: CMActions.CitPlnGetWOCassettesFail,
  payload: error,
})

export const refreshCitPlnWOCassettes = (): AnyAction => ({
  type: CMActions.CitPlnRefreshWOCassettes,
})

export const refreshCitPlnRepairOrder = (): AnyAction => ({
  type: CMActions.CitPlnRefreshRepairOrder,
})

export const refreshCitPlnUnloadOrder = (): AnyAction => ({
  type: CMActions.CitPlnRefreshUnloadOrder,
})

export const getCitPlnUnloadOrderRequest = (name: string): AnyAction => ({
  type: CMActions.CitPlnGetUnloadOrderRequest,
  payload: name,
})

export const getCitPlnUnloadOrderResponse = (payload: CitPlnOrderTasksResponse): AnyAction => ({
  type: CMActions.CitPlnGetUnloadOrderResponse,
  payload,
})

export const getCitPlnUnloadOrderResponseFail = (error: string): AnyAction => ({
  type: CMActions.CitPlnGetUnloadOrderResponseFail,
  payload: error,
})

export const getCitPlnRepairOrderRequest = (name: string): AnyAction => ({
  type: CMActions.CitPlnGetRepairOrderRequest,
  payload: name,
})

export const getCitPlnRepairOrderResponse = (payload: CitPlnOrderTasksResponse): AnyAction => ({
  type: CMActions.CitPlnGetRepairOrderResponse,
  payload,
})

export const getCitPlnRepairOrderResponseFail = (error: string): AnyAction => ({
  type: CMActions.CitPlnGetRepairOrderResponseFail,
  payload: error,
})

export const sortCitPlnWOCassettes = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.CitPlnSortWOCassettes,
  payload,
})

export const sortCitPlnRepairOrder = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.CitPlnSortRepairOrder,
  payload,
})

export const sortCitPlnUnloadOrder = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.CitPlnSortUnloadOrder,
  payload,
})

export const seCitPlnSelectedWO = (workOrder: CitPlnWorkOrderCassettes): AnyAction => ({
  type: CMActions.CitPlnSetSelectedWO,
  payload: workOrder,
})
export const seCitPlnSelectedWOInitial = (): AnyAction => ({
  type: CMActions.CitPlnSetSelectedWOInitial,
})

export const seCitPlnWOReportsModal = ({
  isOpen,
  autoPrint = false,
}: {
  isOpen: boolean
  autoPrint?: boolean
}): AnyAction => ({
  type: CMActions.CitPlnSetWOReportsModal,
  payload: { isOpen, autoPrint },
})

export const getCitPlnWOReportsRequest = (): AnyAction => ({
  type: CMActions.CitPlnGetWOReportsRequest,
})
export const getCitPlnWOReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.CitPlnGetWOReportsResponse,
  payload,
})
export const getCitPlnWOReportsFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnGetWOReportsFail,
  payload: message,
})

export const setCitPlnWOFilterRequest = (payload: { teamId: string }): AnyAction => ({
  type: CMActions.CitPlnSetWOFilter,
  payload,
})

export const setCitPlnSelectedUnloadOrder = (workOrder: CitPlnOrderTask | null): AnyAction => ({
  type: CMActions.CitPlnSetSelectedUnloadOrder,
  payload: workOrder,
})
export const setCitPlnUnloadOrderReportsModal = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetUnloadOrderReportsModal,
  payload: isOpen,
})

export const getCitPlnUnloadOrderReportsRequest = (): AnyAction => ({
  type: CMActions.CitPlnGetUnloadOrderReportsRequest,
})
export const getCitPlnUnloadOrderReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.CitPlnGetUnloadOrderReportsResponse,
  payload,
})
export const getCitPlnUnloadOrderReportsFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnGetUnloadOrderReportsFail,
  payload: message,
})

export const setCitPlnSelectedRepairOrder = (workOrder: CitPlnOrderTask | null): AnyAction => ({
  type: CMActions.CitPlnSetSelectedRepairOrder,
  payload: workOrder,
})
export const setCitPlnRepairOrderReportsModal = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitPlnSetRepairOrderReportsModal,
  payload: isOpen,
})

export const getCitPlnRepairOrderReportsRequest = (): AnyAction => ({
  type: CMActions.CitPlnGetRepairOrderReportsRequest,
})
export const getCitPlnRepairOrderReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.CitPlnGetRepairOrderReportsResponse,
  payload,
})
export const getCitPlnRepairOrderReportsFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnGetRepairOrderReportsFail,
  payload: message,
})

export const getCitPlnUnloadOrderReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.CitPlnGetUnloadOrderReportRequest,
  payload,
})
export const getCitPlnUnloadOrderReportResponse = (payload: any | null): AnyAction => ({
  type: CMActions.CitPlnGetUnloadOrderReportResponse,
  payload,
})
export const getCitPlnUnloadOrderReportFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnGetUnloadOrderReportFail,
  payload: message,
})

export const getCitPlnRepairOrderReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.CitPlnGetRepairOrderReportRequest,
  payload,
})
export const getCitPlnRepairOrderReportResponse = (payload: any | null): AnyAction => ({
  type: CMActions.CitPlnGetRepairOrderReportResponse,
  payload,
})
export const getCitPlnRepairOrderReportFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnGetRepairOrderReportFail,
  payload: message,
})

export const getCitPlnWOReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.CitPlnGetWOReportRequest,
  payload,
})
export const getCitPlnWOReportResponse = (payload: any | null): AnyAction => ({
  type: CMActions.CitPlnGetWOReportResponse,
  payload,
})
export const getCitPlnWOReportFail = (message?: string): AnyAction => ({
  type: CMActions.CitPlnGetWOReportFail,
  payload: message,
})

export const setIsAllOrders = (isAll: boolean): AnyAction => ({
  type: CMActions.CitPlnSetIsAllOrders,
  payload: isAll,
})

export const setCitPlnEditedCassettes = (
  updatedWO: CitPlnWorkOrderCassettes,
  cassettes?: CitPlnSetWorkOrderCassettes[],
): AnyAction => ({
  type: CMActions.CitPlnSetEditedCassettes,
  payload: { updatedWO, cassettes },
})

export const applyWOCassettesStamps = (): AnyAction => ({
  type: CMActions.CitPlnApplyWOCassettesStamps,
})
export const applyWOCassettesStampsFail = (): AnyAction => ({
  type: CMActions.CitPlnApplyWOCassettesStampsFail,
})

export const applyUnloadOrder = (tasks: CitPlnOrderTask[]): AnyAction => ({
  type: CMActions.CitPlnApplyUnloadOrder,
  payload: tasks,
})

export const applyRepairOrder = (tasks: CitPlnOrderTask[]): AnyAction => ({
  type: CMActions.CitPlnApplyRepairOrder,
  payload: tasks,
})
export const clearCheckedOrders = (): AnyAction => ({
  type: CMActions.CitPlnClearCheckedOrders,
})

export const setCitPlnWorkOrdersPaging = (payload: Paging): AnyAction => ({
  type: CMActions.CitPlnSetWorkOrdersPaging,
  payload,
})

export const setVaultTransactionRequest = (): AnyAction => ({
  type: CMActions.CitPlnSetVaultTransactionRequest,
})
export const setVaultTransactionResponse = (): AnyAction => ({
  type: CMActions.CitPlnSetVaultTransactionResponse,
})
export const setVaultTransactionFail = (): AnyAction => ({
  type: CMActions.CitPlnSetVaultTransactionFail,
})

// Cassette Sets =======================================================
// get
export const getCitCassetteSetsRequest = (): AnyAction => ({
  type: CMActions.CitGetCassetteSetsRequest,
})
export const getCitCassetteSetsResponse = (sets: AdmPlnCasSet[]): AnyAction => ({
  type: CMActions.CitGetCassetteSetsResponse,
  payload: sets,
})
export const getCitCassetteSetsFail = (error: string | unknown): AnyAction => ({
  type: CMActions.CitGetCassetteSetsFail,
  payload: error,
})

// add
export const addCitNewCassetteSetRequest = (set: AdmPlnCasSetBase): AnyAction => ({
  type: CMActions.CitAddNewCassetteSetRequest,
  payload: set,
})
export const addCitNewCassetteSetFail = (): AnyAction => ({
  type: CMActions.CitAddNewCassetteSetFail,
})

// edit
export const updateCitNewCassetteSetRequest = (set: AdmPlnCasSetBase): AnyAction => ({
  type: CMActions.CitUpdateNewCassetteSetRequest,
  payload: set,
})
export const updateCitNewCassetteSetFail = (): AnyAction => ({
  type: CMActions.CitUpdateNewCassetteSetFail,
})

// delete
export const removeCassetteSetRequest = (): AnyAction => ({
  type: CMActions.CitRemoveCassetteSetRequest,
})
export const removeCassetteSetResponse = (): AnyAction => ({
  type: CMActions.CitRemoveCassetteSetResponse,
})
export const removeCassetteSetFail = (error: string | unknown): AnyAction => ({
  type: CMActions.CitRemoveCassetteSetFail,
  payload: error,
})

// selected
export const setCitSelectedCassetteSet = (set: AdmPlnCasSet): AnyAction => ({
  type: CMActions.CitSetSelectedCassetteSet,
  payload: set,
})

// sort
export const sortCitCassetteSets = (payload: Sort): AnyAction => ({
  type: CMActions.CitSortCassettesSets,
  payload,
})

// modals
export const setCitCassetteSetsModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitSetCassetteSetsModalOpen,
  payload: isOpen,
})

// cassette Type
export const getCassetteTypesRequest = (): AnyAction => ({
  type: CMActions.CitPlnGetCassetteTypesRequest,
})
export const getCassetteTypesResponse = (cassetteSets: AdmPlnCassetteType[]): AnyAction => ({
  type: CMActions.CitPlnGetCassetteTypesResponse,
  payload: cassetteSets,
})

// Teams ===========================================================
interface CheckedTeam {
  [key: string]: {
    name?: string
  }
}
interface TeamModal {
  isTeamsModalOpen: boolean
  isTeamsModalEdit?: boolean
}

// get
export const getCitTeamsRequest = (): AnyAction => ({
  type: CMActions.CitPlnGetTeamsRequest,
})
export const getCitTeamsResponse = (payload: AdmPlnTeamGroup[]): AnyAction => ({
  type: CMActions.CitPlnGetTeamsResponse,
  payload,
})
export const getCitTeamsFail = (payload: string | unknown): AnyAction => ({
  type: CMActions.CitPlnGetTeamsFail,
  payload,
})

// delete
export const deleteCitTeamRequest = (payload: string): AnyAction => ({
  type: CMActions.CitPlnDeleteTeamRequest,
  payload,
})
export const deleteCitTeamFail = (payload: string | unknown): AnyAction => ({
  type: CMActions.CitPlnDeleteTeamFail,
  payload,
})

// checked
export const setCitPlnCheckedTeams = (payload: CheckedTeam): AnyAction => ({
  type: CMActions.CitPlnSetCheckedTeams,
  payload,
})

// selected
export const setCitPlnSelectedTeam = (payload: AdmPlnTeam | null): AnyAction => ({
  type: CMActions.CitPlnSetSelectedTeam,
  payload,
})

// sort
export const sortCitPlnTeamsTable = (payload: Sort): AnyAction => ({
  type: CMActions.CitPlnSortTeamsTable,
  payload,
})

// modals
export const setCitPlnTeamsModalOpen = (payload: TeamModal): AnyAction => ({
  type: CMActions.CitPlnSetTeamsModalOpen,
  payload,
})

export interface CassetteValidationProps {
  sessionId?: string
  validation: { [cassetteNr: number]: CassetteForValidationResponse }
}

export const setCitPlnCassetteValidations = (payload: CassetteValidationProps): AnyAction => ({
  type: CMActions.CitPlnSetCassetteValidation,
  payload,
})
export const setCitPlnInitialCassetteValidations = (payload?: string): AnyAction => ({
  type: CMActions.CitPlnSetInitialCassetteValidation,
  payload,
})
