import { ResponseError } from '@/types'
import { requestService } from '@/utils/services/request'

import {
  GetDevicesByAdvancedSearchParametersBody,
  GetDevicesByAdvancedSearchParametersResponse,
  GetDevicesByMerchantAndServiceResponse,
  GettingFilteredDevicesByMerchantIdAndServiceIdRequestData,
} from './types'

export class DeviceApi {
  public static async getDevicesByAdvancedSearchParameters(
    data: GetDevicesByAdvancedSearchParametersBody,
  ): Promise<GetDevicesByAdvancedSearchParametersResponse | ResponseError> {
    try {
      const response = await requestService.post('/device/advanced', data)

      return response.devices
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFilteredDevicesByMerchantIdAndServiceId(
    merchantId: number,
    serviceId: number,
    data: GettingFilteredDevicesByMerchantIdAndServiceIdRequestData,
  ): Promise<GetDevicesByMerchantAndServiceResponse | ResponseError> {
    try {
      const response = await requestService.post(
        `/device/filter?merchantId=${merchantId}&serviceId=${serviceId}`,
        data,
      )

      return response.devices
    } catch (e) {
      throw new Error(e)
    }
  }
}
