import moment from 'moment'
import { AnyAction } from 'redux'

import {
  AdmPlnCasSet,
  AdmPlnCassetteType,
  AdmPlnTeam,
  AdmPlnTeamGroup,
  CassetteForValidationResponse,
  CitPlnCashPoint,
  CitPlnCashPointsFilter,
  CitPlnOrderTask,
  CitPlnSetWorkOrderCassettes,
  CitPlnWorkOrder,
  CitPlnWorkOrderCassettes,
  CitPlnWorkOrderCassettesResponse,
  CitPlnWorkOrdersFilter,
  ComCassetteConfig,
  ComCassetteCurrency,
  ComCMReport,
  ComUuidValue,
  PlnCashPoint,
  PlnModifyCalculateResponse,
  PlnSettingsResponse,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions, LocalStorageItems } from '@/constants'
import { citPlnOrdersPanelId as tableId } from '@/constants/cm/cmTableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

export type State = {
  isCalculateModalOpen: boolean
  isDeclineModalOpen: boolean
  isDeclineNoteModalOpen: boolean
  isDocumentModalOpen: boolean
  isFiltersModalOpen: boolean
  isLoadingModalOpen: boolean
  isSettingsModalOpen: boolean
  isUnloadConfirmationModalOpen: boolean
  teamsModalState: {
    isTeamsModalOpen: boolean
    isTeamsModalEdit?: boolean
  }
  isUpperLimitsModalOpen: boolean
  isWorkOrdersFiltersModalOpen: boolean
  isWorkOrdersDocumentModalOpen: boolean
  declineModalOpenType: string
  isModifyDisabled: boolean
  modifyDialog: {
    isModifyApplyDisabled: boolean
    expenses: string
    areExpensesLoading: boolean
    expensesError?: string
  }
  formsSettings: {
    settings: PlnSettingsResponse | null
    isLoading: boolean
    errorMessage: string | null
  }
  cassetteConfig: {
    config: ComCassetteConfig[]
    types: string[]
    currencies: ComCassetteCurrency[]
    isLoading: boolean
    errorMessage?: string
  }

  teamsChecked: {
    [key: string]: {
      name?: string
    }
  }
  workOrders: {
    selectedWorkOrder: CitPlnWorkOrder | null

    total: number
    data: CitPlnWorkOrder[]

    filter: CitPlnWorkOrdersFilter
    sortColumn?: string
    sortOrder?: 'asc' | 'desc'
    isLoading: boolean
    isLoaded: boolean
    error: string | null

    paging: {
      page: number
      pageSize: number
    }

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }
  detailsSelectedTab: string | number
  cashPoints: {
    checkedLoads: Set<string>
    checkedUnloads: Set<string>
    checkedRepairs: Set<string>

    selectedCashPoint: ((CitPlnCashPoint & { id: string }) & any) | undefined
    checkedCashPoints: { [key: string]: PlnCashPoint }
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    data: PlnCashPoint[]
    total: number
    isLoading: boolean
    isLoaded: boolean
    properties: { [key: string]: ComUuidValue[] }
    error: string | null
    filter: CitPlnCashPointsFilter
    calculateExtResponse: PlnModifyCalculateResponse | null
    isCalculatingCP: boolean
    paging: {
      page: number
      pageSize: number
    }
    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }
  selectedOrderType: string
  workOrder: {
    cassetteValidation: {
      [orderId: string]: { [cassetteNr: number]: CassetteForValidationResponse }
    }
    workOrderReportsModal: {
      isOpen: boolean
      autoPrint: boolean
    }
    name: string
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    selectedWorkOrder: CitPlnWorkOrderCassettes | null
    data: {
      title: string
      cassettes?: CitPlnWorkOrderCassettes[]
    }
    isLoading: boolean
    isLoaded: boolean
    error: string | null

    editedCassettes: CitPlnSetWorkOrderCassettes[]

    isAllOrders: boolean

    filter: {
      teamId: string
    }

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }
  unloadOrder: {
    isUnloadOrderReportsModalOpen: boolean
    name: string
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    data: {
      title: string
      tasks?: CitPlnOrderTask[]
    }
    isLoading: boolean
    isLoaded: boolean
    error: string | null
    selectedUnloadOrder: CitPlnOrderTask | null
    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }
  repairOrder: {
    isRepairOrderReportsModalOpen: boolean
    name: string
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    data: {
      title: string
      tasks?: CitPlnOrderTask[]
    }
    isLoading: boolean
    isLoaded: boolean
    error: string | null
    selectedRepairOrder: CitPlnOrderTask | null

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }

  cassetteSets: {
    isCassetteSetsModalOpen: boolean
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    data: AdmPlnCasSet[]
    isLoading: boolean
    isLoaded: boolean
    error: string | null
    selectedCassetteSet: AdmPlnCasSet | null

    isUpdating: boolean
  }

  cassetteTypes: AdmPlnCassetteType[]

  teams: {
    data: AdmPlnTeamGroup[]
    isLoading: boolean
    isLoaded: boolean
    sortColumn?: string
    sortOrder?: string

    selected: AdmPlnTeam | null
  }
}

export const initialState: State = {
  isCalculateModalOpen: false,
  isDeclineModalOpen: false,
  isDeclineNoteModalOpen: false,
  isDocumentModalOpen: false,
  isFiltersModalOpen: false,
  isLoadingModalOpen: false,
  isUnloadConfirmationModalOpen: false,
  teamsModalState: {
    isTeamsModalOpen: false,
    isTeamsModalEdit: false,
  },
  isSettingsModalOpen: false,
  isUpperLimitsModalOpen: false,
  isWorkOrdersFiltersModalOpen: false,
  isWorkOrdersDocumentModalOpen: false,
  declineModalOpenType: '',
  isModifyDisabled: true,
  formsSettings: {
    settings: null,
    isLoading: false,
    errorMessage: '',
  },
  modifyDialog: {
    isModifyApplyDisabled: false,
    expenses: '',
    areExpensesLoading: false,
    expensesError: undefined,
  },
  cassetteConfig: {
    config: [],
    types: [],
    currencies: [],
    isLoading: false,
    errorMessage: '',
  },
  detailsSelectedTab: 'workOrders',
  workOrders: {
    selectedWorkOrder: null,

    total: 0,
    data: [],

    filter: {
      orderName: '',
      from: moment()
        .startOf('day')
        .format(),
      until: '',
    },
    isLoading: false,
    isLoaded: false,
    error: null,

    paging: {
      page: 1,
      pageSize: 50,
    },

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },

  teamsChecked: JSON.parse(localStorage.getItem(LocalStorageItems.ActiveTeamsCM) || '{}'),
  cashPoints: {
    selectedCashPoint: undefined,
    checkedCashPoints: {},
    checkedLoads: new Set(),
    checkedUnloads: new Set(),
    checkedRepairs: new Set(),
    sortColumn: undefined,
    sortOrder: undefined,
    data: [],
    total: 0,
    isLoading: false,
    isLoaded: false,
    error: null,
    properties: {},
    calculateExtResponse: null,
    isCalculatingCP: false,
    filter: {
      from: undefined,
      until: undefined,
      planStatuses: [],
      workRepairOrder: undefined,
    },
    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },
  selectedOrderType: '',
  workOrder: {
    cassetteValidation: {},

    workOrderReportsModal: {
      isOpen: false,
      autoPrint: false,
    },
    name: '',
    sortColumn: 'tid',
    sortOrder: 'asc',
    selectedWorkOrder: null,
    data: {
      title: '',
      cassettes: [],
    },
    isLoading: false,
    isLoaded: false,
    error: null,

    editedCassettes: [],

    isAllOrders: false,

    filter: {
      teamId: '',
    },

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },
  repairOrder: {
    isRepairOrderReportsModalOpen: false,
    name: '',
    sortColumn: undefined,
    sortOrder: undefined,
    data: {
      title: '',
      tasks: [],
    },
    isLoading: false,
    isLoaded: false,
    error: null,
    selectedRepairOrder: null,

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },
  unloadOrder: {
    isUnloadOrderReportsModalOpen: false,
    name: '',
    sortColumn: undefined,
    sortOrder: undefined,
    data: {
      title: '',
      tasks: [],
    },
    isLoading: false,
    isLoaded: false,
    error: null,
    selectedUnloadOrder: null,

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },
  cassetteSets: {
    isCassetteSetsModalOpen: false,
    sortColumn: undefined,
    sortOrder: undefined,
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
    selectedCassetteSet: null,

    isUpdating: false,
  },
  cassetteTypes: [],

  teams: {
    data: [],
    isLoading: false,
    isLoaded: false,
    sortColumn: undefined,
    sortOrder: undefined,

    selected: null,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.CitPlnStopLoadingAfterError:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoaded: true,
          isLoading: false,
        },
      }
    case CMActions.CitPlnClearCheckedOrders:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          checkedLoads: new Set(),
          checkedUnloads: new Set(),
          checkedRepairs: new Set(),
        },
      }
    case CMActions.CitPlnGetRepairOrderRequest:
      return {
        ...state,
        repairOrder: { ...state.repairOrder, isLoading: true },
        selectedOrderType: 'repairOrder',
      }
    case CMActions.CitPlnGetRepairOrderResponse:
      return {
        ...state,
        repairOrder: { ...state.repairOrder, data: payload, isLoading: false, isLoaded: true },
      }
    case CMActions.CitPlnGetRepairOrderResponseFail:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }
    case CMActions.CitPlnRefreshRepairOrder:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          isLoading: true,
        },
      }
    case CMActions.CitPlnSortRepairOrder:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          ...payload,
        },
      }

    case CMActions.CitPlnGetUnloadOrderRequest:
      return {
        ...state,
        unloadOrder: { ...state.unloadOrder, isLoading: true },
        selectedOrderType: 'unloadOrder',
      }
    case CMActions.CitPlnGetUnloadOrderResponse:
      return {
        ...state,
        unloadOrder: { ...state.unloadOrder, data: payload, isLoading: false, isLoaded: true },
      }
    case CMActions.CitPlnGetUnloadOrderResponseFail:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }
    case CMActions.CitPlnRefreshUnloadOrder:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          isLoading: true,
        },
      }
    case CMActions.CitPlnSortUnloadOrder:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          ...payload,
        },
      }

    case CMActions.CitPlnSetWorkOrdersDocumentModalOpen:
      return {
        ...state,
        isWorkOrdersDocumentModalOpen: payload,
      }

    case CMActions.CitPlnSetSelectedWorkOrder:
      return {
        ...state,
        workOrders: { ...state.workOrders, selectedWorkOrder: payload },
      }

    case CMActions.CitPlnGetReportRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitPlnGetReportResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.CitPlnGetReportResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitPlnGetReportsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.cashPoints.reports.selectedReport,
          },
        },
      }
    case CMActions.CitPlnGetReportsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.CitPlnWorkOrdersGetReportsRequest:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          reports: {
            ...state.workOrders.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.workOrders.reports.selectedReport,
          },
        },
      }
    case CMActions.CitPlnWorkOrdersGetReportsResponse:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          reports: {
            ...state.workOrders.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }

    case CMActions.CitPlnWorkOrdersGetReportRequest:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          reports: {
            ...state.workOrders.reports,
            selectedReport: {
              ...state.workOrders.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitPlnWorkOrdersGetReportResponse:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          reports: {
            ...state.workOrders.reports,
            selectedReport: {
              ...state.workOrders.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.CitPlnWorkOrdersGetReportResponseFail:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          reports: {
            ...state.workOrders.reports,
            selectedReport: {
              ...state.workOrders.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitPlnWorkOrdersGetReportsResponseFail:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          reports: {
            ...state.workOrders.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }
    case CMActions.CitPlnGetReportsResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case CMActions.CitPlnSetCheckedWorkOrder:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          [`checked${payload.type}s`]: payload.checked,
        },
      }

    case CMActions.CitPlnSetCheckedWorkOrderSingle: {
      const { type, checked, sessionId } = payload

      const key: 'Load' | 'Repair' | 'Unload' | 'None' = type

      const unload = state.cashPoints.checkedUnloads
      const repair = state.cashPoints.checkedRepairs
      const load = state.cashPoints.checkedLoads

      const checkedMap = {
        Unload: unload,
        Repair: repair,
        Load: load,
        None: new Set(''),
      }
      const checkedByType = checkedMap[key]

      const newChecked = new Set(checkedByType)

      const newUnload = new Set(unload)
      const newLoad = new Set(load)

      if (checked) {
        newChecked.add(sessionId)
      } else {
        newChecked.delete(sessionId)
      }

      if (key === 'Load') {
        newUnload.delete(sessionId)
      }

      if (key === 'Unload') {
        newLoad.delete(sessionId)
      }

      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          checkedLoads: newLoad,
          checkedUnloads: newUnload,
          [`checked${key}s`]: newChecked,
        },
      }
    }

    case CMActions.CitPlnSetDeclineModalsOpen:
      return {
        ...state,
        declineModalOpenType: payload,
      }

    case CMActions.CitPlnSetDeclineNoteModalOpen:
      return {
        ...state,
        isDeclineNoteModalOpen: payload,
      }

    case CMActions.CitPlnAcceptCashPointRequest:
    case CMActions.CitPlnRefreshCashPointsPanel:
    case CMActions.CitPlnGetCashPointRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isCalculatingCP: false,
          isLoading: true,
        },
      }

    case CMActions.CitPlnGetCashPointResponse: {
      const { point, withoutUpdateSelected } = payload

      const cashPoint = { ...point, id: point.sessionId }

      const newData = [...state.cashPoints.data]
      const cashPointIndex = newData.findIndex(el => el?.sessionId === point?.sessionId)

      if (cashPointIndex !== undefined && cashPointIndex !== null) {
        newData[cashPointIndex] = cashPoint

        return {
          ...state,
          cashPoints: {
            ...state.cashPoints,
            selectedCashPoint: withoutUpdateSelected
              ? state.cashPoints.selectedCashPoint
              : cashPoint,
            data: newData,
            isLoading: false,
          },
        }
      }
      return state
    }

    case CMActions.CitPlnGetCashPointFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: false,
        },
      }

    case CMActions.CitPlnSortCashPointsTable:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          ...payload,
          isLoading: true,
        },
      }

    case CMActions.CitPlnSortWorkOrdersTable:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          ...payload,
          isLoading: true,
        },
      }

    case CMActions.CitPlnSetCashPointsPagingResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            ...payload,
          },
        },
      }

    case CMActions.CitPlnSetSelectedCashPoint: {
      if (payload === null) {
        return {
          ...state,
          cashPoints: {
            ...state.cashPoints,
            selectedCashPoint: null,
          },
          workOrder: {
            ...state.workOrder,
            name: '',
            cassetteValidation: {},
          },
        }
      }

      const isShouldClearWOName =
        payload.workOrder !== state.cashPoints.selectedCashPoint?.workOrder
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPoint: payload,
        },
        workOrder: {
          ...state.workOrder,
          name: isShouldClearWOName ? '' : state.workOrder.name,
          cassetteValidation: {},
        },
      }
    }

    case CMActions.CitPlnSetCalculateModalOpen:
      return {
        ...state,
        isCalculateModalOpen: payload,
      }
    case CMActions.CitPlnSetUnloadConfirmationModalOpen:
      return {
        ...state,
        isUnloadConfirmationModalOpen: payload,
      }
    case CMActions.CitPlnSetUpperLimitsModalOpen:
      return {
        ...state,
        isUpperLimitsModalOpen: payload,
      }
    case CMActions.CitPlnSetMainSettingsModalOpen:
      return {
        ...state,
        isSettingsModalOpen: payload,
      }
    case CMActions.CitPlnGetCashPointPropertyResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          properties: {
            ...state.cashPoints.properties,
            ...payload,
          },
        },
      }

    case CMActions.SetGlobalFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            page: 1,
          },
        },
        workOrders: {
          ...state.workOrders,
          isLoading: true,
        },
      }

    case CMActions.CitPlnSetCashPointsFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          filter: {
            ...state?.cashPoints?.filter,
            ...payload,
          },
        },
      }

    case CMActions.CitPlnSetWorkOrdersPaging:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isLoading: true,
          paging: {
            ...state.workOrders.paging,
            ...payload,
          },
        },
      }

    case CMActions.CitPlnGetWorkOrdersRequest:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isLoading: true,
        },
      }

    case CMActions.CitPlnGetWorkOrdersResponse:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isLoading: false,
          isLoaded: true,
          data: payload.data,
          total: payload.total,
          selectedWorkOrder:
            (payload.data || []).find(
              (workOrder: CitPlnWorkOrder) =>
                workOrder.orderName === state.workOrders.selectedWorkOrder?.orderName,
            ) || null,
        },
      }

    case CMActions.CitPlnGetWorkOrdersFail:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isLoading: false,
          error: payload,
          isLoaded: true,
        },
      }

    case CMActions.CitPlnSetDetailsSelectedTab:
      return {
        ...state,
        detailsSelectedTab: payload,
      }

    case CMActions.CitPlnSetWorkOrdersFiltersModalOpen:
      return {
        ...state,
        isWorkOrdersFiltersModalOpen: payload,
      }

    case CMActions.CitPlnWorkOrdersFilterSubmitRequest:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          filter: {
            ...state?.workOrders?.filter,
            ...payload,
          },
          paging: {
            ...state.workOrders.paging,
            page: initialState.workOrders.paging.page,
          },
          isLoading: true,
        },
      }

    case CMActions.CitPlnSetFiltersModalOpen:
      return {
        ...state,
        isFiltersModalOpen: payload,
      }

    case CMActions.CitPlnDeclineCashPlanRequest: {
      const { ids } = payload

      const unload = new Set(state.cashPoints.checkedUnloads)
      const repair = new Set(state.cashPoints.checkedRepairs)
      const load = new Set(state.cashPoints.checkedLoads)

      unload.delete(ids[0])
      repair.delete(ids[0])
      load.delete(ids[0])

      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
          checkedLoads: load,
          checkedUnloads: unload,
          checkedRepairs: repair,
        },
      }
    }

    case CMActions.CitPlnCreateWorkOrderRequest:
    case CMActions.CitPlnGetCashPointsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
          error: null,
        },
      }
    case CMActions.CitPlnGetCashPointsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          data: payload?.data.map((cashPont: CitPlnCashPoint) => ({
            ...cashPont,
            id: cashPont.sessionId,
          })),
          total: payload?.total,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      }
    case CMActions.CitPlnGetCashPointsResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          data: [],
          total: 0,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case CMActions.CitPlnSetDocumentModalOpen:
      return {
        ...state,
        isDocumentModalOpen: payload,
      }

    case CMActions.CitPlnSetWorkOrderName:
      return {
        ...state,
        selectedOrderType: 'workOrder',
        workOrder: {
          ...state.workOrder,
          name: payload,
          editedCassettes: initialState.workOrder.editedCassettes,
        },
      }
    case CMActions.CitPlnSetRepairOrderName:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          name: payload,
        },
      }
    case CMActions.CitPlnSetUnloadOrderName:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          name: payload,
        },
      }

    case CMActions.CitPlnSetWOFilter:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          filter: payload,
        },
      }
    case CMActions.CitPlnSetVaultTransactionRequest:
    case CMActions.CitPlnGetWOCassettesRequest:
    case CMActions.CitPlnApplyWOCassettesStamps:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isLoading: true,
          cassetteValidation: initialState.workOrder.cassetteValidation,
        },
      }

    case CMActions.CitPlnGetWOCassettesResponse: {
      const { cassettes } = payload as CitPlnWorkOrderCassettesResponse
      const { editedCassettes } = state.workOrder

      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isLoading: false,
          isLoaded: true,
          data: payload,
          selectedWorkOrder:
            (cassettes || []).find(
              cassette => cassette.sessionId === state.workOrder.selectedWorkOrder?.sessionId,
            ) ||
            cassettes[0] ||
            null,
          editedCassettes: cassettes.map(cassette => {
            const { sessionId, cassetteEntries, coid } = cassette

            const existingCassette = editedCassettes.find(
              editedCassette => editedCassette.sessionId === sessionId,
            )
            if (existingCassette) return existingCassette

            return {
              coid,
              sessionId,
              isSelected: false,
              cassetteEntries: cassetteEntries?.map(cassetteEntry => ({
                ...cassetteEntry,
                stamp: cassetteEntry.stamp || '',
              })),
            }
          }),
        },
      }
    }
    case CMActions.CitPlnSetVaultTransactionFail:
    case CMActions.CitPlnGetWOCassettesFail:
    case CMActions.CitPlnApplyWOCassettesStampsFail:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case CMActions.CitPlnSetVaultTransactionResponse:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isLoading: false,
          isLoaded: true,
        },
      }
    case CMActions.CitPlnRefreshWOCassettes:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isLoading: true,
          cassetteValidation: initialState.workOrder.cassetteValidation,
          editedCassettes: initialState.workOrder.editedCassettes,
        },
      }
    case CMActions.CitPlnSortWOCassettes:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          ...payload,
        },
      }
    case CMActions.CitPlnSetSelectedWO:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          selectedWorkOrder: payload,
          editedCassettes: state.workOrder.editedCassettes.map(cassette => {
            if (cassette.sessionId === payload.sessionId) {
              return {
                ...cassette,
                isSelected: true,
              }
            }
            return {
              ...cassette,
              isSelected: false,
            }
          }),
        },
      }
    case CMActions.CitPlnSetSelectedWOInitial:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          selectedWorkOrder: null,
        },
      }
    case CMActions.CitPlnSetWOReportsModal:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          workOrderReportsModal: payload,
        },
      }

    case CMActions.CitPlnGetWOReportsRequest:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.workOrder.reports.selectedReport,
          },
        },
      }

    case CMActions.CitPlnGetWOReportsResponse:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.CitPlnGetWOReportsFail:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case CMActions.CitPlnSetSelectedRepairOrder:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          selectedRepairOrder: payload,
        },
      }
    case CMActions.CitPlnSetRepairOrderReportsModal:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          isRepairOrderReportsModalOpen: payload,
        },
      }

    case CMActions.CitPlnGetRepairOrderReportsRequest:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          reports: {
            ...state.repairOrder.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.repairOrder.reports.selectedReport,
          },
        },
      }

    case CMActions.CitPlnGetRepairOrderReportsResponse:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          reports: {
            ...state.repairOrder.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.CitPlnGetRepairOrderReportsFail:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          reports: {
            ...state.repairOrder.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case CMActions.CitPlnSetSelectedUnloadOrder:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          selectedUnloadOrder: payload,
        },
      }
    case CMActions.CitPlnSetUnloadOrderReportsModal:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          isUnloadOrderReportsModalOpen: payload,
        },
      }

    case CMActions.CitPlnGetUnloadOrderReportsRequest:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          reports: {
            ...state.unloadOrder.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.unloadOrder.reports.selectedReport,
          },
        },
      }

    case CMActions.CitPlnGetUnloadOrderReportsResponse:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          reports: {
            ...state.unloadOrder.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.CitPlnGetUnloadOrderReportsFail:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          reports: {
            ...state.unloadOrder.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }
    case CMActions.CitPlnSetIsAllOrders:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isAllOrders: payload,
        },
      }

    case CMActions.CitPlnGetRepairOrderReportRequest:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          reports: {
            ...state.repairOrder.reports,
            selectedReport: {
              ...state.repairOrder.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitPlnGetRepairOrderReportResponse:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          reports: {
            ...state.repairOrder.reports,
            selectedReport: {
              ...state.repairOrder.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.CitPlnGetRepairOrderReportFail:
      return {
        ...state,
        repairOrder: {
          ...state.repairOrder,
          reports: {
            ...state.repairOrder.reports,
            selectedReport: {
              ...state.repairOrder.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }

    case CMActions.CitPlnGetUnloadOrderReportRequest:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          reports: {
            ...state.unloadOrder.reports,
            selectedReport: {
              ...state.unloadOrder.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitPlnGetUnloadOrderReportResponse:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          reports: {
            ...state.unloadOrder.reports,
            selectedReport: {
              ...state.unloadOrder.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.CitPlnGetUnloadOrderReportFail:
      return {
        ...state,
        unloadOrder: {
          ...state.unloadOrder,
          reports: {
            ...state.unloadOrder.reports,
            selectedReport: {
              ...state.unloadOrder.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }

    case CMActions.CitPlnGetWOReportRequest:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            selectedReport: {
              ...state.workOrder.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitPlnGetWOReportResponse:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            selectedReport: {
              ...state.workOrder.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.CitPlnGetWOReportFail:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            selectedReport: {
              ...state.workOrder.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitPlnSetEditedCassettes: {
      const updatedWO = state.workOrder.data.cassettes?.map(cassette => {
        if (cassette.sessionId === payload.updatedWO.sessionId) {
          return payload.updatedWO
        }
        return cassette
      })

      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          editedCassettes: payload.cassettes || state.workOrder.editedCassettes,
          selectedWorkOrder:
            payload.updatedWO?.sessionId === state.workOrder.selectedWorkOrder?.sessionId
              ? payload.updatedWO
              : state.workOrder.selectedWorkOrder,
          data: {
            ...state.workOrder.data,
            cassettes: updatedWO,
          },
        },
      }
    }

    case CMActions.CitPlnSetCassetteValidation: {
      const { sessionId, validation } = payload

      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          cassetteValidation: {
            ...state.workOrder.cassetteValidation,
            [sessionId || '']: {
              ...state.workOrder.cassetteValidation[sessionId || ''],
              ...validation,
            },
          },
        },
      }
    }

    case CMActions.CitPlnSetInitialCassetteValidation: {
      const { sessionId } = payload

      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          cassetteValidation: {
            ...state.workOrder.cassetteValidation,
            [sessionId || '']: {},
          },
        },
      }
    }

    case CMActions.CitPlnCalculateCashPointRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isCalculatingCP: true,
        },
      }
    case CMActions.CitPlnCalculateCashPointFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isCalculatingCP: false,
        },
      }

    case CMActions.CitPlnSetCashPointPropertyRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
        },
      }
    case CMActions.CitPlnSetCashPointPropertyFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: false,
        },
      }

    case CMActions.CitPlnDeleteWorkOrderRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
        },
      }
    case CMActions.CitPlnDeleteWorkOrderFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: false,
        },
      }
    case CMActions.CitPlnDeleteWorkOrderResponse:
      return {
        ...state,
        selectedOrderType: '',
        workOrder: initialState.workOrder,
      }

    // Cassette Sets
    case CMActions.CitGetCassetteSetsRequest:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          isLoading: true,
          isUpdating: false,
        },
      }
    case CMActions.CitGetCassetteSetsResponse:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case CMActions.CitGetCassetteSetsFail:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case CMActions.CitRemoveCassetteSetRequest:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          isLoading: true,
        },
      }
    case CMActions.CitAddNewCassetteSetRequest:
    case CMActions.CitUpdateNewCassetteSetRequest:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          isUpdating: true,
        },
      }

    case CMActions.CitRemoveCassetteSetFail:
    case CMActions.CitAddNewCassetteSetFail:
    case CMActions.CitUpdateNewCassetteSetFail:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          isUpdating: false,
          error: payload,
        },
      }

    case CMActions.CitRemoveCassetteSetResponse:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          selectedCassetteSet: null,
        },
      }

    case CMActions.CitSortCassettesSets:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          ...payload,
          isLoading: true,
        },
      }

    case CMActions.CitSetSelectedCassetteSet:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          selectedCassetteSet: payload,
        },
      }

    case CMActions.CitSetCassetteSetsModalOpen:
      return {
        ...state,
        cassetteSets: {
          ...state.cassetteSets,
          isCassetteSetsModalOpen: payload,
        },
      }

    case CMActions.CitPlnGetCassetteTypesResponse:
      return {
        ...state,
        cassetteTypes: payload,
      }

    // Teams =============================================
    case CMActions.CitPlnGetTeamsRequest:
    case CMActions.CitPlnDeleteTeamRequest:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: true,
        },
      }
    case CMActions.CitPlnGetTeamsResponse:
      return {
        ...state,
        teams: {
          ...state.teams,
          data: payload,
          isLoading: false,
          isLoaded: true,
        },
      }
    case CMActions.CitPlnGetTeamsFail:
    case CMActions.CitPlnDeleteTeamFail:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
          isLoaded: true,
        },
      }

    case CMActions.CitPlnSetCheckedTeams:
      return {
        ...state,
        teamsChecked: payload,
      }
    case CMActions.CitPlnSetSelectedTeam:
      return {
        ...state,
        teams: {
          ...state.teams,
          selected: payload,
        },
      }

    case CMActions.CitPlnSetTeamsModalOpen:
      return {
        ...state,
        teamsModalState: payload,
      }

    case CMActions.CitPlnSortTeamsTable:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: true,
          ...payload,
        },
      }

    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
