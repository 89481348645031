import React from 'react'

import { store } from '@/App'

export const VaultV1Routes = React.lazy(async () => {
  const { injectReducer, injectSaga } = store

  const {
    default: { reducer: vaultV1MonitoringReducer, saga: vaultV1MonitoringSaga },
  } = await import('@/store/vault-v1/monitoring')

  injectReducer('vaultV1Monitoring', vaultV1MonitoringReducer)
  injectSaga('vaultV1Monitoring', vaultV1MonitoringSaga)

  const {
    default: { reducer: vaultV1OrdersReducer, saga: vaultV1OrdersSaga },
  } = await import('@/store/vault-v1/cashOrders')

  injectReducer('vaultV1CashOrders', vaultV1OrdersReducer)
  injectSaga('vaultV1CashOrders', vaultV1OrdersSaga)

  const {
    default: { reducer: vaultV1TeamsReducer, saga: vaultV1TeamsSaga },
  } = await import('@/store/vault-v1/teams')

  injectReducer('vaultV1Teams', vaultV1TeamsReducer)
  injectSaga('vaultV1Teams', vaultV1TeamsSaga)

  const {
    default: { reducer: vaultV1BagsReducer, saga: vaultV1BagsSaga },
  } = await import('@/store/vault-v1/bags')

  injectReducer('vaultV1Bags', vaultV1BagsReducer)
  injectSaga('vaultV1Bags', vaultV1BagsSaga)

  const {
    default: { reducer: vaultV1CassettesReducer, saga: vaultV1CassettesSaga },
  } = await import('@/store/vault-v1/cassettes')

  injectReducer('vaultV1Cassettes', vaultV1CassettesReducer)
  injectSaga('vaultV1Cassettes', vaultV1CassettesSaga)

  const {
    default: { reducer: vaultV1EventJournalReducer, saga: vaultV1EventJournalSaga },
  } = await import('@/store/vault-v1/eventJournal')

  injectReducer('vaultV1EventJournal', vaultV1EventJournalReducer)
  injectSaga('vaultV1EventJournal', vaultV1EventJournalSaga)

  const {
    default: { reducer: vaultV1CashDeskReducer, saga: vaultV1CashDeskSaga },
  } = await import('@/store/vault-v1/cashDesk')

  injectReducer('vaultV1CashDesk', vaultV1CashDeskReducer)
  injectSaga('vaultV1CashDesk', vaultV1CashDeskSaga)

  const {
    default: { reducer: vaultV1AdminReducer, saga: vaultV1AdminSaga },
  } = await import('@/store/vault-v1/admin')

  injectReducer('vaultV1Admin', vaultV1AdminReducer)
  injectSaga('vaultV1Admin', vaultV1AdminSaga)

  return import('@/components/routers/vault-v1')
})
