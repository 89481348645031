import queryString from 'query-string'

import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import { AdmPlnTeam, AdmPlnTeamGroup, AdmPlnUser, ComUuidValue } from '../swaggerGeneratedApi'

export class CitTeamsApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmPlnTeamGroup[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cit/planning/teams',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await CitTeamsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async add(team: AdmPlnTeam): Promise<void> {
    try {
      return await CitTeamsApi.requestService.post('/cit/planning/teams/add', team)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async edit(team: AdmPlnTeam): Promise<void> {
    try {
      return await CitTeamsApi.requestService.put('/cit/planning/teams/update', team)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cit/planning/teams/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await CitTeamsApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getUsers(): Promise<AdmPlnUser[]> {
    try {
      return await CitTeamsApi.requestService.get('/cit/planning/teams/users')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getRoles(): Promise<ComUuidValue[]> {
    try {
      return await CitTeamsApi.requestService.get('/cit/planning/teams/roles')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getVehicles(): Promise<ComUuidValue[]> {
    try {
      return await CitTeamsApi.requestService.get('/cit/planning/teams/vehicles')
    } catch (error) {
      throw Error(error)
    }
  }
}
