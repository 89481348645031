import Box from '@material-ui/core/Box'
import { Alert } from '@material-ui/lab'
import { Field, Form, Formik } from 'formik'
import momentTZ from 'moment-timezone'
import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import Test from 'react-test-attributes'

import Loader from '@/components/blocks/Loader'
import { createTextInputField } from '@/components/controls/TextInput/component'
import { ButtonActionSignIn } from '@/components/pages/common/SignInPage/hooks/useSignInAction'
import { Modules } from '@/constants/auth'
import { LoginFormFields, LoginFormValues } from '@/constants/forms/LoginForm'
import { timezones } from '@/constants/timezones'
import { getAuthError } from '@/store/auth/index'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from '../../styles'
import { Props } from '../../types'
import { validate } from './validate'

const timezoneList = timezones.map(zone => ({
  name: zone.text,
  value: zone.text,
}))
const momentGuess = momentTZ.tz.guess()
const { text: guess } = timezones.find(zone => zone.utc.includes(momentGuess)) || { text: '' }

const LoginForm = ({ onSignIn, isLoggingIn }: Props): React.ReactElement => {
  const error = useSelector(getAuthError)
  const testId = getTestId('login-form')

  const formRef = useRef() as any

  const translate = useTranslate()
  const classes = useClasses()
  const IdentifierInput = createTextInputField({
    fullWidth: true,
    autoComplete: 'off',
    label: translate('translate#login.form.title.user.name'),
  })

  const PasswordInput = createTextInputField({
    fullWidth: true,
    type: 'password',
    label: translate('translate#login.form.title.password'),
  })

  // @ not delete
  // const TimezoneSelect = createSelectField({
  //   required: false,
  //   hideEmptyItem: true,
  //   label: translate('translate#login.form.title.time-zone'),
  //   options: timezoneList,
  //   fontSize: '80%',
  // })

  const handleSubmit = useCallback(
    (values: LoginFormValues): void => {
      onSignIn(values)
    },
    [onSignIn],
  )

  return (
    <Formik
      innerRef={formRef}
      autoComplete="off"
      onSubmit={handleSubmit}
      // validate={validate}
      initialValues={{
        [LoginFormFields.Identifier]: '',
        [LoginFormFields.Password]: '',
        [LoginFormFields.Timezone]: guess,
        [LoginFormFields.Module]: Modules.Other,
      }}
    >
      {({ isValid, dirty }): React.ReactElement => {
        return (
          <Test id={testId(0)}>
            <Form>
              {isLoggingIn && (
                <div className={classes.loaderWrapper}>
                  <Loader />
                </div>
              )}
              {error && (
                <Test id={testId(1)}>
                  <Alert severity="error">
                    {translate(
                      `translate#${
                        error.indexOf('login.error.') === -1
                          ? 'usmServer.login.error.default.message'
                          : error
                      }`,
                    )}
                  </Alert>
                </Test>
              )}
              <Box>
                <Test id={testId(2)}>
                  <Field name={LoginFormFields.Identifier}>{IdentifierInput}</Field>
                </Test>
              </Box>
              <Box>
                <Test id={testId(3)}>
                  <Field name={LoginFormFields.Password}>{PasswordInput}</Field>
                </Test>
              </Box>
              <ButtonActionSignIn
                translate={{ idSubmit: 'action.login', messageSubmit: 'Login' }}
                isDisabled={!(isValid && dirty) || isLoggingIn}
              />
            </Form>
          </Test>
        )
      }}
    </Formik>
  )
}

export default LoginForm
