import { BaseATMeye } from '@/api/atmeye/baseAPIATMeye'
import { ResponseError } from '@/types'

export class NotificationApi<T> extends BaseATMeye<T> {
  constructor(protected data?: T, protected fetchParam?: string) {
    super(data)
    this.data = data
  }

  async getNotificationsList(): Promise<any | ResponseError> {
    try {
      return this.ATMeyeRequestService.get('/atmeye-notifications')
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async closeSecurityAlarm(): Promise<any | ResponseError> {
    try {
      return await this.ATMeyeRequestService.post('alarms/reaction', this.data, true)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async closeTechnicalEvent(): Promise<any | ResponseError> {
    try {
      return await this.ATMeyeRequestService.post('technical-events/reaction', this.data, true)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
