import { AnyAction } from 'redux'

import { STeam, TeamGroup } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'

export interface State {
  teams: {
    data: TeamGroup[]
    isLoading: boolean
    isLoaded: boolean
    sortColumn?: string
    sortOrder?: string

    checkedTeams: {
      [key: string]: {
        name?: string
      }
    }
  }

  selectedTeam: STeam | null

  teamModal: {
    isOpen: boolean
    isEdit: boolean
  }

  isApplyingTeam: boolean
}

const initialState: State = {
  teams: {
    data: [],
    isLoading: false,
    isLoaded: false,
    sortColumn: undefined,
    sortOrder: undefined,
    checkedTeams: {},
  },
  selectedTeam: null,

  teamModal: {
    isOpen: false,
    isEdit: false,
  },

  isApplyingTeam: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.TmsDeleteTeamRequest:
    case VaultV1Actions.TmsGetTeamsRequest:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: true,
        },
      }
    case VaultV1Actions.TmsGetTeamsResponse:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.TmsGetTeamsFail:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
          isLoaded: true,
        },
      }
    case VaultV1Actions.TmsSortTeams:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: true,
          sortColumn: payload.sortColumn,
          sortOrder: payload.sortOrder,
        },
      }

    case VaultV1Actions.TmsDeleteTeamResponse:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
        },
        selectedTeam: null,
      }
    case VaultV1Actions.TmsDeleteTeamFail:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
        },
      }
    case VaultV1Actions.TmsSetSelectedTeam:
      return {
        ...state,
        selectedTeam: payload,
      }

    case VaultV1Actions.TmsSetTeamModalOpen:
      return {
        ...state,
        teamModal: payload,
      }

    case VaultV1Actions.TmsEditTeamRequest:
    case VaultV1Actions.TmsAddTeamRequest:
      return {
        ...state,
        isApplyingTeam: true,
      }

    case VaultV1Actions.TmsAddTeamResponse:
    case VaultV1Actions.TmsEditTeamResponse:
    case VaultV1Actions.TmsEditTeamFail:
    case VaultV1Actions.TmsAddTeamFail:
      return {
        ...state,
        isApplyingTeam: false,
        teamModal: {
          ...state.teamModal,
          isOpen: false,
          isEdit: false,
        },
      }

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
