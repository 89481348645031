import { requestService } from '@/utils/services/request'

export class SecurityAlarmsApi {
  public static async reactToAlarm({ alarmId }: { alarmId: string }) {
    try {
      return await requestService.post(`/alarms/${alarmId}/reaction`, { alarmId })
    } catch (e) {
      throw new Error(e)
    }
  }
}
