import { AnyAction } from 'redux'

import { Filter, GetGroupDetailsResponse, Grouping, Sorting } from '@/api/sd/ticketsApi/types'
import { TableFilter } from '@/components/controls/AppTable/types'
import { ListResponseTypes } from '@/components/NPMPakage/components/ObjectsTree/types'
import { TechnicalEventsActions, TicketActions } from '@/constants'
import { WINDOWS_TYPES } from '@/constants/atmeye/tables/windowTypes'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { DEFAULT_TICKET_DETAILS, ticketConfig } from '@/store/sd/tickets/reducer'
import {
  GroupingTemplate,
  Pagination,
  ParentGroup,
  TicketDetailsData,
  TicketsConfigData,
  TypeOfTickets,
} from '@/types'
import { deepCopy } from '@/utils/deepCopy'
import { getAtmeyeSavedGrouping, getLocalStorageItem } from '@/utils/localStorage'

export interface State {
  selectedGroupingTemplate: string
  data: {
    allTickets: ListResponseTypes
    myTickets: ListResponseTypes
    historyTickets: ListResponseTypes
  }
  groupDetails: GetGroupDetailsResponse

  pageSizeForGroupingTickets: string

  pageCount: number
  filter: {
    allTickets: Filter
    myTickets: Filter
    historyTickets: Filter
  }
  filterTemplateFields: {
    [key: string]: {
      id: string
      displayName: string
    }
  }
  filterFields: {
    allTickets: {
      id: string
      displayName: string
    }
    myTickets: {
      id: string
      displayName: string
    }
    historyTickets: {
      id: string
      displayName: string
    }
  }
  grouping: {
    allTickets: Grouping
    myTickets: Grouping
    historyTickets: Grouping
  }
  ticketDetails: TicketDetailsData
  config: {
    allTickets: TicketsConfigData
    myTickets: TicketsConfigData
    historyTickets: TicketsConfigData
  }
  pagination: Pagination
  sorting: {
    allTickets: Sorting
    myTickets: Sorting
    historyTickets: Sorting
  }
  stringFitler: {
    allTickets: TableFilter | {}
    myTickets: TableFilter | {}
    historyTickets: TableFilter | {}
  }
  typeOfTickets: TypeOfTickets
  rowsPerPage: {
    allTickets: number
    myTickets: number
    historyTickets: number
  }
  page: {
    allTickets: number
    myTickets: number
    historyTickets: number
  }
  media: {
    currentEvent: number | null
    fetchingEnabled: boolean
    data: {
      [key: string]: {
        image: any
        type: string
      }
    }
  }
  parentGroups: ParentGroup[]
  isFetchingTickets: boolean
  isFetchingGroupDetails: boolean
  isSavingSettings: boolean
  isDeletingTicket: boolean
  isFetchingTicket: boolean
  isFetchingGroupTemplates: boolean
  isFetchingFilterTemplates: boolean
  isSavingFilterTemplates: boolean
  isEditingFilterTemplate: boolean
  isSavingGroupTemplate: boolean
  isEditingGroupTemplate: boolean
  isDeletingFilterTemplate: boolean
  isDeletingGroupingTemplate: boolean
  isFetchingTicketsConfig: boolean
  isFetchingTicketDetails: boolean
  downloadFile: string
  isDownloadingFile: boolean
  error: string | null
  isFetchingPagination: boolean
  isOpenFiltersModal: boolean
  isSubmitButtonClicked: boolean
}

const initialTicketConfig = deepCopy(ticketConfig)
const savedGrouping = getAtmeyeSavedGrouping(WINDOWS_TYPES.TECHNICAL_EVENTS)

const lsConfig = getLocalStorageItem(LocalStorageItems.TechnicalEvents)

export const initialState: State = {
  data: {
    allTickets: {
      details: undefined,
      groups: [],
      hasGroups: false,
      totalCount: 0,
    },
    myTickets: { details: undefined, groups: [], hasGroups: false, totalCount: 0 },
    historyTickets: {
      details: undefined,
      groups: [],
      hasGroups: false,
      totalCount: 0,
    },
  },
  groupDetails: null,
  pageCount: 0,
  filter: {
    allTickets: {
      predefinedFilters: {
        availableSortingFields: [],
        deviceTypeItems: [],
        receiveDateItems: {
          end: null,
          start: null,
        },
        statusItems: [],
        serviceTypeItems: [],
      },
      selectedTemplate: lsConfig?.config?.selectedFiltersTemplate || '',
      selectedFilters: lsConfig?.config?.selectedFilters || {},
      filterTemplates: [],
    },
    myTickets: {
      predefinedFilters: {
        availableSortingFields: [],
        deviceTypeItems: [],
        receiveDateItems: {
          end: null,
          start: null,
        },
        statusItems: [],
        serviceTypeItems: [],
      },
      selectedTemplate: '',
      selectedFilters: {},
      filterTemplates: [],
    },
    historyTickets: {
      predefinedFilters: {
        availableSortingFields: [],
        deviceTypeItems: [],
        receiveDateItems: {
          end: null,
          start: null,
        },
        statusItems: [],
        serviceTypeItems: [],
      },
      selectedTemplate: '',
      selectedFilters: {},
      filterTemplates: [],
    },
  },
  filterTemplateFields: {},
  filterFields: {
    allTickets: {
      id: '',
      displayName: '',
    },
    myTickets: {
      id: '',
      displayName: '',
    },
    historyTickets: {
      id: '',
      displayName: '',
    },
  },
  grouping: {
    allTickets: {
      predefinedGroupings: {
        items: [],
      },
      selectedGroupings: savedGrouping?.groups || [],
      groupingsTemplates: [],
    },
    myTickets: {
      predefinedGroupings: {
        items: [],
      },
      selectedGroupings: [],
      groupingsTemplates: [],
    },
    historyTickets: {
      predefinedGroupings: {
        items: [],
      },
      selectedGroupings: [],
      groupingsTemplates: [],
    },
  },
  sorting: {
    allTickets: { atm_date: 'desc' },
    myTickets: { atm_date: 'desc' },
    historyTickets: { atm_date: 'desc' },
  },
  stringFitler: {
    allTickets: {},
    myTickets: {},
    historyTickets: {},
  },
  typeOfTickets: 'allTickets',
  rowsPerPage: {
    allTickets: 50,
    myTickets: 50,
    historyTickets: 50,
  },
  page: {
    allTickets: 1,
    myTickets: 1,
    historyTickets: 1,
  },
  parentGroups: [],
  ticketDetails: deepCopy(DEFAULT_TICKET_DETAILS),
  pagination: {
    pageNumber: 1,
    pageSize: 1,
    totalElements: 1,
    totalPages: 1,
  },
  config: {
    allTickets: initialTicketConfig,
    myTickets: initialTicketConfig,
    historyTickets: initialTicketConfig,
  },
  media: {
    currentEvent: null,
    data: {},
    fetchingEnabled: true,
  },

  selectedGroupingTemplate: savedGrouping?.templateId || '',
  pageSizeForGroupingTickets: '',
  downloadFile: '',
  isDownloadingFile: false,
  isFetchingTickets: false,
  isFetchingGroupDetails: false,
  isFetchingTicket: false,
  isSavingSettings: false,
  isFetchingGroupTemplates: false,
  isSavingGroupTemplate: false,
  isEditingGroupTemplate: false,
  isFetchingFilterTemplates: false,
  isSavingFilterTemplates: false,
  isEditingFilterTemplate: false,
  isDeletingTicket: false,
  isDeletingFilterTemplate: false,
  isDeletingGroupingTemplate: false,
  isFetchingTicketsConfig: false,
  isFetchingTicketDetails: false,
  error: null,
  isFetchingPagination: false,
  isOpenFiltersModal: false,
  isSubmitButtonClicked: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case TechnicalEventsActions.GetTransactionsRequest:
      return {
        ...state,
        isFetchingTickets: true,
      }
    case TechnicalEventsActions.GetTransactionsResponse:
      return {
        ...state,
        data: {
          ...state.data,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.data[state.typeOfTickets],
            hasGroups: payload.hasGroups
              ? payload.hasGroups
              : state.data[state.typeOfTickets].hasGroups,
            groups: payload.groups ? payload.groups : state.data[state.typeOfTickets].groups,
            details: payload.details
              ? {
                  ...state.data[state.typeOfTickets].details,
                  items: payload.details ? payload.details.items : null,
                }
              : state.data[state.typeOfTickets].details,
            totalCount:
              payload.totalCount != null
                ? payload.totalCount
                : state.data[state.typeOfTickets] != null &&
                  state.data[state.typeOfTickets].totalCount != null
                ? state.data[state.typeOfTickets].totalCount
                : null,
          },
        },
        isFetchingTickets: false,
        isOpenFiltersModal: state.isSubmitButtonClicked ? false : state.isOpenFiltersModal,
        error: null,
        isSubmitButtonClicked: false,
      }
    case TechnicalEventsActions.GetTicketResponseFail:
      return {
        ...state,
        error: payload.error,
        isFetchingTickets: false,
        isSubmitButtonClicked: false,
      }
    case TechnicalEventsActions.IsSubmitFiltersButtonClicked:
      return {
        ...state,
        isSubmitButtonClicked: true,
      }
    case TechnicalEventsActions.SetFilterModalState:
      return {
        ...state,
        isOpenFiltersModal: payload,
      }
    case TechnicalEventsActions.GetTechnicalEventsExport:
      return {
        ...state,
        isDownloadingFile: true,
      }
    case TechnicalEventsActions.GetExportResponse:
      return {
        ...state,
        downloadFile: payload,
        isDownloadingFile: false,
      }
    case TechnicalEventsActions.GetExportResponseFail:
      return {
        ...state,
        error: payload,
        isDownloadingFile: false,
      }
    case TechnicalEventsActions.GetTechnicalEventsExportGrouped:
      return {
        ...state,
        isDownloadingFile: true,
      }
    case TechnicalEventsActions.GetExportGroupedResponse:
      return {
        ...state,
        downloadFile: payload,
        isDownloadingFile: false,
      }
    case TechnicalEventsActions.GetExportGroupedResponseFail:
      return {
        ...state,
        error: payload,
        isDownloadingFile: false,
      }
    case TechnicalEventsActions.GetGroupDetailsRequest:
      return {
        ...state,
        isFetchingGroupDetails: true,
      }
    case TicketActions.GetGroupDetailsResponse:
      return {
        ...state,
        groupDetails: Array.isArray(state.groupDetails)
          ? [...state.groupDetails, ...payload.details.items]
          : [...payload.details.items],
        isFetchingGroupDetails: false,
      }

    case TicketActions.GetGroupDetailsResponseFail:
      return {
        ...state,
        error: payload,
        isFetchingGroupDetails: false,
      }
    case TechnicalEventsActions.SetParentGroups:
      return {
        ...state,
        parentGroups: payload,
      }
    case TechnicalEventsActions.CollectFilterTemplateFields: {
      const updatedFields = {
        ...state.filterTemplateFields,
        [payload.fieldName]: payload.value,
      }
      return {
        ...state,
        filterTemplateFields: { ...updatedFields },
        filterFields: {
          ...state.filterFields,
          [state.typeOfTickets as TypeOfTickets]: {
            filterFields: { ...updatedFields },
          },
        },
      }
    }
    case TechnicalEventsActions.ChangeSelectedFilters:
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            selectedFilters: payload,
          },
        },
        isFetchingTickets: true,
      }

    case TechnicalEventsActions.SetSdFilterTemplateResponse: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            filterTemplates: payload,
          },
        },
      }
    }

    case TechnicalEventsActions.CreateSdFilterTemplateResponse: {
      const updatedFilterTemplatesArray = [
        ...state.filter[state.typeOfTickets as TypeOfTickets].filterTemplates,
        payload,
      ]
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            filterTemplates: updatedFilterTemplatesArray,
          },
        },
      }
    }

    case TechnicalEventsActions.UpdateSdFilterTemplateResponse: {
      const updatedTemplateArray = state.filter[
        state.typeOfTickets as TypeOfTickets
      ].filterTemplates.map((template: any) =>
        template.id === payload.id ? { ...template, name: payload.name } : template,
      )
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            filterTemplates: updatedTemplateArray,
          },
        },
      }
    }

    case TechnicalEventsActions.DeleteFilterTemplateResponse: {
      const updatedTemplateArray = state.filter[
        state.typeOfTickets as TypeOfTickets
      ].filterTemplates.filter((template: any) => template.id !== payload.id)
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            filterTemplates: updatedTemplateArray,
          },
        },
      }
    }

    case TechnicalEventsActions.GetGroupingTemplatesRequest:
      return {
        ...state,
        isFetchingGroupTemplates: true,
      }
    case TicketActions.GetGroupingTemplatesResponse:
      return {
        ...state,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            groupingsTemplates: payload,
          },
        },
        isFetchingGroupTemplates: false,
      }
    case TechnicalEventsActions.SetGroupTemplates: {
      const { templates, withAppendNewTemplate } = payload
      const arrayWithNewAppendedTemplate = [
        ...(state.grouping[state.typeOfTickets as TypeOfTickets].groupingsTemplates
          ? state.grouping[state.typeOfTickets as TypeOfTickets].groupingsTemplates
          : []),
        ...templates,
      ]
      return {
        ...state,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            groupingsTemplates: withAppendNewTemplate ? arrayWithNewAppendedTemplate : templates,
          },
        },
        isFetchingGroupTemplates: false,
      }
    }
    case TechnicalEventsActions.EditSdGroupTemplateFromResponse: {
      const resultArray = state.grouping[
        state.typeOfTickets as TypeOfTickets
      ].groupingsTemplates.map((tem: GroupingTemplate) => (tem.id !== payload.id ? tem : payload))
      return {
        ...state,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            groupingsTemplates: resultArray,
          },
        },
      }
    }
    case TicketActions.DeleteSdGroupTemplateResponseSuccess: {
      const resultArray = state.grouping[
        state.typeOfTickets as TypeOfTickets
      ].groupingsTemplates.filter((tem: GroupingTemplate) => tem.id !== payload.id)

      return {
        ...state,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            groupingsTemplates: resultArray,
          },
        },
      }
    }
    case TicketActions.GetGroupingTemplatesResponseFail:
      return {
        ...state,
        error: payload,
        isFetchingGroupTemplates: false,
      }

    case TechnicalEventsActions.SetCurrentFilterTemplate:
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            selectedTemplate: payload,
          },
        },
      }
    case TicketActions.GetTicketsConfigRequest:
      return { ...state, isFetchingTicketsConfig: true, error: null }
    case TechnicalEventsActions.GetTicketsConfigResponse:
      return {
        ...state,
        isFetchingTicketsConfig: false,
        config: {
          ...state.config,
          [payload.typeOfTickets]: payload.config,
        },
      }
    case TicketActions.GetTicketsConfigResponseFail:
      return { ...state, isFetchingTicketsConfig: false, error: payload }

    case TicketActions.GetTicketDetailsResponse:
      return { ...state, isFetchingTicketDetails: false, ticketDetails: payload }
    case TicketActions.GetTicketDetailsResponseFail:
      return {
        ...state,
        isFetchingTicketDetails: false,
        error: payload,
        ticketDetails: initialState.ticketDetails,
      }
    case TechnicalEventsActions.ChangeSorting:
      return {
        ...state,
        sorting: { ...state.sorting, [state.typeOfTickets as TypeOfTickets]: payload },
      }

    case TechnicalEventsActions.SetRowPerPage:
      return {
        ...state,
        rowsPerPage: { ...state.rowsPerPage, [state.typeOfTickets as TypeOfTickets]: payload },
      }
    case TechnicalEventsActions.SetPage:
      return { ...state, page: { ...state.page, [state.typeOfTickets as TypeOfTickets]: payload } }
    case TechnicalEventsActions.ChangeGrouping:
      return {
        ...state,
        isFetchingTickets: true,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            selectedGroupings: payload,
          },
        },
      }
    case TicketActions.SetStringFilter:
      return {
        ...state,
        stringFitler: { ...state.stringFitler, [state.typeOfTickets as TypeOfTickets]: payload },
      }
    case TechnicalEventsActions.ClearGroupedTicketDetailsData:
      return {
        ...state,
        page: initialState.page,
        groupDetails: initialState.groupDetails,
      }

    case TechnicalEventsActions.SetMedia:
      return {
        ...state,
        media: {
          currentEvent: payload.eventId,
          data: {
            ...state.media.data,
            [payload.eventId]: {
              image: payload.image,
              type: payload.type,
            },
          },
          fetchingEnabled: payload.fetchingEnabled,
        },
      }
    case TechnicalEventsActions.ClearCurrentMedia:
      return {
        ...state,
        media: {
          currentEvent: null,
          data: {},
          fetchingEnabled: false,
        },
      }
    case TechnicalEventsActions.SetCurrentMedia:
      return {
        ...state,
        media: {
          currentEvent: payload.eventId,
          data: {
            ...state.media.data,
          },
          fetchingEnabled: state.media.fetchingEnabled,
        },
      }
    case TechnicalEventsActions.SetMediaErrorMessage:
      return {
        ...state,
        media: {
          currentEvent: payload.eventId,
          data: {
            [payload.eventId]: {
              image: {
                errorMessage: payload.message,
              },
            },
            ...state.media.data,
          },
          fetchingEnabled: state.media.fetchingEnabled,
        },
      }
    case TechnicalEventsActions.SaveTableCell: {
      const newItems = [...state.data[state.typeOfTickets as TypeOfTickets].details?.items]
      if (!payload.nesting) {
        newItems[payload.parentIndex].item[payload.fieldId] = payload.fieldValue
      } else {
        newItems[payload.parentIndex].childItems[payload.rowIndex][payload.fieldId] =
          payload.fieldValue
      }
      return {
        ...state,
        data: {
          ...state.data,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.data[state.typeOfTickets as TypeOfTickets],
            details: {
              ...state.data[state.typeOfTickets as TypeOfTickets].details,
              items: newItems,
            },
          },
        },
      }
    }
    case TechnicalEventsActions.SaveTableCellGrouping: {
      const newItems = [...state.groupDetails]
      newItems[payload.parentIndex].item[payload.fieldId] = payload.fieldValue
      return {
        ...state,
        groupDetails: newItems,
      }
    }
    case TechnicalEventsActions.SetDataPaginationRequest:
      return {
        ...state,
        isFetchingPagination: true,
      }
    case TechnicalEventsActions.SetDataPagination: {
      return {
        ...state,
        isFetchingPagination: false,
        data: {
          ...state.data,
          [state.typeOfTickets as TypeOfTickets]: {
            totalCount: payload.totalCount,
            details: {
              ...state.data[state.typeOfTickets].details,
              pageCount: payload.pageCount,
              outputPageNo: payload.outputPageNo,
            },
          },
        },
      }
    }
    case TechnicalEventsActions.SetSelectedGroupingTemplate: {
      return {
        ...state,
        selectedGroupingTemplate: payload,
      }
    }
    default:
      return state
  }
}
