import faker from 'faker'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { APIConfiguration, cmBaseUrl, LocalStorageItems } from '@/constants'
import { getUserDataLocalStorage, setDataToLocalStorage } from '@/helpers/localStorage'
import { getSocketData, setCMSocketData, setSortersStatus } from '@/store/cm/common'
import { PopUpService } from '@/utils/services/popUpService'

import { CMSocketProps, Message, MessageProps, MessageType, ResponseMessage } from './types'

const setLocalStorageWithBaseUrl = setDataToLocalStorage(cmBaseUrl)

export const Types = {
  [MessageType.LoginRequest]: 0,
  [MessageType.GetAvailableSortersRequest]: 1,

  [MessageType.TakeSorterRequest]: 2,
  [MessageType.ReleaseSorterRequest]: 3,

  [MessageType.GetSorterStatusRequest]: 4,
  [MessageType.SorterStatusResponse]: 6,

  [MessageType.AckResponse]: 7,
  [MessageType.UpdateAvailableSortersNotification]: 8,
  [MessageType.CountDataNotification]: 9,
}

const commandBase = {
  [MessageType.LoginRequest]: '',
  [MessageType.GetAvailableSortersRequest]: '',
  [MessageType.TakeSorterRequest]: '',
  [MessageType.ReleaseSorterRequest]: '',
  [MessageType.GetSorterStatusRequest]: '',

  [MessageType.AckResponse]: '',
  [MessageType.UpdateAvailableSortersNotification]: '',
  [MessageType.CountDataNotification]: 9,
}

export const getSocketsKey = (baseUrl: string): any => {
  const webSocketString = localStorage.getItem(LocalStorageItems.WebSocket) || '{}'

  const webSocket = JSON.parse(webSocketString)

  if (!webSocket[baseUrl]) {
    setLocalStorageWithBaseUrl(LocalStorageItems.WebSocket, JSON.stringify(commandBase))
  }

  return webSocket[baseUrl] || commandBase
}

export const generateMessage = ({ typeKey, payload = '' }: MessageProps): Message => {
  const fakeGuid = faker.random.uuid()

  const command = getSocketsKey(cmBaseUrl)

  command[typeKey] = fakeGuid

  setLocalStorageWithBaseUrl(LocalStorageItems.WebSocket, JSON.stringify(command))

  return {
    MessageId: fakeGuid,
    OriginalMessageId: null,
    Type: Types[typeKey],
    Payload: payload,
  }
}

export const useSocketData = (socketKey: string): any => {
  const command = getSocketsKey(cmBaseUrl)

  return useSelector(getSocketData(command[socketKey]))
}

const parseResponseData = (data: string): ResponseMessage => {
  const parsedData = JSON.parse(data)

  return { ...parsedData, Payload: JSON.parse(parsedData.Payload || '{}') || '' }
}

export const useCMWebSocket = (): CMSocketProps => {
  const dispatch = useDispatch()

  const socket = useRef<WebSocket | null>(null)
  const isConnected = useRef<boolean>(false)

  const { id, login, isSortersEnabled } = getUserDataLocalStorage(cmBaseUrl) || {}
  const token = localStorage.getItem(LocalStorageItems.AccessTokenCM)

  useEffect(() => {
    if (isSortersEnabled && !socket.current && !isConnected.current) {
      try {
        socket.current = new WebSocket(APIConfiguration.CM_WSS_SOCKET_API_URL)

        socket.current.onopen = (): void => {
          isConnected.current = true

          setLocalStorageWithBaseUrl(LocalStorageItems.WebSocket, JSON.stringify(commandBase))
          console.log('🚀 ~ socket.opened')

          if (login && id && socket.current) {
            const payload = {
              UserId: id,
              UserName: login,
              Options: [0],
              Token: token,
            }
            const message = generateMessage({
              typeKey: MessageType.LoginRequest,
              payload: JSON.stringify(payload),
            })

            socket.current.send(JSON.stringify(message))
          }
        }

        socket.current.onmessage = (event: MessageEvent): void => {
          const parsedData = parseResponseData(event.data)

          if (parsedData.Type === Types[MessageType.AckResponse]) {
            if (parsedData.Payload?.Result === 2) {
              PopUpService.showGlobalPopUp(parsedData.Payload?.Message, 'error')

              return
            }
          }

          if (parsedData.Type !== Types[MessageType.SorterStatusResponse]) {
            const message = generateMessage({
              typeKey: MessageType.GetSorterStatusRequest,
            })
            if (socket.current) {
              socket.current.send(JSON.stringify(message))
            }
          }

          if (parsedData.Type === Types[MessageType.SorterStatusResponse]) {
            dispatch(
              setSortersStatus({
                isOnline: parsedData.Payload.IsOnline,
                sorter: parsedData.Payload.Name,
              }),
            )
            return
          }

          dispatch(
            setCMSocketData({
              [parsedData.OriginalMessageId || parsedData.Type]: parsedData.Payload,
            }),
          )
        }

        socket.current.onclose = (): void => {
          socket.current = null
          isConnected.current = false
          setLocalStorageWithBaseUrl(LocalStorageItems.WebSocket, JSON.stringify(commandBase))
          console.log('🚀 ~ socket.closed')
        }

        socket.current.onerror = (): void => {
          console.log('🚀 ~ socket.error')
        }
      } catch (error) {
        console.log('🚀 ~ WS ~ error', error)
      }
    }
    return (): void => {
      if (socket.current) {
        socket.current.close()
      }
    }
  }, [])

  return { socket: socket.current, isConnected: isConnected.current }
}
