import { BaseUSM } from '@/api/usm'
import { ResponseTypes } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/shortNameLookup/types'
import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import {
  PersonalCardTypes,
  UpdatePersonalCardTypes,
  UserInformationTypes,
} from '@/types/usm/personalInformation/personalInformationTypes'
import { requestService } from '@/utils/services'

export class USMApi<T> extends BaseUSM<T> {
  constructor(
    protected readonly data: T,
    private readonly firstQuery?: string | number | unknown,
    private readonly secondQuery?: string | number | unknown,
  ) {
    super(data)
    this.data = data
    this.firstQuery = firstQuery
    this.secondQuery = secondQuery
  }

  // getting personal card
  public async getPersonalCard(): Promise<PersonalCardTypes> {
    try {
      const res = await this.USMRequestService.get(`/user-account/user-info/${this.data}`)
      return res
    } catch (e) {
      // throw Reflect.construct(Error, [e])
      return Promise.reject(e)
    }
  }

  public async upDateUserCard(): Promise<UpdatePersonalCardTypes> {
    try {
      return await this.USMRequestService.put(`/user-account/${this.firstQuery}`, this.data)
    } catch (e) {
      // throw Reflect.construct(Error, [e])
      return Promise.reject(e)
    }
  }

  public async addNewUser(): Promise<PersonalCardTypes> {
    try {
      return await this.USMRequestService.post(`/user-account/add-user`, this.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public async changeUser(): Promise<UserInformationTypes | ResponseErrorTypes> {
    try {
      return await this.USMRequestService.post(`user-account/edit-user`, this.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // add-new-user-to user-info-block
  public async addUserInfo(): Promise<UserInformationTypes | ResponseErrorTypes> {
    try {
      return await this.USMRequestService.post(
        `/user-account/add-user-info?loginId=${this.firstQuery}&personId=${this.secondQuery}`,
        this.data,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // delete-user-info-block
  public async deleteUserInfo() {
    try {
      return await this.USMRequestService.delete(
        `/user-account/${this.firstQuery}/${this.secondQuery}`,
        true,
        {
          data: this.data,
        },
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }
  // lookup-service ================================

  public async getLookupConfigCreateUser() {
    try {
      return await this.USMRequestService.get(`/user/lookup-config/create-form`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public async getLookupConfigEditUser() {
    try {
      return await this.USMRequestService.get(`/user/lookup-config/edit-form`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public static async getCompanyNameAndShortName(
    searchFragment: string,
  ): Promise<Array<ResponseTypes>> {
    try {
      return await requestService.get(
        `/companies/company/list-shortname/allowed?searchFragment=${searchFragment}`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
