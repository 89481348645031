export default {
  primaryLight: '#f2fdfe',
  primary: '#50a8ae',
  primaryDark: '#3c7378',
  secondary: '#1a2046',
  secondaryLight: '#232953',
  danger: '#cb362d',
  dangerLight: '#f7e1df',
  text: '#000000',
  textLight: '#424242',
  textDisabled: '#606060',
  grey50: '#f7f8f9',
  grey100: '#e4e4e4',
  grey200: '#bdbdbd',
  grey300: '#b3bcbc',
  grey400: '#555555',
  background: '#ffffff',
  backgroundMenu: '#183280',
  backgroundHeaderMenu: '#F9F9F9',
  backgroundLogin: '#25ADBF',
  cameraIcon: '#229dad',
  green: '#1ad36f',
  products: {
    primary: '#25ADBF',
    primaryLight: '#eaf7f9',
    secondary: '#929A9B',
    secondaryFont: '#CAD5D7',
    secondaryBackground: '#ECF2F2',
    danger: '#E45B4A',
    warning: '#FBC91B',
    dangerLight: '#FDEFED',
    black: '#041517',
    greyLight: '#F9F9F9',
  },
  cmTable: {
    primaryDark: '#25adbf85',
    primaryLight: '#CDF2F8',
    text: '#7b8385',
    lightGray: '#f2f2f2',
    border: 'rgba(224, 224, 224, 1)',
  },
}
