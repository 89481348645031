import { AppState } from '@/store/reducers'
import { createSelector } from 'reselect'
import * as fromAuthVaultV1 from './reducer'

export const getAuthStateVaultV1 = (state: AppState): fromAuthVaultV1.State => state.vaultV1Auth

export const getChangePasswordState = createSelector(
  getAuthStateVaultV1,
  state => state.changePasswordState,
)
