import { AnyAction } from 'redux'

import { VaultBag } from '@/api/vault-v1/swaggerGeneratedApi'
import { BagModalNames } from '@/components/pages/vault-v1/Bags/types'
import { VaultV1Actions, VaultV1BagsActions } from '@/constants'
import { bagTableId as tableId } from '@/constants/vault-v1/tableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

import { VaultBagsFilter } from './types'

export type State = {
  modals: {
    settingsModal: boolean
    documentModal: boolean
    filtersModal: boolean

    bagModalType: BagModalNames | ''

    bagModalLoading: boolean
  }

  bags: {
    canCreateBag: boolean
    data: VaultBag[]
    total: number
    isLoading: boolean
    isLoaded: boolean

    errorMessage?: string
    sortColumn?: string
    sortOrder?: 'asc' | 'desc'

    selected: VaultBag | null

    filter: VaultBagsFilter

    paging: {
      page: number
      pageSize: number
    }
  }

  details: {
    isDetailsChanged: boolean
  }
}

export const initialState: State = {
  modals: {
    settingsModal: false,
    documentModal: false,
    filtersModal: false,

    bagModalType: '',

    bagModalLoading: false,
  },

  bags: {
    canCreateBag: false,
    data: [],
    total: 0,
    isLoading: false,
    isLoaded: false,

    errorMessage: '',
    sortColumn: undefined,
    sortOrder: undefined,

    selected: null,

    filter: {
      name: undefined,
      location: undefined,
      status: undefined,
      comment: undefined,
      cassetteSetId: undefined,
      cashierCode: undefined,
      companyName: undefined,
      matchWholeWord: false,
    },

    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
  },

  details: {
    isDetailsChanged: false,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1BagsActions.BgsGetBagsRequest:
    case VaultV1BagsActions.BgsGetBagRequest:
    case VaultV1BagsActions.BgsDeleteBagRequest:
      return {
        ...state,
        bags: {
          ...state.bags,
          isLoading: true,
          paging: {
            ...state.bags.paging,
            page: payload?.initialPage ? initialState.bags.paging.page : state.bags.paging.page,
          },
        },
      }
    case VaultV1BagsActions.BgsGetBagsResponse:
      return {
        ...state,
        bags: { ...state.bags, isLoading: false, isLoaded: true, ...payload },
      }
    case VaultV1BagsActions.BgsGetBagsFail:
    case VaultV1BagsActions.BgsGetBagFail:
    case VaultV1BagsActions.BgsDeleteBagFail:
      return {
        ...state,
        bags: { ...state.bags, isLoading: false, errorMessage: payload },
      }

    case VaultV1BagsActions.BgsGetBagResponse: {
      const copyData = [...state.bags.data]
      const refreshedIndex = copyData.findIndex(el => el.id === payload.id)

      if (refreshedIndex !== undefined && refreshedIndex !== null) {
        copyData[refreshedIndex] = { ...payload }

        return {
          ...state,
          bags: {
            ...state.bags,
            data: copyData,
            isLoading: false,
            selected: payload.id === state.bags.selected?.id ? payload : state.bags.selected,
          },
        }
      }

      return state
    }

    case VaultV1BagsActions.BgsSetFilter:
      return {
        ...state,
        bags: {
          ...state.bags,
          selected: null,
          isLoading: true,
          paging: {
            ...state.bags.paging,
            page: initialState.bags.paging.page,
          },
          filter: {
            ...state.bags.filter,
            ...payload,
          },
        },
      }

    case VaultV1BagsActions.BgsSetSelectedBag: {
      return {
        ...state,
        bags: {
          ...state.bags,
          selected: payload,
        },
      }
    }
    case VaultV1BagsActions.BgsSortBags:
      return {
        ...state,
        bags: {
          ...state.bags,
          ...payload,
          isLoading: true,
        },
      }
    case VaultV1BagsActions.BgsPagingBags:
      return {
        ...state,
        bags: {
          ...state.bags,
          isLoading: true,
          paging: {
            ...state.bags.paging,
            ...payload,
          },
        },
      }

    case VaultV1BagsActions.BgsSetModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.type]: payload.isOpen,
        },
      }
    case VaultV1BagsActions.BgsSetBagModalType:
      return {
        ...state,
        modals: {
          ...state.modals,
          bagModalType: payload || '',
        },
      }
    case VaultV1BagsActions.BgsAddEditBagsRequest:
    case VaultV1BagsActions.BgsUnloadBagRequest:
    case VaultV1BagsActions.BgsEditBagRequest:
    case VaultV1BagsActions.BgsLoadBagRequest:
      return {
        ...state,
        modals: {
          ...state.modals,
          bagModalLoading: true,
        },
      }
    case VaultV1BagsActions.BgsAddEditBagsResponse:
    case VaultV1BagsActions.BgsLoadBagResponse:
    case VaultV1BagsActions.BgsEditBagResponse:
    case VaultV1BagsActions.BgsUnloadBagResponse:
      return {
        ...state,
        modals: {
          ...state.modals,
          bagModalType: initialState.modals.bagModalType,
          bagModalLoading: false,
        },
      }
    case VaultV1BagsActions.BgsAddEditBagsFail:
    case VaultV1BagsActions.BgsUnloadBagFail:
    case VaultV1BagsActions.BgsEditBagFail:
    case VaultV1BagsActions.BgsLoadBagFail:
      return {
        ...state,
        modals: {
          ...state.modals,
          bagModalLoading: false,
        },
      }

    case VaultV1BagsActions.BgsSetDetailsIsChanged:
      return {
        ...state,
        details: {
          ...state.details,
          isDetailsChanged: payload,
        },
      }

    case VaultV1BagsActions.BgsSetSearchingFields:
      return {
        ...state,
        bags: {
          ...state.bags,
          filter: {
            ...state.bags.filter,
            ...payload,
          },
        },
      }
    case VaultV1Actions.SignOut:
      return initialState

    default:
      return state
  }
}
