import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { Formik, FormikProps } from 'formik'
import React, { DragEventHandler, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import Test from 'react-test-attributes'

import TicketDetailModal from '@/components/blocks/TicketDetailsModal'
import { AdvancedSearchModal } from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/AdvancedSearchModal/component'
import {
  CreateNewTicketFormFields,
  CreateNewTicketFormValues,
} from '@/constants/forms/CreateNewTicketForm'
import { addTicketRequest } from '@/store/sd/drawerActions/actions'
import { getTicketDetailsResponse } from '@/store/sd/tickets/actions'
import { DEFAULT_TICKET_DETAILS } from '@/store/sd/tickets/reducer'
import { checkFile } from '@/utils/fileOperations'
import { preventDefault } from '@/utils/functions'
import { getTestId } from '@/utils/getTestId'

import CreateNewTicketForm from './components/CreateNewTicketForm/index'
import { useClasses } from './styles'
import { Props } from './types'
import { validate } from './utils/validate'

const formInitialValues = {
  [CreateNewTicketFormFields.Customer]: '',
  [CreateNewTicketFormFields.ContactPerson]: '',
  [CreateNewTicketFormFields.RequestNumber]: '',
  [CreateNewTicketFormFields.UnitId]: '',
  [CreateNewTicketFormFields.ServiceCompanyId]: '',
  [CreateNewTicketFormFields.Notes]: '',
  [CreateNewTicketFormFields.Files]: [],
}

const CreateNewTicketModal = ({
  open,
  handleClose,
  disableBackdropClick,
  disableEscapeKeyDown,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const [displayDnDPopUp, setDisplayDnDPopUp] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [isTicketDetailsOpened, setIsTicketDetailsOpened] = useState(false)
  const idPrefix = 'sd-tickets-create-new-ticket-modal'
  const testId = getTestId(idPrefix)

  const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown'): void | boolean => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof handleClose === 'function') {
      handleClose()
    }
  }

  useEffect((): void => {
    // dispatch(getTicketValuesRequest())
  }, [dispatch])

  const handleSearchClose = useCallback((): void => {
    setSearchOpen(false)
    // @todo need we this ?
    // dispatch(setInitialUnitsAdvanced())
  }, [])

  const handleSearchOpen = useCallback((): void => {
    setSearchOpen(true)
  }, [])

  const handleSubmit = useCallback(
    (values: CreateNewTicketFormValues): void => {
      if (!values.attachments.some(file => !checkFile(file))) {
        dispatch(addTicketRequest(values))
        setIsTicketDetailsOpened(true)
        handleClose()
      }
    },
    [dispatch, handleClose],
  )

  const handleDragStart = useCallback((e: SyntheticEvent): void => {
    e.stopPropagation()
    setDisplayDnDPopUp(true)
  }, [])

  const handleDragEnd = useCallback((e: SyntheticEvent): void => {
    e.stopPropagation()
    setDisplayDnDPopUp(false)
  }, [])

  const handleFileDrop = useCallback<
    (
      setField: (name: string, value: File[]) => void,
      prevValues: File[],
    ) => DragEventHandler<HTMLDivElement>
  >(
    (setField, prevValues) => (e): void => {
      e.stopPropagation()
      e.preventDefault()
      setField(CreateNewTicketFormFields.Files, [
        ...prevValues,
        ...Array.from(e.dataTransfer.files || []),
      ])
      setDisplayDnDPopUp(false)
    },
    [],
  )

  const handleAdvancedSearchSave = useCallback(
    (setField: (name: string, value: string) => void): ((value: string) => void) => (
      value,
    ): void => {
      setField(CreateNewTicketFormFields.UnitId, value)
    },
    [],
  )

  const handleCloseTicketDetailModal = () => {
    setIsTicketDetailsOpened(false)
    dispatch(getTicketDetailsResponse({ ticketDetails: DEFAULT_TICKET_DETAILS }))
  }

  return (
    <>
      {isTicketDetailsOpened && (
        <TicketDetailModal
          open={isTicketDetailsOpened}
          ticketId={null}
          handleClose={handleCloseTicketDetailModal}
        />
      )}
      <Test id={testId(0)}>
        <Test id={testId(1)}>
          <Dialog
            onDragOver={preventDefault}
            onDrop={preventDefault}
            fullWidth
            open={open}
            classes={{
              paper: clsx({ [classes.hideOverflow]: displayDnDPopUp }, classes.dialog),
            }}
            onClose={onClose}
          >
            <Box
              display="flex"
              flexDirection="column"
              overflow="auto"
              onDragEnter={handleDragStart}
            >
              <Formik onSubmit={handleSubmit} validate={validate} initialValues={formInitialValues}>
                {({
                  values,
                  setFieldValue,
                  setTouched,
                  ...formProps
                }: FormikProps<CreateNewTicketFormValues>): React.ReactElement => {
                  return (
                    <>
                      {displayDnDPopUp && (
                        <div
                          onDragLeave={handleDragEnd}
                          onDragOver={preventDefault}
                          onDrop={handleFileDrop(
                            setFieldValue,
                            values[CreateNewTicketFormFields.Files],
                          )}
                          className={classes.fileDnDPopUp}
                        >
                          <Test id={testId(2)}>
                            <Typography variant="h3" className={classes.DnDText}>
                              <FormattedMessage
                                id="title.dropFilesHere"
                                defaultMessage="Drop files here"
                              />
                            </Typography>
                          </Test>
                        </div>
                      )}
                      <Test id={testId(3)}>
                        <IconButton
                          size="small"
                          className={classes.closeButton}
                          onClick={handleClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Test>
                      <CreateNewTicketForm
                        values={values}
                        handleSearchOpen={handleSearchOpen}
                        setFieldValue={setFieldValue}
                        handleClose={handleClose}
                        setTouched={setTouched}
                        {...formProps}
                      />
                      <AdvancedSearchModal
                        handleSave={handleAdvancedSearchSave(setFieldValue)}
                        handleClose={handleSearchClose}
                        open={searchOpen}
                      />
                    </>
                  )
                }}
              </Formik>
            </Box>
          </Dialog>
        </Test>
      </Test>
    </>
  )
}

export default CreateNewTicketModal
