import { AnyAction } from 'redux'

import { STeam, TeamGroup } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions/index'

export const getTeamsRequest = ({ isAdmin = false }: { isAdmin?: boolean }): AnyAction => ({
  type: VaultV1Actions.TmsGetTeamsRequest,
  payload: { isAdmin },
})
export const getTeamsResponse = (payload: TeamGroup[]): AnyAction => ({
  type: VaultV1Actions.TmsGetTeamsResponse,
  payload,
})
export const getTeamsFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.TmsGetTeamsFail,
  payload: error,
})

export const sortTeamsTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
  isAdmin?: boolean
}): AnyAction => ({
  type: VaultV1Actions.TmsSortTeams,
  payload,
})

export const setSelectedTeam = (payload: STeam | null): AnyAction => ({
  type: VaultV1Actions.TmsSetSelectedTeam,
  payload,
})

export const setTeamModalOpen = ({
  isOpen,
  isEdit = false,
}: {
  isOpen: boolean
  isEdit?: boolean
}): AnyAction => ({
  type: VaultV1Actions.TmsSetTeamModalOpen,
  payload: { isOpen, isEdit },
})

// add
export const addTeamRequest = ({
  isAdmin = false,
  team,
}: {
  isAdmin?: boolean
  team: STeam
}): AnyAction => ({
  type: VaultV1Actions.TmsAddTeamRequest,
  payload: { isAdmin, team },
})
export const addTeamResponse = (): AnyAction => ({
  type: VaultV1Actions.TmsAddTeamResponse,
})
export const addTeamFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.TmsAddTeamFail,
  payload: error,
})

// add
export const editTeamRequest = ({
  isAdmin = false,
  team,
}: {
  isAdmin?: boolean
  team: STeam
}): AnyAction => ({
  type: VaultV1Actions.TmsEditTeamRequest,
  payload: { isAdmin, team },
})
export const editTeamResponse = (): AnyAction => ({
  type: VaultV1Actions.TmsEditTeamResponse,
})
export const editTeamFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.TmsEditTeamFail,
  payload: error,
})

export const deleteTeamRequest = ({
  isAdmin = false,
  id,
}: {
  isAdmin?: boolean
  id: string
}): AnyAction => ({
  type: VaultV1Actions.TmsDeleteTeamRequest,
  payload: { isAdmin, id },
})
export const deleteTeamResponse = (): AnyAction => ({
  type: VaultV1Actions.TmsDeleteTeamResponse,
})
export const deleteTeamFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.TmsDeleteTeamFail,
  payload: error,
})
