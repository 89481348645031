import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

import { useTableWithBorder } from '@/components/blocks/cm/CMTable/hooks'

import { CMTableCell, useCommonClasses } from '../../../commonStyles'
import { Props } from './types'

export const TableCell: FC<Props> = React.memo((props: Props) => {
  const {
    isFixed,
    isFixedLast,
    cellClasses,
    isTwiceCell,
    isNumber,
    isRightSpace,
    children,
    className,
    ...rest
  } = props

  const commonClasses = useCommonClasses()

  const isBorder = useTableWithBorder()

  const localClassName = useMemo(
    () =>
      clsx(cellClasses, {
        [commonClasses.fixed]: isFixed,
        [commonClasses.fixedLast]: isFixedLast,
        [commonClasses.rightSpace]: isRightSpace && !isBorder,
        [commonClasses.rightAlign]: isNumber,
      }),
    [commonClasses, cellClasses, isFixed, isFixedLast, isRightSpace, isNumber, isBorder],
  )

  return !isTwiceCell ? (
    <CMTableCell {...rest} className={clsx(localClassName, className)}>
      {children}
    </CMTableCell>
  ) : (
    <>{children}</>
  )
})
