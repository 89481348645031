export enum FiscalRegistrationField {
  ServiceRequestNumberId = 'serviceRequestNumberId',
  ServiceRequestNumberName = 'serviceRequestNumberName',
  PosSNId = 'posSnId',
  PosSNName = 'posSnName',
  ServiceRequestID = 'serviceRequestId',
  SamModuleId = 'samModuleId',
  SamModuleName = 'samModuleName',
  ServiceRequestDate = 'serviceRequestDate',
  PayerCode = 'payerCode',
  PayerName = 'payerName',
  ObjectAddress = 'objectAddress',
  BrandName = 'brandName',
  ActivityType = 'activityType',
  ObjectType = 'objectType',
  CashRegistersQuantity = 'cashRegistersQuantity',
  ServiceMonthQuantity = 'serviceMonthQuantity',
  CashRegisterModel = 'cashRegisterModel',
  Phone = 'phone',
  Email = 'email',
  ServiceCenterID = 'serviceCenterID',
  ServiceCenterName = 'serviceCenterName',
  Document = 'document',

  DeviceNumber = 'deviceNumber',
  FiscalNumber = 'fiscalNumber',
  SamModuleNumber = 'samModuleNumber',
  SimNumber = 'simNumber',
  SealNumber = 'sealNumber',
  RegId = 'regID',
}

export interface FiscalRegistrationResponse {
  cashRegisterInfoList: FiscalRegistration[]
  error: FiscalRegistrationError | null
  responseStatus: boolean
}
export interface FiscalRegistrationError {
  errorCode: number
  errorDescription: string
}

export interface FiscalRegistration {
  [FiscalRegistrationField.ServiceRequestID]: number
  [FiscalRegistrationField.ServiceRequestDate]: Date | string
  [FiscalRegistrationField.PayerCode]: string
  [FiscalRegistrationField.PayerName]: string
  [FiscalRegistrationField.ObjectAddress]: string
  [FiscalRegistrationField.BrandName]: string
  [FiscalRegistrationField.ActivityType]: string
  [FiscalRegistrationField.ObjectType]: string
  [FiscalRegistrationField.CashRegistersQuantity]: string
  [FiscalRegistrationField.ServiceMonthQuantity]: string
  [FiscalRegistrationField.CashRegisterModel]: string
  [FiscalRegistrationField.Phone]: string
  [FiscalRegistrationField.Email]: string
  [FiscalRegistrationField.ServiceCenterID]: string
  [FiscalRegistrationField.ServiceCenterName]: string
  [FiscalRegistrationField.Document]: any
}

export interface CashRegisterRegistrationForm {
  [FiscalRegistrationField.ServiceRequestNumberId]: number | null
  [FiscalRegistrationField.ServiceRequestNumberName]: string
  [FiscalRegistrationField.PosSNId]: number | null
  [FiscalRegistrationField.PosSNName]: string
  [FiscalRegistrationField.SamModuleId]: number | null
  [FiscalRegistrationField.SamModuleName]: string
  [FiscalRegistrationField.ServiceRequestID]: number
  // [FiscalRegistrationField.DeviceNumber]: string
  // [FiscalRegistrationField.FiscalNumber]: string
  // [FiscalRegistrationField.SamModuleNumber]: number | null
  [FiscalRegistrationField.SimNumber]: string
  [FiscalRegistrationField.SealNumber]: string
  // [FiscalRegistrationField.CashRegisterModel]: string
}

export interface CashRegisterUnRegistrationForm {
  [FiscalRegistrationField.ServiceRequestID]: number
  [FiscalRegistrationField.SamModuleNumber]: number | null
  [FiscalRegistrationField.RegId]: number | null
  [FiscalRegistrationField.DeviceNumber]: string
}

export interface CashRegisterDeleteForm {
  [FiscalRegistrationField.ServiceRequestID]: number
  [FiscalRegistrationField.SamModuleNumber]: number | null
  [FiscalRegistrationField.RegId]: number | null
  [FiscalRegistrationField.DeviceNumber]: string
}

export enum FiscalRegistrationFormFieldsEnum {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export type FiscalRegistrationFormFields = {
  [FiscalRegistrationFormFieldsEnum.StartDate]: string
  [FiscalRegistrationFormFieldsEnum.EndDate]: string
}

export interface ServiceRequestRegistration {
  cashregistermodel: string
  deviceid: number
  devicenumber: string
  fiscalnumber: string
  id: number
  lastactiondate: string
  merchantid: number
  posrfunitid: number
  regid: number
  registrationdate: string
  registrationstateid: number
  sammodulenumber: number
  samrfunitid: number
  sealnumber: string
  sentdate: string
  servicerequestid: number
  simnumber: string
  srdocumentid: number
}
