import 'react-splitter-layout/lib/index.css'

import { Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withResizeDetector } from 'react-resize-detector'
import SplitterLayout from 'react-splitter-layout'

import ActErrorModal from '@/components/blocks/cm/ActErrorModal'
import ObjectsTree from '@/components/blocks/cm/ObjectsTree'
import LoadingOverlay from '@/components/blocks/LoadingOverlay'
import { ErrorBoundary } from '@/components/wrappers/ErrorBoundary'
import { AppNavigationRoutes, LocalStorageItems, vaultV1BaseUrl } from '@/constants'
import { VAULT_OBJECT_TREE } from '@/constants/vault-v1'
import { getParsedLocalStorageItem, getUserDataLocalStorage } from '@/helpers/localStorage'
import { getActErrorModal } from '@/store/cm/common'
import {
  getFilterNodesVaultV1,
  getGlobalFilterVaultV1,
  setGlobalFilter,
  setObjectTreeFilter,
} from '@/store/vault-v1/common'
import { getTeamsRequest } from '@/store/vault-v1/teams'
import { useSplitter } from '@/utils/hooks/useSplitter'
import { useTranslate } from '@/utils/internalization'

import { PageWrapper, useClasses } from './styles'
import { Props } from './types'

const VaultV1PageLayout = ({ children, width }: Props): ReactElement => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const translate = useTranslate()

  const { pathname } = window.location
  const { login } = getUserDataLocalStorage(vaultV1BaseUrl) || {}
  const allUsersSettings =
    getParsedLocalStorageItem(vaultV1BaseUrl)(LocalStorageItems.UsersSettings) || {}
  const isObjectsTreeVisible = allUsersSettings[login]?.isObjectsTreeVisible || false
  const isAppBarVisible = allUsersSettings[login]?.isAppBarVisible || false

  const isTeams = localStorage.getItem(LocalStorageItems.ActiveTeamsVaultV1)

  const tabsWithoutTree = [
    AppNavigationRoutes.VaultV1.TeamsPage,
    AppNavigationRoutes.VaultV1.AdminPage,
  ]

  const { panelWight, collapsedColumn, columnDoubleClickRef, setSplitPane } = useSplitter({
    widthKey: VAULT_OBJECT_TREE,
  })

  const { filterNodes, isLoading, isLoaded } = useSelector(getFilterNodesVaultV1)
  const globalFilter = useSelector(getGlobalFilterVaultV1)
  const { isActErrorVisible } = useSelector(getActErrorModal)

  useEffect(() => {
    if (!isTeams) {
      dispatch(getTeamsRequest({}))
    }
  }, [isTeams])

  const setObjectTreeNodesChecked = useCallback((nodes: string[]) => {
    dispatch(setGlobalFilter({ branchIds: nodes }))
    dispatch(setObjectTreeFilter())
  }, [])

  const isShowObjectTree = useMemo(
    () => isObjectsTreeVisible && !tabsWithoutTree.some(tab => pathname.includes(tab)),
    [isObjectsTreeVisible, tabsWithoutTree, pathname],
  )

  return (
    <PageWrapper isAppBarVisible={isAppBarVisible}>
      <SplitterLayout
        primaryIndex={1}
        secondaryInitialSize={panelWight || 200}
        onSecondaryPaneSizeChange={setSplitPane('objectTree')}
        primaryMinSize={width / 1.16}
        secondaryMinSize={20}
        customClassName={clsx({
          [classes.collapsedColumn]: collapsedColumn,
          [classes.columnHide]: !isShowObjectTree,
        })}
        ref={columnDoubleClickRef}
      >
        {isShowObjectTree ? (
          <ErrorBoundary>
            <div className={classes.objectTreeWrap}>
              <Grid container className={classes.objectsTreeWithTitle}>
                <LoadingOverlay active={isLoading && !isLoaded} loaded={isLoaded}>
                  <Typography variant="h3" className={classes.title}>
                    {translate('translate#title.objectsTree')}
                  </Typography>
                  <Grid item md={12} className={classes.objectsTree}>
                    <ObjectsTree
                      nodes={filterNodes}
                      setCheckedIds={setObjectTreeNodesChecked}
                      checkedIds={globalFilter?.branchIds || []}
                    />
                  </Grid>
                </LoadingOverlay>
              </Grid>
            </div>
          </ErrorBoundary>
        ) : (
          <div />
        )}
        <SplitterLayout>
          <div
            className={clsx(classes.content, {
              [classes.withoutObjectTree]: !isShowObjectTree,
            })}
          >
            {children}
          </div>
        </SplitterLayout>
      </SplitterLayout>
      {isActErrorVisible && <ActErrorModal />}
    </PageWrapper>
  )
}

export default withResizeDetector(VaultV1PageLayout)
