export enum ReactionFilterTypes {
  All = '1',
  OnlyWithReaction = '2',
  OnlyWithoutReaction = '3',
}

type ArrayTypes = null | string | number

export type FiltersAlarmsTypes = {
  atm_date: Array<ArrayTypes>
  device_id: string[]
  event_media_type_id: Array<ArrayTypes>
  event_type_id: Array<ArrayTypes>
  notes: string
  operator_name: string
  reaction_date: Array<ArrayTypes>
  reaction_filter: ReactionFilterTypes
}

export type FiltersEventTypes = Omit<
  FiltersAlarmsTypes,
  'operator_name' | 'reaction_date' | 'reaction_filter'
>
