import { SyntheticEvent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AuthorizationApi } from '@/api'
import { ChangePasswordFormValuesCM } from '@/components/blocks/cm/ChangePasswordModal/types'
import { AppNavigationRoutes } from '@/constants'
import {
  changePasswordRequest,
  getChangePasswordState,
  getCurrentCompany,
  getCurrentUserName,
  getPersonName,
  getUserPhoto,
  setChangePasswordModalOpen,
  signOut,
} from '@/store/auth'

const useUserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)
  const [isShowLogoutConfirmModal, setIsShowLogoutConfirmModal] = useState(false)
  const [isLogoutLoading, setIsLogoutLoading] = useState(false)

  const history = useHistory()

  const handleClick = useCallback((event: SyntheticEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const dispatch = useDispatch()
  const handleSignOut = useCallback(async () => {
    try {
      setIsLogoutLoading(true)
      await AuthorizationApi.Logout()
    } catch (e) {
      console.error('Member access token has already expired', e)
    } finally {
      dispatch(signOut())
      sessionStorage.clear()
      history.push(AppNavigationRoutes.SignInPage)
      setIsLogoutLoading(false)
      handleClose()
    }
  }, [dispatch, handleClose, history, setIsLogoutLoading])

  const userName = useSelector(getCurrentUserName)
  const currentCompany = useSelector(getCurrentCompany)
  const personName = useSelector(getPersonName)
  const userPhotoBase64 = useSelector(getUserPhoto)

  const handleOpenCloseLogoutConfirmModal = useCallback((): void => {
    setIsShowLogoutConfirmModal(isShowLogoutConfirmModal => !isShowLogoutConfirmModal)
    isShowLogoutConfirmModal && setAnchorEl(null)
  }, [setIsShowLogoutConfirmModal, isShowLogoutConfirmModal])

  const { isModalOpen: isChangePasswordModalOpen, isLoading, error } = useSelector(
    getChangePasswordState,
  )

  const handleChangePasswordModalOpen = useCallback(() => {
    dispatch(setChangePasswordModalOpen(true))
  }, [])

  const handleChangePasswordModalClose = useCallback(() => {
    dispatch(setChangePasswordModalOpen(false))
  }, [])

  const handleChangePassword = useCallback<(values: ChangePasswordFormValuesCM) => void>(
    (values: ChangePasswordFormValuesCM): void => {
      dispatch(changePasswordRequest(values))
    },
    [dispatch],
  )

  return {
    anchorEl,
    handleClick,
    handleSignOut,
    handleChangePassword,
    handleChangePasswordModalClose,
    handleChangePasswordModalOpen,
    isChangePasswordModalOpen,
    isLoading,
    error,
    handleOpenCloseLogoutConfirmModal,
    userName,
    currentCompany,
    personName,
    userPhotoBase64,
    handleClose,
    isShowLogoutConfirmModal,
    isLogoutLoading,
  }
}
export { useUserMenu }
