import { ImportProductRequestData } from '@/api/products/types'
import { ResponseError } from '@/types'
import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import {
  AddUpdateProductSpecification,
  AlternativeProduct,
  Product,
  ProductSpecification,
} from '@/types/products/products'
import { requestService } from '@/utils/services/request'

export class ProductsApi {
  public static async getProductById(productId: string): Promise<Product | ResponseError> {
    try {
      return await requestService.get(`/products/product/${productId}`)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async addNewProduct(data: Product): Promise<Product | ResponseErrorTypes> {
    try {
      return await requestService.post('/products/product', data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  public static async editProduct(
    productId: string,
    data: Product,
  ): Promise<Product | ResponseError> {
    try {
      return await requestService.put(`/products/product/${productId}`, data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  public static async deleteProduct(productId: string): Promise<Product | ResponseError> {
    try {
      return await requestService.delete(`/products/product/${productId}`)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  public static async getAlternativeProducts(
    mainProductId: number,
  ): Promise<AlternativeProduct | ResponseError> {
    try {
      return await requestService.get(`/products/alternative-product/list/${mainProductId}`)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getAlternativeProductsAllowedToAdd(
    excludeProductIds: number[],
    searchFragment: string,
  ): Promise<AlternativeProduct | ResponseError> {
    try {
      return await requestService.get(`/products/alternative-product/select`, {
        excludeProductIds: excludeProductIds,
        searchFragment,
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async saveAlternativeProducts(
    mainProductId: number,
    addProducts: number[],
    deleteProducts: number[],
  ): Promise<AlternativeProduct[] | ResponseError> {
    try {
      await requestService.delete(`/products/alternative-product/`, true, {
        data: deleteProducts,
      })

      return await requestService.post('/products/alternative-product', {
        mainProductId,
        alternativeProducts: addProducts,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getProductSpecification(
    mainProductId: number,
  ): Promise<ProductSpecification | ResponseError> {
    try {
      return await requestService.get(`/products/product-specification/list/${mainProductId}`)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getProductSpecificationAllowedToAdd(
    excludeSpecificationIds: number[],
    searchFragment: string,
  ): Promise<ProductSpecification | ResponseError> {
    try {
      return await requestService.get(`/products/product-specification/select`, {
        excludeSpecificationIds: excludeSpecificationIds,
        searchFragment,
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async saveProductSpecification(
    mainProductId: number,
    addProductSpecifications: AddUpdateProductSpecification[],
    deleteProductSpecifications: number[],
  ): Promise<ProductSpecification[] | ResponseError> {
    try {
      await requestService.delete(`/products/product-specification/`, true, {
        data: deleteProductSpecifications,
      })

      return await requestService.post('/products/product-specification', {
        mainProductId,
        productSpecifications: addProductSpecifications,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async importProducts(
    data: ImportProductRequestData[],
  ): Promise<any | ResponseError> {
    try {
      const importResponse = await requestService.post('/products/product/import', data)

      return importResponse.importResult
    } catch (e) {
      throw new Error(e)
    }
  }
}
