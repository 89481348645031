import { BaseUSM } from '@/api/usm'
import { userGroupAndPermissionsType } from '@/types/usm/userGroup/userGroup'
import { PopUpService } from '@/utils/services/popUpService'

export class UserGroupUSMAPI<T> extends BaseUSM<T> {
  constructor(public readonly data: T) {
    super(data)
    this.data = data
  }

  // get-all-groups

  public async getAllGroups() {
    try {
      return await this.USMRequestService.get(`/user-group/get-all`)
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  // add-new-user-group
  public async addGNewGroup() {
    try {
      return await this.USMRequestService.post(`/user-group/add-user-group`, this.data)
    } catch (e) {
      // throw Reflect.construct(Error, [e])
      return Promise.reject(e)
    }
  }

  // get-user-group-by-id
  public async getUserGroupId() {
    try {
      return await this.USMRequestService.get(`/user-group/${this.data}`)
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public async getPermissionsObjectTree() {
    try {
      return await this.USMRequestService.get(`/permission/objects-tree`)
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  // delete-user-group
  public async deleteUserGroupById() {
    try {
      return await this.USMRequestService.delete(`user-group/delete/${this.data}`, true)
    } catch (e) {
      PopUpService.showGlobalPopUp(`translate#${e.errorMessage}`, 'error')
      // PopUpService.showGlobalPopUp(
      //   `${payload.translate('translate#companies.title.updateCompanyError')}.\n${e}`,
      //   'error',
      // )
      throw Reflect.construct(Error, [e])
    }
  }

  // update-user-group
  public async updateUserGroup(): Promise<Array<userGroupAndPermissionsType>> {
    try {
      return await this.USMRequestService.post(`/user-group/edit`, this.data)
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }
}
