import { useState } from 'react'
import { Option } from '@/components/controls/DropDownInput/types'
import { DashBoardAPI } from '@/api/atmeye/dasboard/dashBoardAPI'
import { AllSubpagesResponseType } from '@/types/atmeye/dashboardTypes'
import { actionsATMEyeDashboard } from '@/store/atmeye/dashboard'
import { useDebounce } from '@/utils/hooks/useDebounce'
import { useDispatch } from 'react-redux'

const useSubscription = () => {
  const dispatch = useDispatch()

  const [publishSubPages, setPublishSubPages] = useState<Array<Option>>([])
  const [fetchingPublishedPages, setFetchingPublishedPages] = useState(false)

  const getAllPublishedSubPages = async (searchFragment?: string) => {
    const getPublishedSubPages = new DashBoardAPI(searchFragment)
    try {
      setFetchingPublishedPages(true)
      const pages: Array<AllSubpagesResponseType> = await getPublishedSubPages.getAllPublishedSubpages()
      setFetchingPublishedPages(false)
      setPublishSubPages(
        pages.map(page => ({ value: page.subpageId.toString(), name: page.subpageName })),
      )
    } catch (e) {
      dispatch(actionsATMEyeDashboard.fillAllSubPagesError(e))
    }
  }

  const { debouncedCallback } = useDebounce(getAllPublishedSubPages, 2000)

  return { publishSubPages, debouncedCallback, fetchingPublishedPages }
}

export { useSubscription }
