import { ChartData, ChartExtendedData, Device, ResponseError } from '@/types'
import { GetTicketsResponse } from '@/types/sd/common'
import { requestService } from '@/utils/services/request'

export class DashboardApi {
  public static async getChartData(
    serviceCompanyId?: string,
    deviceType?: string,
  ): Promise<ChartData | ResponseError> {
    try {
      return await requestService.get('/dashboard/my-stats/basic', {
        deviceType,
        serviceCompanyId,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getChartExtendedData(
    serviceCompanyId?: string,
    deviceType?: string[],
  ): Promise<ChartExtendedData | ResponseError> {
    try {
      return await requestService.get('/dashboard/my-stats/extended', {
        deviceType,
        serviceCompanyId,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAllDevices(): Promise<Device[] | ResponseError> {
    try {
      return await requestService.get('/dashboard/my-stats/devices')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTickets(data: any): Promise<GetTicketsResponse | ResponseError> {
    try {
      return await requestService.post('/dashboard/my-stats/tickets', data)
    } catch (e) {
      throw new Error(e)
    }
  }
}
