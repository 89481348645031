import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { VaultV1CommonApi } from '@/api/vault-v1/commonApi'
import { MonitoringApi } from '@/api/vault-v1/monitoringApi/index'
import {
  MonEntryDetails,
  MonVault,
  MonVaultCurrencyEntry,
  MonVaultsResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'
import { MonVaultEntryType } from '@/constants/vault-v1'
import { AppState } from '@/store/reducers'
import {
  getMonCurrentRemaindersToUpdate,
  getMonSelectedVaults,
  getMonVaultsSort,
  getSingleVaultFail,
  getSingleVaultRequest,
  getSingleVaultResponse,
  getVaultDetailsFail,
  getVaultDetailsRequest,
  getVaultDetailsResponse,
  getVaultsFail,
  getVaultsRequest,
  getVaultsResponse,
  updateMonAbsRemaindersFail,
  updateMonAbsRemaindersResponse,
} from '@/store/vault-v1/monitoring'
import { VaultDetailsColumn } from '@/types/vault-v1/monitoring'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { getGlobalFilterVaultV1, setComVaultV1Error, setGlobalFilter } from '../common'
import {
  addMonCashLimitsFail,
  addMonCashLimitsResponse,
  editMonCashLimitsFail,
  editMonCashLimitsResponse,
  getMonCartsFail,
  getMonCartsResponse,
  getMonCashierDetailsFail,
  getMonCashierDetailsResponse,
  getMonCashiersFail,
  getMonCashiersRequest,
  getMonCashiersResponse,
  getMonCashLimitsFail,
  getMonCashLimitsRequest,
  getMonCashLimitsResponse,
  getMonCloseNightDepositoryFail,
  getMonCloseNightDepositoryResponse,
  getMonCurrentRemaindersFail,
  getMonCurrentRemaindersResponse,
  getMonCurrentValuablesRemaindersFail,
  getMonCurrentValuablesRemaindersResponse,
  getMonReportFail,
  getMonReportResponse,
  getMonReportsFail,
  getMonReportsResponse,
  getMonStartDayFail,
  getMonStartDayResponse,
  getMonWorkingTimeEndFail,
  getMonWorkingTimeEndResponse,
  getMonWornOutCashFail,
  getMonWornOutCashRequest,
  getMonWornOutCashResponse,
  openMonDocumentsModal,
  openMonFilterModal,
  refreshMonVaults,
  revertMonBalanceFail,
  revertMonBalanceResponse,
  setMonCloseNightDepositoryFail,
  setMonCloseNightDepositoryResponse,
  setMonCurrentRemaindersFail,
  setMonCurrentRemaindersResponse,
  setMonCurrentValuablesRemaindersFail,
  setMonCurrentValuablesRemaindersResponse,
  setMonSelectedCashier,
  setMonSelectedVault,
  setMonStartDayFail,
  setMonStartDayResponse,
  setMonWorkingTimeEndFail,
  setMonWorkingTimeEndResponse,
  setMonWornOutCashFail,
  setMonWornOutCashResponse,
} from './actions'
import {
  getMonCurrentValuablesRemaindersUpdated,
  getMonVaultCashiersFilter,
  getMonVaultParent,
  getMonVaultParentId,
  getMonVaults,
  getMonVaultsDetailsFilter,
  getMonVaultSelectedCashier,
  getMonVaultsFilter,
  getMonVaultsModals,
} from './selectors'

function* getVaultDetails(): SagaIterator {
  try {
    const selectedVault: MonVaultCurrencyEntry = yield select(getMonSelectedVaults)

    if (selectedVault.type === MonVaultEntryType.Currency) {
      const response: MonEntryDetails[] = yield call(
        MonitoringApi.getVaultDetails,
        selectedVault.entryId,
      )
      yield put(getVaultDetailsResponse(response))
    } else {
      yield put(getVaultDetailsResponse([]))
    }
  } catch (error) {
    yield put(getVaultDetailsFail(error))
  }
}

function* getVaults(): SagaIterator {
  try {
    const globalFilter = yield select(getGlobalFilterVaultV1)
    const filter = yield select(getMonVaultsFilter)
    const selectedVault = yield select(getMonSelectedVaults)
    const sort = yield select(getMonVaultsSort)

    const paging = yield select((state: AppState) => {
      const { page, pageSize } = state?.vaultV1Monitoring.vaults.paging
      return {
        page,
        pageSize: pageSize === -1 ? 'All' : pageSize,
      }
    })

    const response: MonVaultsResponse = yield call(
      MonitoringApi.getVaults,
      paging,
      { globalFilter, vaults: filter.vaults },
      sort.sortColumn,
      sort.sortOrder,
    )

    yield put(getVaultsResponse(response))

    if (response && selectedVault) {
      let foundedEntry = null as MonVaultCurrencyEntry | null

      response.vaults.forEach(vault =>
        vault.entries.forEach(entry => {
          if (entry.entryId === selectedVault.entryId) {
            foundedEntry = { ...entry }
          }
        }),
      )

      if (foundedEntry) {
        yield put(setMonSelectedVault(foundedEntry))
      } else {
        yield put(setMonSelectedVault(null))
      }
    }
  } catch (error) {
    yield put(getVaultsFail(error))
  }
}

function* getSingleVault(): SagaIterator {
  try {
    const { vaultId: parentSelectedVaultId } = yield select(getMonVaultParent)
    const sortOrder = yield select((state: AppState) => state.vaultV1Monitoring.vaults.sortOrder)

    if (parentSelectedVaultId) {
      const vault: MonVault = yield call(
        MonitoringApi.getSingleVault,
        parentSelectedVaultId,
        sortOrder,
      )
      yield put(getSingleVaultResponse(vault))
      yield put(getVaultDetailsRequest())
    }
  } catch (error) {
    yield put(getSingleVaultFail(error))
  }
}

function* handleFilterSubmit({ payload }: AnyAction): SagaIterator {
  yield put(setGlobalFilter(payload.globalFilter))

  yield put(getVaultsRequest())
}

function* getReports(): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)
    const filter = yield select(getMonVaultsFilter)
    const globalFilter = yield select(getGlobalFilterVaultV1)

    const pointsFilter = {
      id: selectedVault?.entryId || '',
      vaults: filter.vaults || '',
      globalFilter,
    }
    const response = yield call(MonitoringApi.getReports, pointsFilter)

    yield put(getMonReportsResponse(response))
  } catch (error) {
    yield put(getMonReportsFail(error?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(VaultV1CommonApi.getReport, payload)
      yield put(getMonReportResponse(response))
    } else {
      yield put(getMonReportResponse(null))
    }
  } catch (error) {
    yield put(getMonReportFail(error?.message))
  }
}

function* getCurrentRemainders({ payload }: AnyAction): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      const response = yield call(
        MonitoringApi.getCurrentRemainders,
        selectedVault?.entryId,
        payload,
      )

      yield put(getMonCurrentRemaindersResponse(response))
    }
  } catch (error) {
    yield put(getMonCurrentRemaindersFail(error?.message))
  }
}

function* setCurrentRemainders({ payload }: AnyAction): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)
    const dataToUpdate = yield select(getMonCurrentRemaindersToUpdate)

    if (selectedVault?.entryId) {
      yield call(MonitoringApi.setCurrentRemainders, selectedVault?.entryId, dataToUpdate, payload)

      yield put(setMonCurrentRemaindersResponse())
      yield put(getSingleVaultRequest())
    }
  } catch (error) {
    yield put(setMonCurrentRemaindersFail(error?.message))
  }
}

function* getCurrentValuablesRemainders(): SagaIterator {
  try {
    const { parentSelectedVault } = yield select(getMonVaults)

    if (parentSelectedVault?.vaultId && parentSelectedVault?.sessionDate) {
      const response = yield call(MonitoringApi.getCurrentValuablesRemainders, {
        id: parentSelectedVault.vaultId,
        sessionDate: parentSelectedVault.sessionDate,
      })

      yield put(getMonCurrentValuablesRemaindersResponse(response))
    }
  } catch (error) {
    yield put(getMonCurrentValuablesRemaindersFail(error?.message))
  }
}

function* setCurrentValuablesRemainders(): SagaIterator {
  try {
    const { parentSelectedVault } = yield select(getMonVaults)
    const dataToUpdate = yield select(getMonCurrentValuablesRemaindersUpdated)

    if (parentSelectedVault?.vaultId && parentSelectedVault?.sessionDate) {
      yield call(MonitoringApi.setCurrentValuablesRemainders, {
        id: parentSelectedVault.vaultId,
        sessionDate: parentSelectedVault.sessionDate,
        remainders: dataToUpdate,
      })

      yield put(setMonCurrentValuablesRemaindersResponse())
      yield put(getSingleVaultRequest())
    }
  } catch (error) {
    yield put(setMonCurrentValuablesRemaindersFail(error?.message))
  }
}

// Mon Start Day
function* getStartDay(): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      const response = yield call(MonitoringApi.getStartDay, selectedVault?.entryId)

      yield put(getMonStartDayResponse(response))
    }
  } catch (error) {
    yield put(getMonStartDayFail(error?.message))
  }
}

function* setStartDay(): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      const response = yield call(MonitoringApi.setStartDay, selectedVault?.entryId)

      yield put(setMonStartDayResponse(response))
      yield put(getSingleVaultRequest())
    }
  } catch (error) {
    yield put(setMonStartDayFail(error?.message))
  }
}

// Working Time End
function* getWorkingTimeEnd(): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      const response = yield call(MonitoringApi.getWorkingTimeEnd, selectedVault?.entryId)

      yield put(getMonWorkingTimeEndResponse(response))
    }
  } catch (error) {
    yield put(getMonWorkingTimeEndFail(error?.message))
  }
}

function* setWorkingTimeEnd(): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      yield call(MonitoringApi.setWorkingTimeEnd, selectedVault?.entryId)

      yield put(setMonWorkingTimeEndResponse())
      yield put(getSingleVaultRequest())
    }
  } catch (error) {
    yield put(setMonWorkingTimeEndFail(error?.message))
  }
}

// Worn Out Cash
function* getWornOutCash(): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      const response = yield call(MonitoringApi.getWornOutCash, selectedVault?.entryId)

      yield put(getMonWornOutCashResponse(response))
    }
  } catch (error) {
    yield put(getMonWornOutCashFail(error?.message))
  }
}

function* setWornOutCash({ payload }: AnyAction): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      yield call(MonitoringApi.setWornOutCash, selectedVault?.entryId, payload)

      yield put(setMonWornOutCashResponse())
      yield put(getMonWornOutCashRequest())
      yield put(getSingleVaultRequest())
    }
  } catch (error) {
    yield put(setMonWornOutCashFail(error?.message))
  }
}

// Close Night Depository
function* getCloseNightDepository(): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      const response = yield call(MonitoringApi.getCloseNightDepository, selectedVault?.entryId)

      yield put(getMonCloseNightDepositoryResponse(response))
    }
  } catch (error) {
    yield put(getMonCloseNightDepositoryFail(error?.message))
  }
}

function* setCloseNightDepository(): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      yield call(MonitoringApi.setCloseNightDepository, selectedVault?.entryId)

      yield put(setMonCloseNightDepositoryResponse())
      yield put(getSingleVaultRequest())
    }
  } catch (error) {
    yield put(setMonCloseNightDepositoryFail(error?.message))
  }
}

// Cash Limits
function* getCashLimits(): SagaIterator {
  try {
    const selectedVault = yield select(getMonSelectedVaults)

    if (selectedVault?.entryId) {
      const response = yield call(MonitoringApi.getCashLimits, selectedVault?.entryId)

      yield put(getMonCashLimitsResponse(response))
    }
  } catch (error) {
    yield put(getMonCashLimitsFail(error?.message))
  }
}

function* addCashLimits({ payload }: AnyAction): SagaIterator {
  try {
    yield call(MonitoringApi.addCashLimits, payload)

    yield put(addMonCashLimitsResponse())
    yield put(getMonCashLimitsRequest())
    yield put(getSingleVaultRequest())
  } catch (error) {
    yield put(addMonCashLimitsFail(error?.message))
  }
}

function* editCashLimits({ payload }: AnyAction): SagaIterator {
  try {
    yield call(MonitoringApi.editCashLimits, payload)

    yield put(editMonCashLimitsResponse())
    yield put(getMonCashLimitsRequest())
    yield put(getSingleVaultRequest())
  } catch (error) {
    yield put(editMonCashLimitsFail(error?.message))
  }
}

function* updateAbsRemainders(): SagaIterator {
  try {
    yield call(MonitoringApi.updateAbsRemainders)

    yield put(updateMonAbsRemaindersResponse())
    yield put(getVaultsRequest())
  } catch (error) {
    yield put(updateMonAbsRemaindersFail(error?.message))
  }
}

function* getCharts(): SagaIterator {
  try {
    const { vaultId: parentSelectedVaultId } = yield select(getMonVaultParent)
    const filter = yield select(getMonVaultsDetailsFilter)

    if (parentSelectedVaultId) {
      const charts = yield call(MonitoringApi.getCharts, {
        vaultId: parentSelectedVaultId,
        ...filter,
      })

      yield put(getMonCartsResponse(charts))
    }
  } catch (error) {
    yield put(getMonCartsFail(error?.message))
  }
}

function* getCashiers(): SagaIterator {
  try {
    const parentSelectedVaultId = yield select(getMonVaultParentId)
    const selected = yield select(getMonVaultSelectedCashier)
    const filter = yield select(getMonVaultCashiersFilter)

    if (parentSelectedVaultId) {
      const response: MonVault[] = yield call(MonitoringApi.getCashiers, {
        guid: parentSelectedVaultId,
        ...filter,
      })

      yield put(getMonCashiersResponse(response))

      if (selected) {
        let foundedEntry = null as MonVaultCurrencyEntry | null

        response.forEach(item =>
          item.entries.forEach(entry => {
            if (entry.entryId === selected.entryId) {
              foundedEntry = { ...entry }
            }
          }),
        )

        if (foundedEntry) {
          yield put(setMonSelectedCashier(foundedEntry))
        } else {
          yield put(setMonSelectedCashier(null))
        }
      }
    }
  } catch (error) {
    yield put(getMonCashiersFail(error?.message))
  }
}

function* getCashierDetails(): SagaIterator {
  try {
    const selectedCashier: MonVaultCurrencyEntry = yield select(getMonVaultSelectedCashier)

    if (selectedCashier) {
      const response: MonEntryDetails[] = yield call(
        MonitoringApi.getVaultDetails,
        selectedCashier.entryId,
      )

      const remainderDetails = response.find(
        detail => detail.column === VaultDetailsColumn.CurrentReminder,
      )

      if (remainderDetails) {
        yield put(getMonCashierDetailsResponse([remainderDetails]))
      } else {
        yield put(getMonCashierDetailsResponse([]))
      }
    } else {
      yield put(getMonCashierDetailsResponse([]))
    }
  } catch (error) {
    yield put(getMonCashierDetailsFail(error))
  }
}

function* revertBalance({ payload }: AnyAction): SagaIterator {
  try {
    yield call(MonitoringApi.revertBalance, payload)

    yield put(revertMonBalanceResponse())
    yield put(getVaultsRequest())
    yield put(getMonCashiersRequest())
  } catch (error) {
    yield put(revertMonBalanceFail(error?.message))
  }
}

function* selectSideEffect(): SagaIterator {
  yield put(getVaultDetailsRequest())
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const { selectedVault: selected, data } = yield select(getMonVaults)
    const modals = yield select(getMonVaultsModals)

    const internalData = data.reduce(
      (accumulator: MonVaultCurrencyEntry[], current: MonVault) => [
        ...accumulator,
        ...current.entries,
      ],
      [],
    )

    hotKeysBinder<MonVaultCurrencyEntry>({
      key: payload,
      data: internalData,
      idName: 'entryId',
      selected,
      isOpenModalsList: Object.keys(modals).map(key => modals[key]),
      setSelectedAction: setMonSelectedVault,
      openFilterAction: openMonFilterModal,
      openPrintAction: openMonDocumentsModal,
      onRefreshAction: refreshMonVaults,
    })
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [
      VaultV1Actions.MonGetVaultsRequest,
      VaultV1Actions.MonRefreshVaults,
      VaultV1Actions.MonSetVaultsPaging,
      VaultV1Actions.MonSortVaultsTable,
    ],
    getVaults,
  )

  yield takeEvery(VaultV1Actions.MonGetSingleVaultRequest, getSingleVault)

  yield takeLatest(VaultV1Actions.MonSubmitFilter, handleFilterSubmit)

  yield takeLatest(VaultV1Actions.MonGetReportsRequest, getReports)

  yield takeLatest(VaultV1Actions.MonGetVaultDetailsRequest, getVaultDetails)

  yield takeLatest(VaultV1Actions.MonGetReportRequest, getReport)
  yield takeLatest(VaultV1Actions.MonGetCurrentRemaindersRequest, getCurrentRemainders)
  yield takeLatest(VaultV1Actions.MonSetCurrentRemaindersRequest, setCurrentRemainders)

  yield takeLatest(
    VaultV1Actions.MonGetCurrentValuablesRemaindersRequest,
    getCurrentValuablesRemainders,
  )
  yield takeLatest(
    VaultV1Actions.MonSetCurrentValuablesRemaindersRequest,
    setCurrentValuablesRemainders,
  )

  yield takeLatest(VaultV1Actions.MonGetStartDayRequest, getStartDay)
  yield takeLatest(VaultV1Actions.MonSetStartDayRequest, setStartDay)

  yield takeLatest(VaultV1Actions.MonGetWorkingTimeEndRequest, getWorkingTimeEnd)
  yield takeLatest(VaultV1Actions.MonSetWorkingTimeEndRequest, setWorkingTimeEnd)

  yield takeLatest(VaultV1Actions.MonGetWornOutCashRequest, getWornOutCash)
  yield takeLatest(VaultV1Actions.MonSetWornOutCashRequest, setWornOutCash)

  yield takeLatest(VaultV1Actions.MonGetCloseNightDepositoryRequest, getCloseNightDepository)
  yield takeLatest(VaultV1Actions.MonSetCloseNightDepositoryRequest, setCloseNightDepository)

  yield takeLatest(VaultV1Actions.MonGetCashLimitsRequest, getCashLimits)
  yield takeLatest(VaultV1Actions.MonAddCashLimitsRequest, addCashLimits)
  yield takeLatest(VaultV1Actions.MonEditCashLimitsRequest, editCashLimits)

  yield takeLatest(VaultV1Actions.MonUpdateAbsRemaindersRequest, updateAbsRemainders)

  yield takeLatest(
    [VaultV1Actions.MonSetDetailsFilter, VaultV1Actions.MonGetChartsRequest],
    getCharts,
  )

  yield takeLatest(
    [VaultV1Actions.MonGetCashiersRequest, VaultV1Actions.MonSetCashiersFilter],
    getCashiers,
  )
  yield takeLatest(VaultV1Actions.MonGetCashierDetailsRequest, getCashierDetails)

  yield takeLatest(VaultV1Actions.MonSetSelectedVault, selectSideEffect)

  yield takeLatest(VaultV1Actions.MonRevertBalanceRequest, revertBalance)

  yield takeLatest(VaultV1Actions.MonHandleKeyPress, handleKeyPress)
}
