import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { CassetteSetsApi, CitPlanningApi, CitTeamsApi } from '@/api/cm/citPlanningApi'
import { refetchCitPlanningBags } from '@/api/cm/citPlanningApi/queries'
import { refetchCitPlnWOButtons } from '@/api/cm/citPlanningApi/queries/workOrder'
import { CMCommonApi } from '@/api/cm/commonApi'
import {
  AdmPlnCasSet,
  AdmPlnTeamGroup,
  CitPlnCashPoint,
  CitPlnSetPropertyRequest,
  CitPlnSetWorkOrderCassettes,
  CitPlnWorkOrderCassettes,
} from '@/api/cm/swaggerGeneratedApi'
import { AppNavigationRoutes, CMActions, WORK_ORDER } from '@/constants'
import { setGlobalFilter } from '@/store/cm/common/actions'
import { AppState } from '@/store/reducers'
import { hotKeysBinder } from '@/utils/hotKeysBinder'
import { translate } from '@/utils/internalization'

import { setActErrorVisible } from '../auth'
import { getGlobalFilter, setComCMError } from '../common'
import {
  getCitPlnCashPointFail,
  getCitPlnCashPoints,
  getCitPlnEditedCassettes,
  getCitPlnWorkOrder,
  getCitPlnWorkOrdersFilter,
  getCitPlnWorkOrdersFormattedPaging,
  getCitPlnWorkOrdersSort,
  getSelectedWorkOrder,
} from '.'
import {
  addCitNewCassetteSetFail,
  applyWOCassettesStampsFail,
  calculateCashPointFail,
  clearCheckedOrders,
  deleteCitTeamFail,
  deleteWorkOrderFail,
  deleteWorkOrderResponse,
  getCassetteTypesResponse,
  getCitCassetteSetsFail,
  getCitCassetteSetsRequest,
  getCitCassetteSetsResponse,
  getCitPlnCashPointPropertyRequest,
  getCitPlnCashPointPropertyResponse,
  getCitPlnCashPointRequest,
  getCitPlnCashPointResponse,
  getCitPlnCashPointsRequest,
  getCitPlnCashPointsResponse,
  getCitPlnCashPointsResponseFail,
  getCitPlnRepairOrderReportFail,
  getCitPlnRepairOrderReportResponse,
  getCitPlnRepairOrderReportsFail,
  getCitPlnRepairOrderReportsResponse,
  getCitPlnRepairOrderRequest,
  getCitPlnRepairOrderResponse,
  getCitPlnRepairOrderResponseFail,
  getCitPlnReportResponse,
  getCitPlnReportResponseFail,
  getCitPlnReportsResponse,
  getCitPlnReportsResponseFail,
  getCitPlnUnloadOrderReportFail,
  getCitPlnUnloadOrderReportResponse,
  getCitPlnUnloadOrderReportsFail,
  getCitPlnUnloadOrderReportsResponse,
  getCitPlnUnloadOrderRequest,
  getCitPlnUnloadOrderResponse,
  getCitPlnUnloadOrderResponseFail,
  getCitPlnWOCassettesFail,
  getCitPlnWOCassettesRequest,
  getCitPlnWOCassettesResponse,
  getCitPlnWOReportFail,
  getCitPlnWOReportResponse,
  getCitPlnWOReportsFail,
  getCitPlnWOReportsResponse,
  getCitPlnWorkOrdersFail,
  getCitPlnWorkOrdersReportResponse,
  getCitPlnWorkOrdersReportResponseFail,
  getCitPlnWorkOrdersReportsResponse,
  getCitPlnWorkOrdersReportsResponseFail,
  getCitPlnWorkOrdersRequest,
  getCitPlnWorkOrdersResponse,
  getCitTeamsFail,
  getCitTeamsRequest,
  getCitTeamsResponse,
  refreshCitPlnCashPointsPanel,
  refreshCitPlnWOCassettes,
  removeCassetteSetFail,
  removeCassetteSetResponse,
  seCitPlnSelectedWO,
  setCitCassetteSetsModalOpen,
  setCitPlnCashPointPropertyFail,
  setCitPlnCashPointsFilter,
  setCitPlnCashPointsPagingResponse,
  setCitPlnDetailsSelectedTab,
  setCitPlnDocumentModalOpen,
  setCitPlnFiltersModalOpen,
  setCitPlnSelectedCashPoint,
  setCitPlnSelectedTeam,
  setCitSelectedCassetteSet,
  setVaultTransactionFail,
  setVaultTransactionResponse,
  stopLoadingAfterError,
  updateCitNewCassetteSetFail,
} from './actions'
import {
  getCitCassetteSetsSort,
  getCitPlnSelectedTeam,
  getCitPlnSelectedWorkOrder,
  getCitPlnWOisAllOrders,
  getCitSelectedCassetteSet,
  getCitTeamsSort,
} from './selectors'

function* getCashPoints(): SagaIterator {
  try {
    const paging = yield select(state => {
      const { page, pageSize } = state?.cmCitPlanning?.cashPoints?.paging
      return {
        page,
        pageSize: pageSize === -1 ? 'All' : pageSize,
      }
    })
    const sort = yield select(state => ({
      sortColumn: state.cmCitPlanning.cashPoints.sortColumn,
      sortOrder: state.cmCitPlanning.cashPoints.sortOrder,
    }))
    const { selectedCashPoint } = yield select(state => state.cmCitPlanning.cashPoints)

    const storeFilter = yield select((state: AppState) => ({
      globalFilter: state?.cmCommon?.globalFilter,
      filter: state?.cmCitPlanning?.cashPoints.filter,
    }))
    const { globalFilter, filter } = storeFilter
    const response = yield call(
      CitPlanningApi.getCashPoints,
      paging,
      {
        globalFilter,
        filter,
      },
      sort.sortColumn,
      sort.sortOrder,
    )

    if (selectedCashPoint) {
      const cashPoint = response?.data.find(
        (el: CitPlnCashPoint) => el?.sessionId === selectedCashPoint?.sessionId,
      )
      yield put(
        setCitPlnSelectedCashPoint(cashPoint ? { ...cashPoint, id: cashPoint.sessionId } : null),
      )
    }

    yield put(getCitPlnCashPointsResponse(response))
  } catch (error) {
    yield put(getCitPlnCashPointsResponseFail(error))
  }
}

function* citPlnGetWorkOrdersRequest(): SagaIterator {
  try {
    if (window.location.pathname.includes(AppNavigationRoutes.CashManagement.CitPlanningPage)) {
      const branchIds = yield select((state: AppState) => state.cmCommon.globalFilter.filterIds)

      const filter = yield select(getCitPlnWorkOrdersFilter)
      const sort = yield select(getCitPlnWorkOrdersSort)
      const paging = yield select(getCitPlnWorkOrdersFormattedPaging)

      const response = yield call(
        CitPlanningApi.getWorkOrders,
        { filter: { ...filter, branchIds: branchIds || [] } },
        paging,
        sort.sortColumn,
        sort.sortOrder,
      )

      yield put(getCitPlnWorkOrdersResponse(response))
      return response
    }
  } catch (error) {
    yield put(getCitPlnWorkOrdersFail(error))
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const { isFiltersModalOpen, isDocumentModalOpen } = yield select(
      (state: AppState) => state.cmCitPlanning,
    )
    const { selectedCashPoint: selected, data } = yield select(
      (state: AppState) => state.cmCitPlanning.cashPoints,
    )

    hotKeysBinder<CitPlnCashPoint>({
      key: payload,
      data,
      selected,
      isOpenModalsList: [isFiltersModalOpen, isDocumentModalOpen],
      setSelectedAction: setCitPlnSelectedCashPoint,
      openFilterAction: setCitPlnFiltersModalOpen,
      openPrintAction: setCitPlnDocumentModalOpen,
      onRefreshAction: refreshCitPlnCashPointsPanel,
    })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* getCashPoint({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const { selectedCashPoint } = yield select(getCitPlnCashPoints)
      const withoutUpdateSelected = selectedCashPoint?.sessionId !== payload

      const response = yield call(CitPlanningApi.getCashPoint, payload)

      yield put(getCitPlnCashPointResponse(response, withoutUpdateSelected))
    }
  } catch (e) {
    yield put(getCitPlnCashPointFail())
  }
}

function* setCashPointsFilter({ payload }: AnyAction): any {
  try {
    yield put(setGlobalFilter(payload.globalFilter))
    yield put(setCitPlnCashPointsFilter(payload.citPlnFilter))
  } catch (e) {
    yield put(setComCMError(e))
  }
}

function* handleFilterSubmit(action: AnyAction): SagaIterator {
  yield setCashPointsFilter(action)
  yield put(getCitPlnCashPointsRequest())
}

function* getCashPointProperty({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CitPlanningApi.getCashPointProperty, payload)
      yield put(getCitPlnCashPointPropertyResponse({ [payload]: response }))
    } else {
      yield put(getCitPlnCashPointPropertyResponse({}))
    }
  } catch (e) {
    yield put(setComCMError(e?.message))
  }
}

function* setCashPointProperty({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CitPlanningApi.setCashPointProperty, payload)

      yield put(getCitPlnCashPointResponse(response))
    }
  } catch (error) {
    yield put(setCitPlnCashPointPropertyFail())

    const parsedError = JSON.parse(error.message)
    if (parsedError.errorType !== 'silent_error') {
      const newPayload = { ...payload, force: true } as CitPlnSetPropertyRequest
      const errorMessage =
        parsedError.errorType === 'use_force'
          ? translate('translate#cm.Error_50002')
          : parsedError.message

      yield put(
        setActErrorVisible({
          type: parsedError.errorType,
          isVisible: true,
          actionType: CMActions.CitPlnSetCashPointPropertyRequest,
          actionPayload: newPayload,
          errorMessage: errorMessage,
        }),
      )
    }
  }
}

function* getReports(): SagaIterator {
  try {
    const { checkedCashPoints, selectedCashPoint, filter } = yield select(
      state => state?.cmCitPlanning?.cashPoints,
    )
    const globalFilter = yield select(state => state?.cmCommon?.globalFilter)
    const pointsFilter = {
      checkedIds: Object.keys(checkedCashPoints) || [],
      selectedId: selectedCashPoint?.id || '',
      globalFilter: globalFilter,
      filter: filter,
    }
    const response = yield call(CitPlanningApi.getReports, pointsFilter)
    yield put(getCitPlnReportsResponse(response))
  } catch (e) {
    yield put(getCitPlnReportsResponseFail(e?.message))
  }
}

function* getUnloadOrderReports(): SagaIterator {
  try {
    const { name, selectedUnloadOrder }: AppState['cmCitPlanning']['unloadOrder'] = yield select(
      (state: AppState) => state?.cmCitPlanning?.unloadOrder,
    )

    const postBody = {
      unloadOrderName: name,
      sessionId: selectedUnloadOrder?.sessionId,
    }

    const response = yield call(CitPlanningApi.getUnloadOrderReports, postBody)

    yield put(getCitPlnUnloadOrderReportsResponse(response))
  } catch (e) {
    yield put(getCitPlnUnloadOrderReportsFail(e?.message))
  }
}

function* getUnloadOrderReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)

      yield put(getCitPlnUnloadOrderReportResponse(response))
    } else {
      yield put(getCitPlnUnloadOrderReportResponse(null))
    }
  } catch (e) {
    yield put(getCitPlnUnloadOrderReportFail(e?.message))
  }
}

function* getRepairOrderReports(): SagaIterator {
  try {
    const { name, selectedRepairOrder }: AppState['cmCitPlanning']['repairOrder'] = yield select(
      (state: AppState) => state?.cmCitPlanning?.repairOrder,
    )

    const postBody = {
      repairOrderName: name,
      sessionId: selectedRepairOrder?.sessionId,
    }

    const response = yield call(CitPlanningApi.getRepairOrderReports, postBody)

    yield put(getCitPlnRepairOrderReportsResponse(response))
  } catch (e) {
    yield put(getCitPlnRepairOrderReportsFail(e?.message))
  }
}

function* getRepairOrderReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)

      yield put(getCitPlnRepairOrderReportResponse(response))
    } else {
      yield put(getCitPlnRepairOrderReportResponse(null))
    }
  } catch (e) {
    yield put(getCitPlnRepairOrderReportFail(e?.message))
  }
}

function* getWOReports(): SagaIterator {
  try {
    const {
      name,
      selectedWorkOrder,
      isAllOrders,
      filter,
    }: AppState['cmCitPlanning']['workOrder'] = yield select(getCitPlnWorkOrder)

    const postBody = {
      workOrderName: name,
      sessionId: selectedWorkOrder?.sessionId,
      currencyCodes: selectedWorkOrder?.cassetteEntries?.map(
        cassette => cassette.currency?.name,
      ) as string[],

      isAllOrders,
      zipFiles: false,
      ...filter,
    }

    const response = yield call(CitPlanningApi.getWorkOrderReports, postBody)

    yield put(getCitPlnWOReportsResponse(response))
  } catch (e) {
    yield put(getCitPlnWOReportsFail(e?.message))
  }
}

function* getWOReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getCitPlnWOReportResponse(response))
    } else {
      yield put(getCitPlnWOReportResponse(null))
    }
  } catch (e) {
    yield put(getCitPlnWOReportFail(e?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getCitPlnReportResponse(response))
    } else {
      yield put(getCitPlnReportResponse(null))
    }
  } catch (e) {
    yield put(getCitPlnReportResponseFail(e?.message))
  }
}

function* getWorkOrdersReports(): SagaIterator {
  try {
    const { orderName } = yield select(getSelectedWorkOrder) || {}
    const filter = yield select(getCitPlnWorkOrdersFilter)
    const { filterIds } = yield select(getGlobalFilter)

    const response = yield call(CitPlanningApi.getWorkOrdersReports, {
      ...filter,
      orderName,
      zipFiles: false,
      branchIds: filterIds,
    })
    yield put(getCitPlnWorkOrdersReportsResponse(response))
  } catch (e) {
    yield put(getCitPlnWorkOrdersReportsResponseFail(e?.message))
  }
}

function* getWorkOrdersReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getCitPlnWorkOrdersReportResponse(response))
    } else {
      yield put(getCitPlnWorkOrdersReportResponse(null))
    }
  } catch (e) {
    yield put(getCitPlnWorkOrdersReportResponseFail(e?.message))
  }
}

function* setUpperLimits({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const {
        selectedCashPoint: { cpId, sessionId },
      } = yield select(state => state?.cmCitPlanning?.cashPoints)

      yield call(CitPlanningApi.setUpperLimits, {
        cpId,
        sessionId,
        newLimits: payload,
      })
      yield put(getCitPlnCashPointRequest(sessionId))
    }
  } catch (e) {
    yield put(stopLoadingAfterError())
  }
}

function* handleCashPointsPagingChange({ payload }: AnyAction): SagaIterator {
  yield put(setCitPlnCashPointsPagingResponse(payload))
  yield put(getCitPlnCashPointsRequest())
}

function* declineCashPlan({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      yield call(CitPlanningApi.declineCashPlan, payload)
      const { selectedCashPoint } = yield select(state => state?.cmCitPlanning?.cashPoints)

      if (selectedCashPoint) {
        yield put(getCitPlnCashPointRequest(selectedCashPoint.sessionId))
      }
    }
  } catch (e) {
    yield put(stopLoadingAfterError())
  }
}
function* calculateCashPoint({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      yield call(CitPlanningApi.calculateCashPoint, payload)
    }
  } catch (e) {
    yield put(calculateCashPointFail(e))
    yield put(clearCheckedOrders())
  } finally {
    const { selectedCashPoint } = yield select(state => state?.cmCitPlanning?.cashPoints)

    if (selectedCashPoint) {
      yield put(getCitPlnCashPointRequest(selectedCashPoint.sessionId))
    }
  }
}

function* acceptCashPoint({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      yield call(CitPlanningApi.acceptCashPoint, payload)
      const { selectedCashPoint } = yield select(state => state?.cmCitPlanning?.cashPoints)

      if (selectedCashPoint) {
        yield put(getCitPlnCashPointRequest(selectedCashPoint.sessionId))
      }
    }
  } catch (e) {
    yield put(stopLoadingAfterError())
  }
}

function* setUnloading({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      yield call(CitPlanningApi.setUnloading, payload)
      const { selectedCashPoint } = yield select(state => state?.cmCitPlanning?.cashPoints)

      if (selectedCashPoint) {
        yield put(getCitPlnCashPointRequest(selectedCashPoint.sessionId))
      }
    }
  } catch (e) {
    yield put(stopLoadingAfterError())
  }
}

function* deleteCashOrder({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      yield call(CitPlanningApi.deleteCashOrder, payload)
      yield put(setCitPlnSelectedCashPoint(undefined))
      yield put(refreshCitPlnCashPointsPanel())
      yield put(getCitPlnWorkOrdersRequest())
    }
  } catch (e) {
    yield put(stopLoadingAfterError())
  }
}

function* declineCashOrder({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      yield call(CitPlanningApi.declineCashOrder, payload)

      yield put(refreshCitPlnCashPointsPanel())
      yield put(getCitPlnWorkOrdersRequest())
    }
  } catch (e) {
    yield put(stopLoadingAfterError())
  }
}

function* createWorkOrder(): SagaIterator {
  try {
    const {
      checkedLoads,
      checkedUnloads,
      checkedRepairs,
      total,
      data,
      paging: { pageSize },
    } = yield select(getCitPlnCashPoints)

    const requestBody = {
      selectedForLoad: [...checkedLoads],
      selectedForUnload: [...checkedUnloads],
      selectedForRepair: [...checkedRepairs],
    }

    yield call(CitPlanningApi.createWorkOrder, requestBody)

    yield put(clearCheckedOrders())

    const ordersIds = Array.from(new Set([...checkedLoads, ...checkedUnloads, ...checkedRepairs]))

    if (ordersIds.length < (pageSize === -1 ? total : pageSize) / 2 && ordersIds.length < 100) {
      const hashData = new Map()

      data.forEach(({ sessionId }: { sessionId: string }) => {
        hashData.set(sessionId, sessionId)
      })

      for (const orderId of ordersIds) {
        if (hashData.has(orderId)) {
          yield put(getCitPlnCashPointRequest(orderId))
        }
      }
    } else {
      yield put(refreshCitPlnCashPointsPanel())
    }
    yield put(getCitPlnWorkOrdersRequest())
  } catch (e) {
    yield put(stopLoadingAfterError())
  }
}

function* approveWorkOrder(): SagaIterator {
  try {
    const selectedWorkOrder = yield select(getCitPlnSelectedWorkOrder)
    const { workOrder, unloadOrder } = yield select(
      state => state?.cmCitPlanning?.cashPoints?.selectedCashPoint,
    )

    const data = {
      workOrderName: workOrder,
      uloadOrderName: unloadOrder,
    }

    yield call(CitPlanningApi.approveWorkOrder, data)

    yield put(refreshCitPlnCashPointsPanel())
    yield put(refreshCitPlnWOCassettes())
    yield put(getCitPlnWorkOrdersRequest())

    refetchCitPlnWOButtons({ isAllOrders: false, orderId: selectedWorkOrder?.coid })
  } catch (e) {
    yield put(stopLoadingAfterError())
  }
}

function* deleteWorkOrder(): SagaIterator {
  try {
    const { workOrder, unloadOrder, repairOrder } = yield select(
      state => state?.cmCitPlanning?.cashPoints?.selectedCashPoint,
    )

    const data = {
      workOrderName: workOrder,
      repairOrderName: repairOrder,
      uloadOrderName: unloadOrder,
    }

    yield call(CitPlanningApi.deleteWorkOrder, data)

    yield put(deleteWorkOrderResponse())
    yield put(refreshCitPlnCashPointsPanel())
    yield put(getCitPlnWorkOrdersRequest())
  } catch (e) {
    yield put(deleteWorkOrderFail())
  }
}

function* getWOCassettes(): SagaIterator {
  try {
    const { name } = yield select(getCitPlnWorkOrder)
    const selectedWorkOrder = yield select(getSelectedWorkOrder)
    const selectedWO = yield select(getCitPlnSelectedWorkOrder)
    const sort = yield select((state: AppState) => ({
      sortColumn: state.cmCitPlanning.workOrder.sortColumn,
      sortOrder: state.cmCitPlanning.workOrder.sortOrder,
    }))

    const response = yield call(CitPlanningApi.getWorkOrderCassettes, { name, sort })

    yield put(getCitPlnWOCassettesResponse(response))

    if (selectedWorkOrder?.sessionId) {
      const cassettes = (response?.cassettes || []) as CitPlnWorkOrderCassettes[]
      const existingElement = cassettes.find(
        cassette => cassette.sessionId === selectedWorkOrder?.sessionId,
      )

      if (existingElement) {
        yield put(seCitPlnSelectedWO(existingElement))
      }
    }

    if (selectedWO) {
      refetchCitPlanningBags({ orderId: selectedWO?.sessionId })
    }
  } catch (error) {
    yield put(getCitPlnWOCassettesFail(error))
  }
}

function* applyWOCassettesStamps(): SagaIterator {
  try {
    const {
      isAllOrders,
      data: { cassettes: initialCassettes },
    } = yield select(getCitPlnWorkOrder)
    const cassettes: CitPlnSetWorkOrderCassettes[] = yield select(getCitPlnEditedCassettes)
    const selectedWorkOrder = yield select(getCitPlnSelectedWorkOrder)

    yield call(CitPlanningApi.applyWOCassettesStamps, {
      isAllOrders,
      entries: cassettes.map(allowedCassette => {
        const { bagId, bagName } =
          initialCassettes.find(
            (cassette: CitPlnWorkOrderCassettes) =>
              cassette.sessionId === allowedCassette.sessionId,
          ) || {}

        return { ...allowedCassette, bagId, bagName }
      }),
    })

    refetchCitPlnWOButtons({ isAllOrders, orderId: selectedWorkOrder?.coid })

    yield put(getCitPlnWOCassettesRequest())
    yield put(getCitPlnCashPointsRequest())
  } catch (error) {
    yield put(applyWOCassettesStampsFail())
  }
}

function* applyUnloadOrder({ payload }: AnyAction): SagaIterator {
  try {
    const orderName = yield select((state: AppState) => state.cmCitPlanning.unloadOrder.name)

    yield call(CitPlanningApi.applyUnloadOrder, { orderName, tasks: payload })

    yield put(getCitPlnUnloadOrderRequest(orderName))
  } catch (error) {}
}

function* applyRepairOrder({ payload }: AnyAction): SagaIterator {
  try {
    const orderName = yield select((state: AppState) => state.cmCitPlanning.repairOrder.name)

    yield call(CitPlanningApi.applyRepairOrder, { orderName, tasks: payload })

    yield put(getCitPlnRepairOrderRequest(orderName))
  } catch (error) {}
}

function* getUnloadOrder({ payload }: AnyAction): SagaIterator {
  try {
    const sort = yield select((state: AppState) => ({
      sortColumn: state.cmCitPlanning.unloadOrder.sortColumn,
      sortOrder: state.cmCitPlanning.unloadOrder.sortOrder,
    }))

    yield put(setCitPlnDetailsSelectedTab(WORK_ORDER))
    const response = yield call(CitPlanningApi.getUnloadOrder, { name: payload, sort })

    yield put(getCitPlnUnloadOrderResponse(response))
  } catch (error) {
    yield put(getCitPlnUnloadOrderResponseFail(error))
  }
}

function* getRepairOrder({ payload }: AnyAction): SagaIterator {
  try {
    const sort = yield select((state: AppState) => ({
      sortColumn: state.cmCitPlanning.repairOrder.sortColumn,
      sortOrder: state.cmCitPlanning.repairOrder.sortOrder,
    }))

    yield put(setCitPlnDetailsSelectedTab(WORK_ORDER))
    const response = yield call(CitPlanningApi.getRepairOrder, { name: payload, sort })

    yield put(getCitPlnRepairOrderResponse(response))
  } catch (error) {
    yield put(getCitPlnRepairOrderResponseFail(error))
  }
}

function* refreshRepairOrder(): SagaIterator {
  try {
    const orderName = yield select((state: AppState) => state.cmCitPlanning.repairOrder.name)

    yield put(getCitPlnRepairOrderRequest(orderName))
  } catch (error) {}
}

function* refreshUnloadOrder(): SagaIterator {
  try {
    const orderName = yield select((state: AppState) => state.cmCitPlanning.unloadOrder.name)

    yield put(getCitPlnUnloadOrderRequest(orderName))
  } catch (error) {}
}

// Cassette Sets
function* getCassetteSets(): SagaIterator {
  try {
    const sort = yield select(getCitCassetteSetsSort)

    const cassetteSet: AdmPlnCasSet = yield select(getCitSelectedCassetteSet)

    const response: AdmPlnCasSet[] = yield call(CassetteSetsApi.get, sort)

    if (cassetteSet) {
      const editedSelectedCassetteSet = response.find(
        (cassette: AdmPlnCasSet) => cassette.id === cassetteSet.id,
      )

      if (editedSelectedCassetteSet) {
        yield put(setCitSelectedCassetteSet(editedSelectedCassetteSet))
      }
    }

    yield put(getCitCassetteSetsResponse(response))
  } catch (error) {
    yield put(getCitCassetteSetsFail(error))
  }
}

function* addNewCassetteSet({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CassetteSetsApi.add, payload)

    yield put(setCitCassetteSetsModalOpen(false))
    yield put(getCitCassetteSetsRequest())

    yield put(getCitPlnCashPointPropertyRequest('cassetteSet'))
  } catch (error) {
    yield put(addCitNewCassetteSetFail())
  }
}

function* updateNewCassetteSet({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CassetteSetsApi.update, payload)

    yield put(setCitCassetteSetsModalOpen(false))
    yield put(getCitCassetteSetsRequest())

    yield put(getCitPlnCashPointPropertyRequest('cassetteSet'))
    yield put(getCitPlnCashPointsRequest())
  } catch (error) {
    yield put(updateCitNewCassetteSetFail())
  }
}

function* removeCassetteSet(): SagaIterator {
  try {
    const cassetteSet: AdmPlnCasSet = yield select(getCitSelectedCassetteSet)

    yield call(CassetteSetsApi.delete, cassetteSet.id)

    yield put(removeCassetteSetResponse())
    yield put(getCitCassetteSetsRequest())

    yield put(getCitPlnCashPointPropertyRequest('cassetteSet'))
    yield put(getCitPlnCashPointsRequest())
  } catch (error) {
    yield put(removeCassetteSetFail(error))
  }
}

function* getCassetteTypes(): SagaIterator {
  try {
    const cassetteTypes = yield call(CassetteSetsApi.getCassetteTypes)

    yield put(getCassetteTypesResponse(cassetteTypes))
  } catch (error) {
    yield put(setComCMError(error))
  }
}

// Teams
function* getCitTeams(): SagaIterator {
  try {
    const sort = yield select(getCitTeamsSort)
    const selectedTeam = yield select(getCitPlnSelectedTeam)

    const response: AdmPlnTeamGroup[] = yield call(CitTeamsApi.get, sort)

    if (selectedTeam) {
      for (let i = 0; i < response.length; i += 1) {
        const team = response[i].teams?.find(team => team.id === selectedTeam.id)
        if (team) {
          yield put(setCitPlnSelectedTeam(team))

          break
        }
      }
    }
    yield put(getCitTeamsResponse(response))
  } catch (error) {
    yield put(getCitTeamsFail(error))
  }
}

function* deleteCitTeam({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CitTeamsApi.delete, payload)

    yield put(setCitPlnSelectedTeam(null))
    yield put(getCitTeamsRequest())
  } catch (error) {
    yield put(deleteCitTeamFail(error))
  }
}

function* vaultTransaction(): SagaIterator {
  try {
    const isAllOrders = yield select(getCitPlnWOisAllOrders)
    const selectedWorkOrder = yield select(getCitPlnSelectedWorkOrder)
    const cassettes: CitPlnSetWorkOrderCassettes[] = yield select(getCitPlnEditedCassettes)

    yield call(CitPlanningApi.vaultTransaction, { isAllOrders, entries: cassettes })

    refetchCitPlnWOButtons({ isAllOrders, orderId: selectedWorkOrder?.coid })
    yield put(setVaultTransactionResponse())
  } catch (error) {
    yield put(setVaultTransactionFail())
  }
}

export default function*(): Generator {
  yield takeLatest(
    [
      CMActions.CitPlnGetCashPointsRequest,
      CMActions.CitPlnSortCashPointsTable,
      CMActions.CitPlnRefreshCashPointsPanel,
    ],
    getCashPoints,
  )

  yield takeLatest(
    [
      CMActions.CitPlnGetWorkOrdersRequest,
      CMActions.CitPlnSortWorkOrdersTable,
      CMActions.CitPlnSetWorkOrdersPaging,
      CMActions.SetGlobalFilter,
      CMActions.CitPlnWorkOrdersFilterSubmitRequest,
    ],
    citPlnGetWorkOrdersRequest,
  )
  yield takeLatest(CMActions.CitPlnHandleKeyPress, handleKeyPress)
  yield takeLatest(CMActions.CitPlnWorkOrdersGetReportsRequest, getWorkOrdersReports)
  yield takeLatest(CMActions.CitPlnWorkOrdersGetReportRequest, getWorkOrdersReport)
  yield takeLatest(CMActions.CitPlnApproveWorkOrderRequest, approveWorkOrder)
  yield takeLatest(CMActions.CitPlnDeleteWorkOrderRequest, deleteWorkOrder)
  yield takeLatest(CMActions.CitPlnCreateWorkOrderRequest, createWorkOrder)
  yield takeLatest(CMActions.CitPlnDeclineCashOrderRequest, declineCashOrder)
  yield takeLatest(CMActions.CitPlnDeleteCashOrderRequest, deleteCashOrder)
  yield takeLatest(CMActions.CitPlnSetUnloadingRequest, setUnloading)
  yield takeLatest(CMActions.CitPlnDeclineCashPlanRequest, declineCashPlan)
  yield takeLatest(CMActions.CitPlnCalculateCashPointRequest, calculateCashPoint)
  yield takeLatest(CMActions.CitPlnAcceptCashPointRequest, acceptCashPoint)

  yield takeEvery(CMActions.CitPlnGetCashPointRequest, getCashPoint)
  yield takeEvery(CMActions.CitPlnGetCashPointPropertyRequest, getCashPointProperty)
  yield takeEvery(CMActions.CitPlnSetCashPointPropertyRequest, setCashPointProperty)
  yield takeLatest(CMActions.CitPlnFilterSubmitRequest, handleFilterSubmit)
  yield takeLatest(CMActions.CitPlnGetReportsRequest, getReports)
  yield takeLatest(CMActions.CitPlnSetUpperLimitsRequest, setUpperLimits)
  yield takeLatest(CMActions.CitPlnGetReportRequest, getReport)
  yield takeLatest(CMActions.CitPlnSetCashPointsPagingRequest, handleCashPointsPagingChange)

  yield takeLatest(CMActions.CitPlnGetWOCassettesRequest, getWOCassettes)
  yield takeLatest(CMActions.CitPlnRefreshWOCassettes, getWOCassettes)
  yield takeLatest(CMActions.CitPlnSortWOCassettes, getWOCassettes)

  yield takeLatest(CMActions.CitPlnGetWOReportsRequest, getWOReports)
  yield takeLatest(CMActions.CitPlnGetWOReportRequest, getWOReport)
  yield takeLatest(CMActions.CitPlnApplyWOCassettesStamps, applyWOCassettesStamps)

  yield takeLatest(CMActions.CitPlnGetRepairOrderReportsRequest, getRepairOrderReports)
  yield takeLatest(CMActions.CitPlnGetRepairOrderReportRequest, getRepairOrderReport)

  yield takeLatest(CMActions.CitPlnGetUnloadOrderReportsRequest, getUnloadOrderReports)
  yield takeLatest(CMActions.CitPlnGetUnloadOrderReportRequest, getUnloadOrderReport)

  yield takeLatest(CMActions.CitPlnGetUnloadOrderRequest, getUnloadOrder)
  yield takeLatest(CMActions.CitPlnGetRepairOrderRequest, getRepairOrder)
  yield takeLatest(CMActions.CitPlnApplyUnloadOrder, applyUnloadOrder)
  yield takeLatest(CMActions.CitPlnApplyRepairOrder, applyRepairOrder)
  yield takeLatest(CMActions.CitPlnRefreshUnloadOrder, refreshUnloadOrder)
  yield takeLatest(CMActions.CitPlnRefreshRepairOrder, refreshRepairOrder)

  // Cassette Sets
  yield takeLatest(
    [CMActions.CitGetCassetteSetsRequest, CMActions.CitSortCassettesSets],
    getCassetteSets,
  )
  yield takeLatest(CMActions.CitAddNewCassetteSetRequest, addNewCassetteSet)
  yield takeLatest(CMActions.CitUpdateNewCassetteSetRequest, updateNewCassetteSet)
  yield takeLatest(CMActions.CitPlnGetCassetteTypesRequest, getCassetteTypes)
  yield takeLatest(CMActions.CitRemoveCassetteSetRequest, removeCassetteSet)

  // Teams
  yield takeLatest([CMActions.CitPlnGetTeamsRequest, CMActions.CitPlnSortTeamsTable], getCitTeams)
  yield takeLatest(CMActions.CitPlnDeleteTeamRequest, deleteCitTeam)

  yield takeLatest(CMActions.CitPlnSetVaultTransactionRequest, vaultTransaction)
}
