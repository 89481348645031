import { useCallback, useState } from 'react'

type SortColumn = string | undefined
type SortOrder = 'asc' | 'desc' | undefined

type Sort = { sortColumn: SortColumn; sortOrder: SortOrder }

export interface SortProps {
  columnsToSort?: string[]
  handleSort?: (sortColumn: SortColumn, sortOrder: SortOrder) => void
}

interface ResultProps {
  order: SortOrder
  orderBy: SortColumn
  handleSortColumn: (value: string) => void
}

export const useSort = (props: SortProps): ResultProps => {
  const { handleSort } = props

  const [sort, setSort] = useState<Sort>({ sortColumn: undefined, sortOrder: undefined })

  const handleSortColumn = useCallback((value: string): void => {
    setSort(({ sortColumn, sortOrder }) => {
      const isAsc = sortColumn === value && sortOrder === 'asc'
      const isDesc = sortColumn === value && sortOrder === 'desc'

      const localSortOrder = isAsc ? 'desc' : isDesc ? undefined : 'asc'
      const localSortColumn = isDesc ? undefined : value

      handleSort && handleSort(localSortColumn, localSortOrder)

      return { sortColumn: localSortColumn, sortOrder: localSortOrder }
    })
  }, [])

  return {
    order: sort.sortOrder,
    orderBy: sort.sortColumn,
    handleSortColumn,
  }
}
