import queryString from 'query-string'

import {
  AddBagRequest,
  BagStatusesResponse,
  BagUser,
  CassetteBarcodeRequest,
  EditBagRequest,
  eUserRole,
  FoundCassetteBarcodeResponse,
  VaultBag,
  VaultBagCassette,
  VaultBagLoadRequest,
  VaultBagsRequest,
  VaultBagsResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { Paging, Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

interface GetAllProps {
  sort: Sort
  paging: Paging
  filter: VaultBagsRequest
}
export class BagsApi {
  private static requestService = getRequestServiceVaultV1()

  public static async getAll(props: GetAllProps): Promise<VaultBagsResponse> {
    const { filter, paging, sort } = props

    try {
      const url = queryString.stringifyUrl(
        {
          url: '/bags',
          query: { ...paging, ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await BagsApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getSingle(guid: string): Promise<VaultBag> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/bags/bag',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await BagsApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async add(payload: AddBagRequest): Promise<string> {
    try {
      return await BagsApi.requestService.post('/bags/add', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async edit(payload: EditBagRequest): Promise<string> {
    try {
      return await BagsApi.requestService.put('/bags/edit', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async delete(guid: string): Promise<string> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/bags/delete',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await BagsApi.requestService.delete(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async load(payload: VaultBagLoadRequest): Promise<void> {
    try {
      return await BagsApi.requestService.post('/bags/load', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async unload(bagId: string): Promise<void> {
    try {
      return await BagsApi.requestService.post('/bags/unload', { bagId })
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async editDetails(payload: VaultBagLoadRequest): Promise<void> {
    try {
      return await BagsApi.requestService.put('/bags/load/edit', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async details(guid: string): Promise<VaultBagCassette[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/bags/details',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await BagsApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async buttonsStatuses(guid: string): Promise<BagStatusesResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/bags/buttons/statuses',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await BagsApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getUsers(roleTypes?: eUserRole[]): Promise<BagUser[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/bags/users',
          query: { filterByCompany: false },
        },
        { skipNull: true, encode: false },
      )

      return await BagsApi.requestService.post(url, { roleTypes })
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async cassetteBarcode(
    vaultId: string,
    props: CassetteBarcodeRequest,
  ): Promise<FoundCassetteBarcodeResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/bags/barcode',
          query: { vaultId },
        },
        { skipNull: true, encode: false },
      )

      return await BagsApi.requestService.post(url, props)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }
}
