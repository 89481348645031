import React, { useCallback, useState, useEffect, useMemo, useRef, Fragment } from 'react'
import { Form, Formik, Field, FieldProps, FormikValues } from 'formik'
import { useClasses } from './styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {
  FIELD_NAME_TERMINAL_IDS,
  FIELD_NAME_TERMINALS,
  Props,
  WorkingHoursBlockError,
} from './types'
import FormFooter from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/formFooter/component'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import { isEmpty, isNumber, isString } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'
import { cutParentheses } from '@/helpers/generateErrorText'
import {
  getPOSServiceFormConfigInitialValues,
  getPOSServiceFormConfigGroupedParameters,
  isLookingForCreatePOSTicketServiceTypeFormConfig,
  getSaveRequestInitialParameters,
  getPOSServiceFormConfigParameters,
} from '@/store/sd/drawerActions/selectors/newPOS'
import { getPOSServiceFormConfigRequest } from '@/store/sd/drawerActions/actions'
import Loader from '@/components/blocks/Loader/component'
import clsx from 'clsx'
import SubTitle from '@/components/wrappers/AppNavigation/components/DrawerActions/components/SubTitle/component'
import { ServiceTerminals } from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/ServiceTypeModal/components/ServiceTerminals'
import TerminalSearchTable from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/fields/TerminalSearchTable/component'
import { useTranslate } from '@/utils/internalization'
import { validateForm } from './validate'
import DynamicFieldsGroupWrapper from '@/components/blocks/DynamicFieldsGroupWrapper/component'
import moment from 'moment'
import { LocalStorageItems } from '@/constants'
import { POSServiceFieldTypes } from '@/components/blocks/DynamicFieldsGroupWrapper/components/DynamicField/types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandMoreLess from '@material-ui/icons/ExpandLess'
import Button from '@/components/controls/Button'

const ServiceTypeForm = ({
  handleSubmit,
  editRow,
  handleClose,
  testIdPrefix,
  serviceTypeId,
  title,
  merchantId,
  withTerminalIdsField,
  withSingleTerminalSelecting,
  merchantValue,
  merchantFullName,
  selectedTerminalIds,
}: Props): React.ReactElement => {
  const [filtersIsVisible, setFiltersIsVisible] = useState<boolean>(true)
  const [modalHeight, setModalHeight] = useState<number | null>(null)

  const classes = useClasses({ filtersIsVisible, modalHeight })
  const idPrefix = `${testIdPrefix}-form`
  const testId = getTestId(idPrefix)
  const dispatch = useDispatch()
  const translate = useTranslate()
  const fieldParameters = useSelector(getPOSServiceFormConfigParameters)
  const fieldGroups = useSelector(getPOSServiceFormConfigGroupedParameters)
  const saveRequestInitialParameters = useSelector(getSaveRequestInitialParameters)
  const fieldsInitialValues = useSelector(getPOSServiceFormConfigInitialValues)
  const isLoadingFormConfig = useSelector(isLookingForCreatePOSTicketServiceTypeFormConfig)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (saveRequestInitialParameters.issuercompanyid) {
      dispatch(
        getPOSServiceFormConfigRequest(serviceTypeId, saveRequestInitialParameters.issuercompanyid),
      )
    }
  }, [saveRequestInitialParameters.issuercompanyid])

  useEffect(() => {
    if (containerRef.current) {
      const container = window.getComputedStyle(containerRef.current, null)
      const padding =
        parseInt(container.getPropertyValue('padding-top').slice(0, -2)) +
        parseInt(container.getPropertyValue('padding-bottom').slice(0, -2))

      if (serviceTypeId === '2' || serviceTypeId === '6') {
        setModalHeight(containerRef.current.clientHeight + padding)
      } else {
        setModalHeight(containerRef.current.clientHeight)
      }
    }
  }, [containerRef.current])

  const handleVisibleFilters = useCallback(() => setFiltersIsVisible(prevState => !prevState), [])

  const validate = useCallback(
    (values: FormikValues): { [key: string]: string | WorkingHoursBlockError } =>
      validateForm(values, withTerminalIdsField, !!editRow, fieldParameters),
    [withTerminalIdsField, editRow, fieldParameters],
  )

  const initialValuesFromEditRow = useMemo(() => {
    if (editRow) {
      return Object.keys(fieldsInitialValues).reduce((accum, key) => {
        const valueFromEditRow = editRow.posFormDataParameters
          ? editRow.posFormDataParameters[key]
          : (editRow as FormikValues)[key]

        let value: string | number | null | string[] = fieldsInitialValues[key]

        if (valueFromEditRow) {
          value = valueFromEditRow
          if (Array.isArray(fieldsInitialValues[key])) {
            if (isString(valueFromEditRow)) {
              value = valueFromEditRow.split(',')
            }
            if (Array.isArray(valueFromEditRow)) {
              value = valueFromEditRow
            }
          } else if (isNumber(valueFromEditRow)) {
            value = `${valueFromEditRow}`
          } else if (fieldParameters[key].displayTypeId === POSServiceFieldTypes.Date) {
            value = moment(valueFromEditRow).toISOString()
          }
        }

        return {
          ...accum,
          [key]: value,
        }
      }, {})
    }

    return {}
  }, [editRow, fieldsInitialValues, fieldParameters])

  const lunoTitle =
    editRow && editRow.posFormDataParameters && editRow.posFormDataParameters.posaquirerluno
      ? editRow.posFormDataParameters.posaquirerluno &&
        ` - ${editRow.posFormDataParameters.posaquirerluno}`
      : editRow &&
        (editRow as FormikValues).acquirerLuno &&
        ` - ${(editRow as FormikValues).acquirerLuno}`

  const userCountry = useMemo(
    () =>
      JSON.parse(
        sessionStorage.getItem(LocalStorageItems.CurrentAccount) || '',
      ).companyCountryId.toString(),
    [],
  )

  return (
    <>
      {isLoadingFormConfig || isEmpty(fieldGroups) || isEmpty(fieldsInitialValues) ? (
        <div className={classes.loadingWrapper}>
          <Loader />
        </div>
      ) : (
        <Formik
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={{
            ...fieldsInitialValues,
            ...(withTerminalIdsField && { [FIELD_NAME_TERMINAL_IDS]: [''] }),
            ...(!withTerminalIdsField && { [FIELD_NAME_TERMINALS]: [] }),
            ...({ poscountryid: userCountry } as FormikValues),
            ...(editRow && { ...initialValuesFromEditRow }),
            ...({
              subject: cutParentheses(merchantFullName || merchantValue || ''),
            } as FormikValues),
          }}
        >
          {({
            values,
            setValues,
            setFieldValue,
            errors,
            isValid,
            setFieldTouched,
          }): React.ReactElement => {
            return (
              <Test id={testId(0)}>
                <div className={classes.container} ref={containerRef}>
                  <div className={clsx(classes.wrapper, classes.titleWrapper)}>
                    <Typography variant="h5">
                      {title || ''}
                      {lunoTitle}
                    </Typography>
                    {serviceTypeId !== '1' && (
                      <Button onClick={handleVisibleFilters} className={classes.collapseBtn}>
                        {filtersIsVisible ? <ExpandMoreLess /> : <ExpandMoreIcon />}
                      </Button>
                    )}
                  </div>
                  <Form className={classes.form}>
                    <div className={clsx(classes.wrapper, classes.fieldsWrapper)}>
                      {filtersIsVisible && (
                        <Grid
                          container
                          alignItems="flex-start"
                          spacing={4}
                          alignContent="flex-start"
                          style={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(${fieldGroups.pageColumnCount}, 1fr)`,
                          }}
                        >
                          {fieldGroups.displayObjectList.map((group: any, index: number) => (
                            <Fragment key={index}>
                              {group.type === 'GROUP' && (
                                <DynamicFieldsGroupWrapper
                                  key={group.outOrderNo}
                                  item={group.outputElement}
                                  formValues={values}
                                  formErrors={errors}
                                  setFormikValues={setValues}
                                  setFormikFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  parentColCount={fieldGroups.pageColumnCount}
                                />
                              )}
                            </Fragment>
                          ))}
                        </Grid>
                      )}
                    </div>
                    <Grid className={classes.wrapper} container>
                      {!editRow && !withTerminalIdsField && (
                        <>
                          <Grid item md={12} style={{ marginTop: filtersIsVisible ? 40 : 16 }}>
                            <SubTitle
                              isRequired
                              text={translate('translate#title.terminalSearch')}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <Test id={testId(10)}>
                              <Field name={FIELD_NAME_TERMINALS}>
                                {(formikFieldProps: FieldProps) => (
                                  <TerminalSearchTable
                                    {...formikFieldProps}
                                    isSingleSelectingRow={withSingleTerminalSelecting}
                                    merchantId={merchantId}
                                    serviceTypeId={+serviceTypeId}
                                    selectedTerminalIds={selectedTerminalIds}
                                    filtersIsVisible={filtersIsVisible}
                                    modalHeight={modalHeight}
                                  />
                                )}
                              </Field>
                            </Test>
                          </Grid>
                          <Grid />
                        </>
                      )}
                      {!editRow && withTerminalIdsField && (
                        <>
                          <Grid item md={12} style={{ marginTop: filtersIsVisible ? 40 : 16 }}>
                            <SubTitle text={translate('translate#title.terminalId')} isRequired />
                          </Grid>
                          <Grid item md={12}>
                            <Test id={testId(20)}>
                              <Field>{ServiceTerminals}</Field>
                            </Test>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <FormFooter
                      values={values}
                      isValid={isValid}
                      onCancel={handleClose}
                      merchantValue={merchantValue}
                    />
                  </Form>
                </div>
              </Test>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default ServiceTypeForm
