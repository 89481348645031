import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getVaultsCassettes = (state: AppState): fromReducer.State => {
  return state.vaultV1Cassettes
}

export const getCassettes = createSelector(getVaultsCassettes, state => state.cassettes)

export const getCanCreateCassette = createSelector(getCassettes, state => state.canCreateCassette)
export const getCassettesFilter = createSelector(getCassettes, state => state.filter)
export const getCassettesPaging = createSelector(getCassettes, state => state.paging)
export const getCassettesSort = createSelector(getCassettes, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))

export const getCassettesTotal = createSelector(getCassettes, state => state.total)
export const getSearchingCassetteNr = createSelector(getCassettes, state => state.filter.name)
export const getSelectedCassette = createSelector(getCassettes, state => state.selected)

export const getModals = createSelector(getVaultsCassettes, state => state.modals)
export const getCassetteModal = createSelector(getVaultsCassettes, state => state.cassetteModal)

export const getLoadUnloadCassette = createSelector(
  getVaultsCassettes,
  state => state.loadUnloadCassetteLoading,
)
