import { Table, TableBody, TableContainer } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import LoadingOverlay from '@/components/blocks/LoadingOverlay'
import { useTranslate } from '@/utils/internalization'

import { CMTableHead, CMTableRows, ExpandRow } from './components'
import { RowsContext } from './context'
import { useColumns, useFixedColumns, useTwiceColumns } from './hooks'
import { useScrollToSelected } from './hooks/useScrollToSelected'
import { useClasses } from './styles'
import { CMTableProps, TableData } from './types'

export const CMTable: FC<CMTableProps> = React.memo((props: CMTableProps) => {
  const {
    tableId,

    data,

    isLoaded = false,
    isLoading = false,

    fixedColumns,
    headerConfig,
    twiceColumnsConfig,

    checkedRows,
    dataWithCheckbox = data,

    tableClasses,

    onSelectAll,
    cellWithNumberValue,

    defaultColumns,
    exceptColumns,
    sortersColumns = [],

    columnsToCollapse,

    columnsToSort,
    handleSort,

    fitTableContent = false,

    headerCellChild,

    settingsModalOpened = false,

    emptyDataText,

    handleCheckRow,

    collapseRowRender,
    formatRow,

    rowGroupKey,
    entriesKey,

    withShadow,

    ...rest
  } = props

  const classes = useClasses()

  const ref = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLDivElement | null>(null)

  const [isAllRowsOpened, setIsAllRowsOpened] = useState<boolean>(false)
  const [showCollapseIcon, setShowCollapseIcon] = useState<boolean>(!!collapseRowRender)

  const checkedRowsKeys = useMemo(() => Object.keys(checkedRows || {}), [checkedRows])

  const { keys, setKeys } = useColumns({
    tableId,
    headerConfig,
    exceptColumns,
    defaultColumns,
    data: data[0],
    settingsModalOpened,
  })
  const { localColumns, localFixedColumns } = useFixedColumns({ keys, fixedColumns })
  const { twiceColumns, getTwiceColumnTitles } = useTwiceColumns({ twiceColumnsConfig })

  useScrollToSelected({
    ref,
    idList: useMemo(
      () => (data || []).map(item => item.id || (rest.rowId ? item[rest.rowId] : '')),
      [data, rest.rowId],
    ),
    selectedId: useMemo(() => rest.selectedRowId, [rest.selectedRowId]),
  })

  useEffect(() => {
    setShowCollapseIcon(keys.some(key => columnsToCollapse?.includes(key)))
  }, [keys, columnsToCollapse])

  const rowColSpan = useMemo(() => {
    let colSpan = keys.length + 1
    handleCheckRow && colSpan++

    return colSpan
  }, [keys.length, handleCheckRow])

  const handleCollapseAll = useCallback(() => {
    setIsAllRowsOpened(prev => !prev)
  }, [])

  const headerCellChildMemo = useCallback(
    (key: string): ReactElement | null => (headerCellChild ? headerCellChild(key) : null),
    [headerCellChild],
  )

  return (
    <div
      ref={ref}
      className={clsx(classes.tableWrapper, tableClasses?.wrapper, {
        [classes.shadow]: withShadow,
      })}
    >
      <LoadingOverlay active={isLoading} loaded={isLoaded}>
        <>
          <TableContainer
            innerRef={tableRef}
            className={classes.tableContainer}
            classes={{ root: tableClasses?.container }}
          >
            <Table stickyHeader className={clsx(tableClasses?.table)}>
              <CMTableHead
                tableId={tableId}
                tableClasses={tableClasses}
                localColumns={localColumns}
                localFixedColumns={localFixedColumns}
                headerConfig={headerConfig || {}}
                twiceColumns={twiceColumns || []}
                isAllRowsOpened={isAllRowsOpened}
                handleCollapseAll={showCollapseIcon ? handleCollapseAll : undefined}
                checkedLength={checkedRowsKeys.length}
                dataWithCheckboxLength={(dataWithCheckbox || []).length}
                onSelectAll={onSelectAll}
                cellWithNumberValue={cellWithNumberValue || []}
                setKeys={setKeys}
                headerCellChild={headerCellChildMemo}
                getTwiceColumnTitles={getTwiceColumnTitles}
                columnsToSort={columnsToSort}
                handleSort={handleSort}
                sortersColumns={sortersColumns}
                fitTableContent={fitTableContent}
                isFirstEmptyCell={!!(rowGroupKey || formatRow)}
              />
              <TableBody>
                <RowsContext.Provider
                  value={{
                    fitTableContent,
                    isAllRowsOpened,
                    showCollapseIcon,
                    keys,
                    fixedColumns: localFixedColumns,
                    twiceColumns,
                    tableRef,
                    checkedRowsKeys,
                    columnsToSort,
                    cellWithNumberValue,
                    rowGroupKey,
                    entriesKey,
                    handleCheckRow,
                    collapseRowRender,
                    tableClasses,
                    ...rest,
                  }}
                >
                  <>
                    {(rowGroupKey || formatRow) && entriesKey ? (
                      data.map((item, index) => {
                        const formattedRow = formatRow && formatRow(item)

                        return (
                          <ExpandRow
                            key={rowGroupKey ? `${item[rowGroupKey]}-${index}` : index}
                            rowColSpan={rowColSpan}
                            collapseName={rowGroupKey ? (item[rowGroupKey] as string) : ''}
                            formattedRow={formattedRow}
                            entries={item[entriesKey] as TableData[]}
                            fitTableContent={fitTableContent}
                          />
                        )
                      })
                    ) : (
                      <CMTableRows data={data} rowColSpan={rowColSpan} />
                    )}
                  </>
                </RowsContext.Provider>
              </TableBody>
            </Table>
          </TableContainer>
          {!data.length && <EmptyBanner emptyDataText={emptyDataText} />}
        </>
      </LoadingOverlay>
    </div>
  )
})

const EmptyBanner = React.memo(({ emptyDataText }: { emptyDataText?: string }) => {
  const translate = useTranslate()
  const classes = useClasses()

  return (
    <div className={classes.noDataBanner}>
      {emptyDataText || translate('translate#cm.NoTotalData')}
    </div>
  )
})
