import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  onEventNameClickTypes,
  onTitleClickTypes,
} from '@/components/blocks/ATMeye/notifications/types/types'
import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { AppNavigationRoutes, ATMEYE_BLOCK_PREFIX, LocalStorageItems } from '@/constants'
import { notificationConfig } from '@/constants/atmeye/notificationConfig'
import { changeGrouping, changeSelectedFilters } from '@/store/atmeye/devices'
import {
  changeGrouping as changeGroupingAlarms,
  changeSorting as changeSortingAlarms,
} from '@/store/atmeye/securityAlarms'
import {
  changeGrouping as changeGroupingEvents,
  changeSorting,
} from '@/store/atmeye/technicalEvents'
import {
  actionsNotifications,
  getAlarms,
  getBlockAlarms,
  getBlockEvents,
  getEvents,
} from '@/store/notifications'
import { FiltersFromURLTypes } from '@/types'
import {
  FiltersAlarmsTypes,
  FiltersEventTypes,
  ReactionFilterTypes,
} from '@/types/atmeye/FiltersAlarmsTypes'

const useNotification = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const events = useSelector(getEvents)
  const alarms = useSelector(getAlarms)
  const blockAlarms = useSelector(getBlockAlarms)
  const blockEvents = useSelector(getBlockEvents)

  const technicalEventIds = useMemo(() => {
    return events.map(({ eventId }) => eventId).filter(x => !blockEvents.includes(x))
  }, [events, blockEvents])

  const securityAlarmIds = useMemo(() => {
    return alarms.map(({ eventId }) => eventId).filter(x => !blockAlarms.includes(x))
  }, [alarms, blockAlarms])

  const filtersPayload: FiltersFromURLTypes<FiltersAlarmsTypes> = {
    filterObject: {
      atm_date: [null, null],
      device_id: [],
      event_media_type_id: [],
      event_type_id: [],
      notes: '',
      operator_name: '',
      reaction_date: [null, null],
      reaction_filter: ReactionFilterTypes.OnlyWithoutReaction,
    },
  }

  const filtersEventPayload: FiltersFromURLTypes<FiltersEventTypes> = {
    filterObject: {
      device_id: [],
      event_media_type_id: [],
      event_type_id: [],
      notes: '',
      atm_date: [null, null],
    },
  }

  const handleOpenSecurityAlarmsTable = (): void => {
    history.push({
      pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.securityAlarms.root}`,
      state: filtersPayload,
    })
    dispatch(changeSortingAlarms({ atm_date: 'desc' }))
    dispatch(actionsNotifications.deleteAlarms({ eventIds: securityAlarmIds }))
  }

  const handleOpenTechnicalEventTable = (): void => {
    history.push({
      pathname: `/atmeye/events`,
      state: filtersEventPayload,
    })
    dispatch(changeSorting({ atm_date: 'desc' }))
    dispatch(actionsNotifications.deleteEvents({ eventIds: technicalEventIds }))
  }

  const onViewAllClick = (container: notificationConfig) => () => {
    return container === notificationConfig.ALARM_EVENT
      ? handleOpenSecurityAlarmsTable()
      : handleOpenTechnicalEventTable()
  }
  const onTitleClick = ({ isAlarm, eventId, deviceId }: onTitleClickTypes) => () => {
    if (isAlarm) {
      dispatch(changeSortingAlarms({ atm_date: 'desc' }))
      dispatch(changeGroupingAlarms([]))
      history.push({
        pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.securityAlarms.root}`,
        state: {
          ...filtersPayload,
          filterObject: { ...filtersPayload.filterObject, device_id: [`${deviceId}`] },
        },
      })

      dispatch(actionsNotifications.deleteAlarms({ eventIds: [eventId] }))
    } else {
      dispatch(changeSorting({ atm_date: 'desc' }))
      dispatch(changeGroupingEvents([]))
      history.push({
        pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.technicalEvents.root}`,
        state: {
          ...filtersEventPayload,
          filterObject: { ...filtersEventPayload.filterObject, device_id: [`${deviceId}`] },
        },
      })

      dispatch(actionsNotifications.deleteEvents({ eventIds: [eventId] }))
    }
  }

  const onEventNameClick = ({
    eventTypeId,
    eventId,
    isAlarm,
    deviceId,
  }: onEventNameClickTypes) => (): void => {
    if (isAlarm) {
      dispatch(changeSortingAlarms({ atm_date: 'desc' }))
      dispatch(changeGroupingAlarms([]))
      history.push({
        pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.securityAlarms.root}`,
        state: {
          ...filtersPayload,
          filterObject: {
            ...filtersPayload.filterObject,
            device_id: [`${deviceId}`],
            event_type_id: [eventTypeId.toString()],
          },
        },
      })

      dispatch(actionsNotifications.deleteAlarms({ eventIds: [eventId] }))
    } else {
      dispatch(changeSorting({ atm_date: 'desc' }))
      dispatch(changeGroupingEvents([]))
      history.push({
        pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.technicalEvents.root}`,
        state: {
          ...filtersEventPayload,
          filterObject: {
            ...filtersEventPayload.filterObject,
            device_id: [`${deviceId}`],
            event_type_id: [eventTypeId.toString()],
          },
        },
      })

      dispatch(actionsNotifications.deleteEvents({ eventIds: [eventId] }))
    }
  }

  const handleOnArrowClick = (
    deviceName: string,
    eventId: number,
    deviceId: number,
  ) => (): void => {
    dispatch(changeGrouping([{ key: 'connection_state', order: 'asc', orderBy: 'name' }]))
    dispatch(
      changeSelectedFilters({
        address: '',
        city_id: [],
        connection_state_id: [],
        core_state_id: [],
        country_id: [],
        device_model_id: [],
        device_id: [`${deviceId}`],
        device_type_id: [],
        excludedDevicesIds: '',
        includedDevicesIds: '',
        last_activity_date: [null, null],
        license_state_id: [],
        serial_number: '',
        vendor_company_id: [],
      }),
    )
    StrategyLocalStorage.setData(LocalStorageItems.ObjectsTreeDataMap, [
      [{ groupUID: 'connection_state', groupValue: '1', nodeId: '|1', valueType: 'INTEGER' }],
    ])
    history.push({
      pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.devices.root}`,
      state: {
        currentDevice: { deviceId, deviceName },
      },
    })
    dispatch(actionsNotifications.closeSecurityAlarm({ eventIds: [eventId] }))
  }

  return { onTitleClick, onEventNameClick, onViewAllClick, handleOnArrowClick }
}

export { useNotification }
