import {
  LogoutResponse,
  SignInResponse,
  SignInVaultResponse,
} from '@/api/common/authorizationApi/types'
import { APIConfiguration, INITIAL_LOCALE, LocalStorageItems } from '@/constants'
import { ResponseError } from '@/types'
import {
  getRequestService,
  LOG_LOGIN_URL,
  LOGIN_USM_URL,
  requestService,
} from '@/utils/services/request'

import { CommonApi } from '../commonApi'

export class AuthorizationApi {
  public static vaultRequestService = getRequestService(APIConfiguration.VAULT_API_BASE_URL)
  public static USMLoginRequestService = getRequestService(APIConfiguration.API_BSE_URL_USM_LOGIN)

  public static async SignIn(
    login: string,
    password: string,
  ): Promise<SignInResponse | ResponseError> {
    try {
      const culture = sessionStorage.getItem(LocalStorageItems.Locale) || INITIAL_LOCALE
      return await AuthorizationApi.USMLoginRequestService.post(
        LOGIN_USM_URL,
        { userName: login, password, culture: culture },
        true,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async SignInVault(
    login: string,
    password: string,
  ): Promise<SignInVaultResponse | ResponseError> {
    try {
      return await AuthorizationApi.vaultRequestService.post(
        '/account/login',
        { login, password },
        true,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async checkSession(): Promise<unknown | ResponseError> {
    try {
      return await requestService.post('/account/checkSession', {})
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async LogLogin(): Promise<any> {
    const clientVersion = await CommonApi.getClientVersion()
    try {
      return await requestService.post(
        LOG_LOGIN_URL,
        { clientType: 'PORTAL_CLIENT', clientVersion: clientVersion },
        true,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async Logout(): Promise<LogoutResponse | ResponseError> {
    try {
      return await requestService.post('/account/logout', {})
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getUserPhoto(): Promise<string | ResponseError> {
    try {
      return await requestService.get('/account/userPhoto')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async ChangePassword(
    login: string,
    oldPassword: string,
    newPassword: string,
  ): Promise<SignInResponse | ResponseError> {
    try {
      return await requestService.post(
        '/account/changePassword',
        { login, newPassword, oldPassword },
        true,
      )
    } catch (e) {
      return Promise.reject(e)
      // throw new Error(e)
    }
  }
}
