import moment from 'moment'
import { AnyAction } from 'redux'

import {
  ComCMReport,
  MonCashPoint,
  MonCashPointCassettesType,
  MonCashPointsFilter,
  MonCassetteEntry,
  MonChartPeriod,
} from '@/api/cm/swaggerGeneratedApi/index'
import { TableFilterDate } from '@/components/controls/AppTable/types'
import { CPDCassette } from '@/components/pages/cm/MonitoringPage/types'
import { CASSETTES, CMActions } from '@/constants'
import { monCashPointsTableId as tableId } from '@/constants/cm/cmTableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'
import { CashPointFilterType, Column, ExtendedCPDetails } from '@/types/cm/monitoring'
import { setInitialDateForCM } from '@/utils/functions'

export type State = {
  cashPoints: {
    isLoading: boolean
    isLoaded: boolean
    data: MonCashPoint[]
    total: number
    errorMessage?: string
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    paging: {
      page: number
      pageSize: number
    }
    selectedCols: Column[]
    isColumnModalOpen: boolean
    isDocumentModalOpen: boolean
    isFiltersModalOpen: boolean
    isAutoRefreshAdded: boolean
    areAllCurrenciesShown: boolean
    selectedCPoint: MonCashPoint | null
    monFilter: MonCashPointsFilter
    checkedCashPoints: { [key: string]: MonCashPoint }
    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }
  cashPointCassettes: {
    data: MonCassetteEntry[]
    isLoading: boolean
    isLoaded: boolean
  }
  cassettesChart: {
    data: CPDCassette[]
    isLoading: boolean
    isLoaded: boolean
  }
  cassetteCINData: {
    data: CPDCassette[]
    isLoading: boolean
    isLoaded: boolean
  }
  cashPointDetails: {
    selectedTab: string
    isFilterModalOpen: boolean
    filter: {
      currency: {
        type: CashPointFilterType
        period: MonChartPeriod
      }
      cassettes: {
        type: MonCashPointCassettesType
      }
      selectedDate: TableFilterDate
      withUpperLimit: boolean
    }
    cinFilter: {
      type: MonCashPointCassettesType
      withUpperLimit: boolean
    }
    isLoading: boolean
    pointDetails: ExtendedCPDetails | null
  }
}

export const initialState: State = {
  cashPoints: {
    isLoading: false,
    isLoaded: false,
    errorMessage: '',
    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
    monFilter: {
      daysUntilEmpty: undefined,
      currencyId: undefined,
      daysUntilCassettesEmpty: undefined,
      daysUntilOverflow: undefined,
      includeLowerLimit: false,
      includeUpperLimit: false,
      replenDate: undefined,
      objectServiceState: undefined,
      objectCashStatus: undefined,
      remaindersDate: moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format(),
    },
    checkedCashPoints: {},
    data: [],
    total: 0,
    sortColumn: undefined,
    sortOrder: undefined,
    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
    isDocumentModalOpen: false,
    isFiltersModalOpen: false,
    selectedCols: [
      { name: 'object', selected: true },
      { name: 'citid', selected: true },
      { name: 'cashPointObjectType', selected: true },
      { name: 'objectFunctionalType', selected: true },
      { name: 'emptyIn', selected: true },
      { name: 'cassetteEmptyIn', selected: true },
      { name: 'errors', selected: true },
      { name: 'planStatus', selected: true },
      { name: 'currency', selected: true },
      { name: 'operation', selected: true },
      { name: 'loaded', selected: true },
      { name: 'dispensed', selected: true },
      { name: 'deposit', selected: true },
      { name: 'remaining', selected: true },
      { name: 'cassetesDisplayValue', selected: true },
      { name: 'lastReplenDate', selected: true },
      { name: 'period', selected: true },
      { name: 'replenDate', selected: true },
      { name: 'cashStatus', selected: true },
      { name: 'serviceStatus', selected: true },
      { name: 'forecastDate', selected: true },
      { name: 'vault', selected: true },
      { name: 'city', selected: true },
      { name: 'address', selected: true },
    ],
    isColumnModalOpen: false,
    isAutoRefreshAdded: true,
    areAllCurrenciesShown: true,
    selectedCPoint: null,
  },

  cashPointCassettes: {
    data: [],
    isLoading: false,
    isLoaded: false,
  },

  cassettesChart: {
    data: [],
    isLoading: false,
    isLoaded: false,
  },

  cassetteCINData: {
    data: [],
    isLoading: false,
    isLoaded: false,
  },

  cashPointDetails: {
    selectedTab: CASSETTES,
    isFilterModalOpen: false,
    filter: {
      currency: {
        type: 'cash_reminders',
        period: 'DailySums',
      },
      cassettes: {
        type: 'Remainders',
      },
      selectedDate: setInitialDateForCM(),
      withUpperLimit: true,
    },
    cinFilter: {
      type: 'Remainders',
      withUpperLimit: true,
    },
    isLoading: false,
    pointDetails: null,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.SetMonCashPointDetailsCinFilter:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          cinFilter: {
            ...state.cashPointDetails.cinFilter,
            type: payload.type,
            withUpperLimit: payload.withUpperLimit,
          },
        },
      }

    case CMActions.GetCashPointCassettesRequest:
      return {
        ...state,
        cashPointCassettes: {
          ...state.cashPointCassettes,
          isLoading: true,
        },
      }
    case CMActions.GetCashPointCassettesResponse:
      return {
        ...state,
        cashPointCassettes: {
          ...state.cashPointCassettes,
          data: payload || [],
          isLoading: false,
          isLoaded: true,
        },
      }
    case CMActions.GetCashPointCassettesFail:
      return {
        ...state,
        cashPointCassettes: {
          ...state.cashPointCassettes,
          isLoading: false,
          isLoaded: true,
        },
      }

    case CMActions.SetGlobalFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            page: 1,
          },
        },
      }
    case CMActions.MonRefreshCashPointResponse: {
      const newData = [...state.cashPoints.data]
      const refreshedCPIndex = newData.findIndex(el => el?.id === payload?.id)
      if (~refreshedCPIndex) {
        newData[refreshedCPIndex] = { ...payload }
        return {
          ...state,
          cashPoints: {
            ...state.cashPoints,
            data: newData,
          },
        }
      }
      return state
    }
    case CMActions.MonResetCashPointsPage:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            page: initialState?.cashPoints?.paging.page || 1,
          },
        },
      }
    case CMActions.MonClearCheckedCashPoints: {
      return {
        ...state,
        cashPoints: {
          ...state?.cashPoints,
          checkedCashPoints: {},
        },
      }
    }
    case CMActions.MonCheckAllCashPoints: {
      const res: State['cashPoints']['checkedCashPoints'] = {}
      const allCashPoints = payload.length > 0 ? payload : state.cashPoints.data

      for (const cashPoint of allCashPoints) {
        if (cashPoint?.id) {
          res[cashPoint.id] = cashPoint
        }
      }
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          checkedCashPoints: res,
        },
      }
    }
    case CMActions.MonToggleCashPoint: {
      const newCheckedCashPoints = { ...state.cashPoints.checkedCashPoints }
      const soughtCP = newCheckedCashPoints[payload?.id]
      if (!soughtCP) {
        newCheckedCashPoints[payload?.id] = payload
      } else {
        delete newCheckedCashPoints[payload?.id]
      }

      return {
        ...state,
        cashPoints: {
          ...state?.cashPoints,
          checkedCashPoints: newCheckedCashPoints,
        },
      }
    }
    case CMActions.SetMonCashPointDetailsCurrencyFilter:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          filter: {
            ...state.cashPointDetails?.filter,
            currency: {
              ...state.cashPointDetails?.filter?.currency,
              ...payload,
            },
          },
        },
      }
    case CMActions.SetMonCashPointDetailsCassettesFilter:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          filter: {
            ...state.cashPointDetails?.filter,
            cassettes: {
              ...state.cashPointDetails?.filter?.cassettes,
              ...payload,
            },
          },
        },
      }
    case CMActions.SetSelectedDate:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          filter: {
            ...state.cashPointDetails?.filter,
            selectedDate: { ...payload },
          },
        },
      }
    case CMActions.GetMonitoringReportRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.GetMonitoringReportResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.GetMonitoringReportResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.GetMonitoringReportsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.cashPoints.reports.selectedReport,
          },
        },
      }
    case CMActions.GetMonitoringReportsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.GetMonitoringReportsResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }
    case CMActions.SetDocumentModalOpen:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isDocumentModalOpen: payload,
        },
      }
    case CMActions.SetFiltersModalOpen:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isFiltersModalOpen: payload,
        },
      }
    case CMActions.AlternateModal:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isColumnModalOpen: payload,
        },
      }
    case CMActions.GetCashPointDetailsRequest:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          isLoading: true,
          pointDetails: null,
        },
      }
    case CMActions.GetCashPointDetailsResponse:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          isLoading: false,
          pointDetails: payload,
        },
      }
    case CMActions.GetCashPointDetailsResponseFail:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          isLoading: false,
          pointDetails: null,
        },
      }
    case CMActions.ToggleColumns:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCols: state.cashPoints.selectedCols.map((col: Column) =>
            !payload.includes(col.name)
              ? { name: col.name, selected: false }
              : { name: col.name, selected: true },
          ) as Column[],
        },
      }
    case CMActions.SetAllCurenciesValueAction:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          areAllCurrenciesShown: payload,
        },
      }
    case CMActions.GetCashPointsForMainRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
          errorMessage: initialState?.cashPoints?.errorMessage || '',
        },
      }
    case CMActions.GetCashPointsForMainResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: false,
          isLoaded: true,
          data: payload.data,
          total: payload.total,
          errorMessage: '',
        },
      }
    case CMActions.GetCashPointsForMainResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: false,
          isLoaded: false,
          data: [],
          total: 0,
          errorMessage: payload,
        },
      }
    case CMActions.MonSetSelectedCashPoint:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCPoint: payload,
        },
      }
    case CMActions.SetCPDetailsTabNumber:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          selectedTab: payload,
        },
      }
    case CMActions.SetMonCashPointsPagingResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            ...payload,
          },
        },
      }
    case CMActions.SetMonitoringFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          monFilter: {
            ...state.cashPoints.monFilter,
            ...payload,
          },
        },
      }
    case CMActions.GetMonCassettesChartsRequest:
      return {
        ...state,
        cassettesChart: {
          ...state.cassettesChart,
          isLoading: true,
        },
      }
    case CMActions.GetMonCassettesChartsResponse:
      return {
        ...state,
        cassettesChart: {
          ...state.cassettesChart,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case CMActions.GetMonCassettesChartsFail:
      return {
        ...state,
        cassettesChart: {
          ...state.cassettesChart,
          isLoading: false,
          isLoaded: true,
        },
      }

    case CMActions.GetMonCINChartsRequest:
      return {
        ...state,
        cassetteCINData: {
          ...state.cassetteCINData,
          isLoading: true,
        },
      }
    case CMActions.GetMonCINChartsResponse:
      return {
        ...state,
        cassetteCINData: {
          ...state.cassetteCINData,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case CMActions.GetMonCINChartsFail:
      return {
        ...state,
        cassetteCINData: {
          ...state.cassetteCINData,
          isLoading: false,
          isLoaded: true,
        },
      }

    case CMActions.MonSortCashPointsTable:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          ...payload,
          isLoading: true,
        },
      }
    case CMActions.MonHandleCassettesUpperLimit:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          filter: {
            ...state.cashPointDetails.filter,
            withUpperLimit: payload,
          },
        },
      }
    case CMActions.MonSetDetailsFilterModalOpen:
      return {
        ...state,
        cashPointDetails: {
          ...state.cashPointDetails,
          isFilterModalOpen: payload,
        },
      }
    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
