import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromCommonVaultV1 from './reducer'

export const getCommonStateVaultV1 = (state: AppState): fromCommonVaultV1.State =>
  state.vaultV1Common

export const getGlobalFilterVaultV1 = createSelector(
  getCommonStateVaultV1,
  state => state.globalFilter,
)

export const getActErrorModal = createSelector(getCommonStateVaultV1, state => state.actError)
export const getAboutModalOpen = createSelector(getCommonStateVaultV1, state => state.about)

export const getFilterNodesVaultV1 = createSelector(getCommonStateVaultV1, state => ({
  filterNodes: state.filterNodes.data,
  isLoading: state.filterNodes.isLoading,
  isLoaded: state.filterNodes.isLoaded,
  error: state.filterNodes.error,
}))

export const getDeclineNote = createSelector(getCommonStateVaultV1, state => state.declineNoteState)

export const getIsIntegrationEnable = (integrationType: string) =>
  createSelector(getCommonStateVaultV1, state => {
    const { integrations } = state

    return integrations.some(integration => integration === integrationType)
  })

export const getBuildInfoVaultV1 = createSelector(getCommonStateVaultV1, state => state.buildInfo)

export const getComVaults = createSelector(getCommonStateVaultV1, state => state.vaults)
export const getComCurrencies = createSelector(getCommonStateVaultV1, state => state.currencies)

export const getRollbackAvailable = createSelector(
  getCommonStateVaultV1,
  state => state.rollbackAvailable,
)
