import { MonOperationsFilter, MonValuableRemainder } from '../swaggerGeneratedApi'

export interface GetCurrentValuablesRemaindersProps {
  id: string
  sessionDate: string
}

export interface SetCurrentValuablesRemaindersProps {
  id: string
  sessionDate: string
  remainders: MonValuableRemainder[]
}

export interface GetCashiersProps {
  guid: string
  name?: string
}

export interface MonOperationDetailsFilter {
  vaultId?: string
  currencyId?: number

  filter: MonOperationsFilter
}

export enum MonVaultType {
  Cashier = 'Cashier',
  SubVault = 'SubVault',
}
