import queryString from 'query-string'

import {
  Currency,
  IntegrationResponse,
  Order,
  OrderBagDetailsEntry,
  OrderBase,
  OrderCash,
  OrderCassetteDetailsEntry,
  OrderDocumentsRequest,
  OrderReportGeneratorEntry,
  OrderReportsRequest,
  OrdersRequest,
  OrdersResponse,
  OrderStage,
  OrderValuable,
  OrderVault,
  SelectorItem,
  SetStageRequest,
  VaultBag,
  VaultCassette,
  WorkOrderSeals,
  WorkOrdersResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class CashOrdersApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getOrders(
    paging: { page: number; pageSize: number },
    requestBody: OrdersRequest,
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<OrdersResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/orders/orders',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CashOrdersApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSingleVault(id: string): Promise<Order> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/orders/order',
          query: {
            id,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrderStages(orderId: string): Promise<OrderStage[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/orders/orders/stages',
          query: {
            id: orderId,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setOrderStages(
    url: string,
    requestBody: SetStageRequest,
  ): Promise<OrderStage[]> {
    try {
      return await CashOrdersApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(
    payload: OrderReportsRequest,
  ): Promise<OrderReportGeneratorEntry[]> {
    try {
      return await CashOrdersApi.requestService.post('/orders/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getStageReports(
    payload: OrderDocumentsRequest,
  ): Promise<OrderReportGeneratorEntry[]> {
    try {
      return await CashOrdersApi.requestService.post('/orders/documents', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrdDestinations(): Promise<OrderVault[]> {
    try {
      return await CashOrdersApi.requestService.get('/orders/cashOrder/vaults/destinations')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrdSources(guid: string): Promise<OrderVault[]> {
    try {
      const url = queryString.stringifyUrl(
        { url: '/orders/cashOrder/vaults/sources', query: { guid } },
        { skipNull: true, encode: false },
      )

      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrdDenominations(guid: string, toVaultId: string): Promise<OrderCash[]> {
    try {
      const url = queryString.stringifyUrl(
        { url: '/orders/cashOrder/cash', query: { guid, toVaultId } },
        { skipNull: true, encode: false },
      )

      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrdExpSources(): Promise<OrderVault[]> {
    try {
      return await CashOrdersApi.requestService.get('/orders/exportOrder/vaults/sources')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrdExpDestinations(guid: string): Promise<OrderVault[]> {
    try {
      const url = queryString.stringifyUrl(
        { url: 'orders/exportOrder/vaults/destinations', query: { guid } },
        { skipNull: true, encode: false },
      )

      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async addNewCashOrder(payload: OrderBase): Promise<string> {
    try {
      const { orderId } = await CashOrdersApi.requestService.post('/orders/cashOrder/add', payload)
      return orderId
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editNewCashOrder(payload: OrderBase): Promise<void> {
    try {
      return await CashOrdersApi.requestService.put('/orders/cashOrder/edit', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFilterCurrency(): Promise<Currency[]> {
    try {
      return await CashOrdersApi.requestService.get('/common/currencies/active')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFilterTeam(): Promise<SelectorItem[]> {
    try {
      return await CashOrdersApi.requestService.get('/common/teams')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getBagSeals(id: string): Promise<WorkOrderSeals[]> {
    try {
      const url = queryString.stringifyUrl(
        { url: '/orders/workOrders/seals', query: { id } },
        { skipNull: true, encode: false },
      )

      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setBagSeals(url: string, requestBody: WorkOrderSeals[]): Promise<void> {
    try {
      return await CashOrdersApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getBags(vaultId: string): Promise<VaultBag[]> {
    try {
      const url = queryString.stringifyUrl(
        { url: '/orders/bagsByVault', query: { vaultId } },
        { skipNull: true, encode: false },
      )

      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getBagDetails(id: string): Promise<OrderBagDetailsEntry[]> {
    try {
      const url = queryString.stringifyUrl(
        { url: '/orders/bagsDetails', query: { id } },
        { skipNull: true, encode: false },
      )

      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCassettes(vaultId: string): Promise<VaultCassette[]> {
    try {
      const url = queryString.stringifyUrl(
        { url: '/orders/cassettesByVault', query: { vaultId } },
        { skipNull: true, encode: false },
      )

      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCassetteDetails(id: string): Promise<OrderCassetteDetailsEntry[]> {
    try {
      const url = queryString.stringifyUrl(
        { url: '/orders/cassettesDetails', query: { id } },
        { skipNull: true, encode: false },
      )

      return await CashOrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getValuables(): Promise<OrderValuable[]> {
    try {
      return await CashOrdersApi.requestService.get('/orders/cashOrder/valuables')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getWorkOrders(payload: OrdersRequest): Promise<WorkOrdersResponse> {
    try {
      return await CashOrdersApi.requestService.post('/orders/workOrders', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getIntegrationStage(id: string): Promise<IntegrationResponse> {
    try {
      return await CashOrdersApi.requestService.get('/orders/integration/status', { guid: id })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async retryIntegrationStage(payload: {
    guid: string
    orderStatusId: string
  }): Promise<void> {
    try {
      return await CashOrdersApi.requestService.get('/orders/integration/retry', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setWorkOrders(url: string, requestBody: SetStageRequest): Promise<void> {
    try {
      return await CashOrdersApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }
}
