import { createSelector, Selector } from 'reselect'

import { eBS2AvailableIntegration } from '@/api/cm/swaggerGeneratedApi'
import { AppState } from '@/store/reducers'

import * as fromCommonCM from './reducer'

export const getCommonStateCM = (state: AppState): fromCommonCM.State => state.cmCommon

export const getBuildInfoCM = createSelector(getCommonStateCM, state => state?.buildInfo)

export const getComTeams = createSelector(getCommonStateCM, state => state.teams)

export const getFilterNodesCM = createSelector(getCommonStateCM, state => ({
  filterNodes: state.filterNodes,
  filterNodesLoading: state.filterNodesLoading,
  filterNodesLoaded: state.filterNodesLoaded,
  filterNodesError: state.filterNodesError,
}))

export const getUsersCM = createSelector(getCommonStateCM, state => ({
  users: state.users,
  usersLoading: state.usersLoading,
  usersError: state.usersError,
}))

export const getUsersOptions = createSelector(getCommonStateCM, state => state.usersOptions)

export const getCurrenciesCM = createSelector(getCommonStateCM, state => ({
  currencies: state.currencies,
  currenciesLoading: state.currenciesLoading,
  currenciesError: state.currenciesError,
}))

export const getFilterCurrenciesCM = createSelector(getCommonStateCM, state => ({
  filterCurrencies: state.filterCurrencies,
  filterCurrenciesLoading: state.filterCurrenciesLoading,
  filterCurrenciesError: state.filterCurrenciesError,
}))

export const getDenominationsCM = createSelector(getCommonStateCM, state => state.denominations)
export const getCompaniesCM = createSelector(getCommonStateCM, state => state.companies)

export const getGlobalFilter = createSelector(getCommonStateCM, state => state.globalFilter)

export const getSessionDocumentsReports = createSelector(
  getCommonStateCM,
  state => state.sessionDocumentsReports,
)
export const getGlobalFilterCashPoints = createSelector(
  getCommonStateCM,
  state => state.globalFilterCashPoints,
)

export const getActErrorModal = createSelector(getCommonStateCM, state => state.actError)

export const getIcsMissedReplenishment = createSelector(
  getCommonStateCM,
  state => state.missedReplenishment,
)

export const getIcsMissedReplenishmentChart = createSelector(
  getCommonStateCM,
  state => state.missedReplenishment.chart,
)
export const getIcsMissedReplenishmentEvents = createSelector(
  getCommonStateCM,
  state => state.missedReplenishment.events,
)
export const getIcsMissedReplenishmentCassettes = createSelector(
  getCommonStateCM,
  state => state.missedReplenishment.cassettes,
)
export const getIcsMRCassettesTotals = createSelector(
  getIcsMissedReplenishmentCassettes,
  ({ totals: { data, error, isLoaded, isLoading } }) => ({
    totals: data,
    totalsError: error,
    isTotalsLoaded: isLoaded,
    isTotalsLoading: isLoading,
  }),
)

// -------------------------------------------------------------------

export const getBalancingInfo = createSelector(getCommonStateCM, ({ balancingInfo }) => ({
  isBalancingInfoLoaded: balancingInfo.isLoaded,
  isBalancingInfoLoading: balancingInfo.isLoading,
  balancingInfoError: balancingInfo.error,
  balancingInfoData: balancingInfo.data,
}))
export const getBalancingInfoData = createSelector(
  getCommonStateCM,
  state => state.balancingInfo.data,
)

export const getBalancingCassettes = createSelector(getCommonStateCM, ({ balancingCassettes }) => ({
  isBalancingCassettesLoaded: balancingCassettes.isLoaded,
  isBalancingCassettesLoading: balancingCassettes.isLoading,
  balancingCassettesError: balancingCassettes.error,
  balancingCassettesData: balancingCassettes.data,
  selectedCycleEndSessionId: balancingCassettes.selectedCycleEndSessionId,
}))
export const getBalCassettesData = createSelector(
  getCommonStateCM,
  state => state.balancingCassettes.data,
)

export const getBalUnloadedCassettesData = createSelector(
  getCommonStateCM,
  state => state.balancingCassettes.data.unloadedCassettes,
)

export const getBalUnloadedInfoData = createSelector(
  getCommonStateCM,
  state => state.balancingInfo.data,
)

export const getBalNotLoadedCassettesData = createSelector(
  getCommonStateCM,
  state => state.balancingCassettes.data.cashOrderNotLoadedCassettes,
)

export const getBalSelectedUnloadedCassettes = createSelector(
  getCommonStateCM,
  state => state.selectedUnloadedCassettes,
)
export const getBalSelectedCassette = createSelector(
  getCommonStateCM,
  state => state.balancingCassettes.selected,
)
export const getBalNotLoadedCassettes = createSelector(
  getCommonStateCM,
  state => state.selectedNotLoadedCassettes,
)

export const getApplyLoading = createSelector(getCommonStateCM, state => state.isApplyLoading)
export const geUnloadedCassettesApplied = createSelector(
  getCommonStateCM,
  state => state.isUnloadedCassettesApplied,
)

export const getUnloadedTotals = createSelector(
  getCommonStateCM,
  state => state.balancingUnloadedTotals,
)

export const getNotLoadedTotals = createSelector(
  getCommonStateCM,
  state => state.balancingNotLoadedTotals,
)

export const getBalancingLoadingModal = createSelector(
  getCommonStateCM,
  state => state.isBalancingLoadingModal,
)

export const getCassetteModalType = createSelector(
  getCommonStateCM,
  state => state.cassetteModalType,
)

export const getTotalSessionId = createSelector(
  getCommonStateCM,
  state => state.balancingUnloadedTotals.totalSessionId,
)

export const getCommonAccounts = createSelector(getCommonStateCM, state => state.accounts)
export const getComCassetteConfig = createSelector(getCommonStateCM, state => state.cassetteConfig)
export const getComAvailableCassetteNumbers = createSelector(
  getCommonStateCM,
  state => state.availableCassetteNumbers,
)
export const getCompanyModalData = createSelector(getCommonStateCM, state => state.addCompany)

export const getApprovalItem = createSelector(getCommonStateCM, state => state.approvalItem)

export const getCashSortersModalOpen = createSelector(
  getCommonStateCM,
  state => state.isCashSortersModalOpen,
)
export const getCloseCashSorterModalOpen = createSelector(
  getCommonStateCM,
  state => state.isCloseCashSorterModalOpen,
)
export const getSorterStatus = createSelector(getCommonStateCM, state => state.isSortersStatus)
export const getSorterId = createSelector(getCommonStateCM, state => state.sorter)

export const getAboutModalOpen = createSelector(getCommonStateCM, state => state.about)

export const getSocketData = (id: string): Selector<AppState, any> =>
  createSelector(getCommonStateCM, state => {
    const { socketData } = state

    return socketData[id] || null
  })

export const getComIntegration = (name: eBS2AvailableIntegration): Selector<AppState, boolean> =>
  createSelector(getCommonStateCM, state => state.integration.includes(name))

export const getComVaultsWithLocation = createSelector(
  getCommonStateCM,
  state => state.vaultsWithLocation,
)
