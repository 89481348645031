import 'moment/locale/en-gb'
import 'moment/locale/ru'
import 'moment/locale/lt'
import 'moment/locale/lv'
import 'moment/locale/et'

import { ENGLISH, ESTONIAN, LATVIAN, LITHUANIAN, RUSSIAN } from '@/constants'

export const languages = [
  { label: 'EN', value: ENGLISH, code: 'gb' },
  { label: 'RU', value: RUSSIAN, code: 'ru' },
  { label: 'LT', value: LITHUANIAN, code: 'lt' },
  { label: 'LV', value: LATVIAN, code: 'lv' },
  { label: 'ET', value: ESTONIAN, code: 'ee' },
]
