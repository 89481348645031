import { PERMISSIONS, PermissionsResponseType } from '@/constants/permissions'
import { ResponseError } from '@/types'
import { turnObjectIntoURLSchema } from '@/utils/functions'
import { requestService } from '@/utils/services/request'

export class PermissionsApi {
  public static async getPermissionsByParamsIds(): Promise<
    PermissionsResponseType | ResponseError
  > {
    try {
      const paramsString = turnObjectIntoURLSchema(PERMISSIONS, 'objectNames')

      return await requestService.get(`/account/allowed-permissions?${paramsString}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAllUserPermissions(): Promise<PermissionsResponseType | ResponseError> {
    try {
      return await requestService.get(`/account/allUserPermissions`)
    } catch (e) {
      throw new Error(e)
    }
  }
}
