/* eslint-disable */
// common reducers
import * as fromADMDevice from './adm/device/reducer'
import * as fromADMDevicesConfigurationImport from './adm/devicesConfigurationImport/reducer'
import * as fromADMDevicesImport from './adm/devicesImport/reducer'
// adm
import * as fromADMWindowsSplitter from './adm/windowsSplitter/reducer'
import * as fromBlackList from './atmeye/blackList/reducer'
import * as fromDayNightSettings from './atmeye/cameraDayNightSettings/reducer'
import * as fromCentralSettings from './atmeye/centralSettings/reducer'
import * as fromATMEyeDashboard from './atmeye/dashboard/reducer'
// atmeye
import * as fromDevices from './atmeye/devices/reducer'
import * as fromDeviceSettings from './atmeye/deviceSettings/reducer'
import * as fromATMEyeMap from './atmeye/map/reducer'
import * as fromMultipleSnapshots from './atmeye/multipleSnapshots/reducer'
import * as fromMultipleSnapshotsDeviceSelector from './atmeye/multipleSnapshotsDeviceSelector/reducer'
import * as fromAtemeyeReports from './atmeye/reports/reducer'
import * as fromATMeyeCommon from './atmeye/common/reducer'
import * as fromRmf from './atmeye/rfm/reducer'
import * as fromRmfMultipleDevices from './atmeye/rfmMultipleDevices/reducer'
import * as fromCentralSettingsDeviceSelector from './atmeye/centralSettingsDeviceSelector/reducer'
import * as fromSecurityAlarms from './atmeye/securityAlarms/reducer'
import * as fromSecurityAlarmsByDevice from './atmeye/securityAlarmsByDevice/reducer'
import * as fromTechnicalEvents from './atmeye/technicalEvents/reducer'
import * as fromTechnicalEventsByDevice from './atmeye/technicalEventsByDevice/reducer'
import * as fromTransactions from './atmeye/transactions/reducer'
import * as fromTransactionsByDevice from './atmeye/transactionsByDevice/reducer'
import * as fromAuth from './auth/reducer'
import * as fromCMAdmin from './cm/admin/reducers'
import * as fromCMAnalysis from './cm/analysis/reducer'
import * as fromCMAuth from './cm/auth/reducer'
import * as fromCMBalancing from './cm/balancing/reducer'
import * as fromCMCitBalancing from './cm/citBalancing/reducer'
import * as fromCMCitPlanning from './cm/citPlanning/reducer'
import * as fromCMCommon from './cm/common/reducer'
import * as fromCMMaps from './cm/maps/reducer'
import * as fromCMMonitoring from './cm/monitoring/reducer'
import * as fromCMOrders from './cm/orders/reducer'
import * as fromCMPlanning from './cm/planning/reducer'
import * as fromCMPlasticCard from './cm/plasticCard/reducer'
import * as fromCMReplenishments from './cm/replenishments/reducer'
import * as fromCMShared from './cm/shared/reducer'
import * as fromPortal from './common/portal/reducer'
// Cash Management
import * as fromRoutes from './common/routes/reducer'
import * as fromTablesData from './common/tables/reducer'
import * as fromCompaniesCompanies from './companies/companies/reducer'
import * as fromCompaniesImport from './companies/companiesImport/reducer'
// companies
import * as fromCompaniesDrawerActions from './companies/drawerActions/reducer'
import * as fromCompaniesFormsConfigs from './companies/forms-configs/reducer'
import * as fromDashboard from './dashboard/reducer'
// dictionary
import * as fromDictionaryDictionariesList from './dictionaries/dictionariesList/reducer'
import * as fromDictionaryInfo from './dictionaries/dictionary/reducer'
import * as fromDictionaryLanguages from './dictionaries/languages/reducer'
import * as fromDictionaryUnsavedChanges from './dictionaries/unsavedChanges/reducer'
import * as fromInternalization from './internalization/reducer'
import * as fromMapActions from './map/reducer'
import * as fromNotifications from './notifications/reducer'
import * as fromProductsImport from './products/productsImport/reducer'
// import * as fromProductsListSearchForm from './products/productsList/reducer'
// products
import * as fromProducts from './products/reducer'
import * as fromDictionary from './sd/dictionary/reducer'
import * as fromDrawerActions from './sd/drawerActions/reducer'
import * as fromModals from './sd/modals/reducer'
import * as fromReports from './sd/reports/reducer'
// sd
import * as fromTickets from './sd/tickets/reducer'
import * as fromAdditionalCosts from './srm/additionalCosts/reducer'
import * as fromCreateGroupOfServiceRequests from './srm/createGroupOfServiceRequests/reducer'
import * as fromCriticTerms from './srm/criticTerms/reducer'
import * as fromDeviceDefectsActions from './srm/deviceDefectsActions/reducer'
import * as fromDirectSelectScript from './srm/directSelectScript/reducer'
import * as fromFiscalRegistration from './srm/fiscalRegistration/reducer'
import * as fromImportDevices from './srm/importDevices/reducer'
import * as fromLogActions from './srm/logActions/reducer'
import * as fromNotificationsActions from './srm/notifications/reducer'
import * as fromNotificationsServiceRequestEvent from './srm/notifyServiceRequestsEvent/reducer'
import * as fromPropertiesWindowActions from './srm/propertiesWindowActions/reducer'
import * as fromRepairNorms from './srm/repairNorms/reducer'
import * as fromSRMTickets from './srm/tickets/reducer'
import * as fromTicketsWindowTree from './srm/ticketsWindowTree/reducer'
import * as fromWindowsEditActions from './srm/windowsEditActions/reducer'
// import * as fromUSMSearchForm from './usm/searchForm/reducer'
import * as fromUSMSecurityNetworksObjectsMerge from './usm/securityNetworksObjectMerge/reducer'
import * as fromCommonSearchForm from './common/searchForm/reducer'
import * as fromUSMSecurityObjectsList from './usm/securityObjects/reducer'
import * as fromUSMUserGroup from './usm/userGroup/reducer'
// USM reducers
import * as fromUSMUsers from './usm/users/reducer'
import * as fromVaultCashBags from './vault/cashBags/reducer'
import * as fromVaultCashOrders from './vault/cashOrders/reducer'
import * as fromVaultTeams from './vault/teams/reducer'
import * as fromVaultUsers from './vault/users/reducer'
// Vault V1
import * as fromVaultV1Admin from './vault-v1/admin/reducers'
import * as fromVaultV1Auth from './vault-v1/auth/reducer'
import * as fromVaultV1Bags from './vault-v1/bags/reducer'
import * as fromVaultV1CashDesk from './vault-v1/cashDesk/reducer'
import * as fromVaultV1Ord from './vault-v1/cashOrders/reducer'
import * as fromVaultV1Cassettes from './vault-v1/cassettes/reducer'
import * as fromVaultV1Common from './vault-v1/common/reducer'
import * as fromVaultV1EventJournal from './vault-v1/eventJournal/reducer'
import * as fromVaultV1Mon from './vault-v1/monitoring/reducer'
import * as fromVaultV1Teams from './vault-v1/teams/reducer'
/* eslint-disable */
// This interface describe all (common and async reducers)
export interface AppState {
  notificationsSetting: fromNotifications.State
  intl: fromInternalization.State
  auth: fromAuth.State
  tickets: fromTickets.State
  devices: fromDevices.State
  deviceSettings: fromDeviceSettings.State
  atemeyeReports: fromAtemeyeReports.State
  rfm: fromRmf.State
  rfmMultipleDevices: fromRmfMultipleDevices.State
  centralSettingsDeviceSelector: fromCentralSettingsDeviceSelector.State
  commonATMeye: fromATMeyeCommon.State
  deviceDayNightSettings: fromDayNightSettings.State
  centralSettings: fromCentralSettings.State
  transactions: fromTransactions.State
  transactionsByDevice: fromTransactionsByDevice.State
  securityAlarms: fromSecurityAlarms.State
  securityAlarmsByDevice: fromSecurityAlarmsByDevice.State
  technicalEvents: fromTechnicalEvents.State
  technicalEventsByDevice: fromTechnicalEventsByDevice.State
  blackList: fromBlackList.State
  multipleSnapshots: fromMultipleSnapshots.State
  multipleSnapshotsDeviceSelector: fromMultipleSnapshotsDeviceSelector.State
  dictionary: fromDictionary.State
  mapPage: fromMapActions.State
  drawerActions: fromDrawerActions.State
  modals: fromModals.State
  windowsEditActions: fromWindowsEditActions.State
  notificationsActions: fromNotificationsActions.State
  notificationsServiceRequestEvent: fromNotificationsServiceRequestEvent.State
  srmTickets: fromSRMTickets.State
  srmFiscalRegistration: fromFiscalRegistration.State
  reports: fromReports.State
  vaultCashBags: fromVaultCashBags.State
  vaultCashOrders: fromVaultCashOrders.State
  vaultTeams: fromVaultTeams.State
  vaultUsers: fromVaultUsers.State

  // cash management
  commonRoutes: fromRoutes.State

  cmAnalysis: fromCMAnalysis.State
  cmAdmin: fromCMAdmin.AppState
  cmAuth: fromCMAuth.State
  cmCommon: fromCMCommon.State
  cmShared: fromCMShared.State
  cmMonitoring: fromCMMonitoring.State
  cmPlanning: fromCMPlanning.State
  cmCitPlanning: fromCMCitPlanning.State
  cmCitBalancing: fromCMCitBalancing.State
  cmOrders: fromCMOrders.State
  cmReplenishments: fromCMReplenishments.State
  cmBalancing: fromCMBalancing.State
  cmMaps: fromCMMaps.State
  cmPlasticCard: fromCMPlasticCard.State

  deviceDefects: fromDeviceDefectsActions.State
  propertiesWindow: fromPropertiesWindowActions.State
  dashboard: fromDashboard.State
  repairNorms: fromRepairNorms.State
  additionalCosts: fromAdditionalCosts.State
  logActions: fromLogActions.State
  criticTerms: fromCriticTerms.State
  directSelectScript: fromDirectSelectScript.State
  importDevices: fromImportDevices.State
  ticketsWindowTree: fromTicketsWindowTree.State
  tables: fromTablesData.State
  createGroupOfServiceRequests: fromCreateGroupOfServiceRequests.State
  atmEyeDashboard: fromATMEyeDashboard.State
  atmEyeMap: fromATMEyeMap.State
  portal: fromPortal.State
  // adm
  admWindowsSplitter: fromADMWindowsSplitter.State
  admDevice: fromADMDevice.State
  admDevicesImport: fromADMDevicesImport.State
  admDevicesConfigurationImport: fromADMDevicesConfigurationImport.State
  // usm
  usmUsers: fromUSMUsers.State
  usmUserGroup: fromUSMUserGroup.State
  usmSecurityNetworksObjectsMerge: fromUSMSecurityNetworksObjectsMerge.State
  usmSecurityObjectsList: fromUSMSecurityObjectsList.State
  commonSearchForm: fromCommonSearchForm.State

  // companies
  companiesDrawerActions: fromCompaniesDrawerActions.State
  companiesCompanies: fromCompaniesCompanies.State
  companiesImport: fromCompaniesImport.State
  companiesFormsConfigs: fromCompaniesFormsConfigs.State

  dictionaryDictionariesList: fromDictionaryDictionariesList.State
  dictionaryLanguages: fromDictionaryLanguages.State
  dictionaryDictionaryInfo: fromDictionaryInfo.State
  dictionaryUnsavedChanges: fromDictionaryUnsavedChanges.State

  products: fromProducts.State
  productsImport: fromProductsImport.State
  // productsProductsListSearchForm: fromProductsListSearchForm.State

  // vaultV1
  vaultV1Auth: fromVaultV1Auth.State
  vaultV1Common: fromVaultV1Common.State
  vaultV1Monitoring: fromVaultV1Mon.State
  vaultV1CashOrders: fromVaultV1Ord.State
  vaultV1Teams: fromVaultV1Teams.State
  vaultV1Admin: fromVaultV1Admin.AppState
  vaultV1Bags: fromVaultV1Bags.State
  vaultV1CashDesk: fromVaultV1CashDesk.State
  vaultV1Cassettes: fromVaultV1Cassettes.State
  vaultV1EventJournal: fromVaultV1EventJournal.State
}

// This are common reducers
export default {
  notificationsSetting: fromNotifications.reducer,
  intl: fromInternalization.intlReducer,
  auth: fromAuth.reducer,
  cmAuth: fromCMAuth.reducer,
  dashboard: fromDashboard.reducer,
  cmCommon: fromCMCommon.reducer,
  atmEyeDashboard: fromATMEyeDashboard.reducer,
  portal: fromPortal.reducer,

  vaultV1Auth: fromVaultV1Auth.reducer,
  vaultV1Common: fromVaultV1Common.reducer,
  vaultV1Admin: fromVaultV1Admin.reducer,
}
