import { memoize } from 'lodash-es'
import React, { ReactElement } from 'react'
import { createSelector } from 'reselect'

import { Command } from '@/api/rsocket/command'
import { ChartExtendedTableDataColumns } from '@/components/pages/sd/dashboard/TicketPage/component'
import { AppState } from '@/store/reducers'
import { RSocket } from '@/types'

import { initialState, State } from './reducer'
import { useClasses } from './styles'

export const getDashboardState = (state: AppState): State => state.dashboard || initialState
export const getSocketState = (state: AppState): RSocket => state.dashboard.rSocketData

export const getPieChartData = createSelector(getDashboardState, state => [
  { name: 'undefined', value: state.pieChartData.statuses.undefined, status: 'undefined' },
  { name: 'submitted', value: state.pieChartData.statuses.submitted, status: 'submitted' },
  { name: 'prepared', value: state.pieChartData.statuses.prepared, status: 'prepared' },
  { name: 'finished', value: state.pieChartData.statuses.finished, status: 'finished' },
  { name: 'running', value: state.pieChartData.statuses.running, status: 'running' },
  { name: 'created', value: state.pieChartData.statuses.created, status: 'created' },
  { name: 'suspended', value: state.pieChartData.statuses.suspended, status: 'suspended' },
  { name: 'planned', value: state.pieChartData.statuses.planned, status: 'planned' },
  {
    name: 'sentToExecutant',
    value: state.pieChartData.statuses.sentToExecutant,
    status: 'sentToExecutant',
  },
  { name: 'generated', value: state.pieChartData.statuses.generated, status: 'generated' },
  {
    name: 'partiallyGenerated',
    value: state.pieChartData.statuses.partiallyGenerated,
    status: 'partiallyGenerated',
  },
  {
    name: 'suspendedDueCancelWO',
    value: state.pieChartData.statuses.suspendedDueCancelWO,
    status: 'suspendedDueCancelWO',
  },
])

export const getPieChartDataTotal = createSelector(
  getDashboardState,
  state => state.pieChartData.total,
)

export const getPieChartLegendData = createSelector(getDashboardState, state => [
  { color: '#b5b5b5', label: 'SLA (no deadline)', value: state.pieChartData.sla.noDeadline },
  { color: '#ff274e', label: 'SLA (no delayed)', value: state.pieChartData.sla.noDelayed },
  { color: '#f1c40f', label: 'SLA (nearing)', value: state.pieChartData.sla.nearing },
  { color: '#99BE54', label: 'SLA (ok)', value: state.pieChartData.sla.ok },
])

const CellValueWrapper = ({ value, percents, children }: any): ReactElement => {
  const classes = useClasses()

  if (value > 0) {
    return value
  } else {
    return <></>
  }

  // return (
  //   <>
  //     <span className={classes.cellUnits}>{value}</span>
  //     <span>({percents}%)</span>
  //   </>
  // )
}

const CellPercentageWrapper = ({ value, percents, children }: any): ReactElement => {
  const classes = useClasses()

  if (percents > 0) {
    return percents
  } else {
    return <></>
  }

  // return (
  //   <>
  //     <span className={classes.cellUnits}>{value}</span>
  //     <span>({percents}%)</span>
  //   </>
  // )
}

export const getChartExtendedTableData = createSelector(getDashboardState, state => {
  if (!state.extendedChartData) {
    return []
  }
  const { statuses } = state.extendedChartData

  return Object.keys(statuses).map((key: string) => ({
    [ChartExtendedTableDataColumns.id]: key,
    [ChartExtendedTableDataColumns.Status]: key,
    [ChartExtendedTableDataColumns.Count]: { value: `${statuses[key].total}` },
    [ChartExtendedTableDataColumns.SLANoDeadline]: {
      value: (
        <CellValueWrapper
          value={statuses[key].sla.noDeadline}
          percents={statuses[key].sla.noDeadlinePercents}
        />
      ),
    },
    [ChartExtendedTableDataColumns.SLANoDeadlinePercents]: {
      value: (
        <CellPercentageWrapper
          value={statuses[key].sla.noDeadline}
          percents={statuses[key].sla.noDeadlinePercents}
        />
      ), // `${statuses[key].sla.noDeadlinePercents}`,
    },
    [ChartExtendedTableDataColumns.SLANoDelayed]: {
      value: (
        <CellValueWrapper
          value={statuses[key].sla.noDelayed}
          percents={statuses[key].sla.noDelayedPercents}
        />
      ),
    },
    [ChartExtendedTableDataColumns.SLANoDelayedPercents]: {
      value: (
        <CellPercentageWrapper
          value={statuses[key].sla.noDelayed}
          percents={statuses[key].sla.noDelayedPercents}
        />
      ), // `${statuses[key].sla.noDelayedPercents}`,
    },
    [ChartExtendedTableDataColumns.SLANearing]: {
      value: (
        <CellValueWrapper
          value={statuses[key].sla.nearing}
          percents={statuses[key].sla.nearingPercents}
        />
      ),
    },
    [ChartExtendedTableDataColumns.SLANearingPercents]: {
      value: (
        <CellPercentageWrapper
          value={statuses[key].sla.nearing}
          percents={statuses[key].sla.nearingPercents}
        />
      ), // `${statuses[key].sla.nearingPercents}`,
    },
    [ChartExtendedTableDataColumns.SLAOK]: {
      value: (
        <CellValueWrapper value={statuses[key].sla.ok} percents={statuses[key].sla.okPercents} />
      ),
    },
    [ChartExtendedTableDataColumns.SLAOKPercents]: {
      value: (
        <CellPercentageWrapper
          value={statuses[key].sla.ok}
          percents={statuses[key].sla.okPercents}
        />
      ), // `${statuses[key].sla.okPercents}`
    },
  }))
})

export const getChartExtendedSubHeaderRowData = (translate: any) =>
  createSelector(getDashboardState, state => {
    if (!state.extendedChartData) {
      return []
    }

    return {
      [ChartExtendedTableDataColumns.id]: '',
      [ChartExtendedTableDataColumns.Status]: { value: `` },
      [ChartExtendedTableDataColumns.Count]: { value: `` },
      [ChartExtendedTableDataColumns.SLANoDeadline]: {
        value: translate('translate#title.quantity'),
      },
      [ChartExtendedTableDataColumns.SLANoDeadlinePercents]: {
        value: `%`,
      },
      [ChartExtendedTableDataColumns.SLANoDelayed]: {
        value: translate('translate#title.quantity'),
      },
      [ChartExtendedTableDataColumns.SLANoDelayedPercents]: {
        value: `%`,
      },
      [ChartExtendedTableDataColumns.SLANearing]: {
        value: translate('translate#title.quantity'),
      },
      [ChartExtendedTableDataColumns.SLANearingPercents]: {
        value: `%`,
      },
      [ChartExtendedTableDataColumns.SLAOK]: {
        value: translate('translate#title.quantity'),
      },
      [ChartExtendedTableDataColumns.SLAOKPercents]: { value: `%` },
    }
  })

export const getChartExtendedTotalRowData = createSelector(getDashboardState, state => {
  if (!state.extendedChartData) {
    return []
  }
  const { sla, total } = state.extendedChartData

  return {
    [ChartExtendedTableDataColumns.id]: 'totalRow',
    [ChartExtendedTableDataColumns.Status]: { value: `Total` },
    [ChartExtendedTableDataColumns.Count]: { value: `${total}` },
    [ChartExtendedTableDataColumns.SLANoDeadline]: {
      value: sla.noDeadline,
    },
    [ChartExtendedTableDataColumns.SLANoDeadlinePercents]: {
      value: `${sla.noDeadlinePercents}`,
    },
    [ChartExtendedTableDataColumns.SLANoDelayed]: {
      value: sla.noDelayed,
    },
    [ChartExtendedTableDataColumns.SLANoDelayedPercents]: {
      value: `${sla.noDelayedPercents}`,
    },
    [ChartExtendedTableDataColumns.SLANearing]: {
      value: sla.nearing,
    },
    [ChartExtendedTableDataColumns.SLANearingPercents]: {
      value: `${sla.nearingPercents}`,
    },
    [ChartExtendedTableDataColumns.SLAOK]: {
      value: sla.ok,
    },
    [ChartExtendedTableDataColumns.SLAOKPercents]: {
      value: `${sla.okPercents}`,
    },
  }
})

export const getIsLookingForChartData = createSelector(
  getDashboardState,
  state => state.isLookingForChartData,
)

export const getIsLookingChartExtendedData = createSelector(
  getDashboardState,
  state => state.isLookingForChartExtendedData,
)

export const autoRefresh = createSelector(getDashboardState, state => state.refreshTimer)

export const currentWidgetId = createSelector(getDashboardState, state => state.currentWidgetId)

export const isLookingForCompanies = createSelector(
  getDashboardState,
  state => state.isLookingForCompanies,
)

export const getDevices = createSelector(getDashboardState, state =>
  state.devices.map(({ id, name }) => ({ value: id, name })),
)
export const isLookingForDevices = createSelector(
  getDashboardState,
  state => state.isLookingForDevices,
)

export const getFilter = createSelector(getDashboardState, state => state.filter)

export const getPage = createSelector(getDashboardState, state => state.pageNo)

export const getPageSize = createSelector(getDashboardState, state => state.pageSize)

export const getSorting = createSelector(getDashboardState, state => state.sorting)

export const getTicketsConfig = createSelector(getDashboardState, state => state.config)
export const getIsLookingForTicketsConfig = createSelector(
  getDashboardState,
  state => state.isLookingForTicketsConfig,
)

export const getTickets = createSelector(getDashboardState, state => state.tickets)
export const getIsLookingForTickets = createSelector(
  getDashboardState,
  state => state.isLookingForTickets,
)

export const getSocketData = (command: Command): any => {
  return createSelector(getSocketState, (rSocket: RSocket) => {
    return rSocket[command]
  })
}

export const getAllSocketData = createSelector(getDashboardState, state => state.rSocketData)

export const getSocketDataError = createSelector(getDashboardState, state => state.rSocketDataError)

export const getSocketDataErrorMessage = createSelector(
  getDashboardState,
  state => state.rSocketDataErrorMessage,
)
