import { fork } from 'redux-saga/effects'

import internalization from './internalization/saga'
import auth from './auth/saga'
import cmAuth from './cm/auth/saga'
import vaultV1Auth from './vault-v1/auth/saga'
import dashboard from './dashboard/saga'
import common from './cm/common/saga'
import vaultV1common from './vault-v1/common/saga'
import atmEyeDashboard from './atmeye/dashboard/saga'

// This are common sagas
export default function*(): Generator {
  yield fork(internalization)
  yield fork(auth)
  yield fork(cmAuth)
  yield fork(vaultV1Auth)
  yield fork(vaultV1common)
  yield fork(dashboard)
  yield fork(common)
  yield fork(atmEyeDashboard)
}
