import { get, keys } from 'lodash-es'

import {
  GetGetTreeReportItems,
  ItemsReportMenu,
  ReportLazyItems,
  ReportMenuListType,
} from '@/api/sd/reportsApi/types'
import { APIConfiguration } from '@/constants'
import { ResponseError } from '@/types'
import {
  DownloadReport,
  ReportSaveRequest,
  ReportsConfig,
  SubscribeReportPanel,
} from '@/types/sd/reports/reports'
import { getRequestService, requestService } from '@/utils/services/request'

export class ReportsApi {
  public static async getReportsMenuItems(
    type: ReportMenuListType,
  ): Promise<GetGetTreeReportItems | ResponseError> {
    try {
      const res = await requestService.get('/report/menu/all', { type: type })
      return res.items
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getReportsLazyMenuItems(): Promise<ItemsReportMenu[] | ResponseError> {
    try {
      const res: ReportLazyItems[] = await requestService.get('/report/lazy')

      const items: ItemsReportMenu[] = res.map(item => ({
        id: String(item.id),
        uid: item.uuid,
        name: `${item.reportName}, ${item.startDate} - ${item.endDate}, ${item.status}, ${item.type}`,
        objectTypeId: item.type,
        subscriptionId: 0,
        subscriptionName: '',
        children: [],
      }))

      return items
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getMyReportsMenu(
    type: ReportMenuListType,
    status?: string,
  ): Promise<GetGetTreeReportItems | ResponseError> {
    try {
      const res = await requestService.get(`/report/menu/my`, { status: status, type: type })
      const key = get(keys(res.items), 0, 1)
      return res.items[key]
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getReportSubscribe(
    status: string,
  ): Promise<SubscribeReportPanel | ResponseError> {
    try {
      const res = await requestService.get(`/report?status=${status}`)
      return res.subscriptions
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getReportById(subscriptionId: string | number): Promise<any | ResponseError> {
    try {
      const res = await requestService.get(`/report/${subscriptionId}`)
      return res
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async saveReport(data: ReportSaveRequest): Promise<string | ResponseError> {
    try {
      const res = await requestService.post('/report', data)
      return res
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getReportsConfig(
    reportUid: string,
  ): Promise<Array<ReportsConfig> | ResponseError> {
    try {
      const res = await requestService.get(`/report/config?reportUid=${reportUid}`)
      return res.subscriptionsConfig
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async deleteReport(id: string): Promise<void | ResponseError> {
    try {
      const res = await requestService.delete(`/report/${id}`)
      return res
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async deleteLazyReport(id: string): Promise<void | ResponseError> {
    try {
      const res = await requestService.delete(`/report/lazy/${id}`)
      return res
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static requestServiceMSReport = getRequestService(APIConfiguration.API_MS_REPORT_URL)

  public static async getMSReport(idMSReport: string): Promise<any | ResponseError> {
    try {
      const res = await ReportsApi.requestServiceMSReport.get(`/${idMSReport}/link`)
      return res.msReportLink
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getHTMLReport(subscriptionId: string): Promise<string | ResponseError> {
    try {
      return await requestService.get(`/report/html/${subscriptionId}`)
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async saveJasperReportTemplate(
    reportType: string,
    data: any,
  ): Promise<string | ResponseError> {
    try {
      return await requestService.post(`/report-templates?reportType=${reportType}`, data)
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getReportLazy(id: string): Promise<string | ResponseError> {
    try {
      return await requestService.get(`/report/lazy/${id}`)
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getHTMLWithoutSave(data: ReportSaveRequest): Promise<string | ResponseError> {
    try {
      Object.values(data.parameters).forEach(parameter => {
        if (parameter.__hidden) {
          if (parameter.__type === 6) {
            /*  "typeClassName": "java.lang.Integer", "__type": 6 */
            parameter.value = parameter.__defaultvalue === null ? null : +parameter.__defaultvalue
          } else {
            parameter.value = parameter.__defaultvalue === null ? null : parameter.__defaultvalue
          }
        } else {
          if (data.reportId === '175393') {
            // SRM_NumberATMFailuresBoG.rptdesign
            if (parameter.__type === 1) {
              /*  "typeClassName": "java.lang.String", "__type": 1 */
              if (
                parameter.value !== null &&
                parameter.value.length === 0 &&
                parameter.__defaultvalue !== null &&
                parameter.__defaultvalue.toString().length > 0
              ) {
                parameter.value = parameter.__defaultvalue
              }
            }
          }
        }
      })
      const res = await requestService.post(`/report/html`, data)
      return res
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getDataWithoutSave(data: ReportSaveRequest): Promise<string | ResponseError> {
    try {
      const res = await requestService.post(`/report/data`, data)
      return res
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async saveReportLazy(data: ReportSaveRequest): Promise<string | ResponseError> {
    try {
      const res = await requestService.post('/report/lazy/html', data)
      return res
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async saveReporDataLazy(data: ReportSaveRequest): Promise<string | ResponseError> {
    try {
      const res = await requestService.post('/report/lazy/data', data)
      return res
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }

  public static async getDownloadReportFile({
    valueFormat,
    parametersReport,
    subscriptionID,
  }: DownloadReport): Promise<BlobPart | ResponseError> {
    try {
      if (subscriptionID) {
        const res = await requestService.get(
          `/report/file/${subscriptionID}?format=${valueFormat}`,
          {},
          { responseType: 'blob' },
        )
        return res
      }
      const res = await requestService.post(
        `/report/file/${valueFormat}`,
        parametersReport,
        false,
        { responseType: 'blob' },
      )
      return res
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }
}
