export enum LocalStorageItems {
  AccessToken = '@LocalStorage/AccessToken',
  UserID = '@LocalStorage/UserID',
  CurrentAccount = '@LocalStorage/CurrentAccount',
  ActiveTabs = '@LocalStorage/ActiveTabs',
  Bookmarks = '@LocalStorage/Bookmarks',
  Timezone = '@LocalStorage/Timezone',
  TicketColumns = '@LocalStorage/TicketColumns',
  GroupingTicketColumns = '@LocalStorage/GroupingTicketColumns',
  IsVault = '@LocalStorage/IsVault',
  AllLocales = '@LocalStorage/AllLocales',
  AccessTokenCM = '@LocalStorage/AccessTokenCM',
  UserPermissionsCM = '@LocalStorage/UserPermissionsCM',
  UserModulesCM = '@LocalStorage/UserModulesCM',
  UserDataCM = '@LocalStorage/UserDataCM',
  ActiveTeamsCM = '@LocalStorage/ActiveTeamsCM',
  UsersSettingsCM = '@LocalStorage/UsersSettingsCM',
  IsOpenMenuCM = '@LocalStorage/IsOpenMenuCM',

  // Atmeye
  AtmeyeDeviceColumns = '@LocalStorage/AtmeyeDeviceColumns',
  AtmeyeTransactionColumns = '@LocalStorage/AtmeyeTransactionColumns',
  AtmeyeSelectedDevice = '@LocalStorage/AtmeyeSelectedDevice',
  AtmeyeAlarmColumns = '@LocalStorage/AtmeyeAlarmColumns',
  EventsDictionaryColumns = '@LocalStorage/EventsDictionaryColumns',
  LicenceListFilters = '@LocalStorage/LicenceListFilters',
  LicenceListColumns = '@LocalStorage/LicenceList_COLUMNS',
  ErrorsLogFilters = '@LocalStorage/ErrorsLogFilters',
  ErrorsLogColumns = '@LocalStorage/ErrorsLog_COLUMNS',
  AtmeyeStatuses = '@LocalStorage/AtmeyeStatuses',
  AtmeyeLicenseStatuses = '@LocalStorage/AtmeyeLicenseStatuses',
  Locale = '@LocalStorage/Locale',
  LookupData = '@LocalStorage/LookupData',
  FilterTemplateFields = '@LocalStorage/FilterTemplateFields',
  SearchTerminalColumns = '@LocalStorage/SearchTerminalColumns',
  SubscribeReportPanel = '@LocalStorage/SubscribeReportPanel',
  ColumnMappingTemplate = '@LocalStorage/ColumnMappingTemplate',
  FirstColumnWidgets = '@LocalStorage/FirstColumnWidgets',
  SecondColumnWidgets = '@LocalStorage/SecondColumnWidgets',
  UserSettings = '@LocalStorage/UserSettings',
  ReportsSettings = '@LocalStorage/ReportsSettings',
  DeviceSettings = '@LocalStorage/DeviceSettings',
  LogInspectorColumns = '@LocalStorage/LogInspectorColumns',
  LogInspectorFilters = '@LocalStorage/LogInspectorFilters',
  ExternalNotificationsColumns = '@LocalStorage/ExternalNotifications/Columns',
  ShouldHideExpireLicenceBanner = '@LocalStorage/ShouldHideExpireLicenceBanner',

  // RFM

  Rfm = '@LocalStorage/Rfm',
  RfmTransactionHistoryLogColumns = '@LocalStorage/Rfm/transactionHistoryLogColumns',
  RfmTransactionHistoryLogSorting = '@LocalStorage/Rfm/transactionHistoryLogSorting',

  RfmTransactionHistoryLogFilters = '@LocalStorage/Rfm/transactionHistoryLogFilters',
  RfmSynchronizationColumns = '@LocalStorage/Rfm/SynchronizationColumns',
  RfmSynchronizationSorting = '@LocalStorage/Rfm/SynchronizationSorting',
  RfmSynchronizationFilters = '@LocalStorage/Rfm/SynchronizationFilters',
  CentralSettings = '@LocalStorage/CentralSettings',
  CentralSettingsDeviceSelector = '@LocalStorage/CentralSettingsDeviceSelector',
  RfmMultipleDevice = '@LocalStorage/RfmMultipleDevice',
  RfmSingleDeviceSelector = '@LocalStorage/RfmSingleDeviceSelector',
  RfmSingleDeviceTree = '@LocalStorage/RfmSingleDeviceTree',
  RfmMultiDeviceSelector = '@LocalStorage/RfmMultiDeviceSelector',
  RfmMultiDeviceTree = '@LocalStorage/RfmMultiDeviceTree',
  RfmDeviceCounter = '@LocalStorage/RfmDeviceCounter',
  RfmSingleDeviceSorting = '@LocalStorage/RfmSingleDeviceSorting',
  MultipleDevicesFoldersTree = '@LocalStorage/MultipleDevicesFoldersTree',
  MultipleSnapshots = '@LocalStorage/MultipleSnapshots',
  MultipleSnapshotsDeviceSelector = '@LocalStorage/MultipleSnapshotsDeviceSelector',
  IsFullScreenMap = '@LocalStorage/IsFullScreenMap',
  HistogramSelectedInterval = '@LocalStorage/HistogramSelectedInterval',
  BlackList = '@LocalStorage/BlackList',
  SecurityAlarms = '@LocalStorage/SecurityAlarms',
  Devices = '@LocalStorage/Devices',
  TechnicalEvents = '@LocalStorage/TechnicalEvents',
  Transactions = '@LocalStorage/Transactions',

  ObjectsTreeDataMap = '@LocalStorage/ObjectsTreeDataMap',
  ObjectsTreeDataDevices = '@LocalStorage/ObjectsTreeDataDevices',
  ObjectsTreeDataAlarmsByDevice = '@LocalStorage/ObjectsTreeDataAlarmsByDevice',
  ObjectsTreeDataAlarms = '@LocalStorage/ObjectsTreeDataAlarms',
  ObjectsTreeDataEventsByDevice = '@LocalStorage/ObjectsTreeDataEventsByDevice',
  ObjectsTreeDataEvents = '@LocalStorage/ObjectsTreeDataEvents',
  ObjectsTreeDataTransactions = '@LocalStorage/ObjectsTreeDataTransactions',
  ObjectsTreeDataTransactionsByDevice = '@LocalStorage/ObjectsTreeDataTransactionsByDevice',
  ObjectsTreeDataBlackList = '@LocalStorage/ObjectsTreeDataBlackList',
  ObjectsTreeDataSnapshots = '@LocalStorage/ObjectsTreeDataSnapshots',

  SelectedFiltersAlarms = '@LocalStorage/SelectedFiltersAlarms',
  SelectedFiltersAlarmsByDevice = '@LocalStorage/SelectedFiltersAlarmsByDevice',
  SelectedFiltersEvents = '@LocalStorage/SelectedFiltersEvents',
  SelectedFiltersEventsByDevice = '@LocalStorage/SelectedFiltersEventsByDevice',
  SelectedFiltersTransactions = '@LocalStorage/SelectedFiltersTransactions',
  SelectedFiltersTransactionsByDevice = '@LocalStorage/SelectedFiltersTransactionsByDevice',
  SelectedFiltersOperationsLog = '@LocalStorage/SelectedFiltersOperationsLog',
  SelectedFiltersBlackList = '@LocalStorage/SelectedFiltersBlackList',
  SelectedFiltersSnapshots = '@LocalStorage/SelectedFiltersSnapshots',
  SelectedFiltersEventsDictionary = '@LocalStorage/SelectedFiltersEventsDictionary',

  MonitoringLookAheadPeriodDays = '@LocalStorage/MonitoringLookAheadPeriosDays',
  UserSettingsDictionaries = '@LocalStorage/UserSettingsDictionaries',

  // SD
  SDOpenedTicketsByStatusesColumns = '@LocalStorage/SDOpenedTicketsByStatusesColumns',

  // Vault V1
  AccessTokenVaultV1 = '@LocalStorage/AccessTokenVaultV1',
  UsersSettingsVaultV1 = '@LocalStorage/UsersSettingsVaultV1',
  UserPermissionsVaultV1 = '@LocalStorage/UserPermissionsVaultV1',
  UserModulesVaultV1 = '@LocalStorage/UserModulesVaultV1',
  UserDataVaultV1 = '@LocalStorage/UserDataVaultV1',
  ActiveTeamsVaultV1 = '@LocalStorage/ActiveTeamsVaultV1',
  IsOpenMenuVaultV1 = '@LocalStorage/IsOpenMenuVaultV1',

  // Vault V2
  AccessTokenVaultV2 = '@LocalStorage/AccessTokenVaultV2',
  UsersSettingsVaultV2 = '@LocalStorage/UsersSettingsVaultV2',
  UserPermissionsVaultV2 = '@LocalStorage/UserPermissionsVaultV2',
  UserModulesVaultV2 = '@LocalStorage/UserModulesVaultV2',
  UserDataVaultV2 = '@LocalStorage/UserDataVaultV2',
  ActiveTeamsVaultV2 = '@LocalStorage/ActiveTeamsVaultV2',
  IsOpenMenuVaultV2 = '@LocalStorage/IsOpenMenuVaultV2',

  // Global
  UsersSettings = '@LocalStorage/UsersSettings',
  UserPermissions = '@LocalStorage/UserPermissions',
  RolePermissions = '@LocalStorage/RolePermissions',
  UserModules = '@LocalStorage/UserModules',
  UserData = '@LocalStorage/UserData',
  UserPhoto = '@LocalStorage/UserPhoto',

  WebSocket = '@LocalStorage/WebSocket',

  // Notification
  AlarmSettings = '@LocalStorage/AlarmSettings',
  EventSettings = '@LocalStorage/EventSettings',
  EventNotifications = '@LocalStorage/EventNotifications',
  AlarmNotifications = '@LocalStorage/AlarmNotifications',
}

export const generateAtmeyeGroupingKey = (windowType: string): string =>
  `@LocalStorage/${windowType}/grouping`
