import { FormikValues } from 'formik'
import { AnyAction } from 'redux'

import { GetGroupDetailsResponse, GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import { TableFilter } from '@/components/controls/AppTable/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { TechnicalEventsActions, TicketActions } from '@/constants/index'
import {
  ApiGroupTemplate,
  DevicesConfigResponse,
  ParentGroup,
  ResponseError,
  TypeOfTickets,
  UIGroupTemplate,
} from '@/types'
import { GetTicketsResponse } from '@/types/sd/common'
import { WindowType } from '@/types/sd/tickets/ticket'
import { TicketDetailsResponse } from '@/types/sd/tickets/ticketDetails'

export const setSelectedGroupingTemplate = (template: string): AnyAction => ({
  type: TechnicalEventsActions.SetSelectedGroupingTemplate,
  payload: template,
})

export const getTransactionsRequest = (isInitRequest = false): AnyAction => ({
  type: TechnicalEventsActions.GetTransactionsRequest,
  payload: { isInitRequest },
})

export const getTransactionsResponse = (tickets: GetTicketsResponse): AnyAction => ({
  type: TechnicalEventsActions.GetTransactionsResponse,
  payload: tickets,
})

export const getTicketsResponseFail = (error: ResponseError): AnyAction => ({
  type: TechnicalEventsActions.GetTicketResponseFail,
  payload: { error },
})

export const getGroupDetailsRequest = (): AnyAction => ({
  type: TechnicalEventsActions.GetGroupDetailsRequest,
})

export const getGroupDetailsResponse = (details: GetGroupDetailsResponse): AnyAction => ({
  type: TicketActions.GetGroupDetailsResponse,
  payload: details,
})

export const getGroupDetailsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetGroupDetailsResponseFail,
  payload: { error },
})

export const setParentGroups = (parentGroups: ParentGroup[]): AnyAction => ({
  type: TechnicalEventsActions.SetParentGroups,
  payload: parentGroups,
})

export const getTechnicalEventsExportRequest = (outputFields: any, ext: string): AnyAction => ({
  type: TechnicalEventsActions.GetTechnicalEventsExport,
  payload: {
    outputFields: outputFields,
    format: ext,
  },
})

export const getExportResponse = (file: any): AnyAction => ({
  type: TechnicalEventsActions.GetExportResponse,
  payload: file,
})

export const getExportResponseFail = (error: ResponseError): AnyAction => ({
  type: TechnicalEventsActions.GetExportResponseFail,
  payload: { error },
})

export const getTechnicalEventsExportGroupedRequest = (
  outputFields: any,
  ext: string,
): AnyAction => ({
  type: TechnicalEventsActions.GetTechnicalEventsExportGrouped,
  payload: {
    outputFields: outputFields,
    format: ext,
  },
})

export const getExportGroupedResponse = (file: any): AnyAction => ({
  type: TechnicalEventsActions.GetExportGroupedResponse,
  payload: file,
})

export const getExportGroupedResponseFail = (error: ResponseError): AnyAction => ({
  type: TechnicalEventsActions.GetExportGroupedResponseFail,
  payload: { error },
})

export const changeSelectedFilters = (filters: FormikValues): AnyAction => ({
  type: TechnicalEventsActions.ChangeSelectedFilters,
  payload: filters,
})

export const getTransactionsFilterTemplatesRequest = (): AnyAction => ({
  type: TechnicalEventsActions.GetFiltersTemplatesRequest,
})

export const setSdFilterTemplatesResponse = (
  payload: { id: string; name: string }[],
): AnyAction => ({
  type: TechnicalEventsActions.SetSdFilterTemplateResponse,
  payload,
})

export const createSdFilterTemplateRequest = (payload: {
  filters: GetTicketsBodyFilterItem[]
  name: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: TechnicalEventsActions.CreateFilterTemplateRequest,
  payload,
})

export const createSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: TechnicalEventsActions.CreateSdFilterTemplateResponse,
  payload,
})

export const updateSdFilterTemplateRequest = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: TechnicalEventsActions.UpdateFilterTemplateRequest,
  payload,
})

export const collectFilterTemplateFields = (filterTemplateFields: {
  fieldName: string
  value: { id: string; displayName: string }
}): AnyAction => ({
  type: TechnicalEventsActions.CollectFilterTemplateFields,
  payload: filterTemplateFields,
})

export const updateSdFilterTemplateResponse = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: TechnicalEventsActions.UpdateSdFilterTemplateResponse,
  payload,
})

export const deleteSdFilterTemplateRequest = (payload: {
  id: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: TechnicalEventsActions.DeleteFilterTemplateRequest,
  payload,
})

export const deleteSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: TechnicalEventsActions.DeleteFilterTemplateResponse,
  payload,
})

export const getSdGroupTemplatesRequest = (payload: string): AnyAction => ({
  type: TechnicalEventsActions.GetGroupingTemplatesRequest,
  payload,
})

// @todo replace any to GroupingTemplate[]
export const getGroupingTemplatesResponse = (templates: any): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesResponse,
  payload: templates,
})

export const getGroupingTemplatesResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesResponseFail,
  payload: error,
})

export const editSdGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: TechnicalEventsActions.EditGroupTemplateRequest,
  payload,
})

export const editGroupTemplateFromResponse = (payload: UIGroupTemplate): AnyAction => ({
  type: TechnicalEventsActions.EditSdGroupTemplateFromResponse,
  payload,
})

export const deleteSdGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: TechnicalEventsActions.DeleteGroupTemplateRequest,
  payload,
})

export const deleteSdGroupTemplateResponseSuccess = (payload: {
  template: UIGroupTemplate
}): AnyAction => ({
  type: TicketActions.DeleteSdGroupTemplateResponseSuccess,
  payload,
})

export const saveSdGroupTemplateRequest = (
  template: {
    windowType: WindowType
    name: string
    groupingLevels: ApiGroupTemplate[]
  },
  translate: any,
): AnyAction => ({
  type: TechnicalEventsActions.SaveGroupingTemplateRequest,
  payload: { template, translate },
})

export const setGroupTemplates = (
  templates: UIGroupTemplate[],
  withAppendNewTemplate = false,
): AnyAction => ({
  type: TechnicalEventsActions.SetGroupTemplates,
  payload: { templates, withAppendNewTemplate },
})

export const setCurrentFilterTemplate = (value: string): AnyAction => ({
  type: TechnicalEventsActions.SetCurrentFilterTemplate,
  payload: value,
})

export const getTransactionsConfigRequest = (): AnyAction => ({
  type: TechnicalEventsActions.GetTransactionsConfigRequest,
})

export const getTicketsConfigReponse = (
  typeOfTickets: TypeOfTickets,
  ticketsConfigData: DevicesConfigResponse,
): AnyAction => ({
  type: TechnicalEventsActions.GetTicketsConfigResponse,
  payload: { typeOfTickets, config: ticketsConfigData.formConfig },
})

export const getTicketsConfigResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketsConfigResponseFail,
  payload: error,
})

export const getTicketDetailsResponse = ({ ticketDetails }: TicketDetailsResponse): AnyAction => ({
  type: TicketActions.GetTicketDetailsResponse,
  payload: ticketDetails,
})

export const getTicketDetailsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketDetailsResponseFail,
  payload: { error },
})

export const changeSorting = (sorting: { [key: string]: 'asc' | 'desc' } | null): AnyAction => ({
  type: TechnicalEventsActions.ChangeSorting,
  payload: sorting,
})

export const changeGrouping = (groups: TableGroup[]): AnyAction => ({
  type: TechnicalEventsActions.ChangeGrouping,
  payload: groups,
})

export const setTransactionsRowsPerPage = (rowsCount: number): AnyAction => ({
  type: TechnicalEventsActions.SetRowPerPage,
  payload: rowsCount,
})

export const setTransactionsPage = (page: number): AnyAction => ({
  type: TechnicalEventsActions.SetPage,
  payload: page,
})

export const clearGroupedTicketDetailsData = (): AnyAction => ({
  type: TechnicalEventsActions.ClearGroupedTicketDetailsData,
})

export const setStringFilter = (filter: TableFilter): AnyAction => ({
  type: TicketActions.SetStringFilter,
  payload: filter,
})

export const getMediaRequest = (data: any): AnyAction => ({
  type: TechnicalEventsActions.GetMedia,
  payload: data,
})

export const setMediaRequest = (data: any): AnyAction => ({
  type: TechnicalEventsActions.SetMedia,
  payload: data,
})

export const clearCurrentMediaRequest = (data: any): AnyAction => ({
  type: TechnicalEventsActions.ClearCurrentMedia,
})

export const setCurrentMedia = (data: any): AnyAction => ({
  type: TechnicalEventsActions.SetCurrentMedia,
  payload: data,
})

export const setMediaErrorMessage = (data: any): AnyAction => ({
  type: TechnicalEventsActions.SetMediaErrorMessage,
  payload: data,
})

export const editTableCellRequest = (data: any): AnyAction => ({
  type: TechnicalEventsActions.EditTableCellRequest,
  payload: data,
})

export const editTableCellRequestGrouping = (data: any): AnyAction => ({
  type: TechnicalEventsActions.EditTableCellGrouping,
  payload: data,
})

export const saveTableCell = (data: any): AnyAction => ({
  type: TechnicalEventsActions.SaveTableCell,
  payload: data,
})
export const saveTableCellGrouping = (data: any): AnyAction => ({
  type: TechnicalEventsActions.SaveTableCellGrouping,
  payload: data,
})

export const setDataPagination = (data: any): AnyAction => ({
  type: TechnicalEventsActions.SetDataPagination,
  payload: data,
})

export const setDataPaginationRequest = (): AnyAction => ({
  type: TechnicalEventsActions.SetDataPaginationRequest,
})

export const setFilterModalState = (isOpenFilterModal: boolean): AnyAction => ({
  type: TechnicalEventsActions.SetFilterModalState,
  payload: isOpenFilterModal,
})

export const setIsSubmitFiltersButtonClicked = (): AnyAction => ({
  type: TechnicalEventsActions.IsSubmitFiltersButtonClicked,
})
