export enum Command {
  GET_ATM_PROPERTY = 'GET_ATM_PROPERTY',
  GET_ATM_FULL_PROPERTY = 'GET_ATM_FULL_PROPERTY',
  GET_INI_FILE = 'GET_INI_FILE',
  GET_LOG_FILE = 'GET_LOG_FILE',
  GET_LOG_FILE_DIRS = 'GET_LOG_FILE_DIRS',
  CONTROL_CORE_RESTART = 'CONTROL_CORE_RESTART',
  GET_ACTIVE_CAMS = 'GET_ACTIVE_CAMS',
  CONTROL_CORE_START = 'CONTROL_CORE_START',
  CONTROL_CORE_STOP = 'CONTROL_CORE_STOP',
  CONTROL_LOG_ON_OFF = 'CONTROL_LOG_ON_OFF',
  MAKE_SNAPSHOT = 'MAKE_SNAPSHOT',
  GET_PHOTO_DIRS = 'GET_PHOTO_DIRS',
  CONTROL_DELETE_DIR = 'CONTROL_DELETE_DIR',
  START_VIDEO = 'START_VIDEO',
  STOP_VIDEO = 'STOP_VIDEO',
  GET_SERVER_LICENSE_STATUS = 'GET_SERVER_LICENSE_STATUS',
  GET_ATMEYE_SETTINGS = 'GET_ATMEYE_SETTINGS',
  MULTIPLE_SNAPSHOT = 'MULTIPLE_SNAPSHOT',
  SET_ATMEYE_SETTINGS = 'SET_ATMEYE_SETTINGS',
  SET_CENTRAL_SETTINGS = 'SET_CENTRAL_SETTINGS',
  ATM_EYE_NOTIFICATION = 'NOTIFICATION',
  SET_ALL_DEVICES_CENTRAL_SETTINGS = 'SET_ALL_DEVICES_CENTRAL_SETTINGS',
  ALL_DEVICES_MULTIPLE_SNAPSHOT = 'ALL_DEVICES_MULTIPLE_SNAPSHOT',
  REPORT_FILE_NOTIFICATION = 'REPORT_FILE_NOTIFICATION',
  RFM_JOB_NOTIFICATION = 'RFM_JOB_NOTIFICATION',
  START_EVENT_FILE_DOWNLOAD = 'START_EVENT_FILE_DOWNLOAD',
  REFRESH_EVENTS_AGGREGATION_NOTIFICATION = 'REFRESH_EVENTS_AGGREGATION_NOTIFICATION',
  SET_EVENTS_SETTINGS = 'SET_EVENTS_SETTINGS',
  // RFM Commands

  // Single Device
  RFM_SYNC_GET_FOLDER_LISTING = 'RFM_SYNC_GET_FOLDER_LISTING',
  RFM_SYNC_CMD_STOP = 'RFM_SYNC_CMD_STOP',
  RFM_SYNC_CMD_PAUSE = 'RFM_SYNC_CMD_PAUSE',
  RFM_SYNC_CMD_RESUME = 'RFM_SYNC_CMD_RESUME',
  RFM_SYNC_CMD_START = 'RFM_SYNC_CMD_START',
  RFM_SYNC_CMD_UPDATE = 'RFM_SYNC_CMD_UPDATE',
  RFM_GET_DIRECTORIES_TREE = 'RFM_GET_DIRECTORIES_TREE',
  RFM_GET_DIRECTORY_FILES_LIST = 'RFM_GET_DIRECTORY_FILES_LIST',
  RFM_MOVE_FILES = 'RFM_MOVE_FILES',
  RFM_COPY_FILES = 'RFM_COPY_FILES',
  RFM_DELETE_FILES = 'RFM_DELETE_FILES',
  RFM_RUN_FILE = 'RFM_RUN_FILE',
  RFM_UNARCHIVE_FILE = 'RFM_UNARCHIVE_FILE',
  RFM_DUPLICATE_FILE = 'RFM_DUPLICATE_FILE',
  RFM_RENAME_FILE = 'RFM_RENAME_FILE',
  RFM_CREATE_DIRECTORY = 'RFM_CREATE_DIRECTORY',
  RFM_COPY_DIRECTORY = 'RFM_COPY_DIRECTORY',
  RFM_MOVE_DIRECTORY = 'RFM_MOVE_DIRECTORY',
  RFM_DELETE_DIRECTORY = 'RFM_DELETE_DIRECTORY',
  RFM_DOWNLOAD_ARCHIVED_DIRECTORY = 'RFM_DOWNLOAD_ARCHIVED_DIRECTORY',
  RFM_PURGE_DIRECTORY = 'RFM_PURGE_DIRECTORY',
  RFM_DUPLICATE_DIRECTORY = 'RFM_DUPLICATE_DIRECTORY',
  RFM_RENAME_DIRECTORY = 'RFM_RENAME_DIRECTORY',
  RFM_UPLOAD_ARCHIVED_DIRECTORY = 'RFM_UPLOAD_ARCHIVED_DIRECTORY',
  RFM_UPLOAD_FILES = 'RFM_UPLOAD_FILES',
  RFM_DOWNLOAD_FILES = 'RFM_DOWNLOAD_FILES',
  RFM_DOWNLOAD_ARCHIVED_FILES = 'RFM_DOWNLOAD_ARCHIVED_FILES',

  // Multiple Devices
  RFM_MULTIPLE_DEVICES_UPLOAD_FILES = 'RFM_MULTIPLE_DEVICES_UPLOAD_FILES',
  RFM_MULTIPLE_DEVICES_UPLOAD_AND_RUN_FILE = 'RFM_MULTIPLE_DEVICES_UPLOAD_AND_RUN_FILE',
  RFM_MULTIPLE_DEVICES_CREATE_DIRECTORY = 'RFM_MULTIPLE_DEVICES_CREATE_DIRECTORY',
  RFM_MULTIPLE_DEVICES_UPLOAD_ARCHIVED_DIRECTORY = 'RFM_MULTIPLE_DEVICES_UPLOAD_ARCHIVED_DIRECTORY',
  RFM_MULTIPLE_DEVICES_DOWNLOAD_ARCHIVED_DIRECTORY = 'RFM_MULTIPLE_DEVICES_DOWNLOAD_ARCHIVED_DIRECTORY',
  RFM_MULTIPLE_DEVICES_DELETE_DIRECTORY = 'RFM_MULTIPLE_DEVICES_DELETE_DIRECTORY',
  RFM_MULTIPLE_DEVICES_PURGE_DIRECTORY = 'RFM_MULTIPLE_DEVICES_PURGE_DIRECTORY',

  // MiltipleSnapshots Commands

  MULTIPLE_SNAPSHOT_INIT = 'MULTIPLE_SNAPSHOT_INIT',
  MULTIPLE_SNAPSHOT_ACTIVE_CAMS = 'MULTIPLE_SNAPSHOT_ACTIVE_CAMS',
  MULTIPLE_SNAPSHOT_PART = 'MULTIPLE_SNAPSHOT_PART',
}
