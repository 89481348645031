import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects'

import { CassettesApi } from '@/api/vault-v1/cassettesApi'
import {
  refetchCassetteButtonsStatuses,
  refetchCassetteDetails,
} from '@/api/vault-v1/cassettesApi/queries'
import { VaultCassette } from '@/api/vault-v1/swaggerGeneratedApi'
import { CassetteModalNames, TableModalsNames } from '@/components/pages/vault-v1/Cassettes/types'
import { VaultV1CassettesActions } from '@/constants'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { getGlobalFilterVaultV1 } from '../common'
import {
  addEditCassetteResponse,
  deleteCassetteFail,
  editCassetteResponse,
  getCassetteFail,
  getCassetteRequest,
  getCassetteResponse,
  getCassettesFail,
  getCassettesRequest,
  getCassettesResponse,
  loadCassetteResponse,
  setSelectedCassette,
  setTableModal,
  unloadToCashierFail,
  unloadToCashierResponse,
} from './actions'
import {
  getCassetteModal,
  getCassettes as getCassettesSelector,
  getCassettesFilter,
  getCassettesPaging,
  getCassettesSort,
  getSelectedCassette,
} from './selectors'

function* getCassettes(): SagaIterator {
  try {
    const filter = yield select(getCassettesFilter)
    const globalFilter = yield select(getGlobalFilterVaultV1)
    const paging = yield select(getCassettesPaging)
    const sort = yield select(getCassettesSort)
    const selected = yield select(getSelectedCassette)

    const response = yield call(CassettesApi.getAll, {
      paging,
      filter: { globalFilter, ...filter },
      sort,
    })

    if (response) {
      yield put(getCassettesResponse(response))

      if (selected?.id) {
        const founded = response.data.find(
          (cassette: VaultCassette) => cassette.id === selected?.id,
        )
        yield put(setSelectedCassette(founded || response.data[0] || null))

        if (founded) {
          refetchCassetteButtonsStatuses(founded.id)
        }
      } else {
        yield put(setSelectedCassette(response.data[0] || null))
      }
    }
  } catch (error) {
    yield put(getCassettesFail(String(error as Error)))
  }
}

function* getSingleCassette({ payload }: AnyAction): SagaIterator {
  try {
    const { id, isDetailsRequest } = payload || {}
    const selected = yield select(getSelectedCassette)

    if (id || selected.id) {
      const cassette = yield call(CassettesApi.getSingle, id || selected.id)

      yield put(getCassetteResponse(cassette))
      refetchCassetteButtonsStatuses(id || selected.id)
      isDetailsRequest && refetchCassetteDetails(id || selected.id)
    }
  } catch (error) {
    yield put(getCassetteFail(String(error as Error)))
  }
}

function* addEditCassettesRequest({ payload }: AnyAction): SagaIterator {
  const { type, id, ...rest } = payload

  try {
    if (type === CassetteModalNames.editCassette) {
      yield call(CassettesApi.edit, { ...rest, id })
    } else {
      yield call(CassettesApi.add, { ...rest })
    }

    yield put(addEditCassetteResponse())
    yield put(getCassettesRequest())
  } catch (error) {
    yield put(addEditCassetteResponse())
  }
}

function* deleteCassette({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CassettesApi.delete, payload)

    yield put(setSelectedCassette(null))
    yield put(getCassettesRequest())
  } catch (error) {
    yield put(deleteCassetteFail())
  }
}

function* addLoadCassetteRequest({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CassettesApi.load, payload)

    yield put(loadCassetteResponse())
    yield putResolve(getCassetteRequest())
  } catch (error) {
    yield put(loadCassetteResponse())
  }
}

function* addUnloadCassetteRequest({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CassettesApi.unload, payload)

    yield put(loadCassetteResponse())
    yield putResolve(getCassetteRequest())
  } catch (error) {
    yield put(loadCassetteResponse())
  }
}

function* editCassetteRequest({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CassettesApi.editCassette, payload)

    yield put(editCassetteResponse())
    yield putResolve(getCassetteRequest({ isDetailsRequest: true }))
  } catch (error) {
    yield put(loadCassetteResponse())
  }
}

function* unloadToCashier({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CassettesApi.unloadToCashier, payload)

    yield put(unloadToCashierResponse())
    yield putResolve(getCassetteRequest({ isDetailsRequest: true }))
  } catch (error) {
    yield put(unloadToCashierFail())
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  const { data, selected } = yield select(getCassettesSelector)
  const { settingsModal, documentModal, filtersModal } = yield select(getCassetteModal)

  hotKeysBinder<VaultCassette>({
    key: payload,
    data,
    selected,
    isOpenModalsList: [settingsModal, documentModal, filtersModal],
    setSelectedAction: setSelectedCassette,
    onRefreshAction: getCassettesRequest,
    openFilterAction: isOpen => setTableModal({ type: TableModalsNames.FiltersModal, isOpen }),
  })
}

export default function*(): Generator {
  yield takeLatest(
    [
      VaultV1CassettesActions.CstGetCassettesRequest,
      VaultV1CassettesActions.CstSortCassettes,
      VaultV1CassettesActions.CstSetCassettesPaging,
      VaultV1CassettesActions.CstSetFilter,
    ],
    getCassettes,
  )

  yield takeLatest(VaultV1CassettesActions.CstGetCassetteRequest, getSingleCassette)

  yield takeLatest(VaultV1CassettesActions.CstAddEditCassetteRequest, addEditCassettesRequest)
  yield takeLatest(VaultV1CassettesActions.CstDeleteCassetteRequest, deleteCassette)

  yield takeLatest(VaultV1CassettesActions.CstLoadCassetteRequest, addLoadCassetteRequest)
  yield takeLatest(VaultV1CassettesActions.CstUnloadCassetteRequest, addUnloadCassetteRequest)

  yield takeLatest(VaultV1CassettesActions.CstEditCassetteRequest, editCassetteRequest)

  yield takeLatest(VaultV1CassettesActions.CstUnloadCassetteToCashierRequest, unloadToCashier)

  yield takeLatest(VaultV1CassettesActions.CstHandleKeyPress, handleKeyPress)
}
