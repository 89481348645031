import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { VaultV1CommonApi } from '@/api/vault-v1/commonApi'
import {
  AppNavigationRoutes,
  VaultV1Actions,
  VaultV1BagsActions,
  VaultV1CassettesActions,
} from '@/constants'
import { getOrdersRequest } from '@/store/vault-v1/cashOrders'
import { getCassettesRequest } from '@/store/vault-v1/cassettes'
import { getEventsRequest } from '@/store/vault-v1/eventJournal'
import { getVaultsRequest } from '@/store/vault-v1/monitoring'

import { getBagsRequest } from '../bags'
import { getVaultBuildInfoFail, getVaultBuildInfoResponse } from '.'
import {
  getComVaultBalancesStatusFail,
  getComVaultBalancesStatusResponse,
  getComVaultCurrenciesResponse,
  getFilterNodesFail,
  getFilterNodesResponse,
  getIntegrationsFail,
  getIntegrationsResponse,
  setComVaultV1Error,
} from './actions'

function* setObjectTreeFilter(): SagaIterator {
  try {
    const { pathname } = window.location
    const {
      MonitoringPage,
      OrdersPage,
      EventJournal,
      Cassettes,
      Bags,
    } = AppNavigationRoutes.VaultV1

    if (pathname.includes(MonitoringPage)) {
      yield put(getVaultsRequest())
    }
    if (pathname.includes(OrdersPage)) {
      yield put(getOrdersRequest())
    }
    if (pathname.includes(EventJournal)) {
      yield put(getEventsRequest())
    }
    if (pathname.includes(Cassettes)) {
      yield put(getCassettesRequest())
    }
    if (pathname.includes(Bags)) {
      yield put(getBagsRequest())
    }
  } catch (error) {
    console.log(error)
  }
}

function* getFilterNodes(): SagaIterator {
  try {
    const filterNodes = yield call(VaultV1CommonApi.getFilterNodes)
    yield put(getFilterNodesResponse(filterNodes))
  } catch (error) {
    yield put(getFilterNodesFail(error.message))
  }
}

function* getIntegrations(): SagaIterator {
  try {
    const integrations = yield call(VaultV1CommonApi.getIntegrations)
    yield put(getIntegrationsResponse(integrations))
  } catch (error) {
    yield put(getIntegrationsFail(error.message))
  }
}

function* getCurrencies(): SagaIterator {
  try {
    const currencies = yield call(VaultV1CommonApi.getCurrencies)
    yield put(getComVaultCurrenciesResponse(currencies))
  } catch (error) {
    yield put(setComVaultV1Error(error.message))
  }
}

function* getBuildInfo(): SagaIterator {
  try {
    const serverBuildInfo = yield call(VaultV1CommonApi.getServerBuildInfo)
    const clientBuildInfo = yield call(VaultV1CommonApi.getClientBuildInfo)

    yield put(
      getVaultBuildInfoResponse({ clientBuildInfo, serverBuildInfo: serverBuildInfo?.info }),
    )
  } catch (error) {
    yield put(getVaultBuildInfoFail(error.message))
  }
}

function* getVaultBalancesStatus({ payload }: AnyAction): SagaIterator {
  try {
    const { coids } = payload

    if (coids.length) {
      const response = yield call(VaultV1CommonApi.getVaultBalancesStatus, coids)

      yield put(getComVaultBalancesStatusResponse(response))
    }
  } catch (error) {
    yield put(getComVaultBalancesStatusFail(error.message))
  }
}

export default function*(): Generator {
  yield takeLatest(VaultV1Actions.SetObjectTreeFilter, setObjectTreeFilter)
  yield takeLatest(
    [
      VaultV1Actions.MonGetVaultsRequest,
      VaultV1Actions.OrdGetOrdersRequest,
      VaultV1Actions.EvjGetEventsRequest,
      VaultV1CassettesActions.CstGetCassettesRequest,
      VaultV1BagsActions.BgsGetBagsRequest,
    ],
    getFilterNodes,
  )
  yield takeLatest(
    [
      VaultV1Actions.MonGetVaultsRequest,
      VaultV1Actions.OrdGetOrdersRequest,
      VaultV1Actions.TmsGetTeamsRequest,
      VaultV1Actions.AdmGetNetworksRequest,
      VaultV1Actions.AdmSetAppConfigResponse,
    ],
    getIntegrations,
  )
  yield takeLatest(VaultV1Actions.GetVaultBuildInfoRequest, getBuildInfo)

  yield takeLatest(VaultV1Actions.ComGetVaultCurrenciesRequest, getCurrencies)

  yield takeLatest(VaultV1Actions.ComGetVaultBalancesStatusRequest, getVaultBalancesStatus)
}
