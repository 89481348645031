import { withStyles } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing, palette, shadows }) => ({
  cellWrapper: {
    padding: spacing(0.25, 1),
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: spacing(1.5),
  },
  cell: {
    height: spacing(5),
    minHeight: spacing(5),
    maxHeight: spacing(5),
    padding: spacing(0.5, 2),
    whiteSpace: 'nowrap',

    '&:first-child': {
      paddingLeft: spacing(6),
    },
  },
  wrapper: {
    padding: spacing(0.25, 1),
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: spacing(1.5),
  },
  mark: {
    color: palette.grey[200],
  },
  icon: {
    width: spacing(1.5),
    marginRight: spacing(1),
  },
  deadlineGreen: {
    color: '#1AD36F',
  },
  deadlineYellow: {
    color: '#F2C94C',
  },
  deadlineRed: {
    color: '#CB362D',
  },
  iconSize: {
    fontSize: styledComponentTheme.fontSizes.big,
  },
  withBlueColor: {
    color: '#50A8AE',
  },
  withAdditionalPadding: {
    paddingLeft: 0,
  },
  arrowIcon: {
    color: palette.grey[500],
  },
  clickableCell: {
    cursor: 'pointer',
  },
  cameraCell: {
    display: 'flex',
    alignItems: 'center',
    color: '#25ADBF',
  },
  cameraIcon: {
    marginRight: '5px',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: shadows[3],
    },
  },
  rowWrap: {
    '&:nth-child(even)': {
      backgroundColor: '#f8f8f8',
    },
  },
  dangerRow: {
    backgroundColor: '#FDEFED',
    '& > td:first-child': {
      borderLeft: '2px solid #FF7362',
    },
  },
}))

export const StyledTableRow = withStyles(
  {
    root: {
      border: 'none',
    },
  },
  { name: 'TableRow' },
)(TableRow)

export const StyledState = styled.div`
  ${props =>
    props.theme.data.length > 0
      ? `background-color: #${props.theme.backgroundColor};
     color: #${props.theme.color};
     & > p {
      color: black
     }
  `
      : null}
`
