export const VAULT_OBJECT_TREE = 'objectTree'

export const VAULT_MONITORING_HEIGHT = 'monitoringHeight'
export const VAULT_MONITORING_WIDTH = 'monitoringWidth'
export const VAULT_MONITORING_DETAILS_HEIGHT = 'monitoringDetailsHeight'

export const VAULT_ORDERS_HEIGHT = 'cashOrdersHeight'
export const VAULT_ORDERS_WIDTH = 'cashOrdersWidth'

export const VAULT_EVENT_JOURNAL_WIDTH = 'eventJournalWidth'
export const VAULT_EVENT_JOURNAL_HEIGHT = 'eventJournalHeight'

export const VAULT_CASSETTES_WIDTH = 'cassettesWidth'
export const VAULT_CASSETTES_HEIGHT = 'cassettesHeight'

export const VAULT_BAGS_WIDTH = 'bagsWidth'
export const VAULT_BAGS_HEIGHT = 'bagsHeight'

export const VAULT_CASH_DESK_WIDTH = 'cashDeskWidth'
