import { GetTicketsBodyFilterItem } from '@/api/atmeye/devicesApi/types'
import FailStatusIcon from '@/assets/usm/FailStatus.svg'
import SuccessStatusIcon from '@/assets/usm/have-permission.svg'

export const VALID_USER_GROUP_NAME = /^(?=.*[!@#$%^&,\\*'«».+=\][{}?><":;|])/
export const WARNING_NO_OBJECTS_FOR_MERGE =
  'translate#usm.title.merge.objects.warning.no.objects.for.merge'
// search-form-constants
export enum USMWindowType {
  USM_USERS_REQUEST = 'USM_USERS',
  USM_SECURITY_NETWORKS_REQUEST = 'USM_SECURITY_NETWORKS',
  USM_ACTION_LOG_REQUEST = 'USM_USER_ACTION_LOGS',
  // Exist forms
  USM_SECURITY_NETWORKS_DEVICE_REQUEST = 'USM_SECURITY_NETWORKS_DEVICE',
  USM_SECURITY_NETWORKS_COMPANY_REQUEST = 'USM_SECURITY_NETWORKS_COMPANY',
  USM_SECURITY_NETWORKS_DEPARTMENT_REQUEST = 'USM_SECURITY_NETWORKS_DEPARTMENT',
  USM_SECURITY_NETWORKS_USERS_REQUEST = 'USM_SECURITY_NETWORKS_USERS',
  USM_SECURITY_NETWORKS_COMPANY_PERSONNEL_REQUEST = 'USM_SECURITY_NETWORKS_COMPANY_PERSONNEL',
  USM_SECURITY_NETWORKS_REPAIR_FUND_HOLDER_REQUEST = 'USM_SECURITY_NETWORKS_REPAIR_FUND_HOLDER',
  USM_SECURITY_NETWORKS_USER_GROUP_REQUEST = 'USM_SECURITY_NETWORKS_USER_GROUP',
  USM_SECURITY_NETWORKS_CAR_REQUEST = 'USM_SECURITY_NETWORKS_CAR',
  USM_SECURITY_NETWORKS_DEVICE_FOLDER_GROUP_REQUEST = 'USM_SECURITY_NETWORKS_DEVICE_FOLDER_GROUP',
  // Add forms
  USM_SECURITY_NETWORKS_ADD_DEVICE_REQUEST = 'USM_SECURITY_NETWORKS_ADD_DEVICE',
  USM_SECURITY_NETWORKS_ADD_COMPANY_REQUEST = 'USM_SECURITY_NETWORKS_ADD_COMPANY',
  USM_SECURITY_NETWORKS_ADD_DEPARTMENT_REQUEST = 'USM_SECURITY_NETWORKS_ADD_DEPARTMENT',
  USM_SECURITY_NETWORKS_ADD_USERS_REQUEST = 'USM_SECURITY_NETWORKS_ADD_USERS',
  USM_SECURITY_NETWORKS_ADD_COMPANY_PERSONNEL_REQUEST = 'USM_SECURITY_NETWORKS_ADD_COMPANY_PERSONNEL',
  USM_SECURITY_NETWORKS_ADD_REPAIR_FUND_HOLDER_REQUEST = 'USM_SECURITY_NETWORKS_ADD_REPAIR_FUND_HOLDER',
  USM_SECURITY_NETWORKS_ADD_USER_GROUP_REQUEST = 'USM_SECURITY_NETWORKS_ADD_USER_GROUP',
  USM_SECURITY_NETWORKS_ADD_CAR_REQUEST = 'USM_SECURITY_NETWORKS_ADD_CAR',
  USM_SECURITY_NETWORKS_ADD_DEVICE_FOLDER_GROUP_REQUEST = 'USM_SECURITY_NETWORKS_ADD_DEVICE_FOLDER_GROUP',
}

export enum USMSecurityObjectListGroups {
  Company = 'USMSecurityObjectListCompanyGroupsData',
  Device = 'USMSecurityObjectListDeviceGroupsData',
  Car = 'USMSecurityObjectListCarGroupsData',
  CompanyPersonnel = 'USMSecurityObjectListCompanyPersonnelGroupsData',
  Department = 'USMSecurityObjectListDepartmentGroupsData',
  UserGroup = 'USMSecurityObjectListUserGroupGroupsData',
  User = 'USMSecurityObjectListUserGroupsData',
  RepairFundHolder = 'USMSecurityObjectListRepairFundHolderGroupsData',
  DeviceFolderGroup = 'USMSecurityObjectListDeviceFolderGroupGroupsData',
}

export enum USMSecurityObjectListPageTable {
  Company = 'USMASecurityObjectListCompanyTable',
  Device = 'USMASecurityObjectListDeviceTable',
  Car = 'USMASecurityObjectListCarTable',
  CompanyPersonnel = 'USMASecurityObjectListCompanyPersonnelTable',
  Department = 'USMASecurityObjectListDepartmentTable',
  UserGroup = 'USMASecurityObjectListUserGroupTable',
  User = 'USMASecurityObjectListUsersTable',
  RepairFundHolder = 'USMASecurityObjectListRepairFundHolderTable',
  DeviceFolderGroup = 'USMASecurityObjectListDeviceFolderGroupTable',
}

export enum USMSecurityObjectListRequestBodyKey {
  Company = 'USMSecurityObjectListCompanyConfig',
  Device = 'USMSecurityObjectListDeviceConfig',
  Car = 'USMSecurityObjectListCarConfig',
  CompanyPersonnel = 'USMSecurityObjectListCompanyPersonnelConfig',
  Department = 'USMSecurityObjectListDepartmentConfig',
  UserGroup = 'USMSecurityObjectListUserGroupConfig',
  User = 'USMSecurityObjectListUsersConfig',
  RepairFundHolder = 'USMSecurityObjectListRepairFundHolderConfig',
  DeviceFolderGroup = 'USMSecurityObjectListDeviceFolderGroupConfig',
}

export enum USMSecurityObjectListTypeOfTickets {
  Company = 'usmSecurityNetworksCompanyList',
  Device = 'usmSecurityNetworksDeviceList',
  Car = 'usmSecurityNetworksCarList',
  CompanyPersonnel = 'usmSecurityNetworksCompanyPersonnelList',
  Department = 'usmSecurityNetworksDepartmentList',
  UserGroup = 'usmSecurityNetworksUserGroupList',
  User = 'usmSecurityNetworksUserList',
  RepairFundHolder = 'usmSecurityNetworksRepairFundHolderList',
  DeviceFolderGroup = 'usmSecurityNetworksDeviceFolderGroupList',
}

// table-constants
// users
export const USERS_PAGE_USERS_TABLE = 'UsersTable'
export const REQUEST_BODY_LS_KEY_USERS_TABLE = 'USMUsersConfig'
// security-networks
export const SECURITY_NETWORKS_TABLE = 'SecurityNetworksUSM'
export const REQUEST_BODY_LS_KEY_SECURITY_NETWORKS_TABLE = 'USMSecurityNetworksConfig'
// action-log
export const ACTION_LOG_PAGE_TABLE = 'USMActionLogTable'
export const REQUEST_BODY_LS_KEY_ACTION_LOG_TABLE = 'USMSActionLogConfig'

// require-initial-filters-for-Users-andSecurity-networks
export const getRequireParametersOfFilters = (value: string): GetTicketsBodyFilterItem => {
  return {
    parameterName: 'p_outonlyusers',
    valueType: 'INTEGER',
    parameterValue: [
      {
        id: null,
        value: value,
      },
    ],
    rangeValues: null,
  }
}
export const statusIconActionLogTable: any = {
  Success: SuccessStatusIcon,
  Failed: FailStatusIcon,
}
