import AdminIcon from '@/assets/cashManagement/icons/admin_icon.svg'
import CashOrdersIcon from '@/assets/cashManagement/icons/cm_cash_orders_icon.svg'
import MonitoringIcon from '@/assets/cashManagement/icons/monitoring_icon.svg'
import EventJournalIcon from '@/assets/vault/icons/event_journal_icon.svg'
import TeamsIcon from '@/assets/vault/icons/teams_icon.svg'
import BagsIcon from '@/assets/vault-v1/icons/bags.svg'
import CassetteIcon from '@/assets/vault-v1/icons/cassette.svg'
import { AppNavigationRoutes, VAULT_V1_BLOCK_PREFIX } from '@/constants'

export default [
  {
    path: `${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.MonitoringPage}`,
    label: 'translate#title.monitoring',
    Icon: MonitoringIcon,
    moduleId: 'Monitoring',
  },
  {
    path: `${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.OrdersPage}`,
    label: 'translate#title.cashOrders',
    Icon: CashOrdersIcon,
    moduleId: 'Orders',
  },
  {
    path: `${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.Bags}`,
    label: 'translate#vault.Bags',
    Icon: BagsIcon,
    moduleId: 'CassettesBags',
  },
  {
    path: `${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.Cassettes}`,
    label: 'translate#vault.Cassettes',
    Icon: CassetteIcon,
    moduleId: 'CassettesBags',
  },
  {
    path: `${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.TeamsPage}`,
    label: 'translate#vault.Teams',
    Icon: TeamsIcon,
    moduleId: 'Orders',
  },
  {
    path: `${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.CashDesk}`,
    label: 'translate#vault.CashDesk',
    // @TODO Change menu item icon
    Icon: EventJournalIcon,
    moduleId: 'CashDesk',
  },
  {
    path: `${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.EventJournal}`,
    label: 'translate#vault.EventJournal',
    Icon: EventJournalIcon,
    moduleId: 'EventJournal',
  },
  {
    path: `${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.AdminPage}`,
    label: 'translate#title.adminPanel',
    Icon: AdminIcon,
    moduleId: 'Admin',
  },
]
