import { AnyAction } from 'redux'

import {
  Currency,
  FilterTreeNode,
  GlobalFilter,
  MonRemainderUpdateAvailabilityEntry,
  SelectorItem,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions/index'
import { BuildInfo } from '@/types/cm/common'

export const setComVaultV1Error = (payload: string | unknown): AnyAction => ({
  type: VaultV1Actions.setComVaultV1Error,
  payload,
})

export const setGlobalFilter = (payload: Partial<GlobalFilter>): AnyAction => ({
  type: VaultV1Actions.SetGlobalFilter,
  payload,
})

export const setObjectTreeFilter = (): AnyAction => ({
  type: VaultV1Actions.SetObjectTreeFilter,
})

export const setActErrorVisible = ({
  type = 'ack_error',
  isVisible,
  errorMessage = null,
  actionType = '',
  actionPayload = null,
}: {
  type?: 'ack_error' | 'use_force'
  isVisible: boolean
  errorMessage?: string | null | React.ReactElement
  actionType?: string
  actionPayload?: any | null
}): AnyAction => ({
  type: VaultV1Actions.ComSetActErrorVisible,
  payload: { type, isVisible, errorMessage, actionType, actionPayload },
})

export const setAboutModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.SetAboutModalOpen,
  payload: isOpen,
})

// Global filter tree
export const getFilterNodesResponse = (payload: FilterTreeNode): AnyAction => ({
  type: VaultV1Actions.GetFilterNodesResponse,
  payload,
})
export const getFilterNodesFail = (error: string): AnyAction => ({
  type: VaultV1Actions.GetFilterNodesFail,
  payload: error,
})

// Integrations
export const getIntegrationsResponse = (payload: string[]): AnyAction => ({
  type: VaultV1Actions.GetIntegrationsResponse,
  payload,
})
export const getIntegrationsFail = (error: string): AnyAction => ({
  type: VaultV1Actions.GetIntegrationsFail,
  payload: error,
})

// Version
export const getVaultBuildInfoRequest = (): AnyAction => ({
  type: VaultV1Actions.GetVaultBuildInfoRequest,
})
export const getVaultBuildInfoResponse = (payload: BuildInfo): AnyAction => ({
  type: VaultV1Actions.GetVaultBuildInfoResponse,
  payload,
})
export const getVaultBuildInfoFail = (error: string): AnyAction => ({
  type: VaultV1Actions.GetVaultBuildInfoFail,
  payload: error,
})

// Com Vaults
export const getComVaultsRequest = (payload: boolean): AnyAction => ({
  type: VaultV1Actions.ComGetVaultsListRequest,
  payload,
})
export const getComVaultsResponse = (payload: SelectorItem[]): AnyAction => ({
  type: VaultV1Actions.ComGetVaultsListResponse,
  payload,
})

// Com Currencies
export const getComVaultCurrenciesRequest = (): AnyAction => ({
  type: VaultV1Actions.ComGetVaultCurrenciesRequest,
})
export const getComVaultCurrenciesResponse = (payload: Currency[]): AnyAction => ({
  type: VaultV1Actions.ComGetVaultCurrenciesResponse,
  payload,
})

// VaultBalancesStatus
export const getComVaultBalancesStatusRequest = (payload: { coids: string[] }): AnyAction => ({
  type: VaultV1Actions.ComGetVaultBalancesStatusRequest,
  payload,
})
export const getComVaultBalancesStatusResponse = (
  payload: MonRemainderUpdateAvailabilityEntry[],
): AnyAction => ({
  type: VaultV1Actions.ComGetVaultBalancesStatusResponse,
  payload,
})
export const getComVaultBalancesStatusFail = (error: string): AnyAction => ({
  type: VaultV1Actions.ComGetVaultBalancesStatusFail,
  payload: error,
})
export const getComVaultBalancesStatusClear = (): AnyAction => ({
  type: VaultV1Actions.ComGetVaultBalancesStatusClear,
})
