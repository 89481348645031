import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { ChevronLeft } from '@material-ui/icons'
import clsx from 'clsx'
import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DragDropContext, DragStart, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { isIE } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom'
import Test from 'react-test-attributes'

import AtmeyeLogo from '@/assets/atmeye/logos/atmeye_icon.svg'
import AtmeyeTitle from '@/assets/atmeye/logos/atmeye_icon_text.svg'
import CompaniesLogo from '@/assets/companies/logos/companies_icon.svg'
import CompaniesTitle from '@/assets/companies/logos/companies_icon_text.svg'
import DictionaryLogo from '@/assets/dictionary/logo.svg'
import ADMLogo from '@/assets/logosModules/adm-white.svg'
import SDLogo from '@/assets/logosModules/servicedesk.svg'
import SRMLogo from '@/assets/logosModules/srm.svg'
import ProductsLogo from '@/assets/products/logos/products_icon.svg'
import USMLogo from '@/assets/usm/USM-Logo.svg'
import HorizontalLayoutIcon from '@/assets/vault/icons/layout_horizontal_icon.svg'
import VerticalLayoutIcon from '@/assets/vault/icons/layout_vertical_icon.svg'
import VaultLogo from '@/assets/vault/icons/vault_logo.svg'
import VaultTitle from '@/assets/vault/icons/vault_title.svg'
import { GenerateMenuItems } from '@/components/blocks/ATMeye/GenerateMenuItems'
import { HeaderBanner } from '@/components/blocks/ATMeye/HeaderBanner'
import { SubPageModal } from '@/components/blocks/ATMeye/modals/pageModal/component'
import { NotificationBar } from '@/components/blocks/ATMeye/notifications/NotificationBar'
import SetLocale from '@/components/blocks/SetLocale'
import AccessControl from '@/components/controls/auth/AccessControl'
import Button from '@/components/controls/Button'
import { Restricted } from '@/components/NPMPakage/components/wrappers/PermissionProvider/Restricted'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import MergeSecurityNetworksModalUSM from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/MergeSecurityNetworksModal'
import TransitionMenu from '@/components/wrappers/AppNavigation/components/TransitionMenu/index'
import UserMenu from '@/components/wrappers/AppNavigation/components/UserMenu/component'
import UserMenuCM from '@/components/wrappers/AppNavigation/components/UserMenuCM'
import UserMenuVaultV1 from '@/components/wrappers/AppNavigation/components/UserMenuVaultV1'
import { useAppNavigation } from '@/components/wrappers/AppNavigation/hooks'
import menuItemsUSM from '@/components/wrappers/AppNavigation/menuItemsUSM'
import AppTabBar from '@/components/wrappers/AppTabBar'
import CMPageLayout from '@/components/wrappers/CMPageLayout'
import { ThemeContext } from '@/components/wrappers/ThemeProvider/component'
import { ThemeName } from '@/components/wrappers/ThemeProvider/types'
import VaultV1PageLayout from '@/components/wrappers/VaultV1PageLayout'
import { DEFAULT_CULTURES, FlagMapping } from '@/constants'
import { DroppableIds } from '@/constants/droppableIds'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { REACT_APP_CM_STANDALONE, REACT_APP_VAULT_V1_STANDALONE } from '@/constants/moduleMode'
import {
  ADM_BLOCK_PREFIX,
  AppNavigationRoutes,
  ATMEYE_BLOCK_PREFIX,
  CASH_MANAGEMENT_BLOCK_PREFIX,
  cmBaseUrl,
  COMPANIES_BLOCK_PREFIX,
  DICTIONARY_BLOCK_PREFIX,
  PRODUCTS_BLOCK_PREFIX,
  SD_BLOCK_PREFIX,
  SRM_BLOCK_PREFIX,
  USM_BLOCK_PREFIX,
  VAULT_BLOCK_PREFIX,
  VAULT_V1_BLOCK_PREFIX,
  vaultV1BaseUrl,
} from '@/constants/paths'
import { NavigationContext } from '@/context'
import { getUserModules } from '@/helpers/methods'
import { languages } from '@/internalization'
import { toggleSplitMode as toggleADMSplitMode } from '@/store/adm/windowsSplitter/actions'
import { getCurrentAccount, getUserPermissions } from '@/store/auth'
import { getLogo } from '@/store/cm/admin/appConfig'
import { getAuthStateCM, setServerCulture } from '@/store/cm/auth'
import { getOpenTabs, openTab, setCurrentTab, setTabs } from '@/store/common/portal'
import { foldDictionariesList } from '@/store/dictionaries/dictionariesList/actions'
import { getFoldingDictionariesListState } from '@/store/dictionaries/dictionariesList/selectors'
import { getNewNotifications, getNotifications, readNotifications } from '@/store/srm/notifications'
import {
  setDeviceDefectsStatisticsClosed,
  setSearchConfigurationClosed,
  setSearchPlannedWorksClosed,
  setSearchServiceDeviceClosed,
  setSearchServiceRequestClosed,
  setSearchServiceWorksClosed,
  setServiceWorksStatisticsClosed,
} from '@/store/srm/tickets'
import {
  isToggleMoveSplitPaneable,
  setContentWrapperWidthProvider,
  toggleDrawer,
  toggleMoveSplitPaneable,
} from '@/store/srm/windowsEditActions'
import { getOrdersPanelLayout, setPanelLayout } from '@/store/vault/cashOrders'
import { getVaultsAdmLogo } from '@/store/vault-v1/admin/appConfig'
import { getAuthStateVaultV1 } from '@/store/vault-v1/auth'
import { AddNewSubPageTypes, AddNewSubscriptionPageTypes } from '@/types/atmeye/dashboardTypes'
import { getTestId } from '@/utils'
import { reorderArray } from '@/utils/functions'
import { useClickOutside, usePreviousValue } from '@/utils/hooks/hooks'
import { useTranslate } from '@/utils/internalization'
import { isRouteCurrent } from '@/utils/path'

import DrawerActions from './components/DrawerActions'
import DrawerActionsCompanies from './components/DrawerActionsCompanies'
import DrawerFooter from './components/DrawerFooter'
import DrawerMenuItem from './components/DrawerMenuItem'
import HeaderBookmarks from './components/HeaderBookmarks'
import NotificationsHeader from './components/Notifications/NotificationsHeader'
import NotificationsItem from './components/Notifications/NotificationsItem'
import menuItemsADM from './menuItemsADM'
import menuItemsCM from './menuItemsCM'
import menuItemsCompanies from './menuItemsCompanies'
import menuItemsProducts from './menuItemsProducts'
import menuItemsSD from './menuItemsSD'
import menuItemsSRM from './menuItemsSRM'
import menuItemsVault from './menuItemsVault'
import menuItemsVaultV1 from './menuItemsVaultV1'
import { useClasses } from './styles'
import { Props, TabBarItem, TabBarItemExtended } from './types'

const dontRenderOnRoutes = [AppNavigationRoutes.SignInPage, AppNavigationRoutes.ChangePassword]
const initialArray: TabBarItem[] = []

type DrawerState = 'fixed' | 'relative' | ''

const AppNavigation = ({ children, location: { pathname, search } }: Props): React.ReactElement => {
  const location = useLocation()
  const newNotifications = useSelector(getNewNotifications)
  const notifications = useSelector(getNotifications)
  const userInfoCM = useSelector(getAuthStateCM)
  const userInfoVaultV1 = useSelector(getAuthStateVaultV1)
  const [openNotificationDrawer, setOpenNotificationDrawer] = useState<'relative' | ''>('')
  const previousValue = usePreviousValue(openNotificationDrawer)
  const [activeTabs, setActiveTabs] = useState<TabBarItem[]>(initialArray)
  const openTabs = useSelector(getOpenTabs)
  const [bookmarks, setBookmarks] = useState<TabBarItem[]>(initialArray)
  const [currentDraggable, setCurrentDraggable] = useState<TabBarItem>()
  const toggleSplitePane = useSelector(isToggleMoveSplitPaneable)
  const [currentModule, setCurrentModule] = useState<string>('')
  // const timezone = useSelector(getTimezone)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const currentUser = useSelector(getCurrentAccount)
  const dictionariesListIsFolded = useSelector(getFoldingDictionariesListState)

  const { data: loadedCmLogo } = useSelector(getLogo) || {}
  const { data: loadedVaultLogo } = useSelector(getVaultsAdmLogo) || {}

  const testId = getTestId('app-navigation-wrapper')
  const layoutType = useSelector(getOrdersPanelLayout)
  const notificationsRef = useRef<HTMLButtonElement | null>(null)
  const notificationsBtnRef = useRef<HTMLButtonElement | null>(null)
  const contentWrapper = useRef<HTMLDivElement | null>(null)

  const {
    handleOpenCloseModal,
    openMergeSecurityNetworksModal,
    subpages,
    showSubPageModal,
    handleSubPageModal,
    getDrawerState,
    toggleDrawerState,
    handleSave,
  } = useAppNavigation()

  const setTheme = useContext(ThemeContext)

  const isCM = pathname.includes(CASH_MANAGEMENT_BLOCK_PREFIX)
  const isADM = pathname.includes(ADM_BLOCK_PREFIX)
  const isCompanies = pathname.includes(COMPANIES_BLOCK_PREFIX)
  const isDictionary = pathname.includes(DICTIONARY_BLOCK_PREFIX)
  const isProducts = pathname.includes(PRODUCTS_BLOCK_PREFIX)
  const isSRM = pathname.includes(SRM_BLOCK_PREFIX)
  const isVaultV1 =
    pathname === VAULT_V1_BLOCK_PREFIX || pathname.includes(`${VAULT_V1_BLOCK_PREFIX}/`)
  const isVault = pathname === VAULT_BLOCK_PREFIX || pathname.includes(`${VAULT_BLOCK_PREFIX}/`)
  const isSDMap = pathname.includes(`${SD_BLOCK_PREFIX}${AppNavigationRoutes.MapPage}`)

  const initialDrawerState = useMemo(() => {
    switch (true) {
      case isCM:
      case isVaultV1:
        return getDrawerState()

      default:
        return 'relative'
    }
  }, [isCM, isVaultV1])

  const [openDrawer, setOpenDrawer] = useState<DrawerState>(initialDrawerState)

  useEffect(() => {
    if (initialDrawerState !== openDrawer) {
      setOpenDrawer(initialDrawerState)
    }
  }, [initialDrawerState])

  const dispatch = useDispatch()
  const classes = useClasses({ toggleSplitePane, pathname })
  const history = useHistory()
  const translate = useTranslate()

  const userModulesCM = useMemo(() => getUserModules(cmBaseUrl), [userInfoCM, cmBaseUrl])
  const userModulesVaultV1 = useMemo(() => getUserModules(vaultV1BaseUrl), [
    userInfoVaultV1,
    vaultV1BaseUrl,
  ])

  useEffect(() => {
    if (pathname.includes(DICTIONARY_BLOCK_PREFIX) || pathname.includes(PRODUCTS_BLOCK_PREFIX)) {
      return setTheme(ThemeName.ProductsTheme)
    }
    setTheme(ThemeName.MaterialTheme)
  }, [pathname])

  useEffect(() => {
    if (isSRM) {
      setCurrentModule(SRM_BLOCK_PREFIX)
    }
    if (isADM) {
      setCurrentModule(ADM_BLOCK_PREFIX)
    }
    if (isDictionary) {
      setCurrentModule(DICTIONARY_BLOCK_PREFIX)
    }
    if (isCompanies) {
      setCurrentModule(COMPANIES_BLOCK_PREFIX)
    }
    if (isVaultV1) {
      setCurrentModule(VAULT_V1_BLOCK_PREFIX)
    }
  }, [])

  useEffect(() => {
    if (!!newNotifications.length && openNotificationDrawer === '' && Boolean(previousValue)) {
      dispatch(readNotifications())
    }
  }, [newNotifications.length, openNotificationDrawer, previousValue, dispatch])

  useEffect(() => {
    if (pathname.includes(AppNavigationRoutes.SignInPage)) {
      setActiveTabs([])
      dispatch(setTabs([]))
    }
  }, [pathname])

  useEffect((): void => {
    const tabs = sessionStorage.getItem(LocalStorageItems.ActiveTabs)
    if (tabs) {
      const pathName = history.location.pathname.substring(1, 4)
      const savedTabs: TabBarItem[] = JSON.parse(tabs).filter((tab: TabBarItem) =>
        tab.path.includes(pathName),
      )
      setActiveTabs(prevTabs => {
        const tabs = [
          ...savedTabs,
          ...prevTabs.filter((tab: TabBarItem) =>
            savedTabs.every(savedTab => savedTab.path !== tab.path),
          ),
        ]

        const tabsStore = tabs.map(
          (item, index): TabBarItemExtended => ({ ...item, isInitialOpen: undefined }),
        )

        const currentOpeningPage = tabsStore.find(tab => tab.path === location.pathname)
        if (currentOpeningPage) {
          currentOpeningPage.isInitialOpen = true
        }

        dispatch(setTabs(tabsStore))
        return tabs
      })
    }
    const marks = sessionStorage.getItem(LocalStorageItems.Bookmarks)
    if (marks) {
      const pathName = history.location.pathname.substring(1, 4)
      setBookmarks(JSON.parse(marks).filter((b: TabBarItem) => b.path.includes(pathName)))
    }
  }, [setBookmarks])

  useEffect((): void => {
    if (initialArray !== activeTabs) {
      sessionStorage.setItem(LocalStorageItems.ActiveTabs, JSON.stringify(activeTabs))
    }
  }, [activeTabs])

  useEffect((): void => {
    if (initialArray !== bookmarks) {
      sessionStorage.setItem(LocalStorageItems.Bookmarks, JSON.stringify(bookmarks))
    }
  }, [bookmarks])

  const handleNotificationDrawerClose = useCallback(() => setOpenNotificationDrawer(''), [
    setOpenNotificationDrawer,
  ])

  useClickOutside(notificationsRef, handleNotificationDrawerClose, notificationsBtnRef)

  const handleDragStart = useCallback<(initial: DragStart, provided: ResponderProvided) => void>(
    ({ draggableId }): void => {
      setOpenDrawer(prevState => (prevState === 'fixed' ? 'relative' : prevState))
      const [item] = draggableId.match(/{.+}/) || ['']
      if (item) {
        setCurrentDraggable(JSON.parse(item))
      }
    },
    [],
  )

  useEffect(() => {
    if (contentWrapper.current) {
      dispatch(setContentWrapperWidthProvider(contentWrapper.current.getClientRects()[0].width))
    }
  }, [contentWrapper.current])

  const handleRemoveBookmark = useCallback(
    (path: string): (() => void) => (): void => {
      setBookmarks(prevMarks => prevMarks.filter(mark => mark.path !== path))
    },
    [],
  )

  const logoSwitch = (param: any): React.ReactNode => {
    switch (true) {
      case param === VAULT_BLOCK_PREFIX || param.includes(`${VAULT_BLOCK_PREFIX}/`):
        return (
          <div className={classes.vaultLogoWrapper}>
            <img src={VaultLogo} />
            <img src={VaultTitle} />
          </div>
        )
      case param.includes(ATMEYE_BLOCK_PREFIX):
        return (
          <div className={classes.atmeyeLogoWrapper}>
            <img src={AtmeyeLogo} />
            <img src={AtmeyeTitle} />
          </div>
        )
      case param.includes(CASH_MANAGEMENT_BLOCK_PREFIX):
        return (
          <Link to={CASH_MANAGEMENT_BLOCK_PREFIX} className={classes.cashManagementLogoWrapper}>
            {loadedCmLogo ? (
              <img className={classes.cashManagementLogo} src={loadedCmLogo} />
            ) : (
              <p className={classes.cashManagementTitle}>
                {translate('translate#title.cashManagementLabel')}
              </p>
            )}
          </Link>
        )
      case param.includes(ADM_BLOCK_PREFIX):
        return (
          <div className={classes.admLogoWrapper}>
            <img src={ADMLogo} />
          </div>
        )
      case param.includes(SRM_BLOCK_PREFIX):
        return (
          <div className={classes.sdLogoWrapper}>
            <img src={SRMLogo} />
          </div>
        )
      case param.includes(USM_BLOCK_PREFIX):
        return (
          <div className={classes.usmLogoWrapper}>
            <img src={USMLogo} />
          </div>
        )
      case param.includes(DICTIONARY_BLOCK_PREFIX):
        return (
          <div className={classes.dictionaryLogoWrapper}>
            <img src={DictionaryLogo} />
          </div>
        )
      case param.includes(COMPANIES_BLOCK_PREFIX):
        return (
          <div className={classes.companiesLogoWrapper}>
            <img src={CompaniesLogo} />
            <img src={CompaniesTitle} />
          </div>
        )
      case param.includes(PRODUCTS_BLOCK_PREFIX):
        return (
          <div className={classes.productsLogoWrapper}>
            <img src={ProductsLogo} />
          </div>
        )
      case param === VAULT_V1_BLOCK_PREFIX || param.includes(`${VAULT_V1_BLOCK_PREFIX}/`):
        return (
          <Link to={VAULT_V1_BLOCK_PREFIX} className={classes.vaultV1LogoWrapper}>
            {loadedVaultLogo ? (
              <img className={classes.cashManagementLogo} src={loadedVaultLogo} />
            ) : (
              <>
                <img src={VaultLogo} />
                <img src={VaultTitle} />
              </>
            )}
          </Link>
        )
      default:
        return (
          <div className={classes.sdLogoWrapper}>
            <img src={SDLogo} />
          </div>
        )
    }
  }

  const currentRoute = useMemo((): string => {
    switch (true) {
      case isCM:
        return CASH_MANAGEMENT_BLOCK_PREFIX

      case isVaultV1:
        return VAULT_V1_BLOCK_PREFIX
      default:
        return SD_BLOCK_PREFIX
    }
  }, [])

  const handleRemoveTab = useCallback(
    (path: string): void => {
      path.includes(AppNavigationRoutes.ServiceWorksPage) &&
        dispatch(setSearchServiceWorksClosed(true))
      path.includes(AppNavigationRoutes.Configuration) &&
        dispatch(setSearchConfigurationClosed(true))
      path.includes(AppNavigationRoutes.DeviceDefects) &&
        dispatch(setDeviceDefectsStatisticsClosed(true))
      path.includes(AppNavigationRoutes.ServiceRequestPage) &&
        dispatch(setSearchServiceRequestClosed(true))
      path.includes(AppNavigationRoutes.PlannedWorksPage) &&
        dispatch(setSearchPlannedWorksClosed(true))
      path.includes(AppNavigationRoutes.ServiceDevice) &&
        dispatch(setSearchServiceDeviceClosed(true))
      path.includes(AppNavigationRoutes.WorksStatistics) &&
        dispatch(setServiceWorksStatisticsClosed(true))
      setActiveTabs(prevTabs => {
        if (isRouteCurrent(pathname, path)) {
          const foundIndex = prevTabs.findIndex(tab => tab.path === path)
          if (foundIndex >= 0 && prevTabs.length > 1) {
            const path = foundIndex > 0 ? prevTabs[foundIndex - 1].path : prevTabs[1].path
            history.push(path)
            dispatch(setCurrentTab(location.pathname, path))
          } else {
            history.push(currentRoute)
            dispatch(setCurrentTab(location.pathname, currentRoute))
          }
        }
        const currentOpenTabs = openTabs.filter(tab => tab.path !== path)
        dispatch(setTabs(currentOpenTabs))
        return prevTabs.filter(tab => tab.path !== path)
      })
    },
    [history, pathname, currentRoute, openTabs],
  )

  const renderContent = useCallback(() => {
    // if (pathname.includes(SRM_BLOCK_PREFIX)) {
    //   return <SRMPageWrapper childrens={children} items={activeTabs} onTabClose={handleRemoveTab} />
    // }
    if (pathname.includes(CASH_MANAGEMENT_BLOCK_PREFIX)) {
      return (
        <AppTabBar items={activeTabs} onTabClose={handleRemoveTab}>
          <CMPageLayout>{children}</CMPageLayout>
        </AppTabBar>
      )
    }

    if (
      pathname.includes(VAULT_V1_BLOCK_PREFIX) &&
      history.location.pathname !== VAULT_V1_BLOCK_PREFIX
    ) {
      return (
        <AppTabBar items={activeTabs} onTabClose={handleRemoveTab}>
          <VaultV1PageLayout>{children}</VaultV1PageLayout>
        </AppTabBar>
      )
    }

    return (
      <AppTabBar items={activeTabs} onTabClose={handleRemoveTab}>
        {children}
      </AppTabBar>
    )
  }, [children, activeTabs, handleRemoveTab])

  const renderHeaderMenu = useMemo(() => {
    if (isCM) {
      return <UserMenuCM />
    }

    if (isVaultV1) {
      return <UserMenuVaultV1 />
    }

    return <UserMenu patch={pathname} />
  }, [isCM, isVaultV1, pathname])

  const handleToggleSpitMode = useCallback(() => {
    switch (true) {
      case pathname.includes(ADM_BLOCK_PREFIX):
        dispatch(toggleADMSplitMode())
    }
  }, [pathname, dispatch])

  const onToggleMoveSplitPaneable = useCallback((): void => {
    dispatch(toggleMoveSplitPaneable(!toggleSplitePane))
  }, [toggleSplitePane, dispatch])

  const handleDrawerToggle = useCallback((): void => {
    setOpenDrawer(prevState => (prevState === 'relative' ? '' : 'relative'))

    if (isVaultV1 || isCM) {
      toggleDrawerState()
    }

    if (pathname.includes(SRM_BLOCK_PREFIX)) {
      dispatch(toggleDrawer())
    }
  }, [dispatch, pathname])

  const handleAddTab = useCallback(
    (path: string, label: string): (() => void) => (): void => {
      setActiveTabs(prevTabs => {
        if (prevTabs.find(tab => tab.path === path)) {
          const currentPage = openTabs.find(tab => tab.path === path)
          if (currentPage) {
            currentPage.isInitialOpen = false
            dispatch(setTabs(openTabs))
          }

          return prevTabs
        } else {
          dispatch(openTab({ path, label, isInitialOpen: true }))
          return [...prevTabs, { path, label }]
        }
      })

      history.push({
        pathname: path,
        search: pathname === path ? search : '',
      })
    },
    [history, pathname, search, currentRoute],
  )

  const handleReloadTab = useCallback(
    (pathname: string) => (): void => {
      history.push(pathname)
    },
    [history],
  )

  const handleDragEnd = useCallback<(result: DropResult, provided: ResponderProvided) => void>(
    (result): void => {
      if (!result.destination || !currentDraggable) {
        return
      }

      if (result.destination.droppableId === DroppableIds.bookmark) {
        if (result.source.droppableId !== DroppableIds.bookmark) {
          const newBookmarks = reorderArray(
            [currentDraggable, ...bookmarks.filter(mark => mark.path !== currentDraggable.path)],
            0,
            result.destination.index,
          )
          setBookmarks(newBookmarks.filter(mark => mark))
        } else {
          const newBookmarks = reorderArray(
            bookmarks,
            result.source.index,
            result.destination.index,
          )
          setBookmarks(newBookmarks.filter(mark => mark))
        }
      }

      if (result.destination.droppableId === DroppableIds.tabs) {
        if (result.source.droppableId !== DroppableIds.tabs) {
          const newItems = reorderArray(
            [currentDraggable, ...activeTabs.filter(mark => mark.path !== currentDraggable.path)],
            0,
            result.destination.index,
          )

          const newStoreItems = reorderArray(
            [currentDraggable, ...openTabs.filter(mark => mark.path !== currentDraggable.path)],
            0,
            result.destination.index,
          )
          dispatch(setTabs(newStoreItems))
          setActiveTabs(newItems)
        } else {
          const newItems = reorderArray(activeTabs, result.source.index, result.destination.index)
          const newStoreItems = reorderArray(
            openTabs,
            result.source.index,
            result.destination.index,
          )
          dispatch(setTabs(newStoreItems))
          setActiveTabs(newItems)
        }
      }
    },
    [activeTabs, bookmarks, currentDraggable],
  )

  // for tabbBar

  const handleMouseEnter = useCallback<MouseEventHandler<HTMLDivElement>>((e): void => {
    // prevent mouse enter in dialogs
    const currentTarget = e.currentTarget as Element
    const target = e.target as Element
    if (currentTarget.contains(target)) {
      setOpenDrawer('fixed')
    }
  }, [])

  const handleMouseLeave = useCallback((): void => {
    setOpenDrawer('')
  }, [])

  const handleNotificationDrawerToggle = useCallback((): void => {
    setOpenNotificationDrawer(prevState => (prevState ? '' : 'relative'))
  }, [])

  const setPanelLayoutHorizontal = useCallback(() => dispatch(setPanelLayout('horizontal')), [])

  const setPanelLayoutVertical = useCallback(() => dispatch(setPanelLayout('vertical')), [])

  const notifyServerOnChangeLocale = useCallback(culture => {
    if (isCM) {
      dispatch(setServerCulture(culture))
    }
  }, [])

  const currentLang = sessionStorage.getItem(LocalStorageItems.Locale)
  let allLangs = languages

  if (REACT_APP_CM_STANDALONE === 'true' || REACT_APP_VAULT_V1_STANDALONE === 'true') {
    allLangs = DEFAULT_CULTURES.map(({ id, displayName }) => ({
      label: displayName,
      value: id,
      code: FlagMapping[id],
    }))
  }

  if (isCM || isVaultV1) {
    const stored = sessionStorage.getItem(LocalStorageItems.AllLocales)
    if (stored) {
      const cultures = JSON.parse(stored)
      allLangs = cultures.map((obj: { displayName: string; id: string }) => {
        return { label: obj.displayName, value: obj.id, code: FlagMapping[obj.id] }
      })
    }
  }

  const handleFoldDictionaryList = (): void => {
    dispatch(foldDictionariesList())
  }

  return dontRenderOnRoutes.includes(pathname) ? (
    <>{children}</>
  ) : (
    <NavigationContext.Provider value={{ handleAddTab, handleRemoveTab }}>
      <Test id={testId(0)}>
        <div className={classes.root}>
          <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <CssBaseline />
            <Test id={testId(3)}>
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: openDrawer,
                  [classes.dictionaryAppBar]: isDictionary,
                  [classes.modulesListAppBar]: pathname === SD_BLOCK_PREFIX,
                })}
              >
                <div
                  className={classes.drawerToggleWrapper}
                  style={isDictionary ? { display: 'none' } : {}}
                >
                  {pathname !== SD_BLOCK_PREFIX && (
                    <Test id={testId(1)}>
                      <IconButton onClick={handleDrawerToggle}>
                        <div className={classes.toggleIcon}>
                          <ChevronLeft
                            className={classes.drawerToggle}
                            style={{ transform: `rotate(${!openDrawer ? 180 : 0}deg)` }}
                          />
                        </div>
                      </IconButton>
                    </Test>
                  )}
                </div>
                {isDictionary && (
                  <div className={classes.dictionaryLogoWrapper}>
                    <img src={DictionaryLogo} />
                    <Button
                      variant="text"
                      width="xs"
                      className={classes.foldingButton}
                      onClick={handleFoldDictionaryList}
                    >
                      <div className={classes.toggleIcon}>
                        <ChevronLeft
                          className={clsx(classes.drawerToggle, {
                            [classes.foldedList]: dictionariesListIsFolded,
                          })}
                        />
                      </div>
                    </Button>
                  </div>
                )}
                <Test id={testId(8)}>
                  <Toolbar className={classes.headerToolbar}>
                    {/* {(pathname.includes(SRM_BLOCK_PREFIX) || isADM) && (
                      <ModulesMenu
                        currentModule={currentModule}
                        sideMenuIsOpen={openDrawer === 'relative'}
                        items={modulesList}
                      />
                    )} */}
                    <HeaderBookmarks
                      bookmarks={bookmarks}
                      handleAddTab={handleAddTab}
                      handleRemoveBookmark={handleRemoveBookmark}
                    />
                    {pathname.includes(ATMEYE_BLOCK_PREFIX) && (
                      <Grid item>
                        <HeaderBanner />
                      </Grid>
                    )}
                    <Grid item>
                      <Grid
                        container
                        alignItems="center"
                        direction="row"
                        className={classes.userBarWrapper}
                      >
                        {pathname.includes(
                          VAULT_BLOCK_PREFIX + AppNavigationRoutes.CashOrdersPage,
                        ) && (
                          <Grid
                            item
                            className={clsx({
                              [classes.vaultPaddingHeaderItemWrapper]: isVault,
                            })}
                          >
                            <IconButton
                              className={clsx({
                                [classes.disabledButton]: layoutType === 'horizontal',
                              })}
                              onClick={setPanelLayoutHorizontal}
                            >
                              <img src={HorizontalLayoutIcon} />
                            </IconButton>
                            <IconButton
                              className={clsx({
                                [classes.disabledButton]: layoutType === 'vertical',
                              })}
                              onClick={setPanelLayoutVertical}
                            >
                              <img src={VerticalLayoutIcon} />
                            </IconButton>
                          </Grid>
                        )}
                        {pathname.includes(ATMEYE_BLOCK_PREFIX) && <NotificationBar />}
                        <Grid
                          item
                          className={clsx({
                            [classes.vaultPaddingHeaderItemWrapper]: isVault || isCM || isVaultV1,
                          })}
                        >
                          {renderHeaderMenu}
                        </Grid>
                        {!isCM && !isVaultV1 && (
                          <Grid item>
                            <Test id={testId(2)}>
                              <Typography variant="body1" noWrap>
                                {timezone || 'timezone'}
                              </Typography>
                            </Test>
                          </Grid>
                        )}
                        <Grid
                          item
                          className={clsx({
                            [classes.vaultPaddingHeaderItemWrapper]: isVault,
                          })}
                        >
                          <SetLocale
                            initialLanguageCode={currentLang || undefined}
                            allLanguages={allLangs}
                            serverNotifier={notifyServerOnChangeLocale}
                          />
                        </Grid>
                        {/* {(isProducts || isDictionary) && (
                          <Grid className={classes.moduleIconWrapper}>
                            <ModuleIcon src={SDModuleIcon} />
                          </Grid>
                        )} */}
                        {!isCM && !isVaultV1 && (
                          <Grid
                            item
                            className={clsx({
                              [classes.noPadding]: isVault,
                              [classes.headerActionsInRow]: isADM,
                            })}
                          >
                            {(pathname.includes(USM_BLOCK_PREFIX) ||
                              pathname.includes(ATMEYE_BLOCK_PREFIX) ||
                              pathname.includes(SD_BLOCK_PREFIX + '/') ||
                              isADM ||
                              isDictionary ||
                              isProducts ||
                              isCompanies ||
                              isSRM) &&
                              !!currentUser?.modules.length &&
                              currentUser.modules.length > 1 && (
                                <TransitionMenu
                                  pathname={pathname}
                                  sideMenuIsOpen={openDrawer === 'relative'}
                                  // classes={{
                                  //   button: clsx({ [classes.headerActionsButton]: isADM }),
                                  // }}
                                  modules={currentUser.modules}
                                />
                              )}
                            {/* {isADM && (
                              <Button
                                width="xs"
                                className={clsx(
                                  classes.headerActionsButton,
                                  classes.buttonWithMargin,
                                  {
                                    [classes.activeSplitButton]: admIsSplitting,
                                  },
                                )}
                                onClick={handleToggleSpitMode}
                              >
                                <PanToolIcon fontSize="small" />
                              </Button>
                            )} */}
                            {/* {isADM && (
                              <NotificationsButton
                                drawerIsOpen={openNotificationDrawer === 'relative'}
                                newNotificationsCount={newNotifications.length}
                                ref={notificationsBtnRef}
                                className={clsx(
                                  classes.headerActionsButton,
                                  classes.buttonWithMargin,
                                )}
                                handleNotificationDrawerToggle={handleNotificationDrawerToggle}
                              />
                            )} */}
                          </Grid>
                        )}
                        {/* {pathname.includes(SRM_BLOCK_PREFIX) && (
                          <Grid item>
                            <Button width="xs" variant="outlined" className={classes.button}>
                              <AppsIcon />
                            </Button>
                            <Button
                              width="xs"
                              variant="outlined"
                              className={classes.button}
                              onClick={onToggleMoveSplitPaneable}
                            >
                              <PanToolIcon fontSize="small" />
                            </Button>
                            <NotificationsButton
                              drawerIsOpen={openNotificationDrawer === 'relative'}
                              newNotificationsCount={newNotifications.length}
                              ref={notificationsBtnRef}
                              className={classes.button}
                              handleNotificationDrawerToggle={handleNotificationDrawerToggle}
                            />
                          </Grid>
                        )} */}
                      </Grid>
                    </Grid>
                  </Toolbar>
                </Test>
              </AppBar>
            </Test>
            {pathname !== SD_BLOCK_PREFIX && (
              <Test id={testId(4)}>
                <div
                  className={classes.drawerWrapper}
                  style={isDictionary ? { display: 'none' } : {}}
                >
                  <div className={classes.drawerAnchor} />
                  <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: openDrawer,
                      [classes.drawerClose]: !openDrawer,
                      [classes.drawerFixed]: openDrawer !== 'relative',
                    })}
                    onMouseEnter={
                      !isIE && !isCM && !isVaultV1
                        ? openDrawer !== 'relative'
                          ? handleMouseEnter
                          : undefined
                        : undefined
                    }
                    onMouseLeave={
                      !isIE && !isCM && !isVaultV1
                        ? openDrawer !== 'relative'
                          ? handleMouseLeave
                          : undefined
                        : undefined
                    }
                    classes={{
                      paper: clsx(classes.drawerPaper, {
                        [classes.drawerOpen]: openDrawer,
                        [classes.drawerClose]: !openDrawer,
                        [classes.atmeyeDrawerPaper]: pathname.includes(ATMEYE_BLOCK_PREFIX),
                        [classes.companiesDrawerPaper]: pathname.includes(COMPANIES_BLOCK_PREFIX),
                        [classes.productsDrawerPaper]: isProducts,
                        [classes.USMDrawerPaper]: pathname.includes(USM_BLOCK_PREFIX),
                        [classes.SDDrawerPaper]: pathname.includes(SD_BLOCK_PREFIX),
                        [classes.SRMDrawerPaper]: isSRM,
                        [classes.CMDrawerPaper]: isVault || isCM || isVaultV1,
                      }),
                    }}
                  >
                    <div
                      className={clsx(classes.toolbar, classes.drawerToolbar, {
                        [classes.atmeyeDrawerTitlePaper]: pathname.includes(ATMEYE_BLOCK_PREFIX),
                        [classes.noPadding]: isVault || isCM || isVaultV1,
                        [classes.CMDrawerTitlePaper]: isVault || isCM || isVaultV1,
                      })}
                    >
                      <div
                        className={clsx(classes.logoWrapper, {
                          [classes.logoWrapperHide]: !openDrawer,
                        })}
                      >
                        {logoSwitch(pathname)}
                      </div>
                    </div>

                    <Divider />
                    <Test id={testId(20)}>
                      <List className={classes.drawerList}>
                        {!isVault &&
                          !pathname.includes(ATMEYE_BLOCK_PREFIX) &&
                          !isCM &&
                          !isVaultV1 &&
                          !isDictionary &&
                          !pathname.includes(COMPANIES_BLOCK_PREFIX) && (
                            <DrawerActions
                              showClosed={!openDrawer}
                              pathname={pathname}
                              // setOpenMergeSecurityNetworksModal={setOpenMergeSecurityNetworksModal}
                              // openMergeSecurityNetworksModal={openMergeSecurityNetworksModal}
                            />
                          )}
                        {isCompanies && (
                          <AccessControl
                            allowedPermissions={['Company_insert']}
                            // renderNoAccess={() => <NoAccess permissionsNeeded="Company_insert" />}
                            renderNoAccess={(): React.ReactElement => <></>}
                          >
                            <DrawerActionsCompanies pathname={pathname} showClosed={!openDrawer} />
                          </AccessControl>
                        )}
                        {pathname.includes(SRM_BLOCK_PREFIX) &&
                          menuItemsSRM.map(
                            (
                              {
                                label,
                                path,
                                Icon,
                                activeFor,
                                subItems = [],
                                innerPages,
                                allowedPermissions,
                              },
                              index,
                            ) => (
                              <Test id={testId(31, `${index}`)} key={`Tab${label}`}>
                                <AccessControl
                                  allowedPermissions={allowedPermissions}
                                  renderNoAccess={(): React.ReactElement => <></>}
                                >
                                  <DrawerMenuItem
                                    label={label}
                                    path={path}
                                    innerPages={innerPages}
                                    Icon={Icon}
                                    activeFor={activeFor}
                                    hideSubItems={!openDrawer}
                                    subItems={subItems}
                                    classes={classes}
                                    pathname={pathname}
                                    handleAddTab={handleAddTab}
                                  />
                                </AccessControl>
                              </Test>
                            ),
                          )}
                        {isCM &&
                          menuItemsCM
                            .filter(
                              el =>
                                (el.moduleId && userModulesCM?.includes(el.moduleId)) ||
                                (el.moduleId === 'Admin' &&
                                  userModulesCM?.some(name => name.includes(el.moduleId))),
                            )
                            .map(({ label, Icon, path }, index) => (
                              <Test id={testId(32, `${index}`)} key={`Tab${label}`}>
                                <DrawerMenuItem
                                  label={label}
                                  Icon={Icon}
                                  path={path}
                                  pathname={pathname}
                                  handleAddTab={handleAddTab}
                                  classes={classes}
                                  subItems={[]}
                                />
                              </Test>
                            ))}
                        {isVaultV1 &&
                          menuItemsVaultV1
                            .filter(
                              el =>
                                (el.moduleId && userModulesVaultV1?.includes(el.moduleId)) ||
                                (el.moduleId === 'Admin' &&
                                  userModulesVaultV1?.some(name => name.includes(el.moduleId))),
                            )
                            .map(({ label, Icon, path }, index) => (
                              <Test id={testId(32, `${index}`)} key={`Tab${label}`}>
                                <DrawerMenuItem
                                  label={label}
                                  Icon={Icon}
                                  path={path}
                                  pathname={pathname}
                                  handleAddTab={handleAddTab}
                                  classes={classes}
                                  subItems={[]}
                                />
                              </Test>
                            ))}
                        {isVault &&
                          menuItemsVault.map(({ label, Icon, path }, index) => (
                            <Test id={testId(32, `${index}`)} key={`Tab${label}`}>
                              <DrawerMenuItem
                                label={label}
                                Icon={Icon}
                                path={path}
                                pathname={pathname}
                                handleAddTab={handleAddTab}
                                classes={classes}
                                subItems={[]}
                              />
                            </Test>
                          ))}
                        {pathname.includes(SD_BLOCK_PREFIX) &&
                          menuItemsSD.map(
                            (
                              {
                                label,
                                path,
                                Icon,
                                activeFor,
                                subItems = [],
                                innerPages,
                                allowedPermissions,
                              },
                              index,
                            ) => (
                              <Test id={testId(30, `${index}`)} key={`Tab${label}`}>
                                <AccessControl
                                  allowedPermissions={allowedPermissions}
                                  renderNoAccess={(): React.ReactElement => <></>}
                                >
                                  <DrawerMenuItem
                                    label={label}
                                    path={path}
                                    innerPages={innerPages}
                                    Icon={Icon}
                                    activeFor={activeFor}
                                    hideSubItems={!openDrawer}
                                    subItems={subItems}
                                    classes={classes}
                                    pathname={pathname}
                                    handleAddTab={handleAddTab}
                                  />
                                </AccessControl>
                              </Test>
                            ),
                          )}

                        {pathname.includes(ATMEYE_BLOCK_PREFIX) && !!subpages.length && (
                          <Restricted permissionKey={Permission.Open_dashboard_page}>
                            {subpages.map(({ label, Icon, path, subItems = [] }, index) => (
                              <DrawerMenuItem
                                label={label}
                                key={label}
                                Icon={Icon}
                                path={path}
                                innerPages={subItems}
                                pathname={pathname}
                                classes={classes}
                                handleAddTab={handleAddTab}
                                hideSubItems={!openDrawer}
                                subItems={subItems}
                                setOpenCloseModal={handleSubPageModal('Add new page')}
                              />
                            ))}
                          </Restricted>
                        )}
                        {showSubPageModal && (
                          <SubPageModal
                            modalKind={showSubPageModal}
                            onClose={handleSubPageModal(null)}
                            onSave={(data: AddNewSubPageTypes | AddNewSubscriptionPageTypes) =>
                              handleSave(data)
                            }
                            translateButton={{
                              id: 'atmeye.dashboard.page.modal.action.add',
                              defaultMessage: 'Add',
                            }}
                          />
                        )}

                        {pathname.includes(ATMEYE_BLOCK_PREFIX) && (
                          <GenerateMenuItems
                            testId={testId}
                            pathname={pathname}
                            classes={classes}
                            handleAddTab={handleAddTab}
                            hideSubItems={!openDrawer}
                          />
                        )}

                        {pathname.includes(USM_BLOCK_PREFIX) &&
                          menuItemsUSM.map(({ label, Icon, path }, index) => (
                            <Test id={testId(32, `${index}`)} key={`Tab${label}`}>
                              <DrawerMenuItem
                                label={label}
                                Icon={Icon}
                                path={path}
                                pathname={pathname}
                                handleAddTab={handleAddTab}
                                classes={classes}
                                subItems={[]}
                                setOpenCloseModal={handleOpenCloseModal}
                              />
                            </Test>
                          ))}

                        {openMergeSecurityNetworksModal && (
                          <MergeSecurityNetworksModalUSM
                            open={openMergeSecurityNetworksModal}
                            handleClose={handleOpenCloseModal}
                            disableBackdropClick
                          />
                        )}

                        {isADM &&
                          menuItemsADM.map(({ label, subItems, Icon, path }, index) => (
                            <Test id={testId(33, `${index}`)} key={`Tab${label}`}>
                              <DrawerMenuItem
                                label={label}
                                Icon={Icon}
                                path={path}
                                pathname={pathname}
                                handleAddTab={handleAddTab}
                                classes={classes}
                                subItems={subItems ?? []}
                              />
                            </Test>
                          ))}

                        {pathname.includes(COMPANIES_BLOCK_PREFIX) &&
                          menuItemsCompanies.map(
                            ({ label, Icon, path, allowedPermissions }, index) => (
                              <Test id={testId(35, `${index}`)} key={`Tab${label}`}>
                                <AccessControl
                                  allowedPermissions={allowedPermissions}
                                  // renderNoAccess={() => <NoAccess permissionsNeeded="Company_insert" />}
                                  renderNoAccess={(): React.ReactElement => <></>}
                                >
                                  <DrawerMenuItem
                                    label={label}
                                    Icon={Icon}
                                    path={path}
                                    pathname={pathname}
                                    handleAddTab={handleAddTab}
                                    classes={classes}
                                    subItems={[]}
                                  />
                                </AccessControl>
                              </Test>
                            ),
                          )}

                        {isProducts &&
                          menuItemsProducts.map(({ label, Icon, path }, index) => (
                            <Test id={testId(36, `${index}`)} key={`Tab${label}`}>
                              <DrawerMenuItem
                                label={label}
                                Icon={Icon}
                                path={path}
                                pathname={pathname}
                                handleAddTab={handleAddTab}
                                classes={classes}
                                subItems={[]}
                              />
                            </Test>
                          ))}
                      </List>
                    </Test>
                    {isProducts && (
                      <Test id={testId(21)}>
                        <DrawerFooter />
                      </Test>
                    )}
                  </Drawer>
                </div>
              </Test>
            )}
            <Test id={testId(5)}>
              <div className={classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.contentWrapper}>
                  <div className={classes.container}>
                    {renderContent()}
                    {/* {pathname.includes(SRM_BLOCK_PREFIX) ? (
                      <SRMPageWrapper
                        childrens={children}
                        items={activeTabs}
                        onTabClose={handleRemoveTab}
                      />
                    ) : (
                      <AppTabBar items={activeTabs} onTabClose={handleRemoveTab}>
                        {children}
                      </AppTabBar>
                    )} */}
                  </div>
                </div>
              </div>
            </Test>
            <Test id={testId(7)}>
              <div className={classes.drawerWrapper}>
                <Drawer
                  anchor="right"
                  variant="permanent"
                  classes={{
                    paper: clsx(classes.notificationDrawerPaper, {
                      [classes.notificationDrawerOpen]: openNotificationDrawer,
                      [classes.notificationDrawerClose]: !openNotificationDrawer,
                    }),
                  }}
                  innerRef={notificationsRef}
                >
                  <NotificationsHeader
                    notificationsCount={newNotifications.length + notifications.length}
                  />
                  <div className={classes.list}>
                    {!!newNotifications.length &&
                      newNotifications.map(notification => (
                        <NotificationsItem
                          key={notification.name}
                          name={notification.name}
                          date={notification.date}
                          description={notification.description}
                          children={notification.children}
                          newNotification
                        />
                      ))}
                    {!!notifications.length &&
                      notifications.map(notification => (
                        <NotificationsItem
                          key={notification.name}
                          name={notification.name}
                          date={notification.date}
                          description={notification.description}
                          children={notification.children}
                        />
                      ))}
                  </div>
                </Drawer>
              </div>
            </Test>
          </DragDropContext>
        </div>
      </Test>
    </NavigationContext.Provider>
  )
}

export default withRouter(AppNavigation)
