import queryString from 'query-string'

import { ComUuidValue, Position, STeam, TeamGroup, User } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class TeamsApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getTeams(
    isAdmin: boolean,
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<TeamGroup[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: !isAdmin ? '/teams' : '/admin/teams',
          query: {
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await TeamsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editTeam(isAdmin: boolean, team: STeam): Promise<void> {
    try {
      return await TeamsApi.requestService.put(
        !isAdmin ? '/teams/update' : '/admin/teams/update',
        team,
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async addTeam(isAdmin: boolean, team: STeam): Promise<void> {
    try {
      return await TeamsApi.requestService.post(!isAdmin ? '/teams/add' : '/admin/teams/add', team)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async deleteTeam(isAdmin: boolean, id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: !isAdmin ? '/teams/delete' : 'admin/teams/delete',
          query: {
            id,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await TeamsApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getVehicles(isAdmin: boolean): Promise<ComUuidValue[]> {
    try {
      return await TeamsApi.requestService.get(
        !isAdmin ? '/teams/vehicles' : '/admin/teams/vehicles',
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getRoles(isAdmin: boolean): Promise<ComUuidValue[]> {
    try {
      return await TeamsApi.requestService.get(!isAdmin ? '/teams/roles' : '/admin/teams/roles')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getUsers(isAdmin: boolean): Promise<User[]> {
    try {
      return await TeamsApi.requestService.get(!isAdmin ? '/teams/users' : '/admin/teams/users')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getPosition(): Promise<Position[]> {
    try {
      return await TeamsApi.requestService.get('/common/positions')
    } catch (error) {
      throw Error(error)
    }
  }
}
