// core
import React, { FC } from 'react'
import Logo from '@/components/blocks/Logo/component'
import Typography from '@material-ui/core/Typography'
// components
import SetLocale from '@/components/blocks/SetLocale'
// styles
import { useClasses } from './styles'
// types
import { Props } from './types'
import { useTranslate } from '@/utils/internalization'

const LogoLocalBlock: FC<Props> = ({ allLanguages, title, showLocal, logoProducts }: Props) => {
  const classes = useClasses()
  const translate = useTranslate()

  return (
    <>
      <div className={classes.logoWrapper}>
        <Logo type={logoProducts || 'transparent'} />
      </div>
      <div className={classes.loginAndLocaleWrapper}>
        <Typography variant="inherit">{translate(`translate#title.${title}`)}</Typography>
        {showLocal && <SetLocale allLanguages={allLanguages} />}
      </div>
    </>
  )
}

export { LogoLocalBlock }
