import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects'

import { NewCommonApi } from '@/api/atmeye/common/newCommonAPI'
import {
  GetExportBody,
  GetExportGroupedBody,
  GetGroupDetailsBody,
  GetTicketsBody,
} from '@/api/atmeye/common/types'
import { Account } from '@/api/common/authorizationApi/types'
import { TicketsApi } from '@/api/sd/ticketsApi'
import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { TechnicalEventsActions, TicketActions } from '@/constants'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { MEDIA_MESSAGES } from '@/constants/messages'
import { gettingDevices } from '@/helpers/devices'
import { State } from '@/store/atmeye/transactions/reducer'
import { TableSettingsData, TypeOfTickets } from '@/types'
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/localStorage'
import { PopUpService } from '@/utils/services/popUpService'

import {
  changeSelectedFilters,
  changeSorting,
  collectFilterTemplateFields,
  createSdFilterTemplateResponse,
  deleteSdFilterTemplateResponse,
  deleteSdGroupTemplateResponseSuccess,
  editGroupTemplateFromResponse,
  getExportGroupedResponse,
  getExportGroupedResponseFail,
  getExportResponse,
  getExportResponseFail,
  getGroupDetailsResponse,
  getGroupDetailsResponseFail,
  getGroupingTemplatesResponse,
  getGroupingTemplatesResponseFail,
  getMediaRequest,
  getTicketsConfigReponse,
  getTicketsConfigResponseFail,
  getTicketsResponseFail,
  getTransactionsResponse,
  saveTableCell,
  saveTableCellGrouping,
  setCurrentMedia,
  setDataPagination,
  setDataPaginationRequest,
  setGroupTemplates,
  setMediaErrorMessage,
  setMediaRequest,
  setSdFilterTemplatesResponse,
  setTransactionsPage,
  setTransactionsRowsPerPage,
  updateSdFilterTemplateResponse,
} from './actions'

const WINDOW_TYPE = 'TECHNICAL_EVENTS'

function* getPagination(data: GetTicketsBody, typeOfTickets: string): SagaIterator {
  try {
    const responsePagination = yield call(NewCommonApi.getPagination, data as GetTicketsBody)
    yield put(setDataPagination(responsePagination))
  } catch (e) {
    console.log('e: ', e)
  }
}

function* getPaginationGroupDetails(typeOfTickets: any, data: any): SagaIterator {
  try {
    const responsePagination = yield call(NewCommonApi.getGroupedDetailsPagination, data)
    yield put(setTransactionsPage(responsePagination.outputPageNo))
  } catch (e) {
    console.log('e: ', e)
  }
}

function* getTickets({ payload: { isInitRequest } }: AnyAction): SagaIterator {
  try {
    const accountState: Account | null = yield select(state => state.auth.currentAccount)
    const ticketsState: State = yield select(state => state.technicalEvents)

    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { formUID, getOutTreeURL, filter, groups },
      },
      rowsPerPage: { [typeOfTickets as TypeOfTickets]: rowsPerPage },
      page: { [typeOfTickets as TypeOfTickets]: page },
      grouping: {
        [typeOfTickets as TypeOfTickets]: { selectedGroupings },
      },
      sorting: { [typeOfTickets as TypeOfTickets]: sorting },
      filterFields: { [typeOfTickets as TypeOfTickets]: filterTemplateFields },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters, selectedTemplate },
      },
    } = ticketsState

    const CONFIG_LS_KEY = 'config'

    let savedSettings: TableSettingsData | null = null
    let requestBody
    if (accountState) {
      savedSettings = getLocalStorageItem(LocalStorageItems.TechnicalEvents)
    }
    if (isInitRequest && savedSettings && savedSettings[CONFIG_LS_KEY]) {
      const { requestBodyData, sorting, filterTemplateFields, page, rowsPerPage } = savedSettings[
        CONFIG_LS_KEY
      ]
      const { getOutTreeURL, groups, ...other } = requestBodyData
      requestBody = other

      requestBody.formUID = WINDOW_TYPE
      if (groups.length > 0) requestBody.groups = groups

      yield put(changeSorting(sorting))
      yield put(collectFilterTemplateFields(filterTemplateFields))
      yield put(setTransactionsRowsPerPage(rowsPerPage))
      yield put(setTransactionsPage(page))
    } else {
      requestBody = {
        formUID: WINDOW_TYPE,
        getOutTreeURL,
        groups: gettingDevices.getGroups(selectedGroupings, groups),
        pageNo: page,
        pageSize: rowsPerPage,
        sortDetails: gettingDevices.getSortDetails(sorting),
      }
      if (accountState) {
        setLocalStorageItem(LocalStorageItems.TechnicalEvents, {
          [CONFIG_LS_KEY]: {
            requestBodyData: requestBody,
            selectedFilters,
            sorting,
            page,
            rowsPerPage,
            filterTemplateFields,
            selectedFiltersTemplate: selectedTemplate,
          },
        })
      }
    }

    requestBody.filter = gettingDevices.getFilters(selectedFilters, filter)

    if (!requestBody.groups || requestBody.groups.length <= 0) {
      yield put(setDataPaginationRequest())
      yield fork(getPagination, requestBody, typeOfTickets)
    }
    const response = yield call(NewCommonApi.getData, requestBody as GetTicketsBody)
    yield put(getTransactionsResponse(response))
  } catch (error) {
    yield put(getTicketsResponseFail(error.message))
  }
}

function* getExport({ payload }: AnyAction): SagaIterator {
  try {
    const ticketsState: State = yield select(state => state.technicalEvents)
    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { formUID, getOutTreeURL, filter },
      },
      sorting: { [typeOfTickets as TypeOfTickets]: sorting },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters },
      },
    } = ticketsState

    const requestBody = {
      filter: gettingDevices.getFilters(selectedFilters, filter),
      formUID: formUID,
      getOutTreeURL,
      outputFields: payload.outputFields,
      format: payload.format,
      sortDetails: gettingDevices.getSortDetails(sorting),
    }

    const response = yield call(NewCommonApi.getExportData, requestBody as GetExportBody, true)

    yield put(getExportResponse(response))
  } catch (error) {
    yield put(getExportResponseFail(error.message))
  }
}

function* getExportGrouped({ payload }: AnyAction): SagaIterator {
  try {
    const ticketsState: State = yield select(state => state.technicalEvents)
    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { formUID, getOutTreeURL, filter, groups },
      },
      grouping: {
        [typeOfTickets as TypeOfTickets]: { selectedGroupings },
      },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters },
      },
    } = ticketsState

    const requestBody = {
      filter: gettingDevices.getFilters(selectedFilters, filter),
      formUID: formUID,
      getOutTreeURL,
      outputFields: payload.outputFields,
      format: payload.format,
      groupBy: gettingDevices.getGroups(selectedGroupings, groups),
    }

    const response = yield call(
      NewCommonApi.getExportGroupedData,
      requestBody as GetExportGroupedBody,
      true,
    )

    yield put(getExportGroupedResponse(response))
  } catch (error) {
    yield put(getExportGroupedResponseFail(error.message))
  }
}

function* getGroupDetails(): SagaIterator {
  try {
    const ticketsState: State = yield select(state => state.technicalEvents)
    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { filter },
      },
      page: { [typeOfTickets as TypeOfTickets]: page },
      parentGroups,
      sorting: { [typeOfTickets as TypeOfTickets]: sorting },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters },
      },
      pageSizeForGroupingTickets,
    } = ticketsState
    const requestBody: GetGroupDetailsBody = {
      filter: gettingDevices.getFilters(selectedFilters, filter),
      formUID: WINDOW_TYPE,
      getOutDetailsURL: 'tickets|/search-form/client/srgroup-details/open/all',
      parentGroups: parentGroups,
      pageNo: page,
      pageSize: Number(pageSizeForGroupingTickets) || 10,
      sortDetails: gettingDevices.getSortDetails(sorting),
    }

    yield fork(getPaginationGroupDetails, typeOfTickets as string, requestBody)
    const response = yield call(NewCommonApi.getGroupedDetails, requestBody)
    if (!response.details) {
      yield put(setTransactionsPage(page - 1))
    }
    yield put(getGroupDetailsResponse(response))
  } catch (error) {
    yield put(getGroupDetailsResponseFail(error.message))
  }
}

function* getSdFilterTemplates(): Generator {
  try {
    const response: any = yield call(NewCommonApi.getFilterTemplates, WINDOW_TYPE)
    yield put(setSdFilterTemplatesResponse(response.availableTemplates))
  } catch (error) {
    throw new Error(error)
  }
}

function* createFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const { filters, name } = payload
    const response: any = yield call(NewCommonApi.createFilterTemplate, {
      filters,
      name,
      windowType: WINDOW_TYPE,
    })
    const newTemplateId = response.availableTemplates.find(
      (template: { id: string; name: string }) => template.name === payload.name,
    )
    yield put(
      createSdFilterTemplateResponse({
        name: payload.name,
        id: newTemplateId.id,
      }),
    )
    PopUpService.showGlobalPopUp(`${payload.translate('translate#title.templateSaved')}`)
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('title.templateCreateError')}.\n${error}`,
      'error',
    )
  }
}

function* updateSdFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const response = yield call(NewCommonApi.updateFilterTemplate, {
      filters: payload.filters,
      name: payload.name,
      windowType: WINDOW_TYPE,
      id: payload.id,
    })

    if (response) {
      yield put(updateSdFilterTemplateResponse(payload))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateEdited')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateEditError')}.\n${error}`,
      'error',
    )
  }
}

function* deleteFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.deleteFilterTemplate, {
      id: payload.id,
      windowType: WINDOW_TYPE,
    })

    if (response.status === 200) {
      yield put(deleteSdFilterTemplateResponse(payload))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateRemoved')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateDeletionError')}.\n${error}`,
      'error',
    )
  }
}

function* getGroupingTemplates(): Generator {
  try {
    const response = yield call(TicketsApi.getGroupingTemplates)

    yield put(getGroupingTemplatesResponse(response))
  } catch (error) {
    yield put(getGroupingTemplatesResponseFail(error.message))
  }
}

function* getSdGroupTemplatesRequest({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.getGroupTemplates, WINDOW_TYPE)

    const templateViewUpdated = response.availableTemplates.map((temp: any) => ({
      ...temp,
      config: [],
    }))
    yield put(setGroupTemplates(templateViewUpdated))
  } catch (e) {
    // call error
  }
}

function* saveSdGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const { groupingLevels, name } = payload.template
    const response: any = yield call(NewCommonApi.createGroupTemplate, {
      groupingLevels,
      name,
      windowType: WINDOW_TYPE,
    })

    PopUpService.showGlobalPopUp(payload.translate(`translate#title.templateSaved`), 'success')
    const newTemplateId =
      response.availableTemplates.find((temp: any) => temp.name === payload.template.name).id || ''
    yield put(
      setGroupTemplates(
        [
          {
            id: newTemplateId,
            name: payload.template.name,
            config: payload.template.config,
          },
        ],
        true,
      ),
    )
    StrategyLocalStorage.setData(`@LocalStorage/${WINDOW_TYPE}/groupingTemplate`, newTemplateId)
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate(`translate#title.templateCreateError`)} ${error}`,
      'error',
    )
  }
}

function* editSdGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.editGroupTemplate, {
      template: payload.template,
      windowType: WINDOW_TYPE,
    })

    if (response.availableTemplates) {
      PopUpService.showGlobalPopUp(payload.translate(`translate#title.templateEdited`), 'success')
      yield put(editGroupTemplateFromResponse(payload.template))
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateEditError')} ${error}`,
      'error',
    )
  }
}

function* deleteSdGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.deleteGroupTemplate, {
      id: payload.template.id,
      windowType: WINDOW_TYPE,
    })

    if (response === '') {
      yield put(deleteSdGroupTemplateResponseSuccess(payload.template))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateRemoved')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateDeletionError')} ${error}`,
      'error',
    )
  }
}

function* getDevicesConfig(): SagaIterator {
  try {
    const typeOfTickets: TypeOfTickets = yield select(state => state.technicalEvents.typeOfTickets)

    const response = yield call(NewCommonApi.getFormConfig, {
      formUID: WINDOW_TYPE,
      getConfigURL: '/search-form/config',
    })

    yield put(getTicketsConfigReponse(typeOfTickets, response))
  } catch (error) {
    yield put(getTicketsConfigResponseFail(error.message))
  }
}

function* getMedia({ payload: { eventId, eventType, requestFileType } }: AnyAction): Generator {
  try {
    const media: any = yield select(state => state.technicalEvents)
    if (media.media.data[eventId] && !media.media.data[eventId].image.errorMessage) {
      yield put(setCurrentMedia({ eventId }))
      return
    }

    const response: any = yield call(NewCommonApi.getMedia, {
      eventId,
      eventType: requestFileType || 'TECHNICAL_EVENT',
    })

    yield put(
      setMediaRequest({
        eventId,
        image: response.url,
        type: eventType,
        fetchingEnabled: false,
      }),
    )
  } catch (e) {
    yield put(
      setMediaErrorMessage({
        eventId,
        message: MEDIA_MESSAGES.includes(e.errorMessage)
          ? e.errorMessage
          : 'atmeye.message.cantDownloadFileFromAtm',
      }),
    )
  }
}

function* editTableCell({
  payload: { fieldId, fieldValue, rowId, getUpdateTableRequest },
}: AnyAction): Generator {
  try {
    yield call(NewCommonApi.editNotes, {
      fieldId,
      fieldValue,
      formType: WINDOW_TYPE,
      rowId,
    })
  } catch (e) {
    console.log('e: ', e)
  } finally {
    getUpdateTableRequest && getUpdateTableRequest()
  }
}
function* editTableCellGrouping({
  payload: { fieldId, fieldValue, rowId, rowIndex, parentIndex, nesting },
}: AnyAction): Generator {
  try {
    yield call(NewCommonApi.editNotes, {
      fieldId,
      fieldValue,
      formType: WINDOW_TYPE,
      rowId,
    })

    yield put(saveTableCellGrouping({ fieldValue, rowIndex, parentIndex, fieldId, rowId, nesting }))
  } catch (e) {
    console.log('e: ', e)
  }
}
export default function*(): Generator {
  yield takeLatest(TechnicalEventsActions.GetTransactionsRequest, getTickets)
  yield takeLatest(TechnicalEventsActions.GetTechnicalEventsExport, getExport)
  yield takeLatest(TechnicalEventsActions.GetTechnicalEventsExportGrouped, getExportGrouped)
  yield takeLatest(TechnicalEventsActions.GetGroupDetailsRequest, getGroupDetails)
  yield takeLatest(TechnicalEventsActions.UpdateFilterTemplateRequest, updateSdFilterTemplate)
  yield takeLatest(TechnicalEventsActions.DeleteFilterTemplateRequest, deleteFilterTemplate)
  yield takeLatest(TechnicalEventsActions.DeleteGroupTemplateRequest, deleteSdGroupTemplate)
  yield takeLatest(TechnicalEventsActions.GetFiltersTemplatesRequest, getSdFilterTemplates)
  yield takeLatest(TechnicalEventsActions.CreateFilterTemplateRequest, createFilterTemplate)
  yield takeLatest(TicketActions.GetGroupingTemplatesRequest, getGroupingTemplates)
  yield takeLatest(TechnicalEventsActions.GetGroupingTemplatesRequest, getSdGroupTemplatesRequest)
  yield takeLatest(TechnicalEventsActions.SaveGroupingTemplateRequest, saveSdGroupTemplate)
  yield takeLatest(TechnicalEventsActions.EditGroupTemplateRequest, editSdGroupTemplate)
  yield takeLatest(TechnicalEventsActions.GetTransactionsConfigRequest, getDevicesConfig)
  yield takeEvery(TechnicalEventsActions.EditTableCellRequest, editTableCell)
  yield takeEvery(TechnicalEventsActions.EditTableCellGrouping, editTableCellGrouping)
  yield takeLatest(TechnicalEventsActions.GetMedia, function*(...args) {
    yield race([call(getMedia, ...args), take(TechnicalEventsActions.ClearCurrentMedia)])
  })
}
