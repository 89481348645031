import { AnyAction } from 'redux'

import { MonVaultType } from '@/api/vault-v1/monitoringApi/types'
import {
  ComVaultReport,
  eMonRemainderType,
  GlobalFilter,
  MonCashLimit,
  MonCashLimitBase,
  MonCashLimitsResponse,
  MonChartsFilter,
  MonEntryDetails,
  MonOperationsFilter,
  MonRemaindersResponse,
  MonUnloadCashierToVaultRequest,
  MonValuableRemainder,
  MonVault,
  MonVaultCurrencyEntry,
  MonVaultDetails,
  MonVaultsResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { EntryData } from '@/components/blocks/vault-v1/CurrencyDetailsTable/types'
import { VaultV1Actions } from '@/constants/actions'
import { MonVaultEntryType } from '@/constants/vault-v1'
import { KeyPressActionName, Paging, Sort } from '@/types/cm'

export const getVaultsRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetVaultsRequest,
})

export const getVaultsResponse = (payload: MonVaultsResponse): AnyAction => ({
  type: VaultV1Actions.MonGetVaultsResponse,
  payload,
})

export const getVaultsFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.MonGetVaultsFail,
  payload: error,
})

export const getVaultDetailsRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetVaultDetailsRequest,
})

export const getVaultDetailsResponse = (payload: MonEntryDetails[]): AnyAction => ({
  type: VaultV1Actions.MonGetVaultDetailsResponse,
  payload,
})

export const getVaultDetailsFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.MonGetVaultDetailsFail,
  payload: error,
})

export const getSingleVaultRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetSingleVaultRequest,
})

export const getSingleVaultResponse = (payload: MonVault): AnyAction => ({
  type: VaultV1Actions.MonGetSingleVaultResponse,
  payload,
})

export const getSingleVaultFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.MonGetSingleVaultFail,
  payload: error,
})

export const refreshMonVaults = (): AnyAction => ({
  type: VaultV1Actions.MonRefreshVaults,
})

export const setMonSelectedVault = (payload: MonVaultCurrencyEntry | null): AnyAction => ({
  type: VaultV1Actions.MonSetSelectedVault,
  payload,
})

export const sortMonVaultsTable = (payload: Sort): AnyAction => ({
  type: VaultV1Actions.MonSortVaultsTable,
  payload,
})

export const setMonVaultsPaging = (payload: Paging): AnyAction => ({
  type: VaultV1Actions.MonSetVaultsPaging,
  payload,
})

export const openMonColumnsSettingsModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.MonOpenColumnsSettingsModal,
  payload: isOpen,
})

export const openMonDocumentsModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.MonOpenDocumentsModal,
  payload: isOpen,
})

export const openMonFilterModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.MonOpenFilterModal,
  payload: isOpen,
})

export const submitMonFilter = (payload: {
  globalFilter: GlobalFilter
  filter: { vaults: string }
}): AnyAction => ({
  type: VaultV1Actions.MonSubmitFilter,
  payload,
})

// Details -----------------------------
export const setMonDetailsSelectedTab = (payload: string): AnyAction => ({
  type: VaultV1Actions.MonSetDetailsSelectedTab,
  payload: payload,
})

export const getMonCartsRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetChartsRequest,
})
export const getMonCartsResponse = (payload: MonVaultDetails): AnyAction => ({
  type: VaultV1Actions.MonGetChartsResponse,
  payload,
})
export const getMonCartsFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.MonGetChartsFail,
  payload: error,
})

export const setMonDetailsFilterOpen = (payload: boolean): AnyAction => ({
  type: VaultV1Actions.MonSetDetailsFilterOpen,
  payload: payload,
})

export const setMonDetailsFilter = (payload: Omit<MonChartsFilter, 'vaultId'>): AnyAction => ({
  type: VaultV1Actions.MonSetDetailsFilter,
  payload: payload,
})

// Reports -----------------------------

export const getMonReportsRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetReportsRequest,
})
export const getMonReportsResponse = (payload: ComVaultReport[]): AnyAction => ({
  type: VaultV1Actions.MonGetReportsResponse,
  payload,
})
export const getMonReportsFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetReportsFail,
  payload: message,
})

export const getMonReportRequest = (payload?: ComVaultReport): AnyAction => ({
  type: VaultV1Actions.MonGetReportRequest,
  payload,
})
export const getMonReportResponse = (payload: Blob | null): AnyAction => ({
  type: VaultV1Actions.MonGetReportResponse,
  payload,
})
export const getMonReportFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetReportFail,
  payload: message,
})

// Actions Mon Modals
export const setIsCurrentRemaindersModalOpen = (payload: eMonRemainderType | ''): AnyAction => ({
  type: VaultV1Actions.MonOpenCurrentRemaindersModal,
  payload,
})
export const setIsCurrentValuablesRemaindersModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.MonOpenCurrentValuablesRemaindersModal,
  payload: isOpen,
})
export const setIsStartDayModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.MonOpenStartDayModal,
  payload: isOpen,
})
export const setIsWorkingTimeEndModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.MonOpenWorkingTimeEndModal,
  payload: isOpen,
})
export const setIsWornOutCashModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.MonOpenWornOutCashModal,
  payload: isOpen,
})
export const setIsCloseNightDepositoryModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.MonOpenCloseNightDepositoryModal,
  payload: isOpen,
})
export const setIsCashLimitsModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.MonOpenCashLimitsModal,
  payload: isOpen,
})

export const setMonVaultOperationDetailsFilter = (payload: MonOperationsFilter): AnyAction => ({
  type: VaultV1Actions.MonSetVaultOperationDetailsFilter,
  payload: payload,
})

// Cashiers ----------------------------------------------------------------------
export const setMonCashierSelectedTab = (payload: MonVaultType): AnyAction => ({
  type: VaultV1Actions.MonSetCashierSelectedTab,
  payload,
})

export const getMonCashiersRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetCashiersRequest,
})
export const getMonCashiersResponse = (payload: MonVault[]): AnyAction => ({
  type: VaultV1Actions.MonGetCashiersResponse,
  payload,
})
export const getMonCashiersFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetCashiersFail,
  payload: message,
})

export const setMonCashiersFilter = (payload: { name?: string }): AnyAction => ({
  type: VaultV1Actions.MonSetCashiersFilter,
  payload,
})

export const setMonSelectedCashier = (payload: MonVaultCurrencyEntry | null): AnyAction => ({
  type: VaultV1Actions.MonSetSelectedCashier,
  payload,
})

// Cashier Details
export const getMonCashierDetailsRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetCashierDetailsRequest,
})
export const getMonCashierDetailsResponse = (payload: MonEntryDetails[]): AnyAction => ({
  type: VaultV1Actions.MonGetCashierDetailsResponse,
  payload,
})
export const getMonCashierDetailsFail = (payload: string | unknown): AnyAction => ({
  type: VaultV1Actions.MonGetCashierDetailsFail,
  payload,
})

export const setMonCashierDetailsTab = (payload: MonVaultEntryType): AnyAction => ({
  type: VaultV1Actions.MonSetCashierDetailsTab,
  payload,
})

export const setMonOperationDetailsFilter = (payload: MonOperationsFilter): AnyAction => ({
  type: VaultV1Actions.MonSetOperationDetailsFilter,
  payload: payload,
})

// [START] Current Remainders ----------------------------------------------------------------
export const getMonCurrentRemaindersRequest = (remainderType: eMonRemainderType): AnyAction => ({
  type: VaultV1Actions.MonGetCurrentRemaindersRequest,
  payload: remainderType,
})
export const getMonCurrentRemaindersResponse = (payload: MonRemaindersResponse): AnyAction => ({
  type: VaultV1Actions.MonGetCurrentRemaindersResponse,
  payload,
})
export const getMonCurrentRemaindersFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetCurrentRemaindersFail,
  payload: message,
})

type UpdateCurrentRemainder = {
  currency?: string
  denominations: EntryData[]
  total?: number
}

export const updateCurrentRemainder = (payload: UpdateCurrentRemainder): AnyAction => ({
  type: VaultV1Actions.MonUpdateCurrentRemainder,
  payload,
})

export const setMonCurrentRemaindersRequest = (remainderType: eMonRemainderType): AnyAction => ({
  type: VaultV1Actions.MonSetCurrentRemaindersRequest,
  payload: remainderType,
})
export const setMonCurrentRemaindersResponse = (): AnyAction => ({
  type: VaultV1Actions.MonSetCurrentRemaindersResponse,
})
export const setMonCurrentRemaindersFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonSetCurrentRemaindersFail,
  payload: message,
})

// Current Remainders -------Valuables---------------------------------------------------------
export const getMonCurrentValuablesRemaindersRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetCurrentValuablesRemaindersRequest,
})
export const getMonCurrentValuablesRemaindersResponse = (
  payload: MonValuableRemainder[],
): AnyAction => ({
  type: VaultV1Actions.MonGetCurrentValuablesRemaindersResponse,
  payload,
})
export const getMonCurrentValuablesRemaindersFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetCurrentValuablesRemaindersFail,
  payload: message,
})

export const setMonCurrentValuablesRemaindersRequest = (): AnyAction => ({
  type: VaultV1Actions.MonSetCurrentValuablesRemaindersRequest,
})
export const setMonCurrentValuablesRemaindersResponse = (): AnyAction => ({
  type: VaultV1Actions.MonSetCurrentValuablesRemaindersResponse,
})
export const setMonCurrentValuablesRemaindersFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonSetCurrentValuablesRemaindersFail,
  payload: message,
})

export const updateMonCurrentValuablesRemainders = (
  payload: MonValuableRemainder[],
): AnyAction => ({
  type: VaultV1Actions.MonUpdateCurrentValuablesRemainders,
  payload,
})
// [END] Current Remainders ----------------------------------------------------------------

// [START] Start Day ----------------------------------------------------------------
export const getMonStartDayRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetStartDayRequest,
})
export const getMonStartDayResponse = (payload: MonRemaindersResponse): AnyAction => ({
  type: VaultV1Actions.MonGetStartDayResponse,
  payload,
})
export const getMonStartDayFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetStartDayFail,
  payload: message,
})

export const setMonStartDayRequest = (): AnyAction => ({
  type: VaultV1Actions.MonSetStartDayRequest,
})
export const setMonStartDayResponse = (payload: MonRemaindersResponse): AnyAction => ({
  type: VaultV1Actions.MonSetStartDayResponse,
  payload,
})
export const setMonStartDayFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonSetStartDayFail,
  payload: message,
})
// [END] Start Day ----------------------------------------------------------------

// [START] Working Time End ----------------------------------------------------------------
export const getMonWorkingTimeEndRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetWorkingTimeEndRequest,
})
export const getMonWorkingTimeEndResponse = (payload: MonRemaindersResponse): AnyAction => ({
  type: VaultV1Actions.MonGetWorkingTimeEndResponse,
  payload,
})
export const getMonWorkingTimeEndFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetWorkingTimeEndFail,
  payload: message,
})

export const setMonWorkingTimeEndRequest = (): AnyAction => ({
  type: VaultV1Actions.MonSetWorkingTimeEndRequest,
})
export const setMonWorkingTimeEndResponse = (): AnyAction => ({
  type: VaultV1Actions.MonSetWorkingTimeEndResponse,
})
export const setMonWorkingTimeEndFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonSetWorkingTimeEndFail,
  payload: message,
})
// [END] Working Time End ----------------------------------------------------------------

// [START] Worn Out Cash ----------------------------------------------------------------
export const getMonWornOutCashRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetWornOutCashRequest,
})
export const getMonWornOutCashResponse = (payload: MonRemaindersResponse): AnyAction => ({
  type: VaultV1Actions.MonGetWornOutCashResponse,
  payload,
})
export const getMonWornOutCashFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetWornOutCashFail,
  payload: message,
})

export const setMonWornOutCashRequest = (payload: MonRemaindersResponse): AnyAction => ({
  type: VaultV1Actions.MonSetWornOutCashRequest,
  payload,
})
export const setMonWornOutCashResponse = (): AnyAction => ({
  type: VaultV1Actions.MonSetWornOutCashResponse,
})
export const setMonWornOutCashFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonSetWornOutCashFail,
  payload: message,
})
// [END] Worn Out Cash ----------------------------------------------------------------

// [START] Close Night Depository ----------------------------------------------------------------
export const getMonCloseNightDepositoryRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetCloseNightDepositoryRequest,
})
export const getMonCloseNightDepositoryResponse = (payload: MonRemaindersResponse): AnyAction => ({
  type: VaultV1Actions.MonGetCloseNightDepositoryResponse,
  payload,
})
export const getMonCloseNightDepositoryFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetCloseNightDepositoryFail,
  payload: message,
})

export const setMonCloseNightDepositoryRequest = (): AnyAction => ({
  type: VaultV1Actions.MonSetCloseNightDepositoryRequest,
})
export const setMonCloseNightDepositoryResponse = (): AnyAction => ({
  type: VaultV1Actions.MonSetCloseNightDepositoryResponse,
})
export const setMonCloseNightDepositoryFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonSetCloseNightDepositoryFail,
  payload: message,
})
// [END] Close Night Depository ----------------------------------------------------------------

// [START] Cash Limits ----------------------------------------------------------------
export const getMonCashLimitsRequest = (): AnyAction => ({
  type: VaultV1Actions.MonGetCashLimitsRequest,
})
export const getMonCashLimitsResponse = (payload: MonCashLimitsResponse): AnyAction => ({
  type: VaultV1Actions.MonGetCashLimitsResponse,
  payload,
})
export const getMonCashLimitsFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonGetCashLimitsFail,
  payload: message,
})

export const setSelectedCashLimit = (limit: MonCashLimit): AnyAction => ({
  type: VaultV1Actions.MonSetSelectedCashLimit,
  payload: limit,
})
export const setAddEditModalOpen = (modal: 'add' | 'edit' | null): AnyAction => ({
  type: VaultV1Actions.MonSetAddEditModalOpen,
  payload: modal,
})

// add
export const addMonCashLimitsRequest = (payload: MonCashLimitBase): AnyAction => ({
  type: VaultV1Actions.MonAddCashLimitsRequest,
  payload,
})
export const addMonCashLimitsResponse = (): AnyAction => ({
  type: VaultV1Actions.MonAddCashLimitsResponse,
})
export const addMonCashLimitsFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonAddCashLimitsFail,
  payload: message,
})

// update
export const editMonCashLimitsRequest = (payload: MonCashLimit): AnyAction => ({
  type: VaultV1Actions.MonEditCashLimitsRequest,
  payload,
})
export const editMonCashLimitsResponse = (): AnyAction => ({
  type: VaultV1Actions.MonEditCashLimitsResponse,
})
export const editMonCashLimitsFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonEditCashLimitsFail,
  payload: message,
})
// [END] Cash Limits ----------------------------------------------------------------

export const updateMonAbsRemaindersRequest = (): AnyAction => ({
  type: VaultV1Actions.MonUpdateAbsRemaindersRequest,
})
export const updateMonAbsRemaindersResponse = (): AnyAction => ({
  type: VaultV1Actions.MonUpdateAbsRemaindersResponse,
})
export const updateMonAbsRemaindersFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonUpdateAbsRemaindersFail,
  payload: message,
})

export const handleMonKeyPress = (actionName: KeyPressActionName): AnyAction => ({
  type: VaultV1Actions.MonHandleKeyPress,
  payload: actionName,
})

// Revert Balance
export const revertMonBalanceRequest = (payload: MonUnloadCashierToVaultRequest): AnyAction => ({
  type: VaultV1Actions.MonRevertBalanceRequest,
  payload,
})
export const revertMonBalanceResponse = (): AnyAction => ({
  type: VaultV1Actions.MonRevertBalanceResponse,
})
export const revertMonBalanceFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.MonRevertBalanceFail,
  payload: message,
})
