import { DeviceStatus } from '@/api/atmeye/devicesApi/types'
import { GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import { ApiGroupTemplate, ResponseError } from '@/types'
import { requestService } from '@/utils/services/request'

export const deepClone = (obj: any) => {
  if (obj === null) return null
  const clone = Object.assign({}, obj)
  Object.keys(clone).forEach(key => {
    clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]
    if (key === 'childGroups' && !Array.isArray(clone[key])) {
      clone[key] = []
    }
  })
  if (Array.isArray(obj)) {
    clone.length = obj.length
    return Array.from(clone)
  }
  return clone
}

export class DevicesApi {
  public static getSdFilterTemplateById = async (
    id: string,
    windowType: string,
  ): Promise<GetTicketsBodyFilterItem[] | ResponseError> =>
    await requestService.get(`/filter-templates/${id}/filters?windowType=${windowType}`)

  public static async getGroupTemplateConfigById(
    id: string,
    windowType: string,
  ): Promise<{ groupingLevels: ApiGroupTemplate[] }> {
    try {
      return await requestService.get(`/grouping-templates/${id}/grouping-levels`, {
        windowType,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getActionsState(deviceId: number): Promise<DeviceStatus | undefined> {
    try {
      return await requestService.get('/actions/state', {
        deviceId,
      })
    } catch (e) {
      console.log(e)
    }
  }

  public static async getHealthInfo(deviceName: string): Promise<any> {
    try {
      return await requestService.get(`/devices/health-info?deviceName=${deviceName}`)
    } catch (e) {
      console.log(e)
      // throw new Error(e)
    }
  }
}
