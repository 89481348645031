import { FormikValues } from 'formik'
import { AnyAction } from 'redux'

import { GetGroupDetailsResponse, GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import { TableFilter } from '@/components/controls/AppTable/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { AtmeyeAlarmsActions, TicketActions } from '@/constants/index'
import {
  ApiGroupTemplate,
  DevicesConfigResponse,
  ParentGroup,
  ResponseError,
  TypeOfTickets,
  UIGroupTemplate,
} from '@/types'
import { GetTicketsResponse } from '@/types/sd/common'
import { WindowType } from '@/types/sd/tickets/ticket'

export const setSelectedGroupingTemplate = (template: string): AnyAction => ({
  type: AtmeyeAlarmsActions.SetSelectedGroupingTemplate,
  payload: template,
})

export const getTransactionsRequest = (isInitRequest = false): AnyAction => ({
  type: AtmeyeAlarmsActions.GetTransactionsRequest,
  payload: { isInitRequest },
})

export const getTransactionsResponse = (tickets: GetTicketsResponse): AnyAction => ({
  type: AtmeyeAlarmsActions.GetTransactionsResponse,
  payload: tickets,
})

export const getTicketsResponseFail = (error: ResponseError): AnyAction => ({
  type: AtmeyeAlarmsActions.GetTicketResponseFail,
  payload: { error },
})

export const getGroupDetailsRequest = (): AnyAction => ({
  type: AtmeyeAlarmsActions.GetGroupDetailsRequest,
})

export const getGroupDetailsResponse = (details: GetGroupDetailsResponse): AnyAction => ({
  type: AtmeyeAlarmsActions.GetGroupDetailsResponse,
  payload: details,
})

export const setGroupDetailsResponse = (object: {}): AnyAction => ({
  type: AtmeyeAlarmsActions.SetGroupDetailsResponse,
  payload: object,
})

export const setGroupDetailsResponseEmpty = (object: {}): AnyAction => ({
  type: AtmeyeAlarmsActions.SetGroupDetailsResponseEmpty,
  payload: object,
})

export const getGroupDetailsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetGroupDetailsResponseFail,
  payload: { error },
})

export const setParentGroups = (parentGroups: ParentGroup[]): AnyAction => ({
  type: AtmeyeAlarmsActions.SetParentGroups,
  payload: parentGroups,
})

export const changeSelectedFilters = (filters: FormikValues): AnyAction => ({
  type: AtmeyeAlarmsActions.ChangeSelectedFilters,
  payload: filters,
})

export const getTransactionsFilterTemplatesRequest = (): AnyAction => ({
  type: AtmeyeAlarmsActions.GetFiltersTemplatesRequest,
})

export const setSdFilterTemplatesResponse = (
  payload: { id: string; name: string }[],
): AnyAction => ({
  type: AtmeyeAlarmsActions.SetSdFilterTemplateResponse,
  payload,
})

export const createSdFilterTemplateRequest = (payload: {
  filters: GetTicketsBodyFilterItem[]
  name: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeAlarmsActions.CreateFilterTemplateRequest,
  payload,
})

export const createSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: AtmeyeAlarmsActions.CreateSdFilterTemplateResponse,
  payload,
})

export const updateSdFilterTemplateRequest = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: AtmeyeAlarmsActions.UpdateFilterTemplateRequest,
  payload,
})

export const collectFilterTemplateFields = (filterTemplateFields: {
  fieldName: string
  value: { id: string; displayName: string }
}): AnyAction => ({
  type: AtmeyeAlarmsActions.CollectFilterTemplateFields,
  payload: filterTemplateFields,
})

export const updateSdFilterTemplateResponse = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: AtmeyeAlarmsActions.UpdateSdFilterTemplateResponse,
  payload,
})

export const deleteSdFilterTemplateRequest = (payload: {
  id: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeAlarmsActions.DeleteFilterTemplateRequest,
  payload,
})

export const deleteSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: AtmeyeAlarmsActions.DeleteFilterTemplateResponse,
  payload,
})

export const getAlarmsExportRequest = (outputFields: any, ext: string): AnyAction => ({
  type: AtmeyeAlarmsActions.GetAlarmsExport,
  payload: {
    outputFields: outputFields,
    format: ext,
  },
})

export const getExportResponse = (file: any): AnyAction => ({
  type: AtmeyeAlarmsActions.GetExportResponse,
  payload: file,
})

export const getExportResponseFail = (error: ResponseError): AnyAction => ({
  type: AtmeyeAlarmsActions.GetExportResponseFail,
  payload: { error },
})

export const getAlarmsExportGroupedRequest = (outputFields: any, ext: string): AnyAction => ({
  type: AtmeyeAlarmsActions.GetAlarmsExportGrouped,
  payload: {
    outputFields: outputFields,
    format: ext,
  },
})

export const getExportGroupedResponse = (file: any): AnyAction => ({
  type: AtmeyeAlarmsActions.GetExportGroupedResponse,
  payload: file,
})

export const getExportGroupedResponseFail = (error: ResponseError): AnyAction => ({
  type: AtmeyeAlarmsActions.GetExportGroupedResponseFail,
  payload: { error },
})

export const getSdGroupTemplatesRequest = (payload: string): AnyAction => ({
  type: AtmeyeAlarmsActions.GetGroupingTemplatesRequest,
  payload,
})

// @todo replace any to GroupingTemplate[]
export const getGroupingTemplatesResponse = (templates: any): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesResponse,
  payload: templates,
})

export const getGroupingTemplatesResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesResponseFail,
  payload: error,
})

export const editSdGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeAlarmsActions.EditGroupTemplateRequest,
  payload,
})

export const editGroupTemplateFromResponse = (payload: UIGroupTemplate): AnyAction => ({
  type: AtmeyeAlarmsActions.EditSdGroupTemplateFromResponse,
  payload,
})

export const deleteSdGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeAlarmsActions.DeleteGroupTemplateRequest,
  payload,
})

export const deleteSdGroupTemplateResponseSuccess = (payload: {
  template: UIGroupTemplate
}): AnyAction => ({
  type: TicketActions.DeleteSdGroupTemplateResponseSuccess,
  payload,
})

export const saveSdGroupTemplateRequest = (
  template: {
    windowType: WindowType
    name: string
    groupingLevels: ApiGroupTemplate[]
  },
  translate: any,
): AnyAction => ({
  type: AtmeyeAlarmsActions.SaveGroupingTemplateRequest,
  payload: { template, translate },
})

export const setGroupTemplates = (
  templates: UIGroupTemplate[],
  withAppendNewTemplate = false,
): AnyAction => ({
  type: AtmeyeAlarmsActions.SetGroupTemplates,
  payload: { templates, withAppendNewTemplate },
})

export const setCurrentFilterTemplate = (value: string): AnyAction => ({
  type: AtmeyeAlarmsActions.SetCurrentFilterTemplate,
  payload: value,
})

export const getTransactionsConfigRequest = (): AnyAction => ({
  type: AtmeyeAlarmsActions.GetTransactionsConfigRequest,
})

export const getTicketsConfigReponse = (
  typeOfTickets: TypeOfTickets,
  ticketsConfigData: DevicesConfigResponse,
): AnyAction => ({
  type: AtmeyeAlarmsActions.GetTicketsConfigResponse,
  payload: { typeOfTickets, config: ticketsConfigData.formConfig },
})

export const getTicketsConfigResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketsConfigResponseFail,
  payload: error,
})

export const changeSorting = (sorting: { [key: string]: 'asc' | 'desc' } | null): AnyAction => ({
  type: AtmeyeAlarmsActions.ChangeSorting,
  payload: sorting,
})

export const changeGrouping = (groups: TableGroup[]): AnyAction => ({
  type: AtmeyeAlarmsActions.ChangeGrouping,
  payload: groups,
})

export const setTransactionsRowsPerPage = (rowsCount: number): AnyAction => ({
  type: AtmeyeAlarmsActions.SetRowPerPage,
  payload: rowsCount,
})

export const setTransactionsPage = (page: number): AnyAction => ({
  type: AtmeyeAlarmsActions.SetPage,
  payload: page,
})

export const clearGroupedTicketDetailsData = (): AnyAction => ({
  type: AtmeyeAlarmsActions.ClearGroupedTicketDetailsData,
})

export const setStringFilter = (filter: TableFilter): AnyAction => ({
  type: TicketActions.SetStringFilter,
  payload: filter,
})

export const getMediaRequest = (data: any): AnyAction => ({
  type: AtmeyeAlarmsActions.GetMedia,
  payload: data,
})

export const setMediaRequest = (data: any): AnyAction => ({
  type: AtmeyeAlarmsActions.SetMedia,
  payload: data,
})

export const clearCurrentMediaRequest = (): AnyAction => ({
  type: AtmeyeAlarmsActions.ClearCurrentMedia,
})

export const setCurrentMedia = (data: any): AnyAction => ({
  type: AtmeyeAlarmsActions.SetCurrentMedia,
  payload: data,
})

export const setMediaErrorMessage = (data: any): AnyAction => ({
  type: AtmeyeAlarmsActions.SetMediaErrorMessage,
  payload: data,
})

export const editTableCellRequest = (data: any): AnyAction => ({
  type: AtmeyeAlarmsActions.EditTableCellRequest,
  payload: data,
})

export const editTableCellRequestGrouping = (data: any): AnyAction => ({
  type: AtmeyeAlarmsActions.EditTableCellGrouping,
  payload: data,
})

export const saveTableCell = (data: any): AnyAction => ({
  type: AtmeyeAlarmsActions.SaveTableCell,
  payload: data,
})
export const saveTableCellGrouping = (data: any): AnyAction => ({
  type: AtmeyeAlarmsActions.SaveTableCellGrouping,
  payload: data,
})

export const setDataPagination = (data: any): AnyAction => ({
  type: AtmeyeAlarmsActions.SetDataPagination,
  payload: data,
})

export const setDataPaginationRequest = (): AnyAction => ({
  type: AtmeyeAlarmsActions.SetDataPaginationRequest,
})

export const reactionToAlarms = ({
  eventId,
  getUpdateTableRequest,
  userName,
}: {
  eventId: string
  getUpdateTableRequest: any
  userName?: string
}): AnyAction => ({
  type: AtmeyeAlarmsActions.ReactionToAlarm,
  payload: { eventId, getUpdateTableRequest, userName },
})

export const updateTableRow = (rowData: any): AnyAction => ({
  type: AtmeyeAlarmsActions.UpdateTableRow,
  payload: rowData,
})

export const setFilterModalState = (isOpenFilterModal: boolean): AnyAction => ({
  type: AtmeyeAlarmsActions.SetFilterModalState,
  payload: isOpenFilterModal,
})

export const setIsSubmitFiltersButtonClicked = (): AnyAction => ({
  type: AtmeyeAlarmsActions.IsSubmitFiltersButtonClicked,
})
